import React, { useEffect, useState, useRef, Fragment, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { useSnackbar, withSnackbar } from 'notistack';
import { DropzoneDialog } from 'material-ui-dropzone';
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import Switch from "@material-ui/core/Switch";

import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { withStyles } from '@material-ui/core/styles';
import './index.css';
import { GET_ROLES, GET_BUSINESSTYPES, GET_REPORTINGTOOLS, CURRENCY, GET_AUTHORIZATION_ROLES, GET_SYSTEM_ROLES } from '../../../constants';
import { authActions, authSelectors } from '../../../state/modules/auth';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import LoadingLayout from "../../components/Common/LoadingLayout";
import Layout from '../../layouts/Default';
import { fromJS } from 'immutable';
import axios from '../../../config/axios';
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import LoadingContext from "../../../context/loading/LoadingContext";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { useTranslation } from 'react-i18next';
import UserManagement from './UserManagement'
function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const useStyles = makeStyles({
    root: {
        height: '40px !important',
        padding: '0px',
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        },
    }
});

const useNoBorderName = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23) !important",
            borderWidth: "1px"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        }
    }
});

const TabPanel = (props) => {
    const { children, valueTab, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={valueTab !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {valueTab === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

const Profile = () => {
    const classes = useStyles();
    const nameClasses = useNoBorderName();
    const [password, setPassword] = useState('');
    const [openDropzoneLogo, setOpenDropzoneLogo] = React.useState(false);
    const [openDropzoneProfilePicture, setOpenDropzoneProfilePicture] = React.useState(false);
    const [isLogoHovering, setIsLogoHovering] = React.useState(false);
    const [isProfilePictureHovering, setIsProfilePictureHovering] = React.useState(false);
    const [countries, setCountries] = useState(null);
    const { showLoading, hideLoading } = useContext(LoadingContext);
    const { t, i18n } = useTranslation();
    const ROLES = useMemo(() => GET_ROLES(t), [t]);
    const SYSTEM_ROLES = useMemo(() => GET_SYSTEM_ROLES(t), [t]);
    const BUSINESSTYPES = useMemo(() => GET_BUSINESSTYPES(t), [t]);
    const REPORTINGTOOLS = useMemo(() => GET_REPORTINGTOOLS(t), [t]);
    const AUTHORIZATION_ROLES = useMemo(() => GET_AUTHORIZATION_ROLES(t), [t]);
    const selector = useSelector((state) => authSelectors.user(state));

    const [user, setUserTracking] = useState(selector);
    const [userPic, setUserPic] = useState(null);
    const [orgLogo, setOrgLogo] = useState(null);
    const [defaultCharacter, setDefaultCharacter] = useState(null);
    const [comment, setComment] = useState(null);
    const [smartListForeignKey, setSmartListForeignKey] = useState(null);
    const [autoSendEmail, setAutoSendEmail] = useState(null);
    const [isLogoNotFound, setIsLogoNotFound] = useState(false);
    const [isProfilePictureNotFound, setIsProfilePictureNotFound] = useState(false);
    const [org, setOrg] = useState(null);
    const [reportingTool, setReportingTool] = useState(null);
    const [validName, setValidName] = useState(true);
    const [orgName, setOrgName] = useState(null);
    const [businessType, setBusinessType] = useState(null);
    const [orgMainCurrency, setOrgMainCurrency] = useState(null);
    const [orgCountryCorp, setOrgCountryCorp] = useState(null);
    const [orgForeignKey, setOrgForeignKey] = useState(null);
    const [orgAddress, setOrgAddress] = useState(null);
    const [orgZipCode, setOrgZipCode] = useState(null);
    const [orgWebLink, setOrgWebLink] = useState(null);
    const [orgVATCode, setOrgVATCode] = useState(null);
    const [orgCity, setOrgCity] = useState(null);
    const [valueTab, setValueTab] = React.useState(0);
    const [language, setLanguage] = React.useState('en');
    const [listPermission, setListPermission] = useState([]);
    const [currentSystemRole, setCurrentSystemRole] = useState('');
    const [masterSystemRole, setMasterSystemRole] = useState('');
    const [openWarningRole, setOpenWarningRole] = useState(false);
    const [errorPass, setErrorPass] = useState(false);
    
    const prevData = usePrevious(selector);

    const handleCloseWarningRole = () => {
        setOpenWarningRole(false)
    }
    
    const handleInputPasswordChange = (event) => {
        setErrorPass(false);
        setPassword(event.target.value);
    };
    useEffect(() => {
        (async function getListCountry() {
            showLoading();
            const listCountryResponse = await axios.get('/country/get-list-countries', {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            const { data, status } = listCountryResponse;

            if (status === 200) {
                setCountries(data);
            }
            hideLoading();
        })();
    }, []);

    useEffect(() => {
        setUserTracking(selector);
        if (!user) return;
        var matches = (user.get('First Name') + ' ' + user.get('Name')).match(/\b(\w)/g); // ['J','S','O','N']
        setDefaultCharacter(matches.join('').toLocaleUpperCase()); // JSON


        if (user.get('lang')) {
            setLanguage(user.get('lang'));
        }
        if (user.get('autoSendEmail')) {
            setAutoSendEmail(user.get('autoSendEmail'));
        }
        if (user.get('profilePictureUrl')) {
            setUserPic(user.get('profilePictureUrl'));
        }
        if (user.getIn(['org', 'logo'])) {
            setOrgLogo(user.getIn(['org', 'logo']));
        }
        if (user.getIn(['org', 'comments'])) {
            setComment(user.getIn(['org', 'comments']));
            // setOrg({
            //     ...org,
            //     comments: user.getIn(['org', 'comments'])
            // });
        }
        if (user.getIn(['org', 'smartListForeignKey'])) {
            setSmartListForeignKey(user.getIn(['org', 'smartListForeignKey']));
        }

        if (user.getIn(['org', 'businessType'])) {
            setBusinessType(user.getIn(['org', 'businessType']));
        }

        if (user.getIn(['org', 'mainCurrency'])) {
            setOrgMainCurrency(user.getIn(['org', 'mainCurrency']));
        }

        if (user.getIn(['org', 'reportingTool'])) {
            setReportingTool(user.getIn(['org', 'reportingTool']));
        }

        if (user.getIn(['org', 'countryCorp'])) {
            setOrgCountryCorp(user.getIn(['org', 'countryCorp']));
        }

        if (user.getIn(['org', 'Name'])) {
            setOrgName(user.getIn(['org', 'Name']));
        }

        if (user.getIn(['org', 'foreignKey'])) {
            setOrgForeignKey(user.getIn(['org', 'foreignKey']));
        }

        if (user.getIn(['org', 'address'])) {
            setOrgAddress(user.getIn(['org', 'address']));
        }

        if (user.getIn(['org', 'zipCode'])) {
            setOrgZipCode(user.getIn(['org', 'zipCode']));
        }

        if (user.getIn(['org', 'webLink'])) {
            setOrgWebLink(user.getIn(['org', 'webLink']));
        }

        if (user.getIn(['org', 'VATCode'])) {
            setOrgVATCode(user.getIn(['org', 'VATCode']));
        }

        if (user.getIn(['org', 'city'])) {
            setOrgCity(user.getIn(['org', 'city']));
        }

    }, [selector, prevData]);

    const dispatch = useDispatch();

    useEffect(() => {
        showLoading();
        dispatch(authActions.getProfile({ id: localStorage.getItem('id') }));
        hideLoading();
    }, []);

    useEffect(() => {
        if (user) {
            setListPermission(user.get('permissions').toArray());
            setMasterSystemRole(user.get('systemRole'));
            setCurrentSystemRole(SYSTEM_ROLES.find(r => r.id === user.get('systemRole')));
        }
    }, [user]);

    const BootstrapInput = withStyles((theme) => ({
        root: {
            width: '100%',
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            width: '100%',
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.common.white,
            border: '1px solid #ced4da',
            fontSize: 16,
            padding: '10px 12px',
        },
    }))(InputBase);

    const StylesButton = withStyles((theme) => ({
        root: {
            textTransform: 'capitalize',
            background: '#D05E5A',
            color: '#fff',
            '&:hover': {
                background: '#D05E5A',
            },
            '&:focus': {
                outline: 'none',
            },
            height: '40px',
            width: '100%',
        },
    }))(Button);

    const { enqueueSnackbar } = useSnackbar();

    const handleChangeValueProfile = (e) => {
        const { name, value } = e.target;

        const valueChange = {
            [name]: value
        };

        return setUserTracking(user.merge(fromJS(valueChange)));
    };

    const handleChangeRole = (e, value) => {
        return setUserTracking(user.merge(fromJS({ Role: value.id })));
    };

    const handleChangeSystemRole = (e, value) => {
        setCurrentSystemRole(value);
    };

    const handleSaveProfile = () => {
        if (masterSystemRole !== currentSystemRole.id) {
            setOpenWarningRole(true);
        } else {
            handleUpdateUser();
        }
    }

    async function handleUpdateUser() {
        const dataUpdate = {
            name: user.get('Name'),
            firstName: user.get('First Name'),
            email: user.get('Email'),
            role: user.get('Role'),
            lang: localStorage.getItem('language') || 'en',
            autoSendEmail: autoSendEmail,
            systemRole: currentSystemRole.id
        };
        dispatch(authActions.updateProfile({ dataUpdate, user, enqueueSnackbar }));

        if (!orgName || orgName === '') {
            enqueueSnackbar(t('organization.org_require'), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            return;
        }
        try {
            const orgData = {
                uuid: user.getIn(['org', 'ikastUID']),
                name: orgName,
                businessType: businessType,
                countryCorp: typeof orgCountryCorp === 'number' ? orgCountryCorp : countries.find(r => r.name_translation === orgCountryCorp).code,
                city: orgCity,
                address: orgAddress,
                zipCode: orgZipCode,
                reportingTool: reportingTool,
                mainCurrency: orgMainCurrency,
                status: 0,
                foreignKey: orgForeignKey ? orgForeignKey : '',
                comments: comment,
                smartListForeignKey: smartListForeignKey ? smartListForeignKey : '',
                webLink: orgWebLink,
                VATCode: orgVATCode
            }

            const contentUpdatedResponse = await axios.put('/org/update-info', orgData, {
                headers: {
                    common: {
                        'x-access-token': localStorage.getItem('token')
                    },
                }
            });

            if (contentUpdatedResponse.status === 200) {
                // window.location.href = '/business';
                enqueueSnackbar(t('profile.update_profile_success'), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
    
                    variant: 'success'
                });
            } else {
                enqueueSnackbar(t('notify.something_wrong'), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }
        } catch (e) {
            enqueueSnackbar(t('notify.something_wrong'), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
    }

    async function handleUpdatePassword() {
        if(!password) {
            enqueueSnackbar(t('profile.require_new_pass'), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            setErrorPass(true);
            return;
        }
        showLoading();
        setErrorPass(false);
        const response = await axios.put(`/user/change-password`, {
            email: user.get('Email'),
            password,
        }, {
            headers: {
                common: {
                    'x-access-token': localStorage.getItem('token')
                },
            }
        });

        if (response.status === 200) {
            enqueueSnackbar(t('profile.update_pass_success'), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'success'
            });
        } else {
            enqueueSnackbar(t('profile.update_pass_fail'), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
        hideLoading();
    }

    const handleUpdateComments = (e) => {
        const { value } = e.target;
        setComment(value);
    };

    const handleUpdateSmartListForeignKey = (e) => {
        const { value } = e.target;
        setSmartListForeignKey(value);
    }

    const handleChangeAutoSendEmail = (e) => {
        setAutoSendEmail(e.target.checked);
        const valueChange = {
            [e.target.name]: e.target.checked
        };

        return setUserTracking(user.merge(fromJS(valueChange)));
    };

    const onMouseEnterHandler = (e) => {
        setIsLogoHovering(true)
    };

    const onMouseLeaveHandler = (e) => {
        setIsLogoHovering(false)
    };

    const onMouseEnterPHandler = (e) => {
        setIsProfilePictureHovering(true)
    };

    const onMouseLeavePHandler = (e) => {
        setIsProfilePictureHovering(false)
    };

    const handleChangeInputBusinessType = (e, value) => {
        setBusinessType(value.id);
    };

    const handleChangeInputCurrency = (e, value) => {
        setOrgMainCurrency(value.id);
    };

    const handleChangeInputReportingTool = (e, value) => {
        setOrgForeignKey(value.id !== 3 || value.id !== 4 ? '' : orgForeignKey);
        setSmartListForeignKey(value.id !== 3 || value.id !== 4 ? '' : smartListForeignKey);
        setReportingTool(value.id);
    };

    const handleChangeForeignKey = (e) => {
        const { value } = e.target;
        setOrgForeignKey(value);
    }

    const handleChangeAddress = (e) => {
        const { value } = e.target;
        setOrgAddress(value);
    }

    const handleChangeCity = (e) => {
        const { value } = e.target;
        setOrgCity(value);
    }

    const handleChangeVATCode = (e) => {
        const { value } = e.target;
        setOrgVATCode(value);
    }

    const handleChangeWebLink = (e) => {
        const { value } = e.target;
        setOrgWebLink(value);
    }

    const handleChangeZipCode = (e) => {
        const { value } = e.target;
        setOrgZipCode(value);
    }

    const handleChangeCountry = (e, value) => {
        setOrgCountryCorp(value.code);
    }

    const handleChangeOrgName = (e) => {
        const { name, value } = e.target;
        setOrgName(value);
    }

    const handleValidateName = (e) => {
        const name = e.target.value;
        if (!name) {
            setValidName(false);
        } else {
            setValidName(true);
        }
    };

    const uploadOrganizationLogo = async (files) => {
        if (files.length === 0) {
            return;
        }
        const dataToUpload = {
            uuid: user.getIn(['org', 'ikastUID']),
            logo: files[0].name,
            contentType: files[0].type
        }
        setOpenDropzoneLogo(false);

        try {
            showLoading();
            const response = await axios.put(`/org/update-logo`, dataToUpload
                , {
                    headers: {
                        common: {
                            'x-access-token': localStorage.getItem('token')
                        },
                    }
                });

            const { data, status } = response;

            if (status === 200) {
                const urlToUpload = data.updatedLogoUrl;
                const options = { headers: { 'Content-Type': files[0].type } };
                const uploadToS3 = await axios.put(urlToUpload, files[0], options);
                enqueueSnackbar(t('organization.update_logo'), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });
            }
            hideLoading();
        } catch (error) {
            hideLoading();
            console.log(error);
        }
        window.location.reload();
    }

    const uploadProfilePicture = async (files) => {
        if (files.length === 0 || !user.get('ikastUID')) {
            return;
        }
        const dataToUpload = {
            uuid: user.get('ikastUID'),
            profilePicture: files[0].name,
            contentType: files[0].type
        }
        setOpenDropzoneLogo(false);

        try {
            showLoading();
            const response = await axios.put(`/user/update-profile-picture`, dataToUpload
                , {
                    headers: {
                        common: {
                            'x-access-token': localStorage.getItem('token')
                        },
                    }
                });

            const { data, status } = response;

            if (status === 200) {
                const urlToUpload = data.updatedProfileUrl;
                const options = { headers: { 'Content-Type': files[0].type } };
                const uploadToS3 = await axios.put(urlToUpload, files[0], options);
                enqueueSnackbar(t('profile.upload_picture_success'), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });
            }
            hideLoading();
        } catch (error) {
            hideLoading();
            console.log(error);
        }
        window.location.reload();
    }

    const handleLogoNotFound = () => {
        setIsLogoNotFound(true);
    }

    const handleProfilePictureNotFound = () => {
        setIsProfilePictureNotFound(true);
    }

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    };

    // const onChangeLanguage = (e) => {
    //     setLanguage(e);
    // }

    return user ? (
        <Layout title="My Profile" highlightedItem={5}>
            <div className='flex justify-between content-center'>
                <div>
                    {`${t('profile.settings')} > ${t('profile.profile')}`}
                    <p className='content-title'>
                        {t('profile.edit_profile')}
                    </p>
                </div>
                <div className='flex' style={{ height: '36px' }}>
                    {listPermission.includes('IKAST_SETTING_EDIT_USER') ?
                        <Button className='save-modification-button' onClick={() => handleSaveProfile()}>
                            {t('profile.save_modify')}
                        </Button>
                        : null}
                </div>
            </div>

            <div className='profile-content bg-white p-4 mt-3'>

                <Tabs value={valueTab} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={<b>{t('profile.my_personal_info')}</b>} {...a11yProps(0)} />
                    <Tab label={<b>{t('profile.my_org')}</b>} {...a11yProps(1)} />
                    <Tab label={<b>{t('profile.my_team')}</b>} {...a11yProps(2)} />
                </Tabs>

                <TabPanel valueTab={valueTab} index={0}>
                    <div className='profile-info-personal mt-8'>
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <FormControl className='w-full'>
                                            <InputLabel shrink className='input-color'>
                                                {t('profile.first_name')}
                                            </InputLabel>
                                            <input
                                                onChange={handleChangeValueProfile}
                                                id='first_name'
                                                name='First Name'
                                                type='text'
                                                disabled={!listPermission.includes('IKAST_SETTING_EDIT_USER')}
                                                defaultValue={user.get('First Name')} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl className='w-full'>
                                            <InputLabel shrink className='input-color'>
                                                {t('profile.last_name')}
                                            </InputLabel>
                                            <input onChange={handleChangeValueProfile}
                                                id='last_name' name='Name'
                                                defaultValue={user.get('Name')}
                                                disabled={!listPermission.includes('IKAST_SETTING_EDIT_USER')}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputLabel shrink className='input-color'>
                                            {t('profile.role')}
                                        </InputLabel>
                                        <Autocomplete
                                            name='roles'
                                            onChange={handleChangeRole}
                                            disableClearable
                                            value={ROLES.find(r => r.id === (user.get('Role') || 1))}
                                            className='mt-2 autocomplete-role'
                                            classes={{
                                                root: classes.root
                                            }}
                                            disabled={!listPermission.includes('IKAST_SETTING_EDIT_USER')}
                                            getOptionLabel={(role) => role.name}
                                            options={ROLES}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <FormControl className='w-full'>
                                            <InputLabel shrink className='input-color'>
                                                {t('profile.email')}
                                            </InputLabel>
                                            <BootstrapInput disabled defaultValue={user.get('Email')} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl className='w-full'>
                                            <InputLabel shrink className='input-color'>
                                                {t('profile.password')}
                                            </InputLabel>
                                            <input
                                                id='password_profile'
                                                style={{ color: 'black', border: errorPass ? '1px solid red' : '1px solid #ced4da'}}
                                                className='text-gray-500'
                                                placeholder="************"
                                                name='Password'
                                                type='password'
                                                value={password}
                                                onChange={(event) => handleInputPasswordChange(event)} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {listPermission.includes('IKAST_SETTING_EDIT_USER') ? (
                                            <StylesButton style={{ marginTop: 24 }} onClick={() => handleUpdatePassword()}>
                                                {t('profile.change_pass')}
                                            </StylesButton>
                                        ) : null}
                                    </Grid>

                                </Grid>

                                <Grid container spacing={3}>
                                    {/* <Grid item xs={4}>
                                        <InputLabel shrink className='input-color'>
                                            Language
                                        </InputLabel>
                                        <LanguageComponent  onChangeLanguage={(e) => onChangeLanguage(e)}/>
                                    </Grid> */}
                                    <Grid item xs={4}>
                                        <InputLabel shrink className='input-color'>
                                            {t('profile.system_role')}
                                        </InputLabel>
                                        <Autocomplete
                                            name='systemRoles'
                                            onChange={handleChangeSystemRole}
                                            disableClearable
                                            value={currentSystemRole}
                                            className='mt-2 autocomplete-role'
                                            classes={{
                                                root: classes.root
                                            }}
                                            disabled={masterSystemRole !== "Super Admin"}
                                            getOptionLabel={(role) => role.name}
                                            options={SYSTEM_ROLES}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{ display: "flex", alignItems: "center", marginTop: 15 }}>
                                            <Switch className='send-email-switch'
                                                onChange={handleChangeAutoSendEmail}
                                                id='autoSendEmail'
                                                name='autoSendEmail'
                                                checked={autoSendEmail}
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                            <div>
                                                {t('profile.send_me_email')}
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>

                                {/* <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                       
                                    </Grid>
                                </Grid> */}

                            </Grid>
                            <Grid item xs={4} className='flex justify-end profile-picture-pos'>
                                <Grid item xs={4} className='flex justify-center'>
                                    {user.get('profilePictureUrl') !== null && !isProfilePictureNotFound ?
                                        <div className='text-align-center'>
                                            <img
                                                className='profile-picture'
                                                onMouseEnter={onMouseEnterPHandler}
                                                onMouseLeave={onMouseLeavePHandler}
                                                onClick={() => setOpenDropzoneProfilePicture(true)}
                                                src={userPic}
                                                onError={handleProfilePictureNotFound}
                                                style={{
                                                    backgroundImage: isProfilePictureHovering ? ' linear-gradient(to bottom right, #002f4b, #dc4225)' : '',
                                                    filter: isProfilePictureHovering ? 'brightness(0.6)' : ''
                                                }} alt='profile-picture' />
                                            {isProfilePictureHovering ?
                                                <EditRoundedIcon
                                                    className='profile-picture-hover'
                                                    onMouseEnter={onMouseEnterPHandler}
                                                    onMouseLeave={onMouseLeavePHandler}
                                                    onClick={() => setOpenDropzoneProfilePicture(true)} /> : null} </div> :
                                        <div className='text-align-center'>
                                            <div className='rounded text-2xl rounded-full text-gray-600 p-1 default-profile-picture'>
                                                {defaultCharacter}
                                            </div>
                                            {listPermission.includes('IKAST_SETTING_EDIT_USER') ?
                                                <button
                                                    className='flex text-align-center default-profile-btn'
                                                    onClick={() => setOpenDropzoneProfilePicture(true)}
                                                    style={{
                                                    }}>
                                                    <EditRoundedIcon className='edit-icon' />
                                                    <span className='change-text'> {t('profile.change')}</span>
                                                </button>
                                                : null}
                                        </div>
                                    }
                                    <DropzoneDialog
                                        id='upload-profile-picture'
                                        dialogTitle={t('profile.upload_picture')}
                                        acceptedFiles={['image/*']}
                                        cancelButtonText={t('common.cancel')}
                                        submitButtonText={t('common.submit')}
                                        maxFileSize={5000000}
                                        filesLimit={1}
                                        open={openDropzoneProfilePicture}
                                        onClose={() => setOpenDropzoneProfilePicture(false)}
                                        onSave={uploadProfilePicture}
                                        showPreviews={true}
                                        showFileNamesInPreview={true}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </TabPanel>

                <TabPanel valueTab={valueTab} index={1}>
                    <div className='profile-info-organization mt-8'>
                        <Grid container spacing={3}>
                            <Grid item xs={9}>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <FormControl className='w-full'>
                                            <InputLabel shrink className='input-color'>
                                                {t('organization.org')}
                                            </InputLabel>
                                            <TextField
                                                name='Name'
                                                type="text"
                                                variant="outlined"
                                                className={nameClasses.root}
                                                style={{ width: '100%', marginTop: '24px' }}
                                                defaultValue={orgName ? orgName : ''}
                                                error={!validName}
                                                onChange={handleChangeOrgName}
                                                onBlur={handleValidateName}
                                                autoComplete="off"
                                                helperText={validName ? '' : t('profile.org_require')}
                                                disabled={!listPermission.includes('IKAST_SETTING_EDIT_USER')}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl className='w-full'>
                                            <InputLabel shrink className='input-color'>
                                                {t("organization.business_type")}
                                            </InputLabel>
                                            <Autocomplete
                                                disabled={!listPermission.includes('IKAST_SETTING_EDIT_USER')}
                                                id="business-type"
                                                name="businessType"
                                                classes={{
                                                    root: classes.root
                                                }}
                                                style={{
                                                    width: '100%',
                                                    borderRadius: 4,
                                                    position: 'relative',
                                                    marginTop: '24px',
                                                    fontSize: 16
                                                }}
                                                onChange={handleChangeInputBusinessType}
                                                size='small'
                                                value={businessType ? BUSINESSTYPES.find(b => b.id === businessType) : BUSINESSTYPES.find(b => b.default)}
                                                disableClearable
                                                className='mt-2'
                                                options={BUSINESSTYPES}
                                                getOptionLabel={(ev) => ev.description}
                                                renderOption={options => {
                                                    return (
                                                        <Fragment>
                                                            <p className='ml-2'>{options.description}</p>
                                                        </Fragment>
                                                    )
                                                }}
                                                renderInput={(params) => <TextField {...params} variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                    }} />} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl className='w-full'>
                                            <InputLabel shrink className='input-color'>
                                                {t("organization.currency")}
                                            </InputLabel>
                                            <Autocomplete
                                                disabled={!listPermission.includes('IKAST_SETTING_EDIT_USER')}
                                                id="main-currency"
                                                onChange={handleChangeInputCurrency}
                                                name='mainCurrency'
                                                size='small'
                                                style={{ width: '100%', marginTop: '24px' }}
                                                value={orgMainCurrency ? CURRENCY.find(ev => ev.id === orgMainCurrency) : CURRENCY.find(ev => ev.default)}
                                                disableClearable
                                                className='mt-2'
                                                options={CURRENCY}
                                                getOptionLabel={(ev) => ev.value}
                                                renderOption={options => {
                                                    return (
                                                        <Fragment>
                                                            <p className='ml-2'>{options.value}</p>
                                                        </Fragment>
                                                    )
                                                }}
                                                renderInput={(params) => <TextField {...params} variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                    }} />}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <FormControl className='w-full'>
                                            <InputLabel shrink className='input-color'>
                                                {t("organization.reporting_tool")}
                                            </InputLabel>
                                            <Autocomplete
                                                disabled={!listPermission.includes('IKAST_SETTING_EDIT_USER')}
                                                id="reporting-tool"
                                                style={{ width: '100%', marginTop: '24px' }}
                                                onChange={handleChangeInputReportingTool}
                                                name='reportingTool'
                                                size='small'
                                                defaultValue={reportingTool ? REPORTINGTOOLS.find(ev => ev.id === reportingTool) : REPORTINGTOOLS.find(ev => ev.default)}
                                                disableClearable
                                                className='mt-2'
                                                options={REPORTINGTOOLS}
                                                getOptionLabel={(ev) => ev.value}
                                                renderOption={options => {
                                                    return (
                                                        <Fragment>
                                                            <p className='ml-2'>{options.value}</p>
                                                        </Fragment>
                                                    )
                                                }}
                                                renderInput={(params) => <TextField {...params} variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                    }} />}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {(reportingTool === 3 || reportingTool === 4) ?
                                            <FormControl className='w-full'>
                                                <InputLabel shrink className='input-color'>
                                                    {t("organization.org_key")}
                                                </InputLabel>
                                                <TextField
                                                    disabled={!listPermission.includes('IKAST_SETTING_EDIT_USER')}
                                                    id='add-org-foreignKey'
                                                    style={{ width: '100%', marginTop: '24px' }}
                                                    variant="outlined"
                                                    maxLength="255"
                                                    name='foreignKey'
                                                    defaultValue={orgForeignKey ? orgForeignKey : ''}
                                                    type="text"
                                                    autoComplete="off"
                                                    onChange={handleChangeForeignKey}
                                                />
                                            </FormControl>
                                            : null}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {(reportingTool === 3 || reportingTool === 4) ?
                                            <FormControl className='w-full'>
                                                <InputLabel shrink className='input-color'>
                                                    {t("organization.smartList_key")}
                                                </InputLabel>
                                                <TextField
                                                    id='smart_list_foreign_key'
                                                    className={nameClasses.root}
                                                    style={{ width: '100%', marginTop: '24px' }}
                                                    variant="outlined"
                                                    maxLength="255"
                                                    name='smartListForeignKey'
                                                    defaultValue={smartListForeignKey ? smartListForeignKey : ''}
                                                    disabled={!listPermission.includes('IKAST_SETTING_EDIT_USER')}
                                                    type="text"
                                                    autoComplete="off"
                                                    onChange={handleUpdateSmartListForeignKey}
                                                />
                                            </FormControl>
                                            : null}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={3} className='flex justify-center'>
                                {user.getIn(['org', 'logo']) !== null && !isLogoNotFound ?
                                    <div className='text-align-center'>
                                        <img
                                            className='flex logo-pos'
                                            onMouseEnter={onMouseEnterHandler}
                                            onMouseLeave={onMouseLeaveHandler}
                                            onClick={() => setOpenDropzoneLogo(true)}
                                            src={orgLogo}
                                            onError={handleLogoNotFound}
                                            style={{
                                                backgroundImage: isLogoHovering ? ' linear-gradient(to bottom right, #002f4b, #dc4225)' : '',
                                                filter: isLogoHovering ? 'brightness(0.6)' : ''
                                            }} alt='orgLogo' />
                                        {isLogoHovering ?
                                            <EditRoundedIcon
                                                className='logo-pos-hover'
                                                onMouseEnter={onMouseEnterHandler}
                                                onMouseLeave={onMouseLeaveHandler}
                                                onClick={() => {
                                                    if (listPermission.includes('IKAST_SETTING_EDIT_USER')) {
                                                        setOpenDropzoneLogo(true)
                                                    }
                                                }} /> : null} </div> :
                                    <button
                                        className='logo-dropzone'
                                        onClick={() => {
                                            if (listPermission.includes('IKAST_SETTING_EDIT_USER')) {
                                                setOpenDropzoneLogo(true)
                                            }
                                        }}>
                                        <div style={{ marginBottom: '4%' }}>{t('organization.add_logo_here')}</div>
                                        <CloudUploadIcon style={{ color: 'rgb(208, 94, 90)', fontSize: '30px' }} />
                                    </button>
                                }
                                <DropzoneDialog
                                    dialogTitle={t('organization.upload_logo')}
                                    acceptedFiles={['image/*']}
                                    cancelButtonText={t('common.cancel')}
                                    submitButtonText={t('common.submit')}
                                    maxFileSize={5000000}
                                    filesLimit={1}
                                    open={openDropzoneLogo}
                                    onClose={() => setOpenDropzoneLogo(false)}
                                    onSave={uploadOrganizationLogo}
                                    showPreviews={true}
                                    showFileNamesInPreview={true}
                                />
                            </Grid>

                        </Grid>

                        <Grid container className='mt-4'>
                            <Grid item xs={9}>
                                <FormControl className='w-full'>
                                    <InputLabel shrink className='input-color'>
                                        {t("organization.address")}
                                    </InputLabel>
                                    <TextField
                                        disabled={!listPermission.includes('IKAST_SETTING_EDIT_USER')}
                                        id='add-org-address'
                                        className={nameClasses.root}
                                        style={{ width: '100%', marginTop: '24px' }}
                                        variant="outlined"
                                        maxLength="255"
                                        name='address'
                                        defaultValue={orgAddress ? orgAddress : ''}
                                        type="text"
                                        autoComplete="off"
                                        onChange={handleChangeAddress}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className='mg-top-1rem'>
                            <Grid item xs={3}>
                                <FormControl className='w-full'>
                                    <InputLabel shrink className='input-color'>
                                        {t("organization.zip_code")}
                                    </InputLabel>
                                    <TextField
                                        disabled={!listPermission.includes('IKAST_SETTING_EDIT_USER')}
                                        id='add-org-zipCode'
                                        className={nameClasses.root}
                                        style={{ width: '100%', marginTop: '24px' }}
                                        variant="outlined"
                                        name='zipCode'
                                        defaultValue={orgZipCode ? orgZipCode : ''}
                                        inputProps={{ maxLength: 5 }}
                                        type="text"
                                        autoComplete="off"
                                        onChange={handleChangeZipCode}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl className='w-full'>
                                    <InputLabel shrink className='input-color'>
                                        {t("organization.city")}
                                    </InputLabel>
                                    <TextField
                                        disabled={!listPermission.includes('IKAST_SETTING_EDIT_USER')}
                                        id='add-org-city'
                                        className={nameClasses.root}
                                        style={{ width: '100%', marginTop: '24px' }}
                                        variant="outlined"
                                        maxLength="255"
                                        name='city'
                                        defaultValue={orgCity ? orgCity : ''}
                                        type="text"
                                        autoComplete="off"
                                        onChange={handleChangeCity}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl className='w-full'>
                                    <InputLabel shrink className='input-color'>
                                        {t("organization.country")}
                                    </InputLabel>
                                    {
                                        countries &&
                                        <Autocomplete
                                            disabled={!listPermission.includes('IKAST_SETTING_EDIT_USER')}
                                            onChange={handleChangeCountry}
                                            defaultValue={countries && orgCountryCorp ? countries.find(r => r.name_translation === orgCountryCorp) : 250}
                                            options={countries}
                                            getOptionLabel={(e) => e.name_translation}
                                            style={{
                                                width: '100%',
                                                borderRadius: 4,
                                                position: 'relative',
                                                marginTop: '24px',
                                                fontSize: 16
                                            }}
                                            size='small'
                                            disableClearable
                                            className='mt-2'
                                            classes={{
                                                root: classes.root
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined"
                                                placeholder='Add...' />}
                                        />
                                    }
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container className='mt-4'>
                            <Grid item xs={9}>
                                <FormControl className='w-full'>
                                    <InputLabel shrink className='input-color'>
                                        {t("organization.web_link")}
                                    </InputLabel>
                                    <TextField
                                        id='add-org-webLink'
                                        className={nameClasses.root}
                                        style={{ width: '100%', marginTop: '24px' }}
                                        variant="outlined"
                                        maxLength="255"
                                        name='webLink'
                                        defaultValue={orgWebLink ? orgWebLink : ''}
                                        type="text"
                                        autoComplete="off"
                                        onChange={handleChangeWebLink}
                                        disabled={!listPermission.includes('IKAST_SETTING_EDIT_USER')}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className='mg-top-1rem'>
                            <Grid item xs={3}>
                                <FormControl className='w-full'>
                                    <InputLabel shrink className='input-color'>
                                        {t("organization.vat_code")}
                                    </InputLabel>
                                    <TextField
                                        disabled={!listPermission.includes('IKAST_SETTING_EDIT_USER')}
                                        id='add-org-webLink'
                                        className={nameClasses.root}
                                        style={{ width: '100%', marginTop: '24px' }}
                                        variant="outlined"
                                        maxLength="255"
                                        name='VATCode'
                                        defaultValue={orgVATCode ? orgVATCode : ''}
                                        type="text"
                                        autoComplete="off"
                                        onChange={handleChangeVATCode}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className='mg-top-1rem'>
                            <Grid item xs={12}>
                                <FormControl className='w-full'>
                                    <InputLabel shrink className='input-color'>
                                        {t("organization.comments")}
                                    </InputLabel>
                                    <textarea
                                        disabled={!listPermission.includes('IKAST_SETTING_EDIT_USER')}
                                        className='comment-area'
                                        defaultValue={comment}
                                        name='comments'
                                        maxLength='3000'
                                        rows='5'
                                        onChange={handleUpdateComments}>
                                    </textarea>
                                </FormControl>
                            </Grid>
                        </Grid>

                    </div>
                </TabPanel>
                <TabPanel valueTab={valueTab} index={2}>
                    <UserManagement/>
                </TabPanel>
                <Dialog
                    open={openWarningRole}
                    onClose={handleCloseWarningRole}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t("profile.warning_system_role", { from: masterSystemRole, to: currentSystemRole.name })}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className='no-border-button' onClick={() => handleCloseWarningRole()} color="primary">
                            {t('common.no')}
                        </Button>
                        <Button onClick={() => handleUpdateUser()} className='no-border-button' color="primary" autoFocus>
                            {t('common.yes')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Layout>
    ) : <LoadingLayout />
};

export default withSnackbar(Profile);
