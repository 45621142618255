import React, { useState, Fragment, useEffect, useContext } from 'react';
import Layout from '../../layouts/Default';
import axios from '../../../config/axios';
import RowComponent from '../../components/Royalties/RowComponent';
import Pagination from '@material-ui/lab/Pagination';
import SortContentIconRender from "../../components/Royalties/SortContentIconRender";
import LoadingLayout from "../../components/Common/LoadingLayout";
import LoadingContext from "../../../context/loading/LoadingContext";
import { useTranslation } from 'react-i18next';
import makeStyles from "@material-ui/core/styles/makeStyles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        "& $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&:hover $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&$focused $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        }
    },
    focused: {},
    notchedOutline: {}
}));

const Royalties = () => {
    const outlinedInputClasses = useOutlinedInputStyles();
    const { t, i18n } = useTranslation();
    const [royalties, setRoyalties] = useState([]);
    const [page, setPage] = useState(1);
    const [user, setUser] = useState(null);
    const [sortRoyalties, setSortRoyalties] = useState(null);
    const [sortViewCount, setSortViewCount] = useState(null);
    const [sortContent, setSortContent] = useState(null);
    const [sortStatus, setSortStatus] = useState(null);
    const [sortTitle, setSortTitle] = useState(null);
    const [sortEndDate, setSortEndDate] = useState(null);

    const [columnContent, setColumnContent] = useState(null);
    const [columnViewCount, setColumnViewCount] = useState(null);
    const [columnRoyalty, setColumnRoyalty] = useState(null);
    const [columnStatus, setColumnStatus] = useState(null);
    const [columnTitle, setColumnTitle] = useState(null);
    const [columnEndDate, setColumnEndDate] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [totalContracts, setTotalContracts] = useState(null);

    const { showLoading, hideLoading } = useContext(LoadingContext);

    useEffect(() => {
        if (localStorage.getItem('id') && localStorage.getItem('token')) {
            (async function getUser() {
                try {
                    showLoading();
                    const userResponse = await axios.get(`/user/${localStorage.getItem('id')}`, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });

                    setUser(userResponse.data)
                    hideLoading();
                } catch (e) {
                    hideLoading();
                    console.log(e);
                }
            })();
        }
    }, []);

    useEffect(() => {
        if (user) {
            const timeOutId = setTimeout(() => prepareToFetchRoyaltiesList(searchValue), 50);
            return () => clearTimeout(timeOutId);
        }
    }, [searchValue, user, page]);

    // useEffect(() => {
    //     if (user) {
    //         (async function getListContent() {
    //             // Check current user Org ID existing
    //             if (!user.BelongsTo) {
    //                 return;
    //             }
    //
    //             // Must be change to use drop down list: 10, 25, 50, 100
    //             const ITEM_PER_PAGE = 10;
    //             showLoading();
    //             const contractResponse = await axios.get(`/royalty/get-list-royalties?orgUid=${user.BelongsTo}&pageNo=${page}&itemPerPage=${ITEM_PER_PAGE}`, {
    //                 headers: {
    //                     'x-access-token': localStorage.getItem('token')
    //                 }
    //             });
    //             const { data, status } = contractResponse;
    //
    //             if (status === 200) {
    //                 if (columnContent) {
    //                     if (sortContent === 'asc') {
    //                         sortContentAscending();
    //                     } else if (sortContent === 'des') {
    //                         sortContentDescending()
    //                     }
    //                 } else if (columnViewCount) {
    //                     if (sortViewCount === 'asc') {
    //                         sortViewCountAscending();
    //                     } else if (sortViewCount === 'des') {
    //                         sortViewCountDescending()
    //                     }
    //                 } else if (columnRoyalty) {
    //                     if (sortRoyalties === 'asc') {
    //                         sortRoyaltiesAscending();
    //                     } else if (sortRoyalties === 'des') {
    //                         sortRoyaltiesDescending()
    //                     }
    //                 } else if (columnStatus) {
    //                     if (sortStatus === 'asc') {
    //                         sortStatusAscending();
    //                     } else if (sortStatus === 'des') {
    //                         sortStatusDescending();
    //                     }
    //                 } else if (columnTitle) {
    //                     if (sortTitle === 'asc') {
    //                         sortTitleAscending();
    //                     } else if (sortTitle === 'des') {
    //                         sortTitleDescending();
    //                     }
    //                 } else {
    //                     setRoyalties(data);
    //                 }
    //             }
    //             hideLoading();
    //         })();
    //     }
    //
    // }, [user, page]);

    const prepareToFetchRoyaltiesList = async (searchValue) => {
        try {
            if (columnStatus) {
                if (sortStatus === 'asc') {
                    sortStatusAscending(searchValue);
                } else if (sortStatus === 'des') {
                    sortStatusDescending(searchValue)
                }
            } else if (columnTitle) {
                if (sortTitle === 'asc') {
                    sortTitleAscending(searchValue);
                } else if (sortTitle === 'des') {
                    sortTitleDescending(searchValue);
                }
            } else if (columnContent) {
                if (sortContent === 'asc') {
                    sortContentAscending(searchValue);
                } else if (sortContent === 'des') {
                    sortContentDescending(searchValue);
                }
            } else if (columnViewCount) {
                if (sortViewCount === 'asc') {
                    sortViewCountAscending(searchValue);
                } else if (sortViewCount === 'des') {
                    sortViewCountDescending(searchValue);
                }
            } else if (columnRoyalty) {
                if (sortRoyalties === 'asc') {
                    sortRoyaltiesAscending(searchValue);
                } else if (sortEndDate === 'des') {
                    sortRoyaltiesDescending(searchValue);
                }
            } else if (columnEndDate) {
                if (sortEndDate === 'asc') {
                    sortEndDateAscending(searchValue);
                } else if (sortEndDate === 'des') {
                    sortEndDateDescending(searchValue);
                }
            } else {
                sortingHandle('', 0, searchValue).then(r => { });
            }
        } catch (e) {
            console.log(e);
        }
    }

    const pagingHandleChange = (e, value) => {
        setPage(value);
    };

    const sortingHandle = async (sortBy, sortOrder, searchInput) => {
        showLoading();
        const royaltiesResponse =
            await axios.get(`/royalty/get-list-royalties?orgUid=${user.BelongsTo}&pageNo=${page}&itemPerPage=10&sortBy=${sortBy}&sortOrder=${sortOrder}&searchInput=${searchInput}`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

        const { data, status } = royaltiesResponse;

        if (status === 200) {
            if (searchInput.length === 0) {
                setTotalContracts(data.totalItems);
            }
            setRoyalties(data);
        }
        hideLoading();
    };

    const sortStatusAscending = (searchInput) => {
        sortingHandle('status', 0, searchInput).then(r => {});
    }

    const sortStatusDescending = (searchInput) => {
        sortingHandle('status', 1, searchInput).then(r => {});
    }

    const sortTitleAscending = (searchInput) => {
        sortingHandle('title', 0, searchInput).then(r => {});
    }

    const sortTitleDescending = (searchInput) => {
        sortingHandle('title', 1, searchInput).then(r => {});
    }

    const sortContentAscending = (searchInput) => {
        sortingHandle('contentCount', 0, searchInput).then(r => {});
    }

    const sortContentDescending = (searchInput) => {
        sortingHandle('contentCount', 1, searchInput).then(r => {});
    }

    const sortViewCountAscending = (searchInput) => {
        sortingHandle('viewLogCount', 0, searchInput).then(r => {});
    }

    const sortViewCountDescending = (searchInput) => {
        sortingHandle('viewLogCount', 1, searchInput).then(r => {});
    }

    const sortRoyaltiesAscending = (searchInput) => {
        sortingHandle('royalty', 0, searchInput).then(r => {});
    }

    const sortRoyaltiesDescending = (searchInput) => {
        sortingHandle('royalty', 1, searchInput).then(r => {});
    }

    const sortEndDateAscending = (searchInput) => {
        sortingHandle('endDate', 0, searchInput).then(r => {});
    }

    const sortEndDateDescending = (searchInput) => {
        sortingHandle('endDate', 1, searchInput).then(r => {});
    }

    const sortByStatus = () => {
        setColumnStatus(true);
        setColumnTitle(false);
        setColumnContent(false);
        setColumnViewCount(false);
        setColumnRoyalty(false);
        setColumnEndDate(false);
            if (!sortStatus) {
                sortStatusAscending(searchValue);
                setSortStatus('asc')
            }

            if (sortStatus && sortStatus === 'des') {
                sortStatusAscending(searchValue);
                setSortStatus('asc')
            }

            if (sortStatus && sortStatus === 'asc') {
                sortStatusDescending(searchValue);
                setSortStatus('des')
            }
    };

    const sortByTitle = () => {
        setColumnStatus(false);
        setColumnTitle(true);
        setColumnContent(false);
        setColumnViewCount(false);
        setColumnRoyalty(false);
        setColumnEndDate(false);
        if (!sortTitle) {
            sortTitleAscending(searchValue);
            setSortTitle('asc');
        }

        if (sortTitle && sortTitle === 'des') {
            sortTitleAscending(searchValue);
            setSortTitle('asc');
        }

        if (sortTitle && sortTitle === 'asc') {
            sortTitleDescending(searchValue);
            setSortTitle('des');
        }
    };
    
    const sortByContent = () => {
        setColumnStatus(false);
        setColumnTitle(false);
        setColumnContent(true);
        setColumnViewCount(false);
        setColumnRoyalty(false);
        setColumnEndDate(false);
        if (!sortContent) {
            sortContentAscending(searchValue);
            setSortContent('asc')
        }
        
        if (sortContent && sortContent === 'des') {
            sortContentAscending(searchValue);
            setSortContent('asc')
        }
        
        if (sortContent && sortContent === 'asc') {
            sortContentDescending(searchValue);
            setSortContent('des')
        }
    };
    
    const sortByViewCount = () => {
        setColumnStatus(false);
        setColumnTitle(false);
        setColumnContent(false);
        setColumnViewCount(true);
        setColumnRoyalty(false);
        setColumnEndDate(false);
        if (!sortViewCount) {
            sortViewCountAscending(searchValue);
            setSortViewCount('asc')
        }
        
        if (sortViewCount && sortViewCount === 'des') {
            sortViewCountAscending(searchValue);
            setSortViewCount('asc')
        }
        
        if (sortViewCount && sortViewCount === 'asc') {
            sortViewCountDescending(searchValue);
            setSortViewCount('des')
        }
    };
    
    const sortByRoyalties = () => {
        setColumnStatus(false);
        setColumnTitle(false);
        setColumnContent(false);
        setColumnViewCount(false);
        setColumnRoyalty(true);
        setColumnEndDate(false);
        if (!sortRoyalties) {
            sortRoyaltiesAscending(searchValue);
            setSortRoyalties('asc')
        }

        if (sortRoyalties && sortRoyalties === 'des') {
            sortRoyaltiesAscending(searchValue);
            setSortRoyalties('asc')
        }

        if (sortRoyalties && sortRoyalties === 'asc') {
            sortRoyaltiesDescending(searchValue);
            setSortRoyalties('des')
        }
    };

    const sortByEndDate = () => {
        setColumnStatus(false);
        setColumnTitle(false);
        setColumnContent(false);
        setColumnViewCount(false);
        setColumnRoyalty(false);
        setColumnEndDate(true);
        if (!sortEndDate) {
            sortEndDateAscending(searchValue);
            setSortEndDate('asc')
        }

        if (sortEndDate && sortEndDate === 'des') {
            sortEndDateAscending(searchValue);
            setSortEndDate('asc')
        }

        if (sortEndDate && sortEndDate === 'asc') {
            sortEndDateDescending(searchValue);
            setSortEndDate('des')
        }
    };

    const handleClearSearchValue = async (e) => {
        setSearchValue('');
    }

    const handleChangeSearchValue = async (e) => {
        setSearchValue(e.target.value);
    }

    return royalties ? (
        <Layout title='Royalties' highlightedItem={4}>
            <div className='flex justify-between content-center'>
                <div>
                    {t("royalties.breadcrumb_royalties")}
                    <p style={{ fontSize: '24px' }}>
                    {t("royalties.licensing_contract")}
                        ({!searchValue || searchValue.length === 0 ? royalties.totalItems : `${royalties.totalItems}/${totalContracts}`})
                    </p>
                </div>
            </div>
            <div className='grid grid-cols-12 gap-2'>
                <div className='font-bold col-span-9'/>
                <div className='font-bold col-span-3'>
                    <OutlinedInput
                        id="outlined-size-small"
                        style={{ height: '36px', background: 'white' }}
                        classes={outlinedInputClasses}
                        placeholder={t('common.search')}
                        value={searchValue}
                        inputProps={{
                            autoComplete: "off"
                        }}
                        onChange={handleChangeSearchValue}
                        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                        endAdornment={<InputAdornment position="end">
                            <IconButton
                                className="no-border-button"
                                aria-label="toggle clear button"
                                onClick={handleClearSearchValue}
                            >
                                {'searchValue' !== '' ? <ClearIcon /> : ""}
                            </IconButton>
                        </InputAdornment>} />
                </div>
            </div>
            <div className='bg-white px-8 py-16 mt-8'>
                <div className='grid grid-cols-12 gap-0 justify-items-center text-center'>
                    <div className='font-bold col-span-1'>
                    </div>
                    <div className='font-bold col-span-1 justify-items-center text-center column-header' onClick={sortByStatus}>
                    {t("table.status")}
                        {columnStatus ? <SortContentIconRender sortOrder={sortStatus}/> : null}
                    </div>
                    <div className='font-bold col-span-2 column-header' onClick={sortByTitle}>
                    {t("table.title")}
                        {columnTitle ? <SortContentIconRender sortOrder={sortTitle}/> : null}
                    </div>
                    <div className='font-bold col-span-1 justify-items-center text-center column-header'>
                    {t("table.with")}
                    </div>
                    <div className='font-bold col-span-1 flex justify-center text-center column-header' onClick={sortByContent}>
                    {t("table.contents")}
                        {columnContent ? <SortContentIconRender sortOrder={sortContent}/> : null}
                    </div>
                    <div className='font-bold col-span-1 flex justify-center text-center column-header' onClick={sortByViewCount}>
                    {t("table.views")}
                        {columnViewCount ? <SortContentIconRender sortOrder={sortViewCount} /> : null}
                    </div>
                    <div className='font-bold col-span-2 flex justify-center text-center column-header' onClick={sortByEndDate}>
                    {t("table.end_date")}
                        {columnEndDate ? <SortContentIconRender sortOrder={sortEndDate}/> : null}
                    </div>
                    <div className='font-bold col-span-2 flex justify-center ml-2 column-header' onClick={sortByRoyalties}>
                    {t("royalties.royalties")}
                        {columnRoyalty ? <SortContentIconRender sortOrder={sortRoyalties}/> : null}
                    </div>
                    <div className='font-bold col-span-1 column-header'>
                    </div>
                </div>
                <Fragment>
                    {royalties.records && royalties.records.map((value, key) => {
                        let royalty = {
                            ...value
                        };
                        return (
                            <RowComponent
                                royalty={royalty}
                                key={key}
                            />
                        )
                    })}
                </Fragment>
                <div className='flex flex-row justify-center items-center py-4'>
                    <Pagination count={royalties.totalPages} page={page} onChange={pagingHandleChange} />
                </div>
            </div>
        </Layout>
    ) : <LoadingLayout/>
};

export default Royalties;
