import React, { useContext, useMemo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingContext from './LoadingContext';
import './Spinner.css';
import Logo from '../../assets/images/logo.png';

const Spinner = () => {
    const { loadingCount } = useContext(LoadingContext);
    let isLoading = loadingCount > 0;
    return useMemo(() => {
        return (
            <>
                {isLoading && (
                    <div className="overlay-content">
                        <div className="wrapper">
                            <div style={{ position: 'relative' }}>
                                <img src={Logo} className="logo" alt='logo' />
                                <CircularProgress size={80} />
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }, [isLoading])
};

export default Spinner;