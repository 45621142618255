import React, { useEffect, useState, Fragment, useMemo } from 'react';
import Layout from '../../../layouts/Default';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import './index.css';
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import { useDispatch, useSelector } from "react-redux";
import { GET_CONTENT_STATUS, GET_ENTITIES, EXTRA_FORMAT } from '../../../../constants'
import { authActions, authSelectors } from '../../../../state/modules/auth';
import axios from '../../../../config/axios';
import { useSnackbar, withSnackbar } from 'notistack';
import { Link } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import MovieCreationRoundedIcon from '@material-ui/icons/MovieCreationRounded';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import VideocamIcon from '@material-ui/icons/Videocam';
import TheatersIcon from '@material-ui/icons/Theaters';
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { DropzoneArea } from "material-ui-dropzone";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import { useTranslation } from 'react-i18next'
const useNoBorderStyles = makeStyles({
    root: {
        padding: '0px',
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        }
    }
});

const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        "& $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&:hover $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&$focused $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-input": {
            padding: '18.5px 0px',
        }
    },
    focused: {},
    notchedOutline: {}
}));

const AddContent = () => {
    const classes = useNoBorderStyles();
    const outlinedInputClasses = useOutlinedInputStyles();
    const { t, i18n } = useTranslation();
    const ENTITIES = useMemo(() => GET_ENTITIES(t), [t]);
    const CONTENT_STATUS = useMemo(() => GET_CONTENT_STATUS(t), [t]);
    const initialState = {
        format: ENTITIES.find(e => e.default === true).id,
        status: CONTENT_STATUS.find(s => s.default).id,
        picture: 'https://picsum.photos/200',
        extraFormat: EXTRA_FORMAT.find(e => e.default === true).id
    };
    const [errValidateTitle, setErrTitle] = useState('');
    const [errValidateIsanCode, setErrIsanCode] = useState('');
    const [errValidateUrl, setErrUrl] = useState('');
    const [organizations, setOrganizations] = useState(null);
    const [contentAdded, setContent] = useState(initialState);
    const [isanCode, setISANCode] = useState('');
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [appendPictureFile, setAppendPictureFile] = React.useState(null);
    const [isImagePreviewed, setIsImagePreviewed] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState(null);
    const [isPictureHovering, setIsPictureHovering] = React.useState(false);
    
    useEffect(() => {
        if (localStorage.getItem('id') && localStorage.getItem('token')) {
            dispatch(authActions.getProfile({ id: localStorage.getItem('id') }));
        }
    }, []);

    const user = useSelector(state => authSelectors.user(state));

    useEffect(() => {
        (async function getListOrg() {
            const responseOrg = await axios.get('/org/get-list-orgs', {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            setOrganizations(responseOrg.data);
        })();
    }, []);

    useEffect(() => {
        if (user) {
            setContent({
                ...contentAdded,
                orgUuid: user.get('BelongsTo')
            })
        }

    }, [user]);

    const handleChangeType = (e, value) => {
        setContent({
            ...contentAdded,
            format: value.id
        });
    };

    const handleChangeExtraFormat = (e, value) => {
        setContent({
            ...contentAdded,
            extraFormat: value.id
        });
    };

    const handleChangeOrg = (e, value) => {
        setContent({
            ...contentAdded,
            orgUuid: value.ikastUID
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setContent({
            ...contentAdded,
            [name]: value
        });
    };

    const handleInputISANChange = (e) => {
        const { name, value } = e.target;
        if (value.trim().length === 0) {
            updateISANCodeValue('');
            return;
        }

        let tempValue = value.replace(/[^\w\s]/gi,'');
        if (tempValue.length <= 4) {
            updateISANCodeValue(tempValue);
        } else if (tempValue.length > 4 && tempValue.length <= 8) {
            let format = tempValue.slice(0,4) + '-' + tempValue.slice(4);
            updateISANCodeValue(format);
        } else if (tempValue.length > 8 && tempValue.length <= 12) {
            let format = tempValue.slice(0,4) + '-'
                + tempValue.slice(4, 8) + '-'
                + tempValue.slice(8);
            updateISANCodeValue(format);
        } else if (tempValue.length > 12 && tempValue.length <= 16) {
            let format = tempValue.slice(0,4) + '-'
                + tempValue.slice(4, 8) + '-'
                + tempValue.slice(8, 12) + '-'
                + tempValue.slice(12);
            updateISANCodeValue(format);
        } else if (tempValue.length > 16 && tempValue.length <= 17) {
            let format = tempValue.slice(0,4) + '-'
                + tempValue.slice(4, 8) + '-'
                + tempValue.slice(8, 12) + '-'
                + tempValue.slice(12, 16) + '-'
                + tempValue.slice(16);
            updateISANCodeValue(format);
        } else if (tempValue.length > 17 && tempValue.length <= 21) {
            let format = tempValue.slice(0,4) + '-'
                + tempValue.slice(4, 8) + '-'
                + tempValue.slice(8, 12) + '-'
                + tempValue.slice(12, 16) + '-'
                + tempValue.slice(16, 17) + '-'
                + tempValue.slice(17);
            updateISANCodeValue(format);
        } else if (tempValue.length > 21 && tempValue.length <= 25) {
            let format = tempValue.slice(0,4) + '-'
                + tempValue.slice(4, 8) + '-'
                + tempValue.slice(8, 12) + '-'
                + tempValue.slice(12, 16) + '-'
                + tempValue.slice(16, 17) + '-'
                + tempValue.slice(17, 21) + '-'
                + tempValue.slice(21);
            updateISANCodeValue(format);
        } else if (tempValue.length > 25) {
            let format = tempValue.slice(0,4) + '-'
                + tempValue.slice(4, 8) + '-'
                + tempValue.slice(8, 12) + '-'
                + tempValue.slice(12, 16) + '-'
                + tempValue.slice(16, 17) + '-'
                + tempValue.slice(17, 21) + '-'
                + tempValue.slice(21, 25) + '-'
                + tempValue.slice(25, 26);
            updateISANCodeValue(format);
        }
    }

    const validateISANCode = (e) => {
        const val = e.target.value;
        if (val.trim().length !== 0) {
            let regex = /^(ISAN\s[\d\w]{4,4}-[\d\w]{4,4}-[\d\w]{4,4}-[\d\w]{4,4}-[\d\w]{1,1}-[\d\w]{4,4}-[\d\w]{4,4}-[\d\w]{1,1})$/
            if (!regex.test('ISAN '+ val)) {
                setErrIsanCode(t("content.wrong_format"));
            } else {
                setErrIsanCode('');
            }
        } else {
            setErrIsanCode('');
        }
    };

    const updateISANCodeValue = (value) => {
        setISANCode(value);
        setContent({
            ...contentAdded,
            isanCode: value.length !== 0 ? 'ISAN '+ value : ''
        });
    }

    const handleDurationBlur = () => {
        let e = document.getElementById('add-content-duration');

        if (!e.value.match(/^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/)) {
            e.value = '00:00:00';

            setContent({
                ...contentAdded,
                duration: e.value
            });
            alert(t("content.invalid_duration"));
        }
    };

    const validateTitle = (e) => {
        const val = e.target.value;
        if (!val.trim()) {
            setErrTitle(t("content.title_require"));
        } else {
            setErrTitle('');
        }
    };

    const validateUrl = (e) => {
        const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
        const val = e.target.value;
        if (!regexp.test(val.trim())) {
            setErrUrl(t("content.invalid_url"));
        } else {
            setErrUrl('');
        }
    };

    const handleAddContent = async () => {
        if (!contentAdded.title || contentAdded.title === '') {
            enqueueSnackbar(t("content.title_require"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            return;
        }

        if (errValidateUrl !== '') {
            enqueueSnackbar(t("content.invalid_url"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            return;
        }

        if (errValidateIsanCode !== '') {
            enqueueSnackbar(t("content.invalid_isan"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            return;
        }

        try {
            const response = await axios.post('/content', contentAdded, {
                headers: {
                    common: {
                        'x-access-token': localStorage.getItem('token')
                    },
                }
            });

            if (response.status === 200) {
                enqueueSnackbar(t("content.create_content_success"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });

                if (appendPictureFile) {
                    const urlToUpload = response.data.record.uploadPictureUrl;
                    const options = { headers: { 'Content-Type': appendPictureFile.type} };
                    const uploadIllustration = await axios.put(urlToUpload, appendPictureFile, options);
                }
                history.push('/contents');
            } else {
                enqueueSnackbar(t("notify.something_wrong"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }
        } catch (e) {
            enqueueSnackbar(t("notify.something_wrong"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
    };

    const renderIconForType = (type) => {
        switch (type) {
            case 1:
                return (
                    <MovieCreationRoundedIcon />
                );
            case 2:
                return (
                    <VideocamIcon />
                );
            case 3:
                return (
                    <OndemandVideoIcon />
                );
            case 4:
                return (
                    <ArtTrackIcon />
                );
            case 5:
                return (
                    <TheatersIcon />
                );
            default:

                return (
                    <MovieCreationRoundedIcon />
                );
        }
    };

    const onMouseEnterHandler = (e) => {
        setIsPictureHovering(true)
    };

    const onMouseLeaveHandler = (e) => {
        setIsPictureHovering(false)
    };

    const handleRemovePreviewPicture = () => {
        setAppendPictureFile(null);
        setContent({
            ...contentAdded,
            picture: '',
            contentType: ''
        });
        setIsImagePreviewed(false);
    }

    const uploadIllustration = (files) => {
        if (files.length === 0) {
            setAppendPictureFile(null);
            setContent({
                ...contentAdded,
                picture: '',
                contentType: ''
            });
            return;
        }
        // contentType: files[0].type
        setAppendPictureFile(files[0]);
        setIsImagePreviewed(true);
        setPreviewImage(URL.createObjectURL(files[0]));

        setContent({
            ...contentAdded,
            picture: files[0].name,
            contentType: files[0].type
        });
    }

    return (
        <Layout title="Add Content">
            <div className='flex justify-between content-center'>
                <div>
                    {t("content.breadcrumb_add")}
                    <p style={{ fontSize: '24px' }}>
                    {t("content.add_content")}
                    </p>
                </div>
                <div className='flex' style={{
                    height: '36px'
                }}>
                    <Button style={{
                        backgroundColor: '#D05E5A',
                        color: 'white',
                        fontSize: '0.75rem',
                    }}
                        onClick={handleAddContent}
                    >
                        {t("common.save")}
                    </Button>
                    <Link to={'/contents'}>
                        <Button style={{
                            height: '100%',
                            padding: '6px 16px',
                            backgroundColor: '#D05E5A',
                            marginLeft: '0.5rem',
                            color: 'white',
                            fontSize: '0.75rem'
                        }}>
                            {t("common.cancel")}
                        </Button>
                    </Link>
                </div>
            </div>
            <div className='bg-white px-8 py-16 mt-8'>
                <div className='profile-title pb-2 pt-2' style={{ marginTop: '-30px', borderBottom: '1px solid #D05E5A' }}>
                    <b>  {t("content.identification")}</b>
                </div>
                <div className='flex justify-between content-center' style={{ marginTop: '20px' }}>
                    <div className='content-add-type' style={{
                        width: '20%'
                    }}>
                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t("content.type")}
                        </InputLabel>
                        <Autocomplete
                            classes={{
                                root: classes.root
                            }}
                            id="content-add-type"
                            value={ENTITIES.find(e => e.id === contentAdded.format)}
                            onChange={handleChangeType}
                            size='small'
                            defaultValue={ENTITIES.find(e => e.default)}
                            disableClearable
                            className='mt-2'
                            options={ENTITIES}
                            getOptionLabel={(e) => e.value_creation}
                            renderOption={option => {
                                return (
                                    <Fragment>
                                        {renderIconForType(option.id)}
                                        <p className='ml-2'>{option.value_creation}</p>
                                    </Fragment>
                                )
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment>
                                        {renderIconForType(contentAdded.format)}
                                    </InputAdornment>
                                )
                            }} />}
                        />
                    </div>
                    <div className='content-add-format' style={{
                        width: '20%'
                    }}>
                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t("content.video_format")}
                        </InputLabel>
                        <Autocomplete
                            classes={{
                                root: classes.root
                            }}
                            id="content-add-format"
                            value={EXTRA_FORMAT.find(ev => ev.id === contentAdded.extraFormat)}
                            onChange={handleChangeExtraFormat}
                            size='small'
                            defaultValue={EXTRA_FORMAT.find(ev => ev.default)}
                            disableClearable
                            className='mt-2'
                            options={EXTRA_FORMAT}
                            getOptionLabel={(ev) => ev.value_creation}
                            renderOption={options => {
                                return (
                                    <Fragment>
                                        <p className='ml-2'>{options.value_creation}</p>
                                    </Fragment>
                                )
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" InputProps={{
                                ...params.InputProps,
                            }} />}
                        />
                    </div>
                    <div className='content-add-org' style={{
                        width: '20.5%'
                    }}>
                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t("content.org")}
                        </InputLabel>
                        {
                            (user && organizations) ?
                                <Autocomplete
                                    classes={{
                                        root: classes.root
                                    }}
                                    value={organizations.find(o => o.ikastUID === contentAdded.orgUuid)}
                                    onChange={handleChangeOrg}
                                    defaultValue={organizations.find(org => org.ikastUID === (user.get('BelongsTo')))}
                                    size='small'
                                    disabled={true}
                                    disableClearable
                                    className='mt-2'
                                    options={organizations}
                                    getOptionLabel={(org) => org.Name}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                /> :
                                ''
                        }

                    </div>
                    <div className='content-add-global' style={{
                        width: '37%',
                        marginLeft: '0.2%'
                    }}>
                        <InputLabel shrink style={{color: 'rgba(0, 0, 0, 0.54)', marginBottom: '8px'}}>
                        {t("content.isan_code")}
                        </InputLabel>
                        <div className='w-full'>
                            <OutlinedInput
                                classes={outlinedInputClasses}
                                inputProps={{
                                    autoComplete: "off"
                                }}
                                style={{height: '40px', marginLeft: '-2px'}}
                                size='small'
                                value={isanCode}
                                className='w-full'
                                onInput = {(e) =>{
                                    e.target.value = e.target.value.slice(0,33)}}
                                placeholder='xxxx-xxxx-xxxx-xxxx-x-xxxx-xxxx-x'
                                name='isanCode'
                                onChange={handleInputISANChange}
                                startAdornment={<InputAdornment position="start" style={{marginLeft: '-8px'}}>ISAN</InputAdornment>}
                                type="text"
                                id="outlined-size-small"
                                error={!!errValidateIsanCode}
                                onBlur={validateISANCode}/>
                        </div>
                    </div>
                </div>

                <div className='flex justify-between content-center' style={{marginTop: '20px'}}>
                    <div className='content-add-global' style={{
                        width: '62.2%'
                    }}>
                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t("content.title")}
                        </InputLabel>
                        <div className='w-full'>
                                <TextField
                                    classes={{
                                        root: classes.root
                                    }}
                                    name='title'
                                    size='small'
                                    error={!!errValidateTitle}
                                    type='text'
                                    className='w-full'
                                    helperText={errValidateTitle}
                                    variant="outlined"
                                    onBlur={validateTitle}
                                    onChange={handleInputChange}
                                    placeholder={t("content.title_placeholder")}
                                />
                            </div>
                    </div>
                    <div className='content-add-global' style={{
                        width: '37.1%'
                    }}>
                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t("content.custom_id")}
                        </InputLabel>
                        <div className='w-full'>
                            <input
                                style={{ marginTop: '0rem' }}
                                name='globalID'
                                onChange={handleInputChange}
                                className='w-full' type="text"
                                id='global-id' />
                        </div>
                    </div>
                </div>

                <div className='flex justify-between content-center'>
                    <div style={{
                        width: '62.8%'
                    }}>
                        <div className='mt-4'>
                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                            {t("content.short_description")}
                            </InputLabel>
                            <div className='w-full'>
                                <textarea name='shortDescription' maxLength='255' onChange={handleInputChange} rows='5' id='add-content-description' className='w-full' placeholder='Description of the content...' />
                            </div>
                        </div>
                        <div className='mt-4'>
                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                            {t("content.web_link")}
                            </InputLabel>
                            <div className='w-full'>
                                <TextField
                                    classes={{
                                        root: classes.root
                                    }}
                                    name='webLink'
                                    error={!!errValidateUrl}
                                    helperText={errValidateUrl}
                                    onBlur={validateUrl}
                                    maxLength='1000'
                                    size='small'
                                    variant="outlined"
                                    onChange={handleInputChange}
                                    id='add-content-weblink'
                                    className='w-full'
                                    placeholder= {t("content.web_link_placeholder")} />
                            </div>
                        </div>
                        <div className='mt-4'>
                            <div>
                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                {t("content.duration")}
                                </InputLabel>
                                <div>
                                    <input onBlur={handleDurationBlur} onChange={handleInputChange} name='duration' type='text' id='add-content-duration' defaultValue='00:00:00' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        width: '37%',
                        marginLeft: '1%',
                        textAlign: 'center'
                    }}>
                        <button style={{color: 'rgba(0, 0, 0, 0.54)', fontSize: '0.75rem', marginTop: '11px', height: '19px'}}>
                        {t("content.illustration")}
                        </button>
                        <div className='mt-2'
                             style={{
                                 justifyContent: 'center',
                                 display: 'flex',
                                 height: '215px',
                                 // border: !isImagePreviewed ? 'dashed #d2cdcd 2px' : 'none',
                                 background: 'rgb(255 255 255)',
                                 borderRadius: '4px'}}>
                            {
                                !isImagePreviewed ? <DropzoneArea
                                filesLimit={1}
                                acceptedFiles={['image/*']}
                                dropzoneText= {t("common.drag_drop")}
                                onChange={uploadIllustration}
                                onDelete={uploadIllustration}
                                /> : <div>
                                    <img
                                        onMouseEnter={onMouseEnterHandler}
                                        onMouseLeave={onMouseLeaveHandler}
                                        onClick={() => handleRemovePreviewPicture()}
                                        src={previewImage}
                                        style={{
                                            height: '215px',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'top center',
                                            borderRadius: '1%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundImage: isPictureHovering ? ' linear-gradient(to bottom right, #002f4b, #dc4225)' : '',
                                            filter: isPictureHovering ? 'brightness(0.6)' : ''
                                        }} alt='illustration'/>
                            {isPictureHovering ? <DeleteRoundedIcon
                                onMouseEnter={onMouseEnterHandler}
                                onMouseLeave={onMouseLeaveHandler}
                                onClick={() => handleRemovePreviewPicture()}
                                style={{
                                position: 'absolute',
                                marginTop: '-120px',
                                marginLeft: '-10px',
                                color: 'white'}}
                                /> : null } </div>
                            }

                        </div>

                    </div>
                </div>

                <div className='mt-4'>
                    <div>
                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t("common.status")}
                        </InputLabel>
                        <div>
                            <Autocomplete
                                style={{
                                    width: '22%'
                                }}
                                freeSolo
                                disabled
                                size='small'
                                value={CONTENT_STATUS.find(e => e.default)}
                                disableClearable
                                className='mt-2'
                                options={CONTENT_STATUS}
                                getOptionLabel={(e) => e.value}
                                renderOption={option => {
                                    return (
                                        <Fragment>
                                            {<span id='status' className='rounded-full inline-block m-1' style={{
                                                backgroundColor: CONTENT_STATUS.find(s => s.default).color,
                                                height: '15px',
                                                width: '15px',
                                            }} />}
                                            <p className='ml-2'>{option.value}</p>
                                        </Fragment>
                                    )
                                }}
                                renderInput={(params) => <TextField {...params} variant="outlined" InputProps={{
                                    ...params.InputProps,

                                    startAdornment: (
                                        <InputAdornment>
                                            {<span id='status' className='rounded-full inline-block m-1' style={{
                                                backgroundColor: CONTENT_STATUS.find(s => s.default).color,
                                                height: '15px',
                                                width: '15px',
                                            }} />}
                                        </InputAdornment>
                                    )
                                }} />}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withSnackbar(AddContent);
