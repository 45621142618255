import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import { showStaticNumber } from '../../../../util';
import { CURRENCY, GET_STATUS } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { authActions, authSelectors } from "../../../../state/modules/auth";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import { NumericFormat } from "react-number-format";
import { useTranslation } from 'react-i18next';
const Item = ({ royalty }) => {
    const { t, i18n } = useTranslation();
    const STATUS = useMemo(() => GET_STATUS(t), [t]);
    const [isHover, setIsHover] = useState(false);
    const selector = useSelector((state) => authSelectors.user(state));
    const [user, setUserTracking] = useState(selector);
    const dispatch = useDispatch();

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#696969',
            color: '#dbdbdb',
            marginBottom: 0,
            fontSize: 14,
        },
    }))(Tooltip);

    useEffect(() => {
        if (localStorage.getItem('id') && localStorage.getItem('token')) {
            dispatch(authActions.getProfile({ id: localStorage.getItem('id') }));
        }
    }, []);

    useEffect(() => {
        setUserTracking(selector);
    }, [selector]);

    const onMouseEnterHandler = (e) => {
        setIsHover(true)
    };

    const onMouseLeaveHandler = (e) => {
        setIsHover(false)
    };

    const renderTooltipStatus = (status) => {
        switch (status) {
            case 0:
                return (
                    t("status.rejected")
                );
            case 1:
                return (
                    t("status.started")
                );
            case 2:
                return (
                    t("status.pending")
                );
            case 3:
                return (
                    t("status.running")
                );
            case 4:
                return (
                    t("status.ended")
                );
            case 5:
                return (
                    t("status.infringed")
                );
            default:
                return (
                    t("status.started")
                );
        }
    }

    let displayEndDate = royalty.endDate ? royalty.endDate : "";
    let displayTimeEndDate = displayEndDate.split("T");
    const endTimeArr = displayTimeEndDate[0] ? displayTimeEndDate[0].split("-") : "";
    return (
        <div
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            className='grid grid-cols-12 gap-1 text-center mt-3 rounded-lg items-center justify-items-center py-4' style={{
                background: 'rgb(242, 242, 242)',
                border: isHover ? '1px solid red' : ''
            }}>
            <LightTooltip title={royalty.exclusive ? t("royalties.exclusive") : t("royalties.non_exclusive")} placement="top">
                <div className='col-span-1'>
                    {royalty.exclusive ? <OfflineBoltIcon style={{ fontSize: 18 }} /> : <OfflineBoltIcon style={{ fontSize: 18, opacity: 0.4 }} />}
                </div>
            </LightTooltip>
            <LightTooltip title={renderTooltipStatus(royalty.status)} placement="top">
                <div className='col-span-1 flex pl-2 justify-items-center text-center' style={{ justifyContent: 'center' }}>
                    <span id='status' className='rounded-full block m-2' style={{
                        backgroundColor: STATUS.find(s => s.id === royalty.status).color,
                        height: '15px',
                        width: '15px',
                    }} />
                </div>
            </LightTooltip>
            <div className='col-span-2 flex pl-2' style={{ textAlign: 'left' }}>
                <span className='font-bold' style={{ color: '#000000c4', paddingTop: '6px' }}>{royalty.title}</span>
            </div>
            <div className='col-span-1 flex pl-2'>
                <img
                    src={royalty.partnerLogo}
                    style={{
                        width: '60px',
                        height: '60px',
                        backgroundSize: 'cover',
                        backgroundPosition: 'top center',
                    }}></img>
            </div>
            <div className='col-span-1 text-center'>
                <span>{royalty.contentCount}</span>
            </div>
            <div className='col-span-1 text-center'>
                <span>{royalty.viewLogCount}</span>
            </div>
            <div className='col-span-2 text-center'>
                <span>{endTimeArr ?  `${endTimeArr[2]}/${endTimeArr[1]}/${endTimeArr[0]}` : ""}</span>
            </div>
            <div className='col-span-2 text-right' style={{ marginRight: '10px' }}>
            {/* {royalty.royalty ? royalty.royalty : 0} */}
                <NumericFormat value={royalty.royalty  ? showStaticNumber(royalty.royalty ) : t('common.zero_text')} decimalScale={2} displayType={'text'} thousandSeparator={t('common.thousand_separator')} decimalSeparator={t('common.decimal_separator')} />
                {/* <NumericFormat
                    value={royalty.royalty ? royalty.royalty : 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalSeparator="x"
                    allowNegative={false}
                    decimalScale={2}
                /> */}
                <span style={{ color: 'rgb(113, 111, 111)', fontSize: '13px' }}> {(user && user.get('org')) && CURRENCY.find(c => c.id === user.getIn(['org', 'mainCurrency'])).value}</span>
            </div>

            <div className='float-right col-span-1 pr-4'>
                <div className='float-right pl-4' style={{ borderLeft: '1px solid #ccc', cursor: 'pointer', fontSize: 'small' }}>
                    <Link to={'/royalties/' + royalty.contractUID}>
                        <span>{t('table.see_more')}</span>
                    </Link>
                </div>
            </div >
        </div >
    );
};

export default Item;
