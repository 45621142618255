/* global d3 */

import React, { useEffect, useState } from 'react';

import Datamap from '../../../components/WorldMap/datamap';
import WrapperMap from './WrapperMap';
import { LIST_COUNTRY_CODE } from '../../../../constants';
const colors = d3.scale.category10();

const WorldMap = (props) => {
    const [countryRevenue, setCountryRevenue] = useState({});
    const mapDataRevenueCountry = (data) => {
        const countryData = {
            // USA: {
            //     fillKey: mapFillkey(0, 2),
            //     valid: 0, 
            //     infringed: 2
            // },
            // RUS: {
            //     fillKey: mapFillkey(4, 0),
            //     valid: 4, 
            //     infringed: 0
            // },
            // PRT: {
            //     fillKey: mapFillkey(6, 0),
            //     valid: 6, 
            //     infringed: 0
            // },
            // NGA: {
            //     fillKey: mapFillkey(8, 0),
            //     valid: 8, 
            //     infringed: 0
            // },
            // ESP: {
            //     fillKey: mapFillkey(10, 0),
            //     valid: 10, 
            //     infringed: 0
            // },
            // THA: {
            //     fillKey: mapFillkey(12, 0),
            //     valid: 12, 
            //     infringed: 0
            // },
            // VNM: {
            //     fillKey: mapFillkey(15, 0),
            //     valid: 15, 
            //     infringed: 0
            // },
        };
        data.forEach(item => {
            let revenueValid = 0;
            let revenueInfringed = 0;
            let viewCountValid = 0;
            let viewCountInfringed = 0;
            let isHadValidContentLog = false;
            let isHadInfringedContentLog = false;
            item.contentList.forEach(content => {
                if(content.validContentViewlog) {
                    revenueValid = revenueValid + Number(content.validContentViewlog.revenue);
                    viewCountValid = viewCountValid + Number(content.validContentViewlog.viewLogCount)
                    isHadValidContentLog = true;
                }
                if(content.infringedContentViewlog) {
                    revenueInfringed = revenueInfringed + Number(content.infringedContentViewlog.revenue)
                    viewCountInfringed = viewCountInfringed + Number(content.infringedContentViewlog.viewLogCount)
                    isHadInfringedContentLog = true;
                }
            });
            if(isHadValidContentLog || isHadInfringedContentLog) {
                countryData[getCountryCode3(item.countryCode)] = {
                    fillKey: mapFillkey(revenueValid, revenueInfringed),
                    valid: revenueValid.toFixed(2), 
                    viewCountValid: viewCountValid,
                    infringed: revenueInfringed.toFixed(2),
                    viewCountInfringed: viewCountInfringed
                }
            }
            setCountryRevenue(countryData);
        });
    }

    const mapFillkey = (valid, infringed) => {
        if(infringed > 0 && valid === 0 ) {
            return 'ifringedColor'
        } else if (valid > 0){
            const revenueRate = valid/props.dataTotalRevennue.totalValidRoyalty;
            if(revenueRate <= 0.1) {
                return 'validUnder10'
            }
            if(revenueRate > 0.1 && revenueRate <= 0.2) {
                return 'validUnder20'
            }
            if(revenueRate > 0.2 && revenueRate <= 0.3) {
                return 'validUnder30'
            }
            if(revenueRate > 0.3 && revenueRate <= 0.4) {
                return 'validUnder40'
            }
            if(revenueRate > 0.4 && revenueRate <= 0.5) {
                return 'validUnder50'
            }
            if(revenueRate > 0.5 && revenueRate <= 0.7) {
                return 'validUnder75'
            }
            return 'validOver75'
            
        }
    }

    const getCountryCode3 = (countrycode2) => {
        const objCountry = LIST_COUNTRY_CODE.find(data => data.code === countrycode2);
        return objCountry.code3;
    }

    useEffect(() => {
        if(props.dataTotalRevennue && props.dataTotalRevennue.responseObject) {
            mapDataRevenueCountry(props.dataTotalRevennue.responseObject)
        }
    }, [JSON.stringify(props)]);

    const valuePopup = (data) => {
        let content = '';
        if(data.valid > 0) {
             content = content +  `<p>Total Valid Revenue: ${data.valid}</p><p>Total Valid View : ${data.viewCountValid}</p>`;
        }
        if(data.infringed > 0) {
            content = content +  `<p>Total Infringe Revenue: ${data.infringed}</p><p>Total Infringe View : ${data.viewCountInfringed}</p>`;
        }
        return content;
    }

    return (
        <WrapperMap additionalClasses="ResponsiveMap">
            <Datamap
                responsive
                data={countryRevenue}
                fills={{
                    defaultFill: '#e2e8f0',
                    validUnder10: '#e9e0df',
                    validUnder20: '#e8b4b2',
                    validUnder30: '#e59f9c',
                    validUnder40: '#e98a86',
                    validUnder50: '#e97570',
                    validUnder75: '#e8645e',
                    validOver75: '#e64740',
                    ifringedColor: '#eca06f'
                }}
                geographyConfig={{
                    highlightOnHover: false,
                    highlightBorderColor: false,
                    popupTemplate: (geography, data) =>
                        `<div class='hoverinfo'><p>${geography.properties.name}</p>${valuePopup(data)}</div>`,
                    highlightBorderWidth: 0
                }}
                projection="mercator"
                updateChoroplethOptions={{ reset: false }}
            />
        </WrapperMap>
    );
}
export default WorldMap;

