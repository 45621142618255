import React, { useState, useEffect, useMemo } from 'react';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { GET_STATUS } from "../../../../constants";
import { useTranslation } from 'react-i18next';

const GeneralInfo = ({ contract, updateContractScreen }) => {
    const { t, i18n } = useTranslation();
    const STATUS = useMemo(() => GET_STATUS(t), [t]);
    const getFullDate = (date) => {
        var newDate = new Date(date);
        const month = newDate.toLocaleString('en-us', { month: 'short' })
        const day = newDate.getDate();
        const year = newDate.getFullYear()

        return day + ' ' + month + ' ' + year
    };

    const getFullTime = (date) => {
        var newDate = new Date(date);

        return newDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    }

    const renderNotificationMessage = (type) => {
        switch (type) {
            case 1:
                return (
                    t("general_info.create_at")
                );
            case 2:
                return (
                    t("general_info.create_at")
                );
            case 3:
                return (
                    t("general_info.validated_on")
                );
            case 4:
                return (
                    t("general_info.ended_on")
                );
            case 5:
                return (
                    t("general_info.infringed_since")
                );
            default:
                return (
                    t("general_info.create_at")
                );
        }
    };

    const renderDate = (type) => {
        switch (type) {
            case 1:
                return (
                    getFullDate(contract.createdAt)
                );
            case 2:
                return (
                    getFullDate(contract.createdAt)
                );
            case 3:
                return (
                    getFullDate(contract.anchorDate)
                );
            case 4:
                return (
                    getFullDate(contract.anchorDate)
                );
            case 5:
                return (
                    getFullDate(contract.infringedAt)
                );
            default:
                return (
                    getFullDate(contract.createdAt)
                );
        }
    };

    const renderTime = (type) => {
        switch (type) {
            case 1:
                return (
                    getFullTime(contract.createdAt)
                );
            case 2:
                return (
                    getFullTime(contract.createdAt)
                );
            case 3:
                return (
                    getFullTime(contract.anchorDate)
                );
            case 4:
                return (
                    getFullTime(contract.anchorDate)
                );
            case 5:
                return (
                    getFullTime(contract.infringedAt)
                );
            default:
                return (
                    getFullTime(contract.createdAt)
                );
        }
    };

    return (
        <div className='flex content-center' style={{ justifyContent: 'flex-end' }}>{
            !updateContractScreen ? <div className='left-title'>
                <span className='inline-block' style={{ verticalAlign: 'middle', marginRight: '5px' }}>{contract.Title}</span>
                <span id='status' className='inline-block rounded-full block m-2' style={{
                    backgroundColor: STATUS.find(s => s.id === contract.Status).color,
                    height: '13px',
                    width: '13px',
                    verticalAlign: 'middle'
                }} />
            </div> : <div className='left-title'> </div>
        }
            <div className='right-info' style={{ textAlign: 'left', color: '#828485', fontSize: '13px', paddingLeft: '5px' }}>
                <span style={{ verticalAlign: 'middle', marginRight: '4px' }}>{renderNotificationMessage(contract.Status)}</span>
                <CalendarTodayIcon style={{ fontSize: 14, verticalAlign: 'middle', marginRight: '8px' }} />
                <span style={{ verticalAlign: 'middle', marginRight: '15px' }}>{renderDate(contract.Status)}</span>
                <span style={{ verticalAlign: 'middle' }}>{renderTime(contract.Status)}</span>
                <span style={{ verticalAlign: 'middle' }}>{contract.ethContract ? contract.ethContract : ""}</span>
            </div>
        </div>
    );
};

export default GeneralInfo;
