import React, { useEffect, useState, useRef, Fragment, useContext, useMemo } from 'react';
import Layout from '../../layouts/Default';
import Button from '@material-ui/core/Button';
import './index.css';
import { withSnackbar } from 'notistack';
import { authActions, authSelectors } from '../../../state/modules/auth';

import { useDispatch, useSelector } from "react-redux";
import PartnerItem from "../../components/Business/PartnerItem";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import axios from "../../../config/axios";
import SortContentIconRender from "../../components/Royalties/SortContentIconRender";
import LoadingLayout from "../../components/Common/LoadingLayout";
import { GET_AUTHORIZATION_ROLES } from "../../../constants";
import LoadingContext from "../../../context/loading/LoadingContext";
import { useTranslation } from 'react-i18next';
function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const Profile = () => {
    const [page, setPage] = useState(1);
    const selector = useSelector((state) => authSelectors.user(state));
    const [user, setUserTracking] = useState(selector);
    const [businessPartners, setBusinessPartners] = useState(null);
    const prevData = usePrevious(selector);
    const [listPermission, setListPermission] = useState([]);
    const { t, i18n } = useTranslation();
    const AUTHORIZATION_ROLES = useMemo(() => GET_AUTHORIZATION_ROLES(t), [t]);
    const [sortStatus, setSortStatus] = useState(null);
    const [sortType, setSortType] = useState(null);
    const [sortTitle, setSortTitle] = useState(null);
    const [sortNbOfContents, setSortNbOfContents] = useState(null);
    const [sortNbOfContracts, setSortNbOfContracts] = useState(null);
    const [sortReportingTools, setSortReportingTools] = useState(null);
    const [sortLastUpdate, setSortLastUpdate] = useState(null);

    const [columnStatus, setColumnStatus] = useState(null);
    const [columnType, setColumnType] = useState(null);
    const [columnTitle, setColumnTitle] = useState(null);
    const [columnNbOfContents, setColumnNbOfContents] = useState(null);
    const [columnNbOfContracts, setColumnNbOfContracts] = useState(null);
    const [columnReportingTools, setColumnReportingTools] = useState(null);
    const [columnLastUpdate, setColumnLastUpdate] = useState(null);
    const { showLoading, hideLoading } = useContext(LoadingContext);
    useEffect(() => {
        if (user) {
            setListPermission(user.get('permissions').toArray());
            (async function getListPartnerships() {
                showLoading();
                const contentResponse = await axios.get(`/org/get-list-orgs-partnership?myOrgUid=${user.get('BelongsTo')}&pageNo=${page}&itemPerPage=10`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

                const { data, status } = contentResponse;

                if (status === 200) {
                    if (columnStatus) {
                        if (sortStatus === 'asc') {
                            sortStatusAscending();
                        } else if (sortStatus === 'des') {
                            sortStatusDescending();
                        }
                    } else if (columnType) {
                        if (sortType === 'asc') {
                            sortTypeAscending();
                        } else if (sortType === 'des') {
                            sortTypeDescending();
                        }
                    } else if (columnTitle) {
                        if (sortTitle === 'asc') {
                            sortTitleAscending();
                        } else if (sortTitle === 'des') {
                            sortTitleDescending();
                        }
                    } else if (columnNbOfContents) {
                        if (sortNbOfContents === 'asc') {
                            sortNbOfContentsAscending();
                        } else if (sortNbOfContents === 'des') {
                            sortNbOfContentsDescending();
                        }
                    } else if (columnNbOfContracts) {
                        if (sortNbOfContracts === 'asc') {
                            sortNbOfContractsAscending();
                        } else if (sortNbOfContracts === 'des') {
                            sortNbOfContractsDescending();
                        }
                    } else if (columnReportingTools) {
                        if (sortReportingTools === 'asc') {
                            sortReportingToolsAscending();
                        } else if (sortReportingTools === 'des') {
                            sortReportingToolsDescending();
                        }
                    } else if (columnLastUpdate) {
                        if (sortLastUpdate === 'asc') {
                            sortLastUpdateAscending();
                        } else if (sortLastUpdate === 'des') {
                            sortLastUpdateDescending();
                        }
                    } else {
                        setBusinessPartners(data);
                    }
                }
                hideLoading();
            })();
        }

    }, [user, page]);

    useEffect(() => {
        setUserTracking(selector);

    }, [selector, prevData]);

    const dispatch = useDispatch();

    useEffect(() => {
        showLoading();
        dispatch(authActions.getProfile({ id: localStorage.getItem('id') }));
        hideLoading();
    }, []);

    const pagingHandleChange = (e, value) => {
        setPage(value);
    };

    const sortingHandle = async (sortBy, sortOrder) => {
        showLoading();
        const businessPartnersResponse =
            await axios.get(`/org/get-list-orgs-partnership?myOrgUid=${user.get('BelongsTo')}&pageNo=${page}&itemPerPage=10&sortBy=${sortBy}&sortOrder=${sortOrder}`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

        const { data, status } = businessPartnersResponse;

        if (status === 200) {
            setBusinessPartners(data);
        }
        hideLoading();
    };

    const sortStatusAscending = () => {
        sortingHandle('status', 0).then(r => { });
    }

    const sortStatusDescending = () => {
        sortingHandle('status', 1).then(r => { });
    }

    const sortTypeAscending = () => {
        sortingHandle('type', 0).then(r => { });
    }

    const sortTypeDescending = () => {
        sortingHandle('type', 1).then(r => { });
    }

    const sortTitleAscending = () => {
        sortingHandle('name', 0).then(r => { });
    }

    const sortTitleDescending = () => {
        sortingHandle('name', 1).then(r => { });
    }

    const sortNbOfContentsAscending = () => {
        sortingHandle('numberOfContents', 0).then(r => { });
    }

    const sortNbOfContentsDescending = () => {
        sortingHandle('numberOfContents', 1).then(r => { });
    }

    const sortNbOfContractsAscending = () => {
        sortingHandle('numberOfContracts', 0).then(r => { });
    }

    const sortNbOfContractsDescending = () => {
        sortingHandle('numberOfContracts', 1).then(r => { });
    }

    const sortReportingToolsAscending = () => {
        sortingHandle('reportingTool', 0).then(r => { });
    }

    const sortReportingToolsDescending = () => {
        sortingHandle('reportingTool', 1).then(r => { });
    }

    const sortLastUpdateAscending = () => {
        sortingHandle('lastUpdate', 0).then(r => { });
    }

    const sortLastUpdateDescending = () => {
        sortingHandle('lastUpdate', 1).then(r => { });
    }

    const sortByStatus = () => {
        setColumnStatus(true);
        setColumnType(false);
        setColumnTitle(false);
        setColumnNbOfContents(false);
        setColumnNbOfContracts(false);
        setColumnReportingTools(false);
        setColumnLastUpdate(false);
        if (!sortStatus) {
            sortStatusAscending();
            setSortStatus('asc')
        }

        if (sortStatus && sortStatus === 'des') {
            sortStatusAscending();
            setSortStatus('asc')
        }

        if (sortStatus && sortStatus === 'asc') {
            sortStatusDescending()
            setSortStatus('des')
        }
    };

    const sortByType = () => {
        setColumnStatus(false);
        setColumnType(true);
        setColumnTitle(false);
        setColumnNbOfContents(false);
        setColumnNbOfContracts(false);
        setColumnReportingTools(false);
        setColumnLastUpdate(false);
        if (!sortType) {
            sortTypeAscending();
            setSortType('asc');
        }

        if (sortType && sortType === 'asc') {
            sortTypeDescending();
            setSortType('des');
        }

        if (sortType && sortType === 'des') {
            sortTypeAscending();
            setSortType('asc');
        }
    };

    const sortByTitle = () => {
        setColumnStatus(false);
        setColumnType(false);
        setColumnTitle(true);
        setColumnNbOfContents(false);
        setColumnNbOfContracts(false);
        setColumnReportingTools(false);
        setColumnLastUpdate(false);
        if (!sortTitle) {
            sortTitleAscending();
            setSortTitle('asc');
        }

        if (sortTitle && sortTitle === 'des') {
            sortTitleAscending();
            setSortTitle('asc');
        }

        if (sortTitle && sortTitle === 'asc') {
            sortTitleDescending();
            setSortTitle('des');
        }
    };

    const sortByNbOfContents = () => {
        setColumnStatus(false);
        setColumnType(false);
        setColumnTitle(false);
        setColumnNbOfContents(true);
        setColumnNbOfContracts(false);
        setColumnReportingTools(false);
        setColumnLastUpdate(false);
        if (!sortNbOfContents) {
            sortNbOfContentsAscending();
            setSortNbOfContents('asc');
        }

        if (sortNbOfContents && sortNbOfContents === 'asc') {
            sortNbOfContentsDescending();
            setSortNbOfContents('des');
        }

        if (sortNbOfContents && sortNbOfContents === 'des') {
            sortNbOfContentsAscending();
            setSortNbOfContents('asc');
        }
    };

    const sortByNbOfContracts = () => {
        setColumnStatus(false);
        setColumnType(false);
        setColumnTitle(false);
        setColumnNbOfContents(false);
        setColumnNbOfContracts(true);
        setColumnReportingTools(false);
        setColumnLastUpdate(false);
        if (!sortNbOfContracts) {
            sortNbOfContractsAscending();
            setSortNbOfContracts('asc');
        }

        if (sortNbOfContracts && sortNbOfContracts === 'asc') {
            sortNbOfContractsDescending();
            setSortNbOfContracts('des');
        }

        if (sortNbOfContracts && sortNbOfContracts === 'des') {
            sortNbOfContractsAscending();
            setSortNbOfContracts('asc');
        }
    };

    const sortByReportingTools = () => {
        setColumnStatus(false);
        setColumnType(false);
        setColumnTitle(false);
        setColumnNbOfContents(false);
        setColumnNbOfContracts(false);
        setColumnReportingTools(true);
        setColumnLastUpdate(false);
        if (!sortReportingTools) {
            sortReportingToolsAscending();
            setSortReportingTools('asc');
        }

        if (sortReportingTools && sortReportingTools === 'asc') {
            sortReportingToolsDescending();
            setSortReportingTools('des');
        }

        if (sortReportingTools && sortReportingTools === 'des') {
            sortReportingToolsAscending();
            setSortReportingTools('asc');
        }
    };

    const sortByLastUpdate = () => {
        setColumnStatus(false);
        setColumnType(false);
        setColumnTitle(false);
        setColumnNbOfContents(false);
        setColumnNbOfContracts(false);
        setColumnReportingTools(false);
        setColumnLastUpdate(true);
        if (!sortLastUpdate) {
            sortLastUpdateAscending();
            setSortLastUpdate('asc');
        }

        if (sortLastUpdate && sortLastUpdate === 'des') {
            sortLastUpdateAscending();
            setSortLastUpdate('asc');
        }

        if (sortLastUpdate && sortLastUpdate === 'asc') {
            sortLastUpdateDescending();
            setSortLastUpdate('des');
        }
    };

    const directToAddNewOrganization = () => {
        window.location.href = '/admin/newOrganization';
    }

    return user ? (
        <Layout title="My Profile" highlightedItem={1}>
            <div className='flex justify-between content-center'>
                <div>
                    {t('partners.settings')} > {t('partners.partners')}
                    <p style={{ fontSize: '24px' }}>
                    {t('partners.registered_partners')} (
                        {businessPartners ? businessPartners.totalItems : 0})
                    </p>
                </div>

                <div className='flex' style={{
                    height: '36px'
                }}>
                    {listPermission.includes('IKAST_PARTNER_ADD') ? <Button
                        onClick={() => directToAddNewOrganization()}
                        style={{
                            height: '100%',
                            padding: '6px 16px',
                            backgroundColor: '#D05E5A',
                            marginLeft: '0.5rem',
                            color: 'white',
                            fontSize: '0.75rem'
                        }}>
                         {t('partners.add_new')}
                    </Button> : ''}
                    {/* {listPermission.includes('IKAST_PARTNER_INVITE') ? 
                        <Button
                            style={{
                                height: '100%',
                                padding: '6px 16px',
                                backgroundColor: '#D05E5A',
                                marginLeft: '0.5rem',
                                color: 'white',
                                fontSize: '0.75rem'
                            }}>
                            {t('partners.invite')}
                        </Button>
                    : ''} */}
                </div>
            </div>
            <div className='bg-white px-8 py-16 mt-8'>
                <div className='grid grid-cols-12 gap-2 justify-items-center text-center'>
                    <div className='font-bold col-span-1 flex justify-center text-center'
                        onClick={sortByStatus}
                        style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t('common.status')}
                        {columnStatus ? <SortContentIconRender sortOrder={sortStatus} /> : null}
                    </div>
                    <div className='font-bold col-span-1 justify-center text-center'
                        onClick={sortByType}
                        style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t('partners.type')}
                        {columnType ? <SortContentIconRender sortOrder={sortType} /> : null}
                    </div>
                    <div className='font-bold col-span-4 flex justify-left text-left'
                        onClick={sortByTitle}
                        style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t('partners.name')}
                        {columnTitle ? <SortContentIconRender sortOrder={sortTitle} /> : null}
                    </div>
                    <div className='font-bold col-span-1 flex justify-center text-center'
                        onClick={sortByNbOfContents}
                        style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t('partners.nb_of_contents')}
                        {columnNbOfContents ? <SortContentIconRender sortOrder={sortNbOfContents} /> : null}
                    </div>
                    <div className='font-bold col-span-1 flex justify-center text-center'
                        onClick={sortByNbOfContracts}
                        style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t('partners.nb_of_contracts')}
                        {columnNbOfContracts ? <SortContentIconRender sortOrder={sortNbOfContracts} /> : null}
                    </div>
                    <div className='font-bold col-span-2 flex justify-left text-left'
                        onClick={sortByReportingTools}
                        style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t('partners.reporting')}
                        {columnReportingTools ? <SortContentIconRender sortOrder={sortReportingTools} /> : null}
                    </div>
                    <div className='font-bold col-span-2 flex justify-center text-center'
                        onClick={sortByLastUpdate}
                        style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t('partners.last_update')}
                        {columnLastUpdate ? <SortContentIconRender sortOrder={sortLastUpdate} /> : null}
                    </div>
                </div>

                <Fragment>
                    {businessPartners && businessPartners.records && businessPartners.records.map((value, key) => {
                        return (
                            <PartnerItem
                                myOrgId={user.get('BelongsTo')}
                                listPermission={listPermission}
                                item={value}
                                key={key}
                            />
                        )
                    })}
                </Fragment>

                <div className='flex flex-row justify-center items-center py-4' style={{ marginTop: '20px' }}>
                    <Pagination
                        count={businessPartners ? businessPartners.totalPages : 0}
                        page={page}
                        onChange={pagingHandleChange} />
                </div>

            </div>
        </Layout>
    ) : <LoadingLayout />
};

export default withSnackbar(Profile);
