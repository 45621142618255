import { all, fork } from 'redux-saga/effects';

import { exampleSagas } from './modules/example';
import { authSagas } from './modules/auth';

export default function* rootSaga() {
    yield all([
        fork(exampleSagas),
        fork(authSagas)
    ]);
}
