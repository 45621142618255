import React, { useState, Fragment, useEffect, useContext } from 'react';
import Layout from '../../layouts/Default';
import {
    Link,
} from 'react-router-dom';
import axios from '../../../config/axios';
import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import Item from "../../components/Contract/Item";
import _cloneDeep from "lodash/cloneDeep";
import _findIndex from "lodash/findIndex";
import { useSnackbar, withSnackbar } from 'notistack';
import SortContentIconRender from "../../components/Royalties/SortContentIconRender";
import LoadingContext from "../../../context/loading/LoadingContext";
import { useTranslation } from 'react-i18next';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        "& $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&:hover $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&$focused $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        }
    },
    focused: {},
    notchedOutline: {}
}));

const Contract = () => {
    const outlinedInputClasses = useOutlinedInputStyles();
    const [page, setPage] = useState(1);
    const [contracts, setContracts] = useState({});
    const [totalContracts, setTotalContracts] = useState(null);
    const [user, setUser] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const { t, i18n } = useTranslation();
    const [sortStatus, setSortStatus] = useState(null);
    const [sortTitle, setSortTitle] = useState(null);
    const [sortPricing, setSortPricing] = useState(null);
    const [sortContent, setSortContent] = useState(null);
    const [sortLastUpdate, setSortLastUpdate] = useState(null);
    const [sortEndDate, setSortEndDate] = useState(null);

    const [columnStatus, setColumnStatus] = useState(null);
    const [columnTitle, setColumnTitle] = useState(null);
    const [columnPricing, setColumnPricing] = useState(null);
    const [columnContent, setColumnContent] = useState(null);
    const [columnLastUpdate, setColumnLastUpdate] = useState(null);
    const [columnEndDate, setColumnEndDate] = useState(null);
    const { showLoading, hideLoading } = useContext(LoadingContext);
    const [listPermission, setListPermission] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        if (localStorage.getItem('id') && localStorage.getItem('token')) {
            (async function getUser() {
                showLoading();
                try {
                    const userResponse = await axios.get(`/user/${localStorage.getItem('id')}`, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });

                    setUser(userResponse.data);
                    setListPermission(userResponse.data.permissions);
                    hideLoading();
                } catch (e) {
                    hideLoading();
                    console.log(e);
                }
            })();
        }
    }, []);

    useEffect(() => {
        if (user) {
            const timeOutId = setTimeout(() => prepareToFetchContentList(searchValue), 50);
            return () => clearTimeout(timeOutId);
        }
    }, [searchValue, user, page]);

    const prepareToFetchContentList = async (searchValue) => {
        try {
            if (columnStatus) {
                if (sortStatus === 'asc') {
                    sortStatusAscendingOrder(searchValue);
                } else if (sortStatus === 'des') {
                    sortStatusDescendingOrder(searchValue)
                }
            } else if (columnTitle) {
                if (sortTitle === 'asc') {
                    sortTitleAscendingOrder(searchValue);
                } else if (sortTitle === 'des') {
                    sortTitleDescendingOrder(searchValue);
                }
            } else if (columnContent) {
                if (sortContent === 'asc') {
                    sortContentAscendingOrder(searchValue);
                } else if (sortContent === 'des') {
                    sortContentDescendingOrder(searchValue);
                }
            } else if (columnPricing) {
                if (sortPricing === 'asc') {
                    sortPricingAscendingOrder(searchValue);
                } else if (sortPricing === 'des') {
                    sortPricingDescendingOrder(searchValue);
                }
            } else if (columnEndDate) {
                if (sortEndDate === 'asc') {
                    sortEndDateAscendingOrder(searchValue);
                } else if (sortEndDate === 'des') {
                    sortEndDateDescendingOrder(searchValue);
                }
            } else if (columnLastUpdate) {
                if (sortLastUpdate === 'asc') {
                    sortLastUpdateAscendingOrder(searchValue);
                } else if (sortLastUpdate === 'des') {
                    sortLastUpdateDescendingOrder(searchValue);
                }
            } else {
                sortingHandle('', 0, searchValue).then(r => { });
            }
        } catch (e) {
            console.log(e);
        }
    }

    const pagingHandleChange = (e, value) => {
        setPage(value);
    };

    const handleDeleteContract = async (id) => {
        try {
            showLoading();
            const deletedContractResponse = await axios.delete(`/contract/${id}`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });
            hideLoading();
            if (deletedContractResponse.status === 200) {
                let currentContracts = _cloneDeep(contracts.records)
                let contractDeleteIndex = _findIndex(currentContracts, c => c.ikastUID === id);
                currentContracts.splice(contractDeleteIndex, 1);
                setContracts({
                    ...contracts,
                    totalItems: contracts.totalItems - 1,
                    records: currentContracts
                });

                enqueueSnackbar(t("contract.delete_contract_success"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });
            }
        } catch (e) {
            hideLoading();
            enqueueSnackbar(e.response.data.message, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
    };

    const sortingHandle = async (sortBy, sortOrder, searchInput) => {
        showLoading();
            const contractResponse =
                await axios.get(`/contract/get-list-contracts?orgUid=${user.BelongsTo}&pageNo=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchInput=${searchInput}`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

            const { data, status } = contractResponse;

            if (status === 200) {
                if (searchInput.length === 0) {
                    setTotalContracts(data.totalItems);
                }
                setContracts(data);
            }
            hideLoading();
    };

    const sortStatusAscendingOrder = (searchInput) => {
        sortingHandle('status', 0, searchInput).then(r => {});
    }

    const sortStatusDescendingOrder = (searchInput) => {
        sortingHandle('status', 1, searchInput).then(r => {});
    }

    const sortTitleAscendingOrder = (searchInput) => {
        sortingHandle('title', 0, searchInput).then(r => {});
    }

    const sortTitleDescendingOrder = (searchInput) => {
        sortingHandle('title', 1, searchInput).then(r => {});
    }

    const sortPricingAscendingOrder = (searchInput) => {
        sortingHandle('businessModel', 0, searchInput).then(r => {});
    }

    const sortPricingDescendingOrder = (searchInput) => {
        sortingHandle('businessModel', 1, searchInput).then(r => {});
    }

    const sortContentAscendingOrder = (searchInput) => {
        sortingHandle('numberOfContent', 0, searchInput).then(r => {});
    }

    const sortContentDescendingOrder = (searchInput) => {
        sortingHandle('numberOfContent', 1, searchInput).then(r => {});
    }

    const sortLastUpdateAscendingOrder = (searchInput) => {
        sortingHandle('updatedAt', 0, searchInput).then(r => {});
    }

    const sortLastUpdateDescendingOrder = (searchInput) => {
        sortingHandle('updatedAt', 1, searchInput).then(r => {});
    }

    const sortEndDateAscendingOrder = (searchInput) => {
        sortingHandle('endDate', 0, searchInput).then(r => {});
    }

    const sortEndDateDescendingOrder = (searchInput) => {
        sortingHandle('endDate', 1, searchInput).then(r => {});
    }

    const sortByStatus = () =>
    {
        setColumnStatus(true);
        setColumnTitle(false);
        setColumnPricing(false);
        setColumnContent(false);
        setColumnLastUpdate(false);
        setColumnEndDate(false);
        if (!sortStatus) {
            sortStatusAscendingOrder(searchValue);
            setSortStatus('asc')
        }
        
        if (sortStatus && sortStatus === 'des') {
            sortStatusAscendingOrder(searchValue);
            setSortStatus('asc')
        }
        
        if (sortStatus && sortStatus === 'asc') {
            sortStatusDescendingOrder(searchValue);
            setSortStatus('des')
        }
    };
    
    const sortByTitle = () =>
    {
        setColumnStatus(false);
        setColumnTitle(true);
        setColumnPricing(false);
        setColumnContent(false);
        setColumnLastUpdate(false);
        setColumnEndDate(false);
        if (!sortTitle) {
            sortTitleAscendingOrder(searchValue);
            setSortTitle('asc')
        }
        
        if (sortTitle && sortTitle === 'des') {
            sortTitleAscendingOrder(searchValue);
            setSortTitle('asc')
        }
        
        if (sortTitle && sortTitle === 'asc') {
            sortTitleDescendingOrder(searchValue);
            setSortTitle('des')
        }
    };
    
    const sortByPricing = () =>
    {
        setColumnStatus(false);
        setColumnTitle(false);
        setColumnPricing(true);
        setColumnContent(false);
        setColumnLastUpdate(false);
        setColumnEndDate(false);
        if (!sortPricing) {
            sortPricingAscendingOrder(searchValue);
            setSortPricing('asc')
        }
        
        if (sortPricing && sortPricing === 'des') {
            sortPricingAscendingOrder(searchValue);
            setSortPricing('asc')
        }
        
        if (sortPricing && sortPricing === 'asc') {
            sortPricingDescendingOrder(searchValue);
            setSortPricing('des')
        }
    };
    
    const sortByContent = () =>
    {
        setColumnStatus(false);
        setColumnTitle(false);
        setColumnPricing(false);
        setColumnContent(true);
        setColumnLastUpdate(false);
        setColumnEndDate(false);
        if (!sortContent) {
            sortContentAscendingOrder(searchValue);
            setSortContent('asc')
        }
        
        if (sortContent && sortContent === 'des') {
            sortContentAscendingOrder(searchValue);
            setSortContent('asc')
        }
        
        if (sortContent && sortContent === 'asc') {
            sortContentDescendingOrder(searchValue);
            setSortContent('des')
        }
    };
    
    const sortByLastUpdate = () =>
    {
        setColumnStatus(false);
        setColumnTitle(false);
        setColumnPricing(false);
        setColumnContent(false);
        setColumnLastUpdate(true);
        setColumnEndDate(false);
        if (!sortLastUpdate) {
            sortLastUpdateAscendingOrder(searchValue);
            setSortLastUpdate('asc')
        }
        
        if (sortLastUpdate && sortLastUpdate === 'des') {
            sortLastUpdateAscendingOrder(searchValue);
            setSortLastUpdate('asc')
        }
        
        if (sortLastUpdate && sortLastUpdate === 'asc') {
            sortLastUpdateDescendingOrder(searchValue);
            setSortLastUpdate('des')
        }
    };

    const sortByEndDate = () => {
        setColumnStatus(false);
        setColumnTitle(false);
        setColumnPricing(false);
        setColumnContent(false);
        setColumnLastUpdate(false);
        setColumnEndDate(true);
        if (!sortEndDate) {
            sortEndDateAscendingOrder(searchValue);
            setSortEndDate('asc')
        }

        if (sortEndDate && sortEndDate === 'des') {
            sortEndDateAscendingOrder(searchValue);
            setSortEndDate('asc')
        }

        if (sortEndDate && sortEndDate === 'asc') {
            sortEndDateDescendingOrder(searchValue);
            setSortEndDate('des')
        }
    }

    const handleClearSearchValue = async (e) => {
        setSearchValue('');
    }

    const handleChangeSearchValue = async (e) => {
        setSearchValue(e.target.value);
    }

    return (
        <Layout title='Contract' highlightedItem={3}>
            <div className='flex justify-between content-center'>
                <div>
                    {t("contract.breadcrumb_contracts")}
                    <p style={{ fontSize: '24px' }}>
                    {t("contract.contracts")}
                        ({!searchValue || searchValue.length === 0 ? contracts.totalItems : `${contracts.totalItems}/${totalContracts}`})
                    </p>
                </div>
                <div className='flex' style={{
                    height: '36px'
                }}>
                    <div>
                        {listPermission.includes('IKAST_CONTRACT_ADD') ? 
                            <Link to={'/contracts/add'}>
                                <Button style={{
                                    height: '100%',
                                    padding: '6px 16px',
                                    backgroundColor: '#D05E5A',
                                    marginLeft: '0.5rem',
                                    color: 'white',
                                    fontSize: '0.75rem'
                                }}>
                                    {t("contract.add_new_contract")}
                                </Button>
                            </Link>
                        : null}
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-12 gap-2'>
                <div className='font-bold col-span-9' />
                <div className='font-bold col-span-3'>
                    <OutlinedInput
                        id="outlined-size-small"
                        style={{ height: '36px', background: 'white' }}
                        classes={outlinedInputClasses}
                        placeholder={t('common.search')}
                        value={searchValue}
                        inputProps={{
                            autoComplete: "off"
                        }}
                        onChange={handleChangeSearchValue}
                        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                        endAdornment={<InputAdornment position="end">
                            <IconButton
                                className="no-border-button"
                                aria-label="toggle clear button"
                                onClick={handleClearSearchValue}
                            >
                                {'searchValue' !== '' ? <ClearIcon /> : ""}
                            </IconButton>
                        </InputAdornment>} />
                </div>
            </div>
            <div className='bg-white px-8 py-16 mt-8'>
                <div className='grid grid-cols-12 gap-2 justify-items-center text-center'>
                    <div className='font-bold col-span-1 flex justify-center text-center' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    </div>
                    <div className='font-bold col-span-1 flex justify-center text-center' onClick={sortByStatus} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {t("table.status")}
                        {columnStatus ? <SortContentIconRender sortOrder={sortStatus}/> : null}
                    </div>
                    <div className='font-bold col-span-2 text-left' onClick={sortByTitle} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {t("table.title")}
                        {columnTitle ? <SortContentIconRender sortOrder={sortTitle}/> : null}
                    </div>
                    <div className='font-bold col-span-1 text-center' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {t("table.with")}
                    </div>
                    <div className='font-bold col-span-1 flex justify-center text-center' onClick={sortByPricing} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {t("table.pricing")}
                        {columnPricing ? <SortContentIconRender sortOrder={sortPricing}/> : null}
                    </div>
                    <div className='font-bold col-span-1 flex justify-center text-center' onClick={sortByContent} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {t("table.contents")}
                        {columnContent ? <SortContentIconRender sortOrder={sortContent}/> : null}
                    </div>
                    <div className='font-bold col-span-2 flex justify-center text-center' onClick={sortByEndDate} style={{ color: 'rgba(0,0,0,0.54)' }}>
                    {t("table.end_date")}
                        {columnEndDate ? <SortContentIconRender sortOrder={sortEndDate}/> : null}
                    </div>
                    <div className='font-bold col-span-2 flex justify-center text-center' onClick={sortByLastUpdate} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {t("table.last_update")}
                        {columnLastUpdate ? <SortContentIconRender sortOrder={sortLastUpdate}/> : null}
                    </div>
                    <div className='font-bold col-span-1' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>

                    </div>
                </div>

                <Fragment>
                    {contracts.records && contracts.records.map((value, key) => {
                        return (
                            <Item
                                isContentUpdating={false}
                                organization={user.BelongsTo}
                                contract={value}
                                key={key}
                                deleteContract={handleDeleteContract}
                                listPermission={listPermission}
                            />
                        )
                    })}
                </Fragment>

                <div className='flex flex-row justify-center items-center py-4'>
                    <Pagination count={contracts.totalPages} page={page} onChange={pagingHandleChange} />
                    {/*<IconButton>*/}
                    {/*    <SearchIcon />*/}
                    {/*</IconButton>*/}
                </div>
            </div>
        </Layout>
    )
};

export default withSnackbar(Contract);
