import React, { useEffect, useLayoutEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";

am4core.useTheme(am4themes_animated);
const roundToTwo = (num) => {
    return +(Math.round(num + "e+2") + "e-2");
}
const BarChartSection = ({ barChartData, currency, barChartViewMode }) => {
    const renderBarChart = (inBarChartData) => {
        // Themes begin
        am4core.useTheme(am4themes_material);
        am4core.useTheme(am4themes_animated);
        // Themes end

        // Create chart instance
        var chart = am4core.create("barchartdiv", am4charts.XYChart);
        chart.scrollbarX = new am4core.Scrollbar();
        chart.fontSize = 14
        chart.colors.step = 8;
        chart.maskBullets = false;

        chart.data = inBarChartData;

        prepareParetoData();

        function prepareParetoData() {
            var sum = 0;
            for (var i = 0; i < chart.data.length; i++) {
                var value = chart.data[i].total;
                sum += value;
                chart.data[i].pareto = sum;
            }
        }

        //Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "date";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 80;
        categoryAxis.tooltip.disabled = true;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 50;
        valueAxis.min = 0;
        valueAxis.cursorTooltipEnabled = false;

        var itemList = [];
        for (const key in chart.data[0]) {
            if (key !== 'date' && key !== 'total' && key !== "pareto") {
                itemList.push(key);
            }
        }
        for (let i = 0; i < itemList.length; i++) {
            // Create series
            var series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = itemList[i];
            series.dataFields.categoryX = "date";
            series.name = itemList[i];
            series.tooltipText = barChartViewMode ? "{name}: {valueY.formatNumber('#.00')}" + ` ${currency}` + "[/]" : "{name}: {valueY} views";
            series.stacked = true;
            series.columns.template.fill = am4core.color("#ae323c").brighten(0.2 * Number(i + 1));
            series.stroke = am4core.color("#ae323c").brighten(0.2 * Number(i + 1));

            if (i == itemList.length - 1) {
                series.columns.template.column.cornerRadiusTopLeft = 3;
                series.columns.template.column.cornerRadiusTopRight = 3;
            }
        }

        var paretoValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        paretoValueAxis.renderer.opposite = true;
        paretoValueAxis.strictMinMax = true;
        paretoValueAxis.renderer.grid.template.disabled = true;
        paretoValueAxis.numberFormatter = new am4core.NumberFormatter();
        paretoValueAxis.numberFormatter.numberFormat = "#'" + ` ${currency}'`;
        paretoValueAxis.cursorTooltipEnabled = false;

        var paretoSeries = chart.series.push(new am4charts.LineSeries())
        paretoSeries.dataFields.valueY = "pareto";
        paretoSeries.dataFields.categoryX = "date";
        paretoSeries.yAxis = paretoValueAxis;
        paretoSeries.name = "Cumulative";
        paretoSeries.tooltipText = "Cumulative: {valueY.formatNumber('#.00')}" + ` ${currency}` + "[/]";
        paretoSeries.strokeWidth = 3;
        paretoSeries.stroke = new am4core.InterfaceColorSet().getFor("alternativeBackground");
        paretoSeries.stroke = am4core.color("#000");
        paretoSeries.strokeOpacity = 0.5;

        // Cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "panX";

        // Add legend
        chart.legend = new am4charts.Legend();
        chart.logo.disabled = true;

        // // Add cursor
        // chart.cursor = new am4charts.XYCursor();
        // chart.cursor.fullWidthLineX = true;
        // chart.cursor.xAxis = dateAxis;
        // chart.cursor.lineX.strokeOpacity = 0;
        // chart.cursor.lineX.fill = am4core.color("#000");
        // chart.cursor.lineX.fillOpacity = 0.1;
    }

    useEffect(() => {
        renderBarChart(barChartData);
    }, [barChartData]);

    return (
        <div>
            <div className="flex justify-center">
                <div id="barchartdiv" style={{ width: '100%', height: "350px" }}></div>
            </div>
        </div >

    );
};

export default BarChartSection;
