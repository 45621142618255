import React, { useState, useMemo } from 'react';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { GET_BUSSINESS_MODELS, GET_STATUS } from "../../../../constants";
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from 'react-i18next';
import './index.css'
const Item = ({ organization, isContentUpdating, contract, deleteContract, listPermission }) => {
    const [isHover, setIsHover] = useState(false);
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const BUSSINESS_MODELS = useMemo(() => GET_BUSSINESS_MODELS(t), [t]);
    const STATUS = useMemo(() => GET_STATUS(t), [t]);
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#696969',
            color: '#dbdbdb',
            marginBottom: 0,
            fontSize: 14,
        },
    }))(Tooltip);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseDot = () => {
        setAnchorEl(null);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onMouseEnterHandler = (e) => {
        setIsHover(true)
    };

    const onMouseLeaveHandler = (e) => {
        setIsHover(false)
    };

    const handleDeleteContract = (id) => {
        deleteContract(id);
        setOpen(false);
    };

    let displayTimeOrigin = contract.updatedAt ? contract.updatedAt : "";
    let displayTime = displayTimeOrigin.split("T");
    const time = displayTime[1].split(".");

    const displayTimeArr = displayTime[0] ? displayTime[0].split("-") : "";

    let displayEndDate = contract.endDate ? contract.endDate : "";
    let displayTimeEndDate = displayEndDate.split("T");
    const endTimeArr = displayTimeEndDate[0] ? displayTimeEndDate[0].split("-") : "";

    const renderTooltipStatus = (status) => {
        switch (status) {
            case 0:
                return (
                    t('status.rejected')
                );
            case 1:
                return (
                    t('status.started')
                );
            case 2:
                return (
                    t('status.pending')
                );
            case 3:
                return (
                    t('status.running')
                );
            case 4:
                return (
                    t('status.ended')
                );
            case 5:
                return (
                    t('status.infringed')
                );
            default:
                return (
                    t('status.started')
                );
        }
    }

    const logo = organization === contract.Seller ? contract.buyerLogo : contract.sellerLogo;

    return (
        <div
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            className='grid grid-cols-12 gap-2 text-center mt-3 rounded-lg items-center justify-items-center py-4' style={{
                background: 'rgb(242, 242, 242)',
                border: isHover ? '1px solid red' : ''
            }}>
            <LightTooltip title={contract.exclusive ? 'Exclusive' : 'Non-Exclusive'} placement="top">
                <div className='col-span-1'>
                    {contract.exclusive ? <OfflineBoltIcon style={{ fontSize: 18 }} /> : <OfflineBoltIcon style={{ fontSize: 18, opacity: 0.4 }} />}
                </div>
            </LightTooltip>
            <LightTooltip title={renderTooltipStatus(contract.Status)} placement="top">
                <div className='m-auto col-span-1 flex justify-center text-center'>
                    <span id='status' className='rounded-full block m-1' style={{
                        backgroundColor: STATUS.find(s => s.id === contract.Status).color,
                        height: '15px',
                        width: '15px',
                    }} />
                </div>
            </LightTooltip>
            <div className='col-span-2 text-left'>
                {contract.Title}
            </div>
            <div className='col-span-1 justify-center text-center' style={{ display: 'flex' }}>
                <img
                    src={logo}
                    style={{
                        width: '60px',
                        height: '60px',
                        backgroundSize: 'cover',
                        backgroundPosition: 'top center',
                    }}></img>
            </div>
            <div className='col-span-1 text-center'>
                {BUSSINESS_MODELS.find(b => b.id === contract.businessModel).value}
            </div>
            <div className='col-span-1 text-center'>
                {contract.numberOfContent ? contract.numberOfContent : 0}
            </div>
            <div className='col-span-2 text-center'>
                {endTimeArr ? `${endTimeArr[2]}/${endTimeArr[1]}/${endTimeArr[0]}` : ""}
            </div>
            <div className='col-span-2 text-center'>
                {displayTimeArr ? `${displayTimeArr[2]}/${displayTimeArr[1]}/${displayTimeArr[0]} ${time[0]}` : ""}
            </div>
            <div className='flex m-auto col-span-1' style={{ marginRight: '10px' }}>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <MoreVertIcon />
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseDot}
                >
                    {!isContentUpdating ? (<div>
                        {listPermission.includes('IKAST_CONTRACT_EDIT') ?
                            <MenuItem onClick={handleCloseDot}> <Link to={`/contracts/update/${contract.ikastUID}`}>
                                <EditRoundedIcon style={{
                                    cursor: 'pointer',
                                    width: 50
                                }} />
                            </Link></MenuItem>
                            : null}
                        {listPermission.includes('IKAST_CONTRACT_DUPLICATE') ?
                            <MenuItem onClick={handleCloseDot}><Link to={`/contracts/duplicate/${contract.ikastUID}`}>
                                <FileCopyOutlinedIcon style={{
                                    cursor: 'pointer',
                                    width: 50
                                }} />
                            </Link></MenuItem>
                            : null}
                        {listPermission.includes('IKAST_CONTRACT_DELETE') ?
                            <MenuItem onClick={handleCloseDot}><DeleteRoundedIcon
                                onClick={() => handleClickOpen()}
                                style={{
                                    cursor: 'pointer',
                                    width: 50
                                }} /> </MenuItem> : null}
                    </div>

                    ) : (<div>
                        {listPermission.includes('IKAST_CONTRACT_EDIT') ? 
                            <MenuItem onClick={handleCloseDot}> <Link to={`/contracts/update/${contract.ikastUID}`}>
                                <EditRoundedIcon style={{
                                    cursor: 'pointer',
                                    width: 50
                                }} />
                            </Link></MenuItem>
                        : null}
                        {listPermission.includes('IKAST_CONTRACT_DUPLICATE') ? 
                            <MenuItem onClick={handleCloseDot}><Link to={`/contracts/duplicate/${contract.ikastUID}`}>
                                <FileCopyOutlinedIcon style={{
                                    cursor: 'pointer',
                                    width: 50
                                }} />
                            </Link></MenuItem>
                        : null}
                    </div>)}
                </Menu>
            </div>
            {/* {!isContentUpdating ? <div className='flex m-auto col-span-1' style={{ marginRight: '10px' }}>
                <Link to={`/contracts/update/${contract.ikastUID}`}>
                    <EditRoundedIcon style={{
                        cursor: 'pointer'
                    }} />
                </Link>
                <Link to={`/contracts/duplicate/${contract.ikastUID}`}>
                    <FileCopyOutlinedIcon style={{
                        marginLeft: 12,
                        cursor: 'pointer'
                    }} />
                </Link>
                {isSuperAdmin ? <DeleteRoundedIcon
                    onClick={() => handleClickOpen()}
                    style={{
                        marginLeft: 12,
                        cursor: 'pointer'
                    }} /> : <DeleteRoundedIcon
                    disabled={true}
                    style={{
                        color: "lightgrey",
                        marginLeft: 12,
                        cursor: 'pointer'
                    }} />}
            </div> : <div className='flex m-auto col-span-1' style={{ marginRight: '10px' }}>
                <Link to={`/contracts/update/${contract.ikastUID}`}>
                    <EditRoundedIcon style={{
                        cursor: 'pointer'
                    }} />
                </Link>
                <Link to={`/contracts/duplicate/${contract.ikastUID}`}>
                    <FileCopyOutlinedIcon style={{
                        marginLeft: 12,
                        cursor: 'pointer'
                    }} />
                </Link>
            </div>} */}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Contract"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("contract.delete_contract")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="no-border-button" onClick={handleClose} color="primary">
                        {t("common.cancel")}
                    </Button>
                    <Button className="no-border-button" onClick={() => handleDeleteContract(contract.ikastUID)} color="primary" autoFocus>
                        {t("common.yes")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Item;
