import React, { useState } from 'react';
import { useSnackbar } from "notistack";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import BackspaceIcon from '@material-ui/icons/Backspace';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Link } from "react-router-dom";
import axios from "../../../../config/axios";
import * as moment from "moment";
import { useTranslation } from 'react-i18next';
const MessageItem = ({ item, refreshMessageList, handleChangeCheckItem, listPermission }) => {
    const { t, i18n } = useTranslation();
    const [isHover, setIsHover] = useState(false);
    const [open, setOpen] = useState(false);
    const [openConnection, setOpenConnection] = useState(false);
    const [openRejectConnection, setOpenRejectConnection] = useState(false);
    const [openInfringementResolved, setOpenInfringementResolved] = useState(false);
    const [openImportPreviousMessage, setOpenImportPreviousMessage] = useState(false);
    const [openDisconnectConnection, setOpenDisconnectConnection] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const renderIconForType = (type) => {
        switch (type) {
            case 1:
                return (
                    <GroupAddIcon />
                );
            case 2:
                return (
                    <AssignmentTurnedInIcon />
                );
            case 3:
                return (
                    <ReportProblemIcon />
                );
            case 4:
                return (
                    <BackspaceIcon />
                );
            case 5:
                return (
                    <ThumbUpIcon />
                );
            case 6:
                return (
                    <ThumbDownIcon />
                );
            case 7:
                return (
                    <PeopleOutlineIcon />
                );
            case 8:
                return (
                    <BeenhereIcon />
                );
            case 9:
                return (
                    <EventBusyIcon />
                );
            case 10:
                return (
                    <DoneAllIcon />
                );
            case 11:
                return (
                    <DoneAllIcon />
                );
            case 12:
                return (
                    <CloudDoneIcon />
                );
            case 13:
                return (
                    <CloudDoneIcon />
                );
            case 14:
                return (
                    <ImportExportIcon />
                );
            case 15:
                return (
                    <ImportExportIcon />
                );
            default:
                return (
                    <CloudDoneIcon />
                );
        }
    };

    const renderAction = (type, connectionStatus) => {
        const generateButton = (connectionStatus !== null) ? <div>{connectionStatus === 1 ? "Accepted" : "Rejected"}</div>
            : <Button
                className='no-border-button'
                style={{ textTransform: "none" }}
                onClick={() => handleClickOpen()}>{t("common.decide")}</Button>
        switch (type) {
            case 1:
                return generateButton;
            case 2:
                return (
                    <>
                        {listPermission.includes('IKAST_CONTRACT_EDIT') ?
                            <Button
                                className='no-border-button'
                                style={{ textTransform: "none" }}
                                component={Link} to={`/contracts/update/${item.contractID}`}
                                onClick={() => seenMessage(item.ikastUID, false)}>{t("common.check")}</Button>
                            : null}
                    </>
                );
            case 3:
                return (
                    <>
                        {listPermission.includes('IKAST_ROYALTIES_RESOLVE_INFRINGE') ?
                            <Button
                                className='no-border-button'
                                style={{ textTransform: "none" }}
                                component={Link} to={`/royalties/${item.contractID}`}
                                onClick={() => seenMessage(item.ikastUID, false)}>{t("common.resolve")}</Button>
                            : null}
                    </>
                );
            case 4:
                return (
                    <>
                        {listPermission.includes('IKAST_CONTRACT_EDIT') ?
                            <Button
                                className='no-border-button'
                                style={{ textTransform: "none" }}
                                component={Link} to={`/contracts/update/${item.contractID}`}
                                onClick={() => seenMessage(item.ikastUID, false)}>{t("common.check")}</Button>
                            : null}
                    </>
                );
            case 5:
                return (
                    <>
                        {listPermission.includes('IKAST_MESSAGE_OPEN_CONNECTION') ?
                            <Button
                                className='no-border-button'
                                style={{ textTransform: "none" }}
                                onClick={() => handleClickOpenConnectionDialog()}>{t("common.view")}</Button>
                            : null}
                    </>
                );
            case 6:
                return (
                    <>
                        {listPermission.includes('IKAST_MESSAGE_REJECT_CONNECTION') ?
                            <Button
                                className='no-border-button'
                                style={{ textTransform: "none" }}
                                onClick={() => handleClickOpenRejectConnectionDialog()}>{t("common.view")}</Button>
                            : null}
                    </>
                );
            case 7:
                return (
                    <>
                        {listPermission.includes('IKAST_MESSAGE_DISCONNECT') ?
                            <Button
                                className='no-border-button'
                                style={{ textTransform: "none" }}
                                onClick={() => handleClickOpenDisconnectDialog()}>{t("common.view")}</Button>
                            : null}
                    </>
                );
            case 8:
                return (
                    <>
                        {listPermission.includes('IKAST_CONTRACT_EDIT') ?
                            <Button
                                className='no-border-button'
                                style={{ textTransform: "none" }}
                                component={Link} to={`/contracts/update/${item.contractID}`}
                                onClick={() => seenMessage(item.ikastUID, false)}>{t("common.check")}</Button>
                            : null}
                    </>
                );
            case 9:
                return (
                    <>
                        {listPermission.includes('IKAST_CONTRACT_EDIT') ?
                            <Button
                                className='no-border-button'
                                style={{ textTransform: "none" }}
                                component={Link} to={`/contracts/update/${item.contractID}`}
                                onClick={() => seenMessage(item.ikastUID, false)}>{t("common.check")}</Button>
                            : null}
                    </>
                );
            case 10:
                return (
                    <>
                        {listPermission.includes('IKAST_CONTRACT_EDIT') ?
                            <Button
                                className='no-border-button'
                                style={{ textTransform: "none" }}
                                component={Link} to={`/contracts/update/${item.contractID}`}
                                onClick={() => seenMessage(item.ikastUID, false)}>{t("common.check")}</Button>
                            : null}
                    </>
                );
            case 11:
                return (
                    <>
                        {listPermission.includes('IKAST_CONTENT_VIEW') ?
                            <Button
                                className='no-border-button'
                                style={{ textTransform: "none" }}
                                component={Link} to={`/contents`}
                                onClick={() => seenMessage(item.ikastUID, false)}>{t("common.check")}</Button>
                            : null}
                    </>
                );
            case 12:
                return (
                    <>
                        {listPermission.includes('IKAST_ROYALTIES_VIEW') ?
                            <Button
                                className='no-border-button'
                                style={{ textTransform: "none" }}
                                component={Link} to={`/royalties`}
                                onClick={() => seenMessage(item.ikastUID, false)}>{t("common.check")}</Button>
                            : null}
                    </>
                );
            case 13:
                return (
                    <>
                        {listPermission.includes('IKAST_ROYALTIES_RESOLVE_INFRINGE') ?
                            <Button
                                className='no-border-button'
                                style={{ textTransform: "none" }}
                                onClick={() => handleClickOpenInfringementResolvedDialog()}>{t("common.view")}</Button>
                            : null}
                    </>
                );
            case 14:
                return (
                    <>
                        {listPermission.includes('IKAST_ROYALTIES_IMPORT_LOGS') ?
                            <Button
                                className='no-border-button'
                                style={{ textTransform: "none" }}
                                onClick={() => handleClickOpenImportPreviousMessageDialog()}>{t("common.view")}</Button>
                            : null}
                    </>
                );
            default:
                return (
                    t("common.resolve")
                );
        }
    };

    const renderMessageHeader = (header) => {
        switch (header) {
            case "Connection request":
                return (
                    t("message_api.connection_request")
                );
            case "Disconnected connection":
                return (
                    t("message_api.disconnected_connection")
                );
            case "Connection accepted":
                return (
                    t("message_api.connection_accepted")
                );
            case "Connection rejected":
                return (
                    t("message_api.connection_reject")
                );
            case "CSV file of viewing logs has been successfully imported":
                return (
                    t("message_api.csv_file_imported")
                );
            case "Contract validation request":
                return (
                    t("message_api.contract_validation_request")
                );
            case "Contract validation has been accepted":
                return (
                    t("message_api.contract_validation_accepted")
                );
            case "Contract has been terminated":
                return (
                    t("message_api.contract_terminated")
                );
            case "Contract validation has been rejected":
                return (
                    t("message_api.contract_validation_rejected")
                );
            case "Contract importing previous logs":
                return (
                    t("message_api.contract_importing_previous_logs")
                );
            case "Infringement detection in a contract":
                return (
                    t("message_api.infringement_detection_contract")
                );
            default:
                return (
                    header
                );
        }
    }

    const seenMessage = async (messageId, rejectConnection) => {
        const createMessage = rejectConnection ? {
            uuid: messageId,
            connectionStatus: 0
        } : { uuid: messageId };

        const messageSeen = await axios.put(`/message/update`, createMessage, {
            headers: {
                common: {
                    'x-access-token': localStorage.getItem('token')
                },
            }
        });

        if (messageSeen.status === 200) {
            console.log('Message seen.');
        } else {
            console.log('error');
        }
        refreshMessageList();
        setOpen(false);
        setOpenConnection(false);
        setOpenRejectConnection(false);
        setOpenDisconnectConnection(false);
        setOpenInfringementResolved(false);
        setOpenImportPreviousMessage(false);
    }

    const onMouseEnterHandler = (e) => {
        setIsHover(true)
    };

    const onMouseLeaveHandler = (e) => {
        setIsHover(false)
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpenConnectionDialog = () => {
        setOpenConnection(true);
    };

    const handleClickOpenRejectConnectionDialog = () => {
        setOpenRejectConnection(true);
    };

    const handleClickOpenInfringementResolvedDialog = () => {
        setOpenInfringementResolved(true);
    };

    const handleClickOpenImportPreviousMessageDialog = () => {
        setOpenImportPreviousMessage(true);
    }

    const handleClickOpenDisconnectDialog = () => {
        setOpenDisconnectConnection(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseConnectionDialog = () => {
        setOpenConnection(false);
    }

    const handleCloseRejectConnectionDialog = () => {
        setOpenRejectConnection(false);
    }

    const handleCloseDisconnectionDialog = () => {
        setOpenDisconnectConnection(false);
    }

    const handleCloseInfringementResolvedDialog = () => {
        setOpenInfringementResolved(false);
    };

    const handleCloseImportPreviousMessageDialog = () => {
        setOpenImportPreviousMessage(false);
    }

    const approveConnectionRequest = async (myOrgUid, requestPartner, messageId) => {
        const messageSeen = await axios.put(`/message/update`, {
            uuid: messageId,
            connectionStatus: 1,
        }, {
            headers: {
                common: {
                    'x-access-token': localStorage.getItem('token')
                },
            }
        });

        if (messageSeen.status === 200) {
            console.log('Message seen.');
        } else {
            console.log('error');
        }

        const response = await axios.put(`/org/update-partnership-status`, {
            myOrgUid,
            requestPartner,
            status: 2,
        }, {
            headers: {
                common: {
                    'x-access-token': localStorage.getItem('token')
                },
            }
        });

        if (response.status === 200) {
            enqueueSnackbar(t("notify.connect_accepted"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'success'
            });

            const createMessage = {
                "type": 5,
                "from": myOrgUid,
                "receiver": requestPartner,
                "header": "Connection accepted",
                "body": "Connection accepted"
            }
            const newMessage = await axios.post('/message/', createMessage, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

        } else {
            enqueueSnackbar(t("notify.connect_accept_fail"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
        refreshMessageList();
        setOpen(false);
    };

    const rejectConnectionRequest = async (myOrgUid, requestPartner, messageId) => {
        const messageSeen = await axios.put(`/message/update`, {
            uuid: messageId,
            connectionStatus: 0,
        }, {
            headers: {
                common: {
                    'x-access-token': localStorage.getItem('token')
                },
            }
        });

        if (messageSeen.status === 200) {
            console.log('Message seen.');
        } else {
            console.log('error');
        }

        const response = await axios.put(`/org/update-partnership-status`, {
            myOrgUid,
            requestPartner,
            status: 1,
        }, {
            headers: {
                common: {
                    'x-access-token': localStorage.getItem('token')
                },
            }
        });

        if (response.status === 200) {
            enqueueSnackbar(t("notify.connect_reject"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'success'
            });

            const createRejectMessage = {
                "type": 6,
                "from": myOrgUid,
                "receiver": requestPartner,
                "header": "Connection rejected",
                "body": "Connection rejected"
            }
            const newMessage = await axios.post('/message/', createRejectMessage, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

        } else {
            enqueueSnackbar(t("notify.connect_reject_fail"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
        refreshMessageList();
        setOpen(false);
    };

    let displayTimeOrigin = item.createdAt ? item.createdAt : "";
    const showTime = moment(displayTimeOrigin).local().format('DD/MM/YYYY HH:mm:ss');
    // let displayTime = displayTimeOrigin.split("T");
    // const time = displayTime[1].split(".");

    // const displayTimeArr = displayTime[0] ? (displayTime[0].split("-")) : "";

    return (
        <div
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            className='grid grid-cols-12 gap-2 text-center mt-3 rounded-lg items-center justify-items-center'
            style={item.viewed ? {
                height: '50px',
                background: 'rgb(242, 242, 242)',
                border: isHover ? '1px solid red' : '',
            } : {
                height: '50px',
                background: 'rgb(242, 242, 242)',
                border: isHover ? '1px solid red' : '',
                fontWeight: '600',
            }}>
            {listPermission.includes('IKAST_MESSAGE_DELETE') ?
                <div className='col-span-1'>
                    <Checkbox checked={item.checked} onChange={() => handleChangeCheckItem(item)} />
                </div>
                : null}
            <div className='m-auto col-span-1 flex justify-center text-center'>
                {renderIconForType(item.type ? item.type : 0)}
            </div>
            <div className='col-span-4 justify-left text-left'>
                {renderMessageHeader(item.header)}
            </div>
            <div className='col-span-3 justify-left text-left'>
                {item.senderName}
            </div>
            <div className='col-span-2' style={{ paddingRight: 20 }}>
                {item.createdAt ? showTime : ""}
            </div>
            <div className='col-span-1 justify-left text-left'
                style={{
                    borderLeft: '2px solid #c1c1c1bf',
                    marginLeft: '-20px',
                    paddingLeft: '15px',
                    fontSize: 'small',
                    color: 'grey'
                }}
            >{
                    renderAction(item.type ? item.type : '0', item.connectionStatus)
                }</div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('message.decide')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('message.accept_connect_confirm', { senderName: item.senderName })}
                        {/* Do you accept the connection request from {item.senderName}? */}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='no-border-button' onClick={() => rejectConnectionRequest(item.receiver, item.from, item.ikastUID)} color="primary">
                        {t('common.no')}
                    </Button>
                    <Button onClick={() => approveConnectionRequest(item.receiver, item.from, item.ikastUID)} className='no-border-button' color="primary" autoFocus>
                        {t('common.yes')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openConnection}
                onClose={handleCloseConnectionDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('message.connect_accept')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('message.accept_connect_title', { senderName: item.senderName })}
                        {/* Your connection request to {item.senderName} has been accepted! */}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => seenMessage(item.ikastUID, false)} className='no-border-button' color="primary" autoFocus>
                        {t('common.ok')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openRejectConnection}
                onClose={handleCloseRejectConnectionDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('message.connect_reject')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('message.accept_connect_reject', { senderName: item.senderName })}
                        {/* Your connection request to {item.senderName} has been rejected! */}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => seenMessage(item.ikastUID, false)} className='no-border-button' color="primary" autoFocus>
                        {t('common.ok')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDisconnectConnection}
                onClose={handleCloseDisconnectionDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('message.connect_disconnect')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('message.disconnect_title', { senderName: item.senderName })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => seenMessage(item.ikastUID, false)} className='no-border-button' color="primary" autoFocus>
                        {t('common.ok')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openInfringementResolved}
                onClose={handleCloseInfringementResolvedDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('message.contract_infri_resolve')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {item.body}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => seenMessage(item.ikastUID, false)} className='no-border-button' color="primary" autoFocus>
                        {t('common.ok')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openImportPreviousMessage}
                onClose={handleCloseImportPreviousMessageDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('message.contract_import_log')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {item.body}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => seenMessage(item.ikastUID, false)} className='no-border-button' color="primary" autoFocus>
                        {t('common.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default MessageItem;
