import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import List from '@material-ui/core/List';
import { List } from "react-virtualized";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ClearIcon from "@material-ui/icons/Clear";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import noPicture from '../../../../assets/images/noPicture.jpg';
import { useTranslation } from 'react-i18next';
import './index.css';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: '100%',
        height: 230,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        "& $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&:hover $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&$focused $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        }
    },
    focused: {},
    notchedOutline: {}
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#696969',
        color: '#dbdbdb',
        marginBottom: 0,
        fontSize: 14,
    },
}))(Tooltip);

const imageAndText = ({image, text, index}) => {
    return (
            <LightTooltip key={index} title={text} placement="top">
                <span style={{display: 'flex', alignItems: 'center'}}>{image ? <img alt="" src={image} style={{ height: 30, width: 'auto', marginRight: 5 }} /> : ''}<span>{text}</span></span>
            </LightTooltip>
    )
}
const MemoImageAndText = React.memo(imageAndText);

const TransferList = ({ sellerName, disabled, listContent, pickedContent, licensedContent }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const outlinedInputClasses = useOutlinedInputStyles();
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
    const [initialLeft, setInitialLeft] = React.useState(listContent);
    const [initialRight, setInitialRight] = React.useState(pickedContent);

    const [transferLeft, setTransferLeft] = React.useState([]);
    const [transferRight, setTransferRight] = React.useState([]);

    const [searchLeftValue, setSearchLeftValue] = React.useState('');
    const [searchRightValue, setSearchRightValue] = React.useState('');

    useEffect(() => {
        setInitialLeft(listContent)
    }, [listContent]);

    useEffect(() => {
        setInitialRight(pickedContent)
    }, [pickedContent]);

    useEffect(() => {
        const pickedRightContent = listContent ? listContent.filter(aContent => pickedContent.includes(aContent.ikastUID)) : [];
        setRight(pickedRightContent);
        setTransferRight(pickedRightContent);
    }, [listContent]);

    useEffect(() => {
        const leftContent = listContent ? listContent.filter(aContent => !pickedContent.includes(aContent.ikastUID)) : [];
        setLeft(leftContent);
        setTransferLeft(leftContent);
    }, [listContent]);

    useEffect(() => {
        if (searchLeftValue) {
            let listContentFilter;
            listContentFilter = transferLeft.filter((value, index, arr) => {
                return (value.Title.toLocaleLowerCase()).includes(searchLeftValue.toLocaleLowerCase());
            });
            setLeft(listContentFilter)
        } else {
            setLeft(transferLeft)
        }
    }, [transferLeft]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const filterContentList = (text) => {
        let listContentFilter;
        listContentFilter = transferLeft.filter((value, index, arr) => {
            return (value.Title.toLocaleLowerCase()).includes(text.toLocaleLowerCase());
        });
        setLeft(listContentFilter)
    }

    const filterContentListRight = (text) => {
        let listContentFilter;
        listContentFilter = transferRight.filter((value, index, arr) => {
            return (value.Title.toLocaleLowerCase()).includes(text.toLocaleLowerCase());
        });
        setRight(listContentFilter)
    }

    const handleChangeSearchLeftValue = (e) => {
        setSearchLeftValue(e.target.value);
        filterContentList(e.target.value);
    }

    const handleChangeSearchRightValue = (e) => {
        setSearchRightValue(e.target.value);
        filterContentListRight(e.target.value);
    }

    const handleClearSearchLeftValue = async (e) => {
        setSearchLeftValue('');
        filterContentList('');
    }

    const handleClearSearchRightValue = async (e) => {
        setSearchRightValue('');
        filterContentListRight('');
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setTransferRight(transferRight.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setTransferLeft(not(transferLeft, leftChecked));
        setChecked(not(checked, leftChecked));
        licensedContent(right.concat(leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setTransferLeft(transferLeft.concat(rightChecked));
        setRight(not(right, rightChecked));
        setTransferRight(not(transferRight, rightChecked));
        setChecked(not(checked, rightChecked));
        licensedContent(not(right, rightChecked));
    };

    const testList = (items, isLeft) => {
        if (isLeft) {
            return (
                <List rowRenderer={renderRowLeft}
                    width={1200}
                    height={300}
                    rowCount={items.length}
                    rowHeight={60}
                    style={{ display: 'block' }}>
                    <ListItem />
                </List>
            )
        } else {
            return (
                <List rowRenderer={renderRowRight}
                    width={1200}
                    height={300}
                    rowCount={items.length}
                    rowHeight={60}
                    style={{ display: 'block' }}>
                    <ListItem />
                </List>
            )
        }
    }

    const renderRowLeft = ({ index, key, style }) => {
        const labelId = `transfer-list-all-item-${left[index].Title}-label`;
        return (
            <div key={key} style={style}>
                <ListItem disabled={disabled} role="listitem" button onClick={handleToggle(left[index])}>
                    <ListItemIcon >
                        <Checkbox
                            checked={checked.indexOf(left[index]) !== -1}
                            tabIndex={-1}
                            disableRipple
                            disabled={disabled}
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                    </ListItemIcon>
                    <ListItemText id={labelId} className={'ellipsis_text'} primary={
                        <MemoImageAndText image={left[index]["illustrationUrlS3"] ? left[index]["illustrationUrlS3"] : noPicture} text={left[index].Title} index={index}/>
                    } />
                </ListItem>
            </div>
        )
    }

    const renderRowRight = ({ index, key, style }) => {
        const labelId = `transfer-list-all-item-${right[index].Title}-label`;
        return (
            <div key={key} style={style}>
                <ListItem disabled={disabled} role="listitem" button onClick={handleToggle(right[index])}>
                    <ListItemIcon >
                        <Checkbox
                            checked={checked.indexOf(right[index]) !== -1}
                            tabIndex={-1}
                            disableRipple
                            disabled={disabled}
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                    </ListItemIcon>
                    <ListItemText id={labelId} className={'ellipsis_text'} primary={
                        <MemoImageAndText image={right[index]["illustrationUrlS3"] ? right[index]["illustrationUrlS3"] : noPicture} text={right[index].Title} index={index}/>
                    } />
                </ListItem>
            </div>
        )
    }

    const customList = (title, items, isLeft) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={items && ((numberOfChecked(items) === items.length && items.length !== 0) || false)}
                        indeterminate={items && numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items == null || (items && items.length === 0) || disabled}
                        inputProps={{ 'aria-label': "all items selected" }}
                    />
                }
                title={`${items ? items.length : 0} ${title}`}
                subheader={items ? `${numberOfChecked(items)}/${items.length} ${t("contract.selected")}` : ''}
            />
            <Divider />
            {testList(items, isLeft)}
        </Card>
    );

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.root}>
            <div className='w-2/5'>
                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }} className='my-4'>
                    {sellerName !== null ? sellerName + "'s" : "My"} {t("contract.catalog")}
                </InputLabel>
                <OutlinedInput
                    className='w-full'
                    id="outlined-size-smallL"
                    style={{ height: '36px', background: 'white' }}
                    classes={outlinedInputClasses}
                    placeholder={t("common.search")}
                    value={searchLeftValue}
                    inputProps={{
                        autoComplete: "off"
                    }}
                    onChange={handleChangeSearchLeftValue}
                    startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                    endAdornment={<InputAdornment position="end">
                        <IconButton
                            className="no-border-button"
                            aria-label="toggle clear button"
                            onClick={handleClearSearchLeftValue}
                        >
                            {searchLeftValue !== '' ? <ClearIcon /> : ""}
                        </IconButton>
                    </InputAdornment>} />
                <Grid className='w-full' item>{customList(t("contract.contents"), left, true)}</Grid>
            </div>
            <Grid className='w-1/5' item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={(leftChecked.length === 0 || disabled)}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={(rightChecked.length === 0 || disabled)}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <div className='w-2/5'>
                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }} className='my-4'>
                    {t("contract.licensed_elements")}
                </InputLabel>
                <OutlinedInput
                    className='w-full'
                    id="outlined-size-smallR"
                    style={{ height: '36px', background: 'white' }}
                    classes={outlinedInputClasses}
                    placeholder={t("common.search")}
                    value={searchRightValue}
                    inputProps={{
                        autoComplete: "off"
                    }}
                    onChange={handleChangeSearchRightValue}
                    startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                    endAdornment={<InputAdornment position="end">
                        <IconButton
                            className="no-border-button"
                            aria-label="toggle clear button"
                            onClick={handleClearSearchRightValue}
                        >
                            {searchRightValue !== '' ? <ClearIcon /> : ""}
                        </IconButton>
                    </InputAdornment>} />
                <Grid className='w-full' item>{customList(t("contract.contents"), right, false)}</Grid>
            </div>
        </Grid>
    );
}

export default TransferList;

