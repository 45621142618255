import React, { useEffect, useRef, useState } from 'react';
import logo from '../../../assets/images/logo_text.png';
import './index.css';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { NavLink } from 'react-router-dom';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import StoreFrontIcon from '@material-ui/icons/Storefront';
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import axios from '../../../config/axios';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { authActions, authSelectors } from "../../../state/modules/auth";
import { useDispatch, useSelector } from "react-redux";
import openSocket from 'socket.io-client';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import LanguageComponent from "../../components/Language";
import { useTranslation } from 'react-i18next';
const socket = openSocket(process.env.REACT_APP_SERVER_API_URL ? process.env.REACT_APP_SERVER_API_URL : 'http://localhost:8089')

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const Layout = ({ title, userName, userProfilePic, children, backgroundColor, highlightedItem }) => {
    const useLogoStyles = makeStyles({
        root: {
            fontSize: '2.5rem',
        },
    });
    const useSubMenuStyles = makeStyles({
        root: {
            color: '#000000'
        }
    });

    const [open, setOpen] = useState(false);
    const [userLogo, setUserLogo] = useState(null);
    const [userPic, setUserPic] = useState(null);
    const [isNotNullAvatar, setIsNotNullAvatar] = useState(false);
    const [openReports, setOpenReports] = useState(false);
    const [nbOfUnreadMessage, setNbOfUnreadMessage] = useState(null);
    const [listPermission, setListPermission] = useState([]);
    const selector = useSelector((state) => authSelectors.user(state));
    const [user, setUserTracking] = useState(selector);
    const prevData = usePrevious(selector);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        if(user) {
            setListPermission(user.get('permissions').toArray());
        }
        socket.on('new', (data) => {
            if (data && user) {
                (async function getListMessages() {
                    const contentResponse = await axios.get(`/message/get-list-messages?orgUuid=${user.get('BelongsTo')}&pageNo=1&itemPerPage=10`, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });

                    const { data, status } = contentResponse;

                    if (status === 200) {
                        setNbOfUnreadMessage(data.totalItems);
                    }
                })();
            }
        });
        setUserTracking(selector);
        if (!user) return;
        var matches = (user.get('First Name') + ' ' + user.get('Name')).match(/\b(\w)/g); // ['J','S','O','N']
        setUserLogo(matches.join('').toLocaleUpperCase()); // JSON
        setIsNotNullAvatar(user.get('profilePictureUrl') !== null);

        setUserPic(user.get('profilePictureUrl'));

        if (user && user.get('org')) {
            (async function getListMessages() {
                const contentResponse = await axios.get(`/message/get-list-messages?orgUuid=${user.get('BelongsTo')}&pageNo=1&itemPerPage=10`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

                const { data, status } = contentResponse;

                if (status === 200) {
                    setNbOfUnreadMessage(data.totalItems);
                }
            })();
        }

    }, [selector, prevData]);

    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(authActions.getProfile({ id: localStorage.getItem('id') }));
    // }, []);


    const handleOpenLogoutDialog = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickReports = () => {
        setOpenReports(!openReports);
    }

    const handleLogout = async () => {
        const response = await axios.get(`/auth/signout`, {
            headers: {
                common: {
                    'x-access-token': localStorage.getItem('token')
                }
            }
        });

        if (response.status === 200) {
            localStorage.removeItem('token');
            localStorage.removeItem('id');
            window.location.href = '/';
        }
    };

    const navigationToNewTab = () => {
        var win = window.open('https://support.okast.tv/fr/support/solutions/103000150326', '_blank');
        win.focus();
    };

    return (
        <div className='dashboard-container h-full'>
            <div className='dashboard-layout' style={{ zIndex: '10', position: 'fixed', top: '0px', width: '100%' }}>
                <div className='flex content-center justify-between px-2'>
                    <div className='flex items-center'>
                        <img src={logo} style={{ height: '50%' }} alt='logo' />
                    </div>

                    <div className='flex items-center'>
                        <LanguageComponent />
                        <button className='beamerTrigger' id='bell'>
                            <NotificationsNoneIcon
                                classes={{ root: useLogoStyles().root }}
                                className='mr-4 items-center' style={{ color: '#fff', fontSize: '2rem' }} />
                        </button>
                        <button className='no-border-button'><HelpOutlineOutlinedIcon
                            onClick={navigationToNewTab}
                            classes={{
                                root: useLogoStyles().root
                            }}
                            className='mr-4' style={{ color: '#fff', fontSize: '2rem' }} /></button>
                        {isNotNullAvatar ? <img
                            src={userPic}
                            style={{
                                width: '44px',
                                height: '44px',
                                backgroundSize: 'cover',
                                backgroundPosition: 'top center',
                                borderRadius: '50%',
                            }}></img> :
                            <div className='rounded text-2xl rounded-full text-gray-600 p-1'
                                style={{
                                    background: '#f2f2f2',
                                    width: '44px',
                                    justifyContent: 'center',
                                    display: 'flex'
                                }}>
                                {userLogo}
                            </div>}
                    </div>
                </div>
            </div>
            <div className='dashboard-content w-full flex h-full' style={{ marginTop: 62 }}>
                <div className='dashboard-sidebar bg-white pt-4'
                    style={{ boxShadow: '6px 0px 7px 0px #e7e7e7', color: '#818181', minWidth: 215 }}>
                    <List style={{ zIndex: '10', position: 'sticky', top: '80px' }}>
                        {listPermission.includes('IKAST_DASHBOARD_VIEW') ? (
                            <ListItem
                                button
                                component={NavLink}
                                exact to={'/dashboard'}
                                selected={highlightedItem === 6}
                                style={highlightedItem === 6 ? {
                                    borderLeft: ' solid rgb(208, 94, 90) 3px',
                                } : null}
                            >
                                <ListItemIcon>
                                    <DashboardOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('layout.dashboard')} className='uppercase' />
                            </ListItem>
                        ) : null}
                        {listPermission.includes('IKAST_MESSAGE_VIEW') ? (
                            <ListItem
                                button
                                component={NavLink}
                                exact to={'/messages'}
                                selected={highlightedItem === 0}
                                style={highlightedItem === 0 ? {
                                    borderLeft: ' solid rgb(208, 94, 90) 3px',
                                } : null}
                            >
                                <ListItemIcon>
                                    <MailOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('layout.messages')} className='uppercase' />
                                {nbOfUnreadMessage ? <button className='no-border-button' style={{
                                    justifyContent: 'center',
                                    display: 'flex',
                                    fontSize: '13px',
                                    textAlign: 'center',
                                    borderRadius: '50%',
                                    backgroundColor: '#d05e5a',
                                    color: 'white',
                                    width: '24px',
                                    height: '24px',
                                    alignItems: 'center',
                                }}
                                >{nbOfUnreadMessage}</button> : null}
                            </ListItem>

                        ) : null}
                        {listPermission.includes('IKAST_PARTNER_VIEW') ? (
                            <ListItem
                                button
                                component={NavLink}
                                exact to={'/business'}
                                selected={highlightedItem === 1}
                                style={highlightedItem === 1 ? {
                                    borderLeft: ' solid rgb(208, 94, 90) 3px',
                                } : null}
                            >
                                <ListItemIcon>
                                    <StoreFrontIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('layout.partners')} className='uppercase' />
                            </ListItem>
                         ) : null}
                        {listPermission.includes('IKAST_CONTENT_VIEW') ? (
                            <ListItem
                                button
                                component={NavLink}
                                exact to={'/contents'}
                                selected={highlightedItem === 2}
                                style={highlightedItem === 2 ? {
                                    borderLeft: ' solid rgb(208, 94, 90) 3px',
                                } : null}
                            >
                                <ListItemIcon>
                                    <PlayCircleOutlineOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('layout.contents')} className='uppercase' />
                            </ListItem>
                        ) : null}
                        {listPermission.includes('IKAST_CONTRACT_VIEW') ? (
                            <ListItem
                                button
                                component={NavLink}
                                exact to={'/contracts'}
                                selected={highlightedItem === 3}
                                style={highlightedItem === 3 ? {
                                    borderLeft: ' solid rgb(208, 94, 90) 3px',
                                } : null}
                            >
                                <ListItemIcon>
                                    <ShoppingBasketOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('layout.licensing')} className='uppercase' />
                            </ListItem>
                        ) : null}
                        {listPermission.includes('IKAST_ROYALTIES_VIEW') ? (
                            <ListItem
                                button
                                onClick={handleClickReports}
                                style={highlightedItem === 4 ? {
                                    borderLeft: ' solid rgb(208, 94, 90) 3px',
                                } : null}
                            >
                                <ListItemIcon>
                                    <AssignmentIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('layout.reports')} className='uppercase' />
                                {openReports || highlightedItem === 4 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                        ) : null}
                        <Collapse in={openReports || highlightedItem === 4} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem
                                    button
                                    className={useSubMenuStyles().root}
                                    component={NavLink}
                                    exact to={'/royalties'}
                                    selected={highlightedItem === 4}
                                    style={highlightedItem === 4 ? {
                                        borderLeft: ' solid rgb(208, 94, 90) 3px',
                                    } : null}
                                >
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary={t('layout.royalties')} className='uppercase' />
                                </ListItem>
                            </List>
                        </Collapse>
                        {listPermission.includes('IKAST_SETTING_VIEW') ? (
                            <ListItem
                                button
                                component={NavLink}
                                exact to={'/profile'}
                                selected={highlightedItem === 5}
                                style={highlightedItem === 5 ? {
                                    borderLeft: ' solid rgb(208, 94, 90) 3px',
                                } : null}
                            >
                                <ListItemIcon>
                                    <SettingsOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('layout.settings')} className='uppercase' />
                            </ListItem>
                        ) : null }

                        <ListItem button onClick={handleOpenLogoutDialog}>
                            <ListItemIcon>
                                <ExitToAppOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('layout.logout')} className='uppercase' />
                        </ListItem>
                    </List>
                </div>
                <div className='dashboard-main' style={{ borderLeft: '1px solid #e2e8f0', width: 'calc(100vw - 215px)', minWidth: 1000, minHeight: 'calc(100vh - 62px)' }}>
                    <div className='px-8 py-8' style={{
                        background: backgroundColor ? backgroundColor : 'rgb(242, 242, 242)', minHeight: 'calc(100vh - 62px)'
                    }}>{children}</div>
                </div>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('layout.logout')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('layout.confirm_logout')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='no-border-button' onClick={() => handleClose()} color="primary">
                    {t('common.no')}
                    </Button>
                    <Button onClick={() => handleLogout()} className='no-border-button' color="primary" autoFocus>
                    {t('common.yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Layout;
