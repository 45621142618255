import React, { useState } from 'react';
import LoadingContext from './LoadingContext';

const LoadingProvider = ({ children }) => {
	const [loadingCount, setLoadingCount] = useState(0);

	const showLoading = () => {
		setLoadingCount(prev => prev + 1);
	};

	const hideLoading = () => {
		setTimeout(() => {
			setLoadingCount(prev => (prev > 0 ? prev - 1 : 0));
		}, 300);
	};

	return (
		<LoadingContext.Provider value={{
			loadingCount, showLoading, hideLoading,
		}}
		>
			{children}
		</LoadingContext.Provider>
	);
};

export default LoadingProvider;