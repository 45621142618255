import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './tailwind.css';
import * as serviceWorker from './serviceWorker';
import App from './views/App';
import './main.css';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './state/configureStore';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import rootReducer from "./state/rootReducer";
import rootSaga from './state/rootSaga';
import LoadingProvider from './context/loading/LoadingProvider';
import Spinner from './context/loading/Spinner';
import './i18n';
const store = configureStore({}, rootReducer);

store.runSaga(rootSaga);

ReactDOM.render(
    <Provider store={store}>
        <LoadingProvider>
            <Spinner />
            <BrowserRouter>
                <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
                    <Suspense fallback="loading">
                        <App />
                    </Suspense>
                </SnackbarProvider>
            </BrowserRouter>
        </LoadingProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
