import React, { Fragment, useState, useMemo } from 'react';
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import TextField from "@material-ui/core/TextField/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import PricingLadder from "../../components/Contract/PricingLadder";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from '@material-ui/core/Switch';
import TransferList from '../../components/Contract/TransferList';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
    GET_BUSSINESS_MODELS,
    GET_CALCULATION,
    CURRENCY,
    GET_EVALUATED_OPTIONS,
    GET_MG_APPLIED,
    GET_PRICING,
    GET_VIEWER_SESSION
} from './../../../constants';
import MenuItem from "@material-ui/core/MenuItem";
import { NumericFormat } from "react-number-format";
import { useTranslation } from 'react-i18next';
import './index.css';
const BusinessRuleTab = (props) => {
    const { t, i18n } = useTranslation();
    const BUSSINESS_MODELS = useMemo(() => GET_BUSSINESS_MODELS(t), [t]);
    const EVALUATED_OPTIONS = useMemo(() => GET_EVALUATED_OPTIONS(t), [t]);
    const VIEWER_SESSION = useMemo(() => GET_VIEWER_SESSION(t), [t]);
    const PRICING = useMemo(() => GET_PRICING(t), [t]);
    const CALCULATION = useMemo(() => GET_CALCULATION(t), [t]);
    const MG_APPLIED = useMemo(() => GET_MG_APPLIED(t), [t]);
    const { newContract, isMarketShare, marketShareBonusValue, handleChangeMarketShareBonusValue, handleChangeMarketShare, classes, mappingElementContract, mentionAsValueItem, handleChangeFlatRate, flatRate, user, handleChangeMG, MG, viewerSession, handleChangeViewerSession,
        handleChangeConditionValue, conditionValue, pricingOption, handlePricingChange, calculationsDone, handleCalculationsChange, handleChangeMGApplied,
        handleChangeViewerCount, viewerCount, handleChangeMinutesWatched, minutesWatched, nbTransaction, handleChangeNbTransaction,
        handleChangeNbAdsServed, nbAdsServed, revPerSubs, handleChangeRevPerSubs, handleChangeRevPerAds, revPerAds, screenDisable, errorEndDate, errorStartDate,
        initializePricingLadder, handlePricingLadder, mgApplied, handleClickRestrictButton, isInclude, countries, handleChangeTerritories, handleChangeDatetime} = props;
        return (
        <div className='bg-white px-8 py-8'>
            <div className='add-contracts-licensing'>
                <div className='grid grid-cols-3'>
                    <div className='items-center mt-4'>
                        <InputLabel
                            shrink
                            style={{
                                color: 'rgba(0, 0, 0, 0.54)',
                                marginBottom: '0.5rem'
                            }}
                        >
                            {t("contract.monetized_as")}
                        </InputLabel>

                        <TextField
                            id="select"
                            style={{
                                marginTop: 'auto',
                                width: '60%'
                            }}
                            disabled={screenDisable}
                            className={classes.root}
                            key='add-contract-monetized'
                            onChange={mappingElementContract}
                            variant='outlined'
                            defaultValue={BUSSINESS_MODELS.find(b => b.default).id}
                            select>
                            {
                                BUSSINESS_MODELS && BUSSINESS_MODELS.map((value, key) => {
                                    return (
                                        <MenuItem key={key} value={value.id}>{value.value}</MenuItem>
                                    )
                                })
                            }
                        </TextField>
                    </div>
                    {
                        mentionAsValueItem.includes(1) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)',
                            }}>
                               {t("contract.flat")}
                            </InputLabel>
                            <NumericFormat
                                className='global-id text-right'
                                style={{
                                    width: '60%'
                                }}
                                disabled={screenDisable}
                                onChange={handleChangeFlatRate}
                                value={flatRate}
                                decimalScale={3}
                                // id='global-id'
                                name='flatRate'
                                thousandSeparator={t('common.thousand_separator')} 
                                decimalSeparator={t('common.decimal_separator')}
                                allowNegative={false}
                            />
                            <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>
                                {(user && user.get('org')) && CURRENCY.find(c => c.id === user.getIn(['org', 'mainCurrency'])).value}</span>
                        </div>
                    }
                    {
                        mentionAsValueItem.includes(15) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                            </InputLabel>
                        </div>
                    }
                    {
                        mentionAsValueItem.includes(2) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {t("contract.minimum_guaranteed")}
                            </InputLabel>
                            <NumericFormat
                                className='global-id text-right'
                                style={{
                                    width: '60%'
                                }}
                                disabled={screenDisable}
                                onChange={handleChangeMG}
                                value={MG}
                                decimalScale={3}
                                // id='global-id'
                                name='MG'
                                thousandSeparator={t('common.thousand_separator')} 
                                decimalSeparator={t('common.decimal_separator')}
                                allowNegative={false}
                            />
                            <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>
                                {(user && user.get('org')) && CURRENCY.find(c => c.id === user.getIn(['org', 'mainCurrency'])).value}</span>
                        </div>
                    }
                    {
                        mentionAsValueItem.includes(9) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {t("contract.view_evaluated")}
                            </InputLabel>
                            <TextField
                                id="selectEvaluatedOption"
                                style={{
                                    marginTop: '0.5rem',
                                    width: '60%'
                                }}
                                disabled={screenDisable}
                                className={classes.root}
                                key='selectEvaluatedOption'
                                // onChange={mappingElementContract}
                                variant='outlined'
                                defaultValue={EVALUATED_OPTIONS.find(b => b.default).id}
                                select>
                                {
                                    EVALUATED_OPTIONS && EVALUATED_OPTIONS.map((value, key) => {
                                        return (
                                            <MenuItem key={key} value={value.id}>{value.value}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </div>
                    }
                    {
                        mentionAsValueItem.includes(10) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {t("contract.where_viewer_session")}
                            </InputLabel>
                            <TextField
                                id="selectViewerSession"
                                style={{
                                    marginTop: '0.5rem',
                                    width: '60%'
                                }}
                                disabled={screenDisable}
                                key="selectViewerSession"
                                className={classes.root}
                                value={viewerSession}
                                onChange={handleChangeViewerSession}
                                variant='outlined'
                                defaultValue={VIEWER_SESSION.find(b => b.default).id}
                                select>
                                {
                                    VIEWER_SESSION && VIEWER_SESSION.map((value, key) => {
                                        return (
                                            <MenuItem key={key} value={value.id}>{value.value}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </div>
                    }
                    {
                        mentionAsValueItem.includes(11) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {t("contract.condition")}
                            </InputLabel>
                            <NumericFormat
                                className='global-id text-right'
                                style={{
                                    width: '60%'
                                }}
                                disabled={screenDisable}
                                onChange={handleChangeConditionValue}
                                name='condition'
                                value={conditionValue}
                                decimalScale={4}
                                id='condition'
                                thousandSeparator={t('common.thousand_separator')} 
                                decimalSeparator={t('common.decimal_separator')}
                                allowNegative={false}
                            />
                            {viewerSession ? <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>
                                {VIEWER_SESSION.find(b => b.id === viewerSession).unit}</span> : ''}

                        </div>
                    }
                    {
                        mentionAsValueItem && mentionAsValueItem.includes(12) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {t("contract.pricing")}
                            </InputLabel>
                            <TextField
                                id="selectPricingOption"
                                style={{
                                    marginTop: '0.5rem',
                                    width: '60%'
                                }}
                                disabled={screenDisable}
                                key='selectPricingOption'
                                value={pricingOption ? pricingOption : PRICING.find(b => b.default).id}
                                className={classes.root}
                                onChange={handlePricingChange}
                                variant='outlined'
                                select>
                                {
                                    PRICING && PRICING.map((value, key) => {
                                        return (
                                            <MenuItem key={key} value={value.id}>{value.value}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </div>
                    }
                    {
                        mentionAsValueItem && mentionAsValueItem.includes(13) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {t("contract.calculations_done")}
                            </InputLabel>
                            <TextField
                                id="selectCalculationOption"
                                key="selectCalculationOption"
                                // disabled={true}
                                style={{
                                    marginTop: '0.5rem',
                                    width: '60%'
                                }}
                                disabled={screenDisable}
                                className={classes.root}
                                value={calculationsDone ? calculationsDone : CALCULATION.find(b => b.default).id}
                                onChange={handleCalculationsChange}
                                variant='outlined'
                                select>
                                {
                                    CALCULATION && CALCULATION.map((value, key) => {
                                        return (
                                            <MenuItem key={key} value={value.id}>{value.value}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </div>
                    }
                    {
                        mentionAsValueItem && mentionAsValueItem.includes(14) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                               {t("contract.mg_applied")}
                            </InputLabel>
                            <TextField
                                id="selectMGAppliedOption"
                                key="selectMGAppliedOption"
                                // disabled={true}
                                style={{
                                    marginTop: '0.5rem',
                                    width: '60%'
                                }}
                                disabled={screenDisable}
                                className={classes.root}
                                onChange={handleChangeMGApplied}
                                variant='outlined'
                                defaultValue={MG_APPLIED.find(b => b.default).id}
                                select>
                                {
                                    MG_APPLIED && MG_APPLIED.map((value, key) => {
                                        return (
                                            <MenuItem key={key} value={value.id}>{value.value}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </div>
                    }
                    {
                        mentionAsValueItem.includes(3) && pricingOption === 1 &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {t("contract.each_view_billed")}
                            </InputLabel>
                            <NumericFormat
                                className='global-id text-right'
                                style={{
                                    width: '60%'
                                }}
                                disabled={screenDisable}
                                onChange={handleChangeViewerCount}
                                name='viewerCount'
                                value={viewerCount}
                                decimalScale={4}
                                thousandSeparator={t('common.thousand_separator')} 
                                decimalSeparator={t('common.decimal_separator')}
                                allowNegative={false}
                            />
                            <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>
                                {(user && user.get('org')) && CURRENCY.find(c => c.id === user.getIn(['org', 'mainCurrency'])).value}</span>
                        </div>
                    }

                    {
                        mentionAsValueItem.includes(4) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {t("contract.minutes_watched")}
                            </InputLabel>
                            <NumericFormat
                                className='text-right'
                                style={{
                                    width: '60%'
                                }}
                                disabled={screenDisable}
                                onChange={handleChangeMinutesWatched}
                                value={minutesWatched}
                                name='minutesWatched'
                                decimalScale={4}
                                id='global-id'
                                thousandSeparator={t('common.thousand_separator')} 
                                decimalSeparator={t('common.decimal_separator')}
                                allowNegative={false}
                            />
                            <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>
                                {(user && user.get('org')) && CURRENCY.find(c => c.id === user.getIn(['org', 'mainCurrency'])).value}</span>
                        </div>
                    }
                    {
                        mentionAsValueItem.includes(5) && pricingOption === 1 &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {t("contract.revenue_transaction")}
                            </InputLabel>
                            <input
                                className='global-id text-right'
                                style={{
                                    width: '60%'
                                }}
                                disabled={screenDisable}
                                min='0'
                                step='1'
                                name='nbTransaction'
                                type="number"
                                // id='global-id'
                                value={nbTransaction}
                                onChange={handleChangeNbTransaction}
                            />
                            <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>
                                {(user && user.get('org')) && CURRENCY.find(c => c.id === user.getIn(['org', 'mainCurrency'])).value}</span>
                        </div>
                    }
                    {
                        mentionAsValueItem.includes(6) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {t("contract.per_nb_of_ads_Served")}
                            </InputLabel>
                            <input
                                className='global-id text-right'
                                min='0'
                                step='1'
                                style={{
                                    width: '60%'
                                }} name='nbAdsServed'
                                disabled={screenDisable}
                                onChange={handleChangeNbAdsServed}
                                value={nbAdsServed}
                                type="number"
                            />
                        </div>
                    }

                    {
                        mentionAsValueItem.includes(7) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {t("contract.revenue_subs")}
                            </InputLabel>
                            <NumericFormat
                                className='global-id text-right'
                                style={{
                                    width: '60%'
                                }}
                                value={revPerSubs}
                                disabled={screenDisable}
                                name='revPerSubs'
                                onChange={handleChangeRevPerSubs}
                                decimalScale={3}
                                thousandSeparator={t('common.thousand_separator')} 
                                decimalSeparator={t('common.decimal_separator')}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const { formattedValue, floatValue } = values;
                                    return formattedValue === "" || floatValue <= 100;
                                }
                                }
                            />
                            <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>%</span>
                        </div>
                    }

                    {
                        mentionAsValueItem.includes(8) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {t("contract.revenue_ads")}
                            </InputLabel>
                            <NumericFormat
                                className='global-id text-right'
                                style={{
                                    width: '60%'
                                }}
                                onChange={handleChangeRevPerAds}
                                value={revPerAds}
                                name='revPerAds'
                                decimalScale={3}
                                disabled={screenDisable}
                                // id='global-id'
                                thousandSeparator={t('common.thousand_separator')} 
                                decimalSeparator={t('common.decimal_separator')}
                                allowNegative={false}
                                isAllowed={
                                    (values) => {
                                        const { formattedValue, floatValue } = values;
                                        return formattedValue === "" || floatValue <= 100;
                                    }
                                }
                            />
                            <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>%</span>
                        </div>
                    }
                </div>
            </div>

            {pricingOption === 2 &&
                <PricingLadder
                    disabled={screenDisable}
                    initializePricingLadder={initializePricingLadder}
                    pricingLadder={handlePricingLadder}
                    mgApplied={mgApplied}
                    currency={(user && user.get('org')) && CURRENCY.find(c => c.id === user.getIn(['org', 'mainCurrency'])).value}
                />
            }

            {(newContract.bussinessModel === 4 || newContract.bussinessModel === 5 || newContract.bussinessModel === 6) &&
               <FormControlLabel style={{marginTop: 10}}
               control={<Switch checked={isMarketShare} onClick={handleChangeMarketShare} name="gilad" />}
               label={t("contract.add_market_share")}
             />
            }

            <div className='grid grid-cols-3'>
                {
                    isMarketShare &&
                    <div className='items-center mt-4'>
                        <InputLabel shrink style={{
                            color: 'rgba(0, 0, 0, 0.54)'
                        }}>
                            {t("contract.market_share_bonus_value")}
                        </InputLabel>
                        <NumericFormat
                            className='global-id text-right'
                            style={{
                                width: '60%'
                            }}
                            value={marketShareBonusValue}
                            disabled={screenDisable}
                            name='marketShareBonusValue'
                            onChange={handleChangeMarketShareBonusValue}
                            decimalScale={3}
                            thousandSeparator={t('common.thousand_separator')}
                            decimalSeparator={t('common.decimal_separator')}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const { formattedValue, floatValue } = values;
                                return formattedValue === "" || floatValue <= 100;
                            }
                            }
                        />
                        <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>%</span>
                    </div>
                }
            </div>

            <div className='profile-title pb-2 pt-2' style={{ marginTop: '10px', borderBottom: '1px solid #D05E5A' }}>
                <b>{t("contract.restrictions")}</b>
            </div>
            <div style={{ paddingBottom: '30px' }}>
                <div>
                    <div className='mt-4'>
                        <div className="grid grid-cols-2 gap-20">
                            <div style={{ marginRight: '43px' }}>
                                <ButtonGroup size="small" color="primary" aria-label="outlined primary button group" style={{ marginBottom: '20px', border: 'none' }}>
                                    <Button disabled={screenDisable} onClick={() => handleClickRestrictButton(true)} style={isInclude ? { backgroundColor: '#d05e5a', color: '#fff', border: 'none' } : { color: '#d05e5a' }}>{t("contract.include")}</Button>
                                    <Button disabled={screenDisable} onClick={() => handleClickRestrictButton(false)} style={!isInclude ? { backgroundColor: '#d05e5a', color: '#fff', border: 'none' } : { color: '#d05e5a' }}>{t("contract.exclude")}</Button>
                                </ButtonGroup>
                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                {t("contract.territories")}
                                </InputLabel>
                                {
                                    countries &&
                                    <Autocomplete
                                        disabled={screenDisable}
                                        onChange={handleChangeTerritories}
                                        options={countries}
                                        getOptionLabel={(e) => e.name_translation}
                                        multiple
                                        style={{
                                            width: '100%'
                                        }}
                                        size='small'
                                        disableClearable
                                        className='mt-2'
                                        classes={{
                                            root: classes.root
                                        }}
                                        renderInput={(params) => <TextField key='countries' {...params} variant="outlined" placeholder={t("contract.add_placeholder")} />}
                                    />
                                }
                            </div>

                            <div>
                                <div className='grid grid-cols-2 gap-2' style={{ marginTop: '50px' }}>
                                    <div>
                                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                        {t("contract.start_date")}
                                        </InputLabel>
                                        <TextField
                                            key='startDate'
                                            className={classes.root}
                                            onChange={handleChangeDatetime}
                                            style={{
                                                width: '100%',
                                                marginTop: '0.5rem'
                                            }}
                                            disabled={screenDisable}
                                            name='startDate'
                                            id="datetime-local"
                                            type="datetime-local"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            size='small'
                                            variant='outlined'
                                            inputProps={{
                                                style: {
                                                    padding: '10px',
                                                    border: `${errorStartDate ? '2px solid red' : 'none'}`
                                                }
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                        {t("contract.end_date")}
                                        </InputLabel>
                                        <TextField
                                            key='enDate'
                                            className={classes.root}
                                            onChange={handleChangeDatetime}
                                            style={{
                                                marginTop: '0.5rem',
                                                width: '100%'
                                            }}
                                            disabled={screenDisable}
                                            name='endDate'
                                            id="datetime-local"
                                            type="datetime-local"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            size='small'
                                            variant='outlined'
                                            inputProps={{
                                                style: {
                                                    padding: '10px',
                                                    border: `${errorEndDate ? '2px solid red' : 'none'}`
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default BusinessRuleTab;
