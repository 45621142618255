import React, { useEffect, useState, useRef, Fragment, useContext, useMemo } from 'react';
import Layout from '../../layouts/Default';
import './index.css';
import { withSnackbar } from 'notistack';
import { authActions, authSelectors } from '../../../state/modules/auth';
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import axios from "../../../config/axios";
import InputLabel from "@material-ui/core/InputLabel";
import { CURRENCY } from "../../../constants";
import TextField from "@material-ui/core/TextField";
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleFilled';
import StoreFrontIcon from '@material-ui/icons/Storefront';
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasket';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import BarChartSection from "../../components/Dashboard/BarChartSection";
import RowComponent from "../../components/Royalties/RowComponent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import LoadingLayout from "../../components/Common/LoadingLayout";
import Switch from "@material-ui/core/Switch";
import LoadingContext from '../../../context/loading/LoadingContext';
import * as echarts from 'echarts';
import { useTranslation } from 'react-i18next';
function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const useStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        }
    }
});


const BarChart = React.memo(({ barChartData, currency, startDateToPass, endDateToPass, viewBarChartMode, handleChangeBarChartView, classes }) => {
    const { t, i18n } = useTranslation();
    return (
        <div className='bg-white'
        style={{ marginTop: '15px', boxShadow: '0px 0px 5px 0px #d8d8d8', borderRadius: '5px', padding: '16px' }}>

        <div className='grid grid-cols-6 gap-2'>
            <div />
            <div />
            <div />
            <div />

            <div className="flex justify-end">
                <Switch
                    onChange={handleChangeBarChartView}
                    checked={viewBarChartMode}
                    className={classes.root}
                    style={{ color: '#ae323c' }}
                    name="viewBarChartMode"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            </div>
            <div className="flex justify-start"
                style={{ fontSize: 'small', alignItems: 'center' }}>
                {viewBarChartMode ? t('charts.revenue') : t('charts.views')}
            </div>
            <div />
        </div>

        {barChartData ?
            (
                <BarChartSection
                    barChartData={barChartData}
                    currency={currency}
                    startDate={startDateToPass}
                    endDate={endDateToPass}
                    barChartViewMode={viewBarChartMode}
                />
            ) : null
        }
    </div>)
});

const roundToTwo = (num) => {
    return +(Math.round(num + "e+2") + "e-2");
}

const TableContent = React.memo(({ royalties, page, pagingHandleChange }) => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
    }, []);
    return (
        <div className='bg-white px-8 py-16'
            style={{ marginTop: '15px', boxShadow: '0px 0px 5px 0px #d8d8d8', borderRadius: '5px' }}>
            <div className='grid grid-cols-12 gap-1 justify-items-center text-center'>
                <div className='font-bold col-span-1' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                </div>
                <div className='font-bold col-span-1 justify-items-center text-center' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {t('common.status')}
                </div>
                <div className='font-bold col-span-2' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {t('table.title')}
                </div>
                <div className='font-bold col-span-1 justify-items-center text-center' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {t('table.with')}
                </div>
                <div className='font-bold col-span-1 flex justify-center text-center' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {t('table.contents')}
                </div>
                <div className='font-bold col-span-1 flex justify-center text-center' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {t('table.views')}
                </div>
                <div className='font-bold col-span-2 flex justify-center text-center' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {t('table.end_date')}
                </div>
                <div className='font-bold col-span-2 flex justify-center ml-2' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {t('table.royalties')}
                </div>
                <div className='font-bold col-span-1' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                </div>
            </div>
            <Fragment>
                {royalties.records && royalties.records.map((value, key) => {
                    let royalty = {
                        ...value
                    };
                    return (
                        <RowComponent
                            royalty={royalty}
                            key={key}
                        />
                    )
                })}
            </Fragment>
            <div className='flex flex-row justify-center items-center py-4'>
                <Pagination count={royalties.totalPages} page={page} onChange={pagingHandleChange} />
            </div>
        </div>
    )
})

const Dashboard = () => {
    const { t, i18n } = useTranslation();
    const { showLoading, hideLoading } = useContext(LoadingContext);
    const classes = useStyles();
    const [page, setPage] = useState(1);
    const [startDate, setStartDate] = useState((new Date()).getFullYear() + ( (new Date()).getMonth() + 1 < 10 ? '-0'+((new Date()).getMonth()+1) : '-'+((new Date()).getMonth()+1)) + '-01');
    const [startDateToPass, setStartDateToPass] = useState((new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)).toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState((new Date()).toISOString().split('T')[0]);
    const [endDateToPass, setEndDateToPass] = useState((new Date()).toISOString().split('T')[0]);
    const [indicators, setIndicators] = useState(null);
    const [generatedRevenues, setGeneratedRevenues] = useState(null);
    const [infringedRevenues, setInfringedRevenues] = useState(null);
    const [generatedViews, setGeneratedViews] = useState(null);
    const [infringedViews, setInfringedViews] = useState(null);
    const [generatedRevenuesPerPartner, setGeneratedRevenuesPerPartner] = useState(null);
    const [infringedRevenuesPerPartner, setInfringedRevenuesPerPartner] = useState(null);

    const [royalties, setRoyalties] = useState(null);
    const [barChartData, setBarChartData] = useState([]);
    const [isHover, setIsHover] = useState(null);
    const selector = useSelector((state) => authSelectors.user(state));
    const [user, setUserTracking] = useState(selector);
    const prevData = usePrevious(selector);
    const [viewBarChartMode, setViewBarChartMode] = useState(true);
    const [currency, setCurrency] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        setUserTracking(selector);
    }, [selector, prevData]);

    useEffect(() => {
        const CURRENCY_UNIT = (user && user.get('org')) ? CURRENCY.find(c => c.id === user.getIn(['org', 'mainCurrency'])).value : null;
        setCurrency(CURRENCY_UNIT);
    }, [user]);

    // call API to get indicators
    useEffect(() => {
        if (user && localStorage.getItem('token')) {
            (async function getIndicators() {
                const indicators = await axios.get(`/dashboard/indicators/?orgUid=${user.get('BelongsTo')}&fromDate=${startDateToPass}&toDate=${endDateToPass}`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

                const { data, status } = indicators;
                if (status === 200) {
                    setIndicators(data);
                }
            })();
        }
    }, [user, startDateToPass, endDateToPass]);

    // call API to get list royalties
    useEffect(() => {
        if (user && localStorage.getItem('token')) {
            (async function getListContent() {
                // Check current user Org ID existing
                if (!user.get('BelongsTo')) {
                    return;
                }
                const contractResponse = await axios.get(`/dashboard/active-contracts/?orgUid=${user.get('BelongsTo')}&fromDate=${startDateToPass}&toDate=${endDateToPass}&pageNo=${page}&itemPerPage=10`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

                const { data, status } = contractResponse;
                if (status === 200) {
                    setRoyalties(data);
                }
            })();
        }
    }, [user, page, startDateToPass, endDateToPass]);

    // call API to get data for bar chart
    useEffect(() => {
        (async function getDataBarChart() {
            // showLoading();
            const dataBarChart = viewBarChartMode ? await axios.get(`/dashboard/reports-per-day/?orgUid=${localStorage.getItem('orgUid')}&fromDate=${startDateToPass}&toDate=${endDateToPass}`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            }) : await axios.get(`/dashboard/reports-views-per-day/?orgUid=${localStorage.getItem('orgUid')}&fromDate=${startDateToPass}&toDate=${endDateToPass}`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            const { data, status } = dataBarChart;
            if (status === 200) {
                setBarChartData(data.results);
            }
            // hideLoading();

        })();
    }, [startDateToPass, endDateToPass, viewBarChartMode]);

    // call API to get data for pie chart
    useEffect(() => {
        (async function getDataPieChart() {
            // showLoading();
            const dataPieChart = await axios.get(`/dashboard/reports/?orgUid=${localStorage.getItem('orgUid')}&fromDate=${startDateToPass}&toDate=${endDateToPass}`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            const { data, status } = dataPieChart;
            if (status === 200) {
                setGeneratedRevenues(data.generatedRevenues);
                setInfringedRevenues(data.infringedRevenues);
                setInfringedViews(data.infringedViews);
                setGeneratedViews(data.generatedViews);
                let generatedRevenuesPerPartner;
                let infringedRevenuesPerPartner;
                if(data.generatedRevenuesPerPartner) {
                    generatedRevenuesPerPartner = data.generatedRevenuesPerPartner.map(item => {
                        return {
                            name: item.name,
                            value: item.value0
                        }
                    });

                }
                if(data.generatedRevenuesPerPartner) {
                    infringedRevenuesPerPartner = data.generatedRevenuesPerPartner.map(item => {
                        return {
                            name: item.name,
                            value: item.value1
                        }
                    });
                }
                setGeneratedRevenuesPerPartner(generatedRevenuesPerPartner);
                setInfringedRevenuesPerPartner(infringedRevenuesPerPartner);
                let myGeneratedChart;
                let myInfringedChart;
                if (generatedRevenuesPerPartner) {
                    myGeneratedChart = echarts.init(document.getElementById('generatedPieChart'));
                    const colorRange = generatedRevenuesPerPartner && generatedRevenuesPerPartner.length > 1 ? generatedRevenuesPerPartner.sort(function (a, b) {
                        return a.value - b.value;
                    }) : generatedRevenuesPerPartner;
                    const generatedOption = generatedRevenuesPerPartner ?
                        {
                            tooltip: {
                                trigger: 'item',
                                formatter: '{b} : {d}% ({c})'
                            },
                            visualMap: {
                                show: false,
                                min: 0,
                                max: colorRange[colorRange.length - 1].value * 2,
                                inRange: {
                                    colorLightness: [0.6, 0.25]
                                }
                            },
                            series: [
                                {
                                    type: 'pie',
                                    radius: '55%',
                                    center: ['50%', '50%'],
                                    selectedMode: 'single',
                                    data: colorRange.map(item => {
                                        if (item.value) {
                                            return { value: item.value ? item.value.toFixed(2) : null, name: item.name }
                                        }
                                    }),
                                    itemStyle: {
                                        color: 'rgb(174, 50, 60)',
                                    },
                                    emphasis: {
                                        itemStyle: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    }
                                }
                            ]
                        } : null;
                    myGeneratedChart.setOption(generatedOption);
                }
                if (infringedRevenuesPerPartner) {
                    myInfringedChart = echarts.init(document.getElementById('infringedPieChart'));
                    const colorRangeInfringed = infringedRevenuesPerPartner && infringedRevenuesPerPartner.length > 1 ? infringedRevenuesPerPartner.sort(function (a, b) {
                        return a.value - b.value;
                    }) : infringedRevenuesPerPartner;

                    const infringedOption = infringedRevenuesPerPartner ? {
                        tooltip: {
                            trigger: 'item',
                            formatter: '{b} : {d}% ({c})'
                        },
                        visualMap: {
                            show: false,
                            min: 0,
                            max: colorRangeInfringed[colorRangeInfringed.length - 1].value * 2,
                            inRange: {
                                colorLightness: [0.6, 0.25]
                            }
                        },
                        series: [
                            {
                                type: 'pie',
                                radius: '55%',
                                center: ['50%', '50%'],
                                selectedMode: 'single',
                                data: colorRangeInfringed.map(item => {
                                    if (item.value) {
                                        return { value: item.value ? item.value.toFixed(2) : 0, name: item.name }
                                    }
                                }),
                                itemStyle: {
                                    color: 'rgb(174, 50, 60)',
                                },
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            }
                        ]
                    } : null;
                    myInfringedChart.setOption(infringedOption);
                }
            }
            // hideLoading();
        })();
    }, [startDateToPass, endDateToPass]);

    useEffect(() => {
        // showLoading();
        dispatch(authActions.getProfile({ id: localStorage.getItem('id') }));
        // hideLoading();
    }, []);

    const handleChangeStartDate = (e) => {
        const { value } = e.target;
        const newDate = new Date(value);
        setStartDateToPass(newDate.toISOString().split('T')[0]);
    };

    const onChangeStartDateValue = (e) => {
        const { value } = e.target;
        const newDate = new Date(value);
        setStartDate(newDate.toISOString().split('T')[0]);
    }

    const handleChangeEndDate = (e) => {
        const { value } = e.target;
        const newDate = new Date(value);
        setEndDateToPass(newDate.toISOString().split('T')[0]);
    };

    const onChangeEndDateValue = (e) => {
        const { value } = e.target;
        const newDate = new Date(value);
        setEndDate(newDate.toISOString().split('T')[0]);
    }

    const pagingHandleChange = (e, value) => {
        setPage(value);
    };

    const onMouseEnterHandler = (e, widgetNo) => {
        setIsHover(widgetNo)
    };

    const onMouseLeaveHandler = (e) => {
        setIsHover(null)
    };

    const navigateToContentListUI = () => {
        window.location.href = '/contents';
    }

    const navigateToPartnerListUI = () => {
        window.location.href = '/business';
    }

    const navigateToContractListUI = () => {
        window.location.href = '/contracts';
    }

    const handleChangeBarChartView = () => {
        setViewBarChartMode(!viewBarChartMode);
    }

    return user ? (
        <Layout title="Dashboard" highlightedItem={6}>
            <div className="grid grid-cols-4 gap-3">
                <button
                    onMouseEnter={(e) => onMouseEnterHandler(e, 0)}
                    onMouseLeave={onMouseLeaveHandler}
                    className='bg-white pb-2 pt-2 no-border-button dashboard-widget'
                    style={{ background: isHover === 0 ? '#de943836' : 'white' }}>
                    <div className="grid grid-cols-12"
                        onClick={navigateToContentListUI}
                    >
                        <div className="col-span-8 dashboard-widget-header">
                            <b>{t('dashboard.available_contents')}</b>
                            <div className='indicator'>{indicators ? indicators.availableContents : 0}</div>
                        </div>
                        <div className="col-span-4" style={{ textAlign: 'center' }}>
                            <PlayCircleOutlineOutlinedIcon style={{ color: '#c1802f', fontSize: '46px' }} />
                        </div>
                    </div>
                </button>

                <button
                    onMouseEnter={(e) => onMouseEnterHandler(e, 1)}
                    onMouseLeave={onMouseLeaveHandler}
                    className='bg-white pb-2 pt-2 no-border-button dashboard-widget'
                    style={{ background: isHover === 1 ? '#de943836' : 'white' }}>
                    <div className="grid grid-cols-12"
                        onClick={navigateToPartnerListUI}
                    >
                        <div className="col-span-8 dashboard-widget-header" >
                            <b>{t('dashboard.partners')}</b>
                            <div className='indicator'>{indicators ? indicators.connectedPartners : 0}</div>
                        </div>
                        <div className="col-span-4" style={{ textAlign: 'center' }}>
                            <StoreFrontIcon style={{ color: '#c1802f', fontSize: '46px' }} />
                        </div>
                    </div>
                </button>

                <button
                    onMouseEnter={(e) => onMouseEnterHandler(e, 2)}
                    onMouseLeave={onMouseLeaveHandler}
                    className='bg-white pb-2 pt-2 no-border-button dashboard-widget'
                    style={{ background: isHover === 2 ? '#de943836' : 'white' }}>
                    <div className="grid grid-cols-12"
                        onClick={navigateToContractListUI}
                    >
                        <div className="col-span-8 dashboard-widget-header">
                            <b>{t('dashboard.running_contracts')}</b>
                            <div className='indicator'>{indicators ? indicators.runningContracts : 0}</div>
                        </div>
                        <div className="col-span-4" style={{ textAlign: 'center' }}>
                            <ShoppingBasketOutlinedIcon style={{ color: '#c1802f', fontSize: '46px' }} />
                        </div>
                    </div>
                </button>

                <button
                    onMouseEnter={(e) => onMouseEnterHandler(e, 3)}
                    onMouseLeave={onMouseLeaveHandler}
                    className='bg-white pb-2 pt-2 no-border-button dashboard-widget'
                    style={{ background: isHover === 3 ? '#de943836' : 'white' }}>
                    <div className="grid grid-cols-12"
                        onClick={navigateToContractListUI}
                    >
                        <div className="col-span-8 dashboard-widget-header">
                            <b>{t('dashboard.infringed_contracts')}</b>
                            <div className='indicator'>{indicators ? indicators.infringedContracts : 0}</div>
                        </div>
                        <div className="col-span-4" style={{ textAlign: 'center' }}>
                            <ShoppingBasketOutlinedIcon style={{ color: '#c1802f', fontSize: '46px' }} />
                        </div>
                    </div>
                </button>
            </div>
            <div className='grid grid-cols-4 gap-3 bg-white dashboard-date-time'><div />
                <div>
                    <InputLabel shrink className='column-header'>
                    {t('table.start_date')}
                    </InputLabel>
                    <TextField
                        className={classes.root}
                        onBlur={handleChangeStartDate}
                        onChange={onChangeStartDateValue}
                        style={{
                            width: '100%',
                            marginTop: '0.5rem'
                        }}
                        value={startDate}
                        name='startDate'
                        id="datetime-local"
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size='small'
                        variant='outlined'
                        inputProps={{
                            style: {
                                padding: '10px',
                                fontSize: 'small'
                            }
                        }}
                    />
                </div>
                <div>
                    <InputLabel shrink className='column-header'>
                    {t('table.end_date')}
                    </InputLabel>
                    <TextField
                        className={classes.root}
                        onBlur={handleChangeEndDate}
                        onChange={onChangeEndDateValue}
                        style={{
                            marginTop: '0.5rem',
                            width: '100%'
                        }}
                        value={endDate}
                        name='endDate'
                        id="datetime-local"
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size='small'
                        variant='outlined'
                        inputProps={{
                            style: {
                                padding: '10px',
                                fontSize: 'small'
                            }
                        }}
                    />
                </div>
                <div />
            </div>

            <div className="grid grid-cols-4 gap-3 "
                style={{ marginTop: '15px', borderRadius: '5px' }}>
                <div className="col-span-3">
                    <div className='grid grid-cols-2 gap-3 bg-white'
                        style={{ boxShadow: '0px 0px 5px 0px #d8d8d8', borderRadius: '5px', height: '300px' }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <div style={{ textAlign: 'center' }}>
                                {!generatedRevenuesPerPartner ? t('charts.no_generated_data') : null}
                                <div id="generatedPieChart" style={{ width: '600px', height: '350px', marginTop: '-25%' }}>
                                </div>
                                <p style={{ color: 'white', position: 'relative', textAlign: 'center', marginTop: '-31%' }}>{generatedRevenues ? generatedRevenues.toFixed(2) : 0} {currency ? currency : null}</p>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <div style={{ textAlign: 'center' }}>
                                {!infringedRevenuesPerPartner ? t('charts.no_infringed_data') : null}
                                <div id="infringedPieChart" style={{ width: '600px', height: '350px', marginTop: '-25%' }}>
                                </div>
                                <p style={{ color: 'white', position: 'relative', textAlign: 'center', marginTop: '-31%' }}>{infringedRevenues ? infringedRevenues.toFixed(2) : 0} {currency ? currency : null}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-span-1" style={{ borderRadius: '5px' }}>
                    <button
                        className='bg-white pb-2 pt-2 no-border-button'
                        style={{
                            height: '144px',
                            width: '100%',
                            boxShadow: '0px 0px 5px 0px #d8d8d8',
                            padding: '20px',
                            borderRadius: '5px',
                            background: 'white'
                        }}>
                        <div className="grid grid-cols-12"
                        >
                            <div className="col-span-8"
                                style={{ textAlign: 'left', fontSize: 'small', color: '#9e9e9e' }}>
                                <b>{t('dashboard.generated_revenue')}</b>
                            </div>
                            <div className="col-span-4" style={{ textAlign: 'center' }}>
                                <AccountBalanceWalletIcon style={{ color: '#c1802f', fontSize: '46px' }} />
                            </div>
                            <div className="col-span-12">
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'left',
                                    fontWeight: '700',
                                    fontSize: '22px'
                                }}>
                                    {roundToTwo(Number(generatedRevenues)).toLocaleString()} {currency}</div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'left',
                                    fontWeight: '700',
                                    fontSize: '17px'
                                }}> ( {generatedViews} {t('table.views')} )</div>
                            </div>
                        </div>
                    </button>

                    <button
                        className='bg-white pb-2 pt-2 no-border-button'
                        style={{
                            marginTop: '12px',
                            height: '144px',
                            width: '100%',
                            boxShadow: '0px 0px 5px 0px #d8d8d8',
                            padding: '24px',
                            borderRadius: '5px',
                            background: 'white'
                        }}>
                        <div className="grid grid-cols-12"
                        >
                            <div className="col-span-8"
                                style={{ textAlign: 'left', fontSize: 'small', color: '#9e9e9e' }}>
                                <b>{t('dashboard.infringed_revenue')}</b>
                            </div>
                            <div className="col-span-4" style={{ textAlign: 'center' }}>
                                <AccountBalanceWalletIcon style={{ color: '#c1802f', fontSize: '46px' }} />
                            </div>
                            <div className="col-span-12">
                                <div style={{
                                    color: 'rgb(208, 94, 90)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'left',
                                    fontWeight: '700',
                                    fontSize: '22px'
                                }}>
                                    {Number(infringedRevenues).toLocaleString()} {currency}</div>
                                <div style={{
                                    color: 'rgb(208, 94, 90)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'left',
                                    fontWeight: '700',
                                    fontSize: '17px'
                                }}> ( {infringedViews} {t('table.views')} )</div>
                            </div>
                        </div>
                    </button>
                </div>
            </div>

            {/*    <div id="generatedBarChart" style={{ width: '100%', height: '100%', marginTop: '-25%' }}>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {barChartData !== null && startDateToPass !== null && currency !== null && endDateToPass !== null && viewBarChartMode !== null ?
                <BarChart
                    handleChangeBarChartView={handleChangeBarChartView}
                    classes={classes}
                    barChartData={barChartData}
                    currency={currency}
                    startDateToPass={startDateToPass}
                    endDateToPass={endDateToPass}
                    viewBarChartMode={viewBarChartMode} /> : <></>}

            {royalties && page ? <TableContent royalties={royalties} page={page} pagingHandleChange={pagingHandleChange} /> : <></>}
        </Layout>
    ) : <LoadingLayout />
};

export default withSnackbar(Dashboard);
