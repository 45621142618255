import React, { useEffect, useState, useRef, Fragment, useMemo } from 'react';
import Layout from '../../../layouts/Default';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import './index.css';
import {
    GET_BUSINESSTYPES,
    GET_REPORTINGTOOLS,
    CURRENCY
} from '../../../../constants';
import { useSnackbar, withSnackbar } from 'notistack';
import { authActions, authSelectors } from '../../../../state/modules/auth';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import axios from "../../../../config/axios";
import { DropzoneDialog } from "material-ui-dropzone";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import LoadingLayout from "../../../components/Common/LoadingLayout";
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const useStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px",
        },
        "& .MuiOutlinedInput-input": {
            padding: '0',
        }
    }
});

const useNoBorderName = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        }
    }
});

const EditOrganization = () => {
    let { id } = useParams();
    const classes = useStyles();
    const nameClasses = useNoBorderName();
    const selector = useSelector((state) => authSelectors.user(state));
    const [user, setUserTracking] = useState(selector);
    const [countries, setCountries] = useState(null);
    const [validName, setValidName] = useState(true);
    const [reportingToolId, setReportingToolId] = useState(null);
    const [reportingTool, setReportingTool] = useState(null);
    const [isLogoHovering, setIsLogoHovering] = React.useState(false);
    const [openDropzoneLogo, setOpenDropzoneLogo] = React.useState(false);
    const [isImageNotFound, setIsImageNotFound] = React.useState(false);
    const [org, setOrg] = useState(null);
    const [isDisable, setIsDisable] = useState(false);
    const [statusOrg, setStatusOrg] = useState(0);
    const [nbContract, setNbContract] = useState(0);
    const [myOrgId, setMyOrgId] = useState(0);
    const [partnerId, setPartnerId] = useState(0);
    const prevData = usePrevious(selector);
    const history = useHistory();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const BUSINESSTYPES = useMemo(() => GET_BUSINESSTYPES(t), [t]);
    const REPORTINGTOOLS = useMemo(() => GET_REPORTINGTOOLS(t), [t]);
    const getStatus = (params) => {
        if (params) {
            const listParams = params.split('&');
            const nbOfContract = listParams[0].split('=')[1];
            const status = listParams[1].split('=')[1];
            const myOrgId = listParams[2].split('=')[1];
            const partnerId = listParams[3].split('=')[1];
            setNbContract(nbOfContract);
            setStatusOrg(status);
            setMyOrgId(myOrgId);
            setPartnerId(partnerId);
        }
    }
    useEffect(() => {
        if (location.pathname.includes('view')) {
            setIsDisable(true);
        } else {
            setIsDisable(false);
        }
        getStatus(location.search);
    }, [location])

    useEffect(() => {
        (async function getOneOrganization() {
            try {
                const { data: org, status } = await axios.get(`/org/get-one/${id}`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

                if (status === 200) {
                    setOrg({
                        ...org
                    });
                    setReportingTool(org.reportingTool);
                }
            } catch (e) {
            }
        })();
    }, [id]);

    useEffect(() => {
        (async function getListCountry() {
            const listCountryResponse = await axios.get('/country/get-list-countries', {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            const { data, status } = listCountryResponse;

            if (status === 200) {
                setCountries(data);
            }
        })();
    }, []);

    useEffect(() => {
        setUserTracking(selector);
        if (!user) return;
        setReportingToolId(user.get('reportingToolDetailId'))
    }, [selector, prevData]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(authActions.getProfile({ id: localStorage.getItem('id') }));
    }, []);

    const { enqueueSnackbar } = useSnackbar();

    const handleUpdateOrganization = async () => {
        if (!org.Name || org.Name === '') {
            enqueueSnackbar(t('organization.org_require'), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            return;
        }
        try {
            const orgData = {
                uuid: id,
                name: org.Name,
                businessType: org.businessType,
                countryCorp: typeof org.countryCorp === 'number' ? org.countryCorp : countries.find(r => r.name_translation === org.countryCorp).code,
                city: org.city,
                address: org.address,
                zipCode: org.zipCode,
                reportingTool: org.reportingTool,
                mainCurrency: org.mainCurrency,
                reportingToolParams: reportingToolId,
                status: 0,
                foreignKey: org.foreignKey ? org.foreignKey : '',
                comments: org.comments,
                smartListForeignKey: org.smartListForeignKey ? org.smartListForeignKey : '',
                webLink: org.webLink,
                VATCode: org.VATCode
            }

            const contentUpdatedResponse = await axios.put('/org/update-info', orgData, {
                headers: {
                    common: {
                        'x-access-token': localStorage.getItem('token')
                    },
                }
            });

            if (contentUpdatedResponse.status === 200) {
                enqueueSnackbar(t('organization.update_org_success'), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });

                window.location.href = '/business';
            } else {
                enqueueSnackbar(t('notify.something_wrong'), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }
        } catch (e) {
            enqueueSnackbar(t('notify.something_wrong'), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
    };

    const onMouseEnterHandler = (e) => {
        setIsLogoHovering(true)
    };

    const onMouseLeaveHandler = (e) => {
        setIsLogoHovering(false)
    };

    const handleChangeInputBusinessType = (e, value) => {
        setOrg({
            ...org,
            businessType: value.id
        });
    };

    const handleChangeInputCurrency = (e, value) => {
        setOrg({
            ...org,
            mainCurrency: value.id
        });
    };

    const handleChangeInputReportingTool = (e, value) => {
        setOrg({
            ...org,
            reportingTool: value.id,
            foreignKey: value.id !== 3 || value.id !== 4 ? '' : org.foreignKey,
            smartListForeignKey: value.id !== 3 || value.id !== 4 ? '' : org.smartListForeignKey,
        });
        setReportingTool(value.id);
    };

    const handleChangeCountry = (e, value) => {
        setOrg({
            ...org,
            countryCorp: value.code
        });
    }

    const handleValidateName = (e) => {
        const name = e.target.value;
        if (!name) {
            setValidName(false);
        } else {
            setValidName(true);
        }
    };

    const handleChangeInput = (e) => {
        const { name, value } = e.target;

        setOrg({
            ...org,
            [name]: value
        });
    }

    const handleImageNotFound = () => {
        setIsImageNotFound(true);
    }

    const uploadOrganizationLogo = async (files) => {
        if (files.length === 0) {
            return;
        }
        const dataToUpload = {
            uuid: id,
            logo: files[0].name,
            contentType: files[0].type
        }
        setOpenDropzoneLogo(false);

        try {
            const response = await axios.put(`/org/update-logo`, dataToUpload
                , {
                    headers: {
                        common: {
                            'x-access-token': localStorage.getItem('token')
                        },
                    }
                });

            const { data, status } = response;

            if (status === 200) {
                const urlToUpload = data.updatedLogoUrl;
                const options = { headers: { 'Content-Type': files[0].type } };
                const uploadToS3 = await axios.put(urlToUpload, files[0], options);
                enqueueSnackbar(t('organization.update_logo'), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });
            }
        } catch (error) {
            console.log(error);
        }
        window.location.reload();
    }



    const requestConnectPartner = async () => {
        if (statusOrg === 2) return;
        const request = {
            "myOrgUid": myOrgId,
            "requestPartner": partnerId
        }

        const newRequest = await axios.post('/org/create-partnership', request, {
            headers: {
                'x-access-token': localStorage.getItem('token')
            }
        });

        const { status } = newRequest;

        if (status === 200) {
            enqueueSnackbar(t('notify.request_success'), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'success'
            });

            const createMessage = {
                "type": 1,
                "from": myOrgId,
                "receiver": partnerId,
                "header": "Connection request",
                "body": "Connection request"
            }
            const newMessage = await axios.post('/message/', createMessage, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });
            history.push(`${location.pathname}?nbContract=${nbContract}&status=0&myOrgId=${myOrgId}&partnerId=${partnerId}`);
        } else {
            enqueueSnackbar(t('notify.something_wrong'), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
    }

    const requestDisconnectPartner = async () => {
        if (statusOrg < 2 || (statusOrg === 2 && nbContract > 0)) return;
        const request = {
            "myOrgUid": myOrgId,
            "requestPartner": partnerId,
            "status": 1
        }

        const newRequest = await axios.put('/org/update-partnership-status', request, {
            headers: {
                'x-access-token': localStorage.getItem('token')
            }
        });

        const { status } = newRequest;

        if (status === 200) {
            enqueueSnackbar(t("notify.disconnected_success"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'success'
            });
            if (myOrgId !== partnerId) {
                const createMessageToYourPartner = {
                    "type": 7,
                    "from": myOrgId,
                    "receiver": partnerId,
                    "header": "Disconnected connection",
                    "body": "Disconnected connection"
                }
                const createMessageToYourself = {
                    "type": 7,
                    "from": partnerId,
                    "receiver": myOrgId,
                    "header": "Disconnected connection",
                    "body": "Disconnected connection"
                }
                const newMessageToYourPartner = await axios.post('/message/', createMessageToYourPartner, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });
                const newMessageToYourself = await axios.post('/message/', createMessageToYourself, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });
            } else {
                const createMessageToYourPartner = {
                    "type": 7,
                    "from": myOrgId,
                    "receiver": partnerId,
                    "header": "Disconnected connection",
                    "body": "Disconnected connection"
                }
                const newMessageToYourPartner = await axios.post('/message/', createMessageToYourPartner, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });
            }
            history.push(`${location.pathname}?nbContract=${nbContract}&status=1&myOrgId=${myOrgId}&partnerId=${partnerId}`);
        } else {
            enqueueSnackbar(t('notify.something_wrong'), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
    }

    return org ? (
        <Layout title="Update Organization" highlightedItem={5}>
            <div className='flex justify-between content-center'>
                <div>
                    {`${t('organization.org')} > ${isDisable ? t('organization.view_org') : t('organization.update_org')}`}
                    <p style={{ fontSize: '24px' }}>
                        {`${isDisable ? t('organization.view_org') : t('organization.update_org')}`}
                    </p>
                </div>
                <div className='flex' style={{
                    height: '36px'
                }}>
                    <Button
                        disabled={statusOrg === '2' || statusOrg === '0'}
                        onClick={() => requestConnectPartner()}
                        style={{
                            height: '100%',
                            padding: '6px 16px',
                            backgroundColor: '#D05E5A',
                            marginLeft: '0.5rem',
                            color: 'white',
                            fontSize: '0.75rem'
                        }}>
                        {t('organization.connect')}
                    </Button>
                    <Button
                        disabled={!(statusOrg === '2' && nbContract === '0')}
                        onClick={() => requestDisconnectPartner()}
                        style={{
                            height: '100%',
                            padding: '6px 16px',
                            backgroundColor: '#D05E5A',
                            marginLeft: '0.5rem',
                            color: 'white',
                            fontSize: '0.75rem'
                        }}>
                        {t('organization.disconnect')}
                    </Button>
                    {!isDisable ? (
                        <Button
                            disabled={isDisable}
                            onClick={() => handleUpdateOrganization()}
                            style={{
                                height: '100%',
                                padding: '6px 16px',
                                backgroundColor: '#D05E5A',
                                marginLeft: '0.5rem',
                                color: 'white',
                                fontSize: '0.75rem'
                            }}>
                            {t('common.save')}
                        </Button>) : null}

                </div>
            </div>

            <div className='profile-content bg-white p-4' style={{ marginTop: '10px' }}>
                <div className='profile-info-organization mt-8'>
                    <Grid container spacing={3}>
                        <Grid item xs={9}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <FormControl className='w-full'>
                                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                            {t('organization.org')}
                                        </InputLabel>
                                        <TextField
                                            disabled={isDisable}
                                            id='add-org-name'
                                            className={nameClasses.root}
                                            style={{ width: '100%', marginTop: '24px' }}
                                            variant="outlined"
                                            maxLength="255"
                                            name='Name'
                                            type="text"
                                            defaultValue={org.Name}
                                            error={!validName}
                                            onBlur={handleValidateName}
                                            autoComplete="off"
                                            helperText={validName ? '' : 'Organization must be required'}
                                            onChange={handleChangeInput}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl className='w-full'>
                                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                            {t("organization.business_type")}
                                        </InputLabel>
                                        <Autocomplete
                                            classes={{
                                                root: classes.root
                                            }}
                                            disabled={isDisable}
                                            style={{
                                                width: '100%',
                                                borderRadius: 4,
                                                position: 'relative',
                                                marginTop: '24px',
                                                fontSize: 16,
                                            }}
                                            id="business-type"
                                            name="businessType"
                                            onChange={handleChangeInputBusinessType}
                                            size='small'
                                            value={org.businessType ? BUSINESSTYPES.find(b => b.id === org.businessType) : BUSINESSTYPES.find(b => b.default)}
                                            disableClearable
                                            className='mt-2'
                                            options={BUSINESSTYPES}
                                            getOptionLabel={(ev) => ev.description}
                                            renderOption={options => {
                                                return (
                                                    <Fragment>
                                                        <p className='ml-2'>{options.description}</p>
                                                    </Fragment>
                                                )
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" InputProps={{
                                                ...params.InputProps,
                                            }} />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl className='w-full'>
                                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                            {t("organization.currency")}
                                        </InputLabel>
                                        <Autocomplete
                                            classes={{
                                                root: classes.root
                                            }}
                                            disabled={isDisable}
                                            style={{
                                                width: '100%',
                                                borderRadius: 4,
                                                position: 'relative',
                                                marginTop: '24px',
                                                fontSize: 16,
                                            }}
                                            id="main-currency"
                                            onChange={handleChangeInputCurrency}
                                            name='mainCurrency'
                                            size='small'
                                            value={org.mainCurrency ? CURRENCY.find(ev => ev.id === org.mainCurrency) : CURRENCY.find(ev => ev.default)}
                                            disableClearable
                                            className='mt-2'
                                            options={CURRENCY}
                                            getOptionLabel={(ev) => ev.value}
                                            renderOption={options => {
                                                return (
                                                    <Fragment>
                                                        <p className='ml-2'>{options.value}</p>
                                                    </Fragment>
                                                )
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" InputProps={{
                                                ...params.InputProps,
                                            }} />}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <FormControl className='w-full'>
                                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                            {t("organization.reporting_tool")}
                                        </InputLabel>
                                        <Autocomplete
                                            classes={{
                                                root: classes.root
                                            }}
                                            disabled={isDisable}
                                            style={{
                                                width: '100%',
                                                borderRadius: 4,
                                                position: 'relative',
                                                marginTop: '24px',
                                                fontSize: 16,
                                            }}
                                            id="reporting-tool"
                                            onChange={handleChangeInputReportingTool}
                                            name='reportingTool'
                                            size='small'
                                            defaultValue={org.reportingTool ? REPORTINGTOOLS.find(ev => ev.id === org.reportingTool) : REPORTINGTOOLS.find(ev => ev.default)}
                                            disableClearable
                                            className='mt-2'
                                            options={REPORTINGTOOLS}
                                            getOptionLabel={(ev) => ev.value}
                                            renderOption={options => {
                                                return (
                                                    <Fragment>
                                                        <p className='ml-2'>{options.value}</p>
                                                    </Fragment>
                                                )
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" InputProps={{
                                                ...params.InputProps,
                                            }} />}
                                        />
                                    </FormControl>
                                </Grid>
                                {!isDisable ? (
                                    <>
                                        <Grid item xs={4}>
                                            {reportingTool === 3 || reportingTool === 4 ?
                                                <FormControl className='w-full'>
                                                    <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                        {t("organization.org_key")}
                                                    </InputLabel>
                                                    <TextField
                                                        disabled={isDisable}
                                                        id='add-org-foreignKey'
                                                        className={nameClasses.root}
                                                        style={{ width: '100%', marginTop: '24px' }}
                                                        variant="outlined"
                                                        maxLength="255"
                                                        name='foreignKey'
                                                        defaultValue={org.foreignKey ? org.foreignKey : ''}
                                                        type="text"
                                                        autoComplete="off"
                                                        onChange={handleChangeInput}
                                                    />
                                                </FormControl>
                                                : null}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {reportingTool === 3 || reportingTool === 4 ?
                                                <FormControl className='w-full'>
                                                    <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                        {t("organization.smartList_key")}
                                                    </InputLabel>
                                                    <TextField
                                                        id='add-org-smartListForeignKey'
                                                        disabled={isDisable}
                                                        className={nameClasses.root}
                                                        style={{ width: '100%', marginTop: '24px' }}
                                                        variant="outlined"
                                                        maxLength="255"
                                                        name='smartListForeignKey'
                                                        defaultValue={org.smartListForeignKey ? org.smartListForeignKey : ''}
                                                        type="text"
                                                        autoComplete="off"
                                                        onChange={handleChangeInput}
                                                    />
                                                </FormControl>
                                                : null}
                                        </Grid>
                                    </>
                                ) : null}
                            </Grid>
                        </Grid>

                        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {
                                org.logo !== null && !isImageNotFound ?
                                    <div style={{ textAlign: 'center' }}>
                                        <img
                                            onMouseEnter={onMouseEnterHandler}
                                            onMouseLeave={onMouseLeaveHandler}
                                            onClick={() => setOpenDropzoneLogo(true)}
                                            src={org.logo}
                                            onError={handleImageNotFound}
                                            style={{
                                                height: '150px',
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'top center',
                                                borderRadius: '1%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                backgroundImage: isLogoHovering ? ' linear-gradient(to bottom right, #002f4b, #dc4225)' : '',
                                                filter: isLogoHovering ? 'brightness(0.6)' : ''
                                            }} alt='orgLogo' />
                                        {isLogoHovering ? <EditRoundedIcon
                                            onMouseEnter={onMouseEnterHandler}
                                            onMouseLeave={onMouseLeaveHandler}
                                            onClick={() => setOpenDropzoneLogo(true)}
                                            style={{
                                                position: 'absolute',
                                                marginTop: '-90px',
                                                marginLeft: '-10px',
                                                color: 'white'
                                            }}
                                        /> : null} </div> :
                                    <button
                                        onClick={() => setOpenDropzoneLogo(true)}
                                        disabled={isDisable}
                                        style={{
                                            borderRadius: '4px',
                                            border: 'dashed 2px lightgray',
                                            height: '80%',
                                            color: 'lightgray',
                                            width: '80%'
                                        }}>
                                        <div style={{ marginBottom: '4%' }}>{t('organization.add_logo_here')}</div>
                                        <CloudUploadIcon style={{ color: 'rgb(208, 94, 90)', fontSize: '30px' }} />
                                    </button>
                            }
                            <DropzoneDialog
                                dialogTitle={t('organization.upload_logo')}
                                acceptedFiles={['image/*']}
                                cancelButtonText={t('common.cancel')}
                                submitButtonText={t('common.submit')}
                                maxFileSize={5000000}
                                filesLimit={1}
                                open={openDropzoneLogo}
                                onClose={() => setOpenDropzoneLogo(false)}
                                onSave={uploadOrganizationLogo}
                                showPreviews={true}
                                showFileNamesInPreview={true}
                            />
                        </Grid>
                    </Grid>

                    <Grid container className='mt-4'>
                        <Grid item xs={9}>
                            <FormControl className='w-full'>
                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                    {t("organization.address")}
                                </InputLabel>
                                <TextField
                                    id='add-org-address'
                                    disabled={isDisable}
                                    className={nameClasses.root}
                                    style={{ width: '100%', marginTop: '24px' }}
                                    variant="outlined"
                                    maxLength="255"
                                    name='address'
                                    defaultValue={org.address ? org.address : ''}
                                    type="text"
                                    autoComplete="off"
                                    onChange={handleChangeInput}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                        <Grid item xs={3}>
                            <FormControl className='w-full'>
                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                    {t("organization.zip_code")}
                                </InputLabel>
                                <TextField
                                    id='add-org-zipCode'
                                    disabled={isDisable}
                                    className={nameClasses.root}
                                    style={{ width: '100%', marginTop: '24px' }}
                                    variant="outlined"
                                    name='zipCode'
                                    defaultValue={org.zipCode ? org.zipCode : ''}
                                    inputProps={{ maxLength: 5 }}
                                    type="text"
                                    autoComplete="off"
                                    onChange={handleChangeInput}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl className='w-full'>
                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                    {t("organization.city")}
                                </InputLabel>
                                <TextField
                                    id='add-org-city'
                                    disabled={isDisable}
                                    className={nameClasses.root}
                                    style={{ width: '100%', marginTop: '24px' }}
                                    variant="outlined"
                                    maxLength="255"
                                    name='city'
                                    defaultValue={org.city ? org.city : ''}
                                    type="text"
                                    autoComplete="off"
                                    onChange={handleChangeInput}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl className='w-full'>
                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                    {t("organization.country")}
                                </InputLabel>
                                {
                                    countries &&
                                    <Autocomplete
                                        onChange={handleChangeCountry}
                                        disabled={isDisable}
                                        defaultValue={countries && org.countryCorp ? countries.find(r => r.name_translation === org.countryCorp) : 250}
                                        options={countries}
                                        getOptionLabel={(e) => e.name_translation}
                                        style={{
                                            width: '100%',
                                            borderRadius: 4,
                                            position: 'relative',
                                            marginTop: '24px',
                                            fontSize: 16
                                        }}
                                        size='small'
                                        disableClearable
                                        className='mt-2'
                                        classes={{
                                            root: classes.root
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t('organization.add_placeholder')} />}
                                    />
                                }
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container className='mt-4'>
                        <Grid item xs={9}>
                            <FormControl className='w-full'>
                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                    {t("organization.web_link")}
                                </InputLabel>
                                <TextField
                                    id='add-org-webLink'
                                    disabled={isDisable}
                                    className={nameClasses.root}
                                    style={{ width: '100%', marginTop: '24px' }}
                                    variant="outlined"
                                    maxLength="255"
                                    name='webLink'
                                    defaultValue={org.webLink ? org.webLink : ''}
                                    type="text"
                                    autoComplete="off"
                                    onChange={handleChangeInput}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                        <Grid item xs={3}>
                            <FormControl className='w-full'>
                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                    {t("organization.vat_code")}
                                </InputLabel>
                                <TextField
                                    id='add-org-webLink'
                                    disabled={isDisable}
                                    className={nameClasses.root}
                                    style={{ width: '100%', marginTop: '24px' }}
                                    variant="outlined"
                                    maxLength="255"
                                    name='VATCode'
                                    defaultValue={org.VATCode ? org.VATCode : ''}
                                    type="text"
                                    autoComplete="off"
                                    onChange={handleChangeInput}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                        <Grid item xs={12}>
                            <FormControl className='w-full'>
                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                    {t("organization.comments")}
                                </InputLabel>
                                <textarea
                                    defaultValue={org.comments ? org.comments : ''}
                                    name='comments'
                                    maxLength='3000'
                                    disabled={isDisable}
                                    rows='5'
                                    style={{
                                        height: '100px',
                                        marginTop: '20px',
                                        borderRadius: '4px',
                                        border: '1px solid #ced4da',
                                        backgroundColor: 'white',
                                        paddingLeft: '10px'
                                    }}
                                    onChange={handleChangeInput}
                                >
                                </textarea>
                            </FormControl>
                        </Grid>
                    </Grid>

                </div>
            </div>
        </Layout>
    ) : <LoadingLayout />
};

export default withSnackbar(EditOrganization);
