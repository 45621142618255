const convertAmountToNumber = (value) => {
    const currentLang = localStorage.getItem('language');
    const amount = value.toString();
    if (currentLang === 'en') {
        return Number.parseFloat(amount.replaceAll(',', ''));
    }
    return Number.parseFloat((amount.replaceAll('.', '')).replaceAll(',', '.'));
}

const showStaticNumber = (value) => {
    const currentLang = localStorage.getItem('language');
    if (parseFloat(value) % 1 === 0) {
        if (currentLang === 'en') {
            return parseFloat(value) + '.00';
        } else {
            return parseFloat(value) + ',00';
        }
    } else {
        const splitNumber = parseFloat(value).toString().split('.');
        if(splitNumber[1].length === 1) {
            if (currentLang === 'en') {
                return splitNumber[0] + '.' +  splitNumber[1] + 0;
            } else {
                return splitNumber[0] + ',' +  splitNumber[1] + 0;
            }
        }
    }
    return parseFloat(value);
}

export { convertAmountToNumber,  showStaticNumber}