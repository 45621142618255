import React, { useEffect, useState } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import clsx from 'clsx';
import StepLabel from '@material-ui/core/StepLabel';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import BackspaceIcon from '@material-ui/icons/Backspace';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import StepConnector from '@material-ui/core/StepConnector';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import axios from "../../../../../config/axios";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

const ColorlibConnector = withStyles({
    vertical: {
        padding: '0',
        marginLeft: 13
    },

    lineVertical: {
        minHeight: 30
    },

    line: {
        height: 3,
        border: 0,
        borderLeft: '3px solid rgb(208, 94, 90)',
    },
})(StepConnector);


const renderIconForType = (type) => {
    switch (type) {
        case 1:
            return (
                <GroupAddIcon style={{ fontSize: 16 }} />
            );
        case 2:
            return (
                <AssignmentTurnedInIcon style={{ fontSize: 16 }} />
            );
        case 3:
            return (
                <ReportProblemIcon style={{ fontSize: 16 }} />
            );
        case 4:
            return (
                <BackspaceIcon style={{ fontSize: 16 }} />
            );
        case 5:
            return (
                <ThumbUpIcon style={{ fontSize: 16 }} />
            );
        case 6:
            return (
                <ThumbDownIcon style={{ fontSize: 16 }} />
            );
        case 7:
            return (
                <PeopleOutlineIcon style={{ fontSize: 16 }} />
            );
        case 8:
            return (
                <BeenhereIcon style={{ fontSize: 16 }} />
            );
        case 9:
            return (
                <EventBusyIcon style={{ fontSize: 16 }} />
            );
        case 10:
            return (
                <DoneAllIcon style={{ fontSize: 16 }} />
            );
        case 11:
            return (
                <DoneAllIcon style={{ fontSize: 16 }} />
            );
        case 12:
            return (
                <CloudDoneIcon style={{ fontSize: 16 }} />
            );
        case 13:
            return (
                <CloudDoneIcon style={{ fontSize: 16 }} />
            );
        case 14:
            return (
                <ImportExportIcon style={{ fontSize: 16 }} />
            );
        default:
            return (
                <CloudDoneIcon style={{ fontSize: 16 }} />
            );
    }
};

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: 'rgb(208, 94, 90)',
        zIndex: 1,
        color: '#fff',
        width: 28,
        height: 28,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },

});

const ColorlibStepIcon = (props) => {
    const classes = useColorlibStepIconStyles();
    return (
        <div
            className={clsx(classes.root)}
        >
            {renderIconForType(props)}
        </div>
    );
}

const getDateTime = (data) => {
    const splitDateTime = data.split('T');
    const splitDate = splitDateTime[0].split('-');
    const splitHours = splitDateTime[1].split(':');
    return splitDate[1] + '/' + splitDate[2] + '/'+ splitDate[0] + ' ' + splitHours[0] + ':' + splitHours[1];
}

export default function HistoryTab({contract}) {
    const classes = useStyles();
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [listActions, setListActions] = useState([]);
    const pagingHandleChange = (e, value) => {
        setPage(value);
    };

    useEffect(() => {
        (async function getListOrg() {
            const listHistoryContract = await axios.get(`/contract-history?contractUid=${contract.ikastUID}&pageNo=${page}`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            const { data, status } = listHistoryContract;

            if (status === 200) {
                setListActions(data.records);
                setTotalPage(data.totalPages);
            }
        })();
    }, [page]);

    return listActions ? (
        <div className={classes.root}>
            <Stepper connector={<ColorlibConnector />} orientation="vertical">
                {listActions.map((action) => (
                    <Step key={Math.random()}>
                        <StepLabel StepIconComponent={() => ColorlibStepIcon(action.type)}><span style={{ color: 'black'}}>{getDateTime(action.createdAt)}&nbsp;&nbsp;&nbsp;<b>{action.header}</b> </span></StepLabel>
                    </Step>
                ))}
            </Stepper>

            <div className='flex flex-row justify-center items-center py-4'>
                <Pagination count={totalPage} page={page} onChange={pagingHandleChange} />
            </div>
        </div>
    ): <></>;
}


