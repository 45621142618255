import React, { useEffect, useState } from 'react';
import RoyaltiesDetailSection from '../../../components/Royalties/RoyaltiesDetailSection';
import TotalSection from '../../../components/Royalties/TotalSection';
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import TextField from "@material-ui/core/TextField/TextField";
import { useTranslation } from 'react-i18next';
import {
    CURRENCY,
} from './../../../../constants';
import './index.css';
const RoyaltiesDetailsTab = (props) => {
    const { t, i18n } = useTranslation();

    const { contract, validContentList, dataTotalRevennue, totalSectionData, countryCode, isAnyInfringedLogs, selector, infringeContentList, startDate, endDate, handleChangeStartDate, handleChangeEndDate, countries } = props;
    const CURRENCY_UNIT = CURRENCY.find(c => c.id.toString() === localStorage.getItem('mainCurrency')).value;
    const [startDateLocal, setStartDateLocal] = useState(startDate);
    const handleChangeStartDateView = (e) => {
        const { value } = e.target;
        const newDate = new Date(value);
        setStartDateLocal(newDate.toISOString().split('T')[0]);
    }

    const [endDateLoacal, setEndDateLoacal] = useState(endDate);
    const handleChangeEndDateView = (e) => {
        const { value } = e.target;
        const endDate = new Date(value);
        setEndDateLoacal(endDate.toISOString().split('T')[0]);
    }
    useEffect(() => {
    }, []);

    const totaLogs = (type) => {
        let total = 0;
        if(type==='valid') {
            validContentList.forEach(item => {
                item.contentList.forEach(content => {
                    if(content.validContentViewlog && content.validContentViewlog.revenue) {
                        total++
                    }
                });
            });
        }
        if(type==='infringed') {
            infringeContentList.forEach(item => {
                item.contentList.forEach(content => {
                    if(content.infringedContentViewlog && content.infringedContentViewlog.revenue) {
                        total++
                    }
                });
            });
        }
        return total > 0
    }

    const MemoRoyaltiesDetailSection = React.memo(RoyaltiesDetailSection);
    return (
        <div>
            <div className='bg-white px-8 py-6 pl-8'>
                <div className='grid grid-cols-6 gap-2'>
                    <div />
                    <div />
                    <div>
                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                            {t("contract.start_date")}
                        </InputLabel>
                        <TextField
                            onChange={(e) => handleChangeStartDateView(e)}
                            onBlur={(e) => handleChangeStartDate(e)}
                            value={startDateLocal}
                            style={{
                                width: '100%',
                            }}
                            name='contentStartDate'
                            id="datetime-local"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            size='small'
                            variant='outlined'
                            inputProps={{
                                style: {
                                    padding: '10px',
                                    fontSize: 11,
                                }
                            }}
                        />
                    </div>
                    <div>
                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                            {t("contract.end_date")}
                        </InputLabel>
                        <TextField
                            value={endDateLoacal}
                            onChange={(e) => handleChangeEndDateView(e)}
                            onBlur={(e) => handleChangeEndDate(e)}
                            style={{
                                // marginTop: '0.5rem',
                                width: '100%'
                            }}
                            name='contentEndDate'
                            id="datetime-local"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            size='medium'
                            variant='outlined'
                            inputProps={{
                                style: {
                                    padding: '10px',
                                    fontSize: 11,
                                }
                            }}
                        />
                    </div>
                </div>
                {/* <div className='profile-title pb-2 pt-2' style={{ marginBottom: '10px', borderBottom: '1px solid #D05E5A' }}>
                    <b>Royalties details</b>
                </div> */}
                {!(infringeContentList.length > 0 && validContentList.length > 0) ? (
                    <p style={{textAlign: "center", marginTop: 20}}>{t("royalties.no_view")}</p>
                ) : null}
                {totaLogs('valid') ?
                    <MemoRoyaltiesDetailSection
                        countries={countries}
                        countryCode={countryCode}
                        infringed={false}
                        currencyUnit={CURRENCY_UNIT}
                        contractId={contract.ikastUID}
                        arrayInput={validContentList ? validContentList : []}
                    /> : null
                }
            </div>
            <div className='bg-white pr-8 py-6'>
                {dataTotalRevennue && totaLogs('valid') ?
                    <TotalSection
                        totalRoyalties={dataTotalRevennue.totalValidRoyalty}
                        MG={dataTotalRevennue.MG}
                        flatRate={dataTotalRevennue.flatPrice}
                        totalRevenue={dataTotalRevennue.totalRevenue}
                        currencyUnit={CURRENCY_UNIT}
                        marketShareValue={dataTotalRevennue.marketShareValue}
                        marketSharePercentage={dataTotalRevennue.marketSharePercentage}
                        finalRoyalties={dataTotalRevennue.finalRoyaltiesAll}
                        isWeightOff={contract.isWeightOff}
                    /> : null
                }
            </div>

            {isAnyInfringedLogs && totaLogs('infringed') ? <div className='bg-white px-8 py-6 pl-8'>
                <span className='font-bold' style={{ color: '#d05e5a' }}>{t("royalties.infringed_royalties")}</span>
                {dataTotalRevennue &&
                    (
                        <MemoRoyaltiesDetailSection
                            countries={countries}
                            isSeller={selector && selector.get('BelongsTo') === contract.Seller}
                            infringed={true}
                            contractId={contract.ikastUID}
                            currencyUnit={CURRENCY_UNIT}
                            arrayInput={infringeContentList ? infringeContentList : []}
                        />
                    )
                }
            </div> : null}

            {isAnyInfringedLogs && totaLogs('infringed') ? <div className='bg-white pr-8 py-6'>
                {dataTotalRevennue &&
                    (
                        <TotalSection
                            totalRoyalties={dataTotalRevennue.totalInfringeRoyalty}
                            currencyUnit={CURRENCY_UNIT}
                        />
                    )
                }
            </div> : null}
        </div>
    )
};

export default RoyaltiesDetailsTab;
