import React, { useState, useEffect } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import NativeSelect from '@material-ui/core/NativeSelect';
import { useTranslation } from 'react-i18next';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { authActions, authSelectors } from '../../../state/modules/auth';
import axios from '../../../config/axios';
import './index.css';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const LanguageComponent = ({ onChangeLanguage }) => {
  const [language, setLanguage] = useState(localStorage.getItem('language'));
  const { t, i18n } = useTranslation();
  const selector = useSelector((state) => authSelectors.user(state));
  useEffect(() => {
    setLanguage(localStorage.getItem('language'))
  }, []);

  const handleChange = async (e) => {
    if (onChangeLanguage) {
      onChangeLanguage(e.target.value);
    }
    setLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
    if(selector) {
      const dataUpdate = {
        name: selector.get('Name'),
        firstName: selector.get('First Name'),
        email: selector.get('Email'),
        role: selector.get('Role'),
        lang: e.target.value,
        autoSendEmail: selector.get('autoSendEmail')
      };
      const response = await axios.put(`/user/update-info`, dataUpdate, {
        headers: {
          common: {
            'x-access-token': localStorage.getItem('token') ? localStorage.getItem('token') : null,
          },
        }
      });
      if (response.status === 200) {
        localStorage.setItem('language', e.target.value);
        window.location.reload(false);
      }
    }


  }
  return (
    <div className='language-select'>
      {!onChangeLanguage ? (
        <Select
          style={{ color: 'white', marginRight: 10 }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={language}
          onChange={handleChange}
        >
          <MenuItem value={'en'}>EN</MenuItem>
          <MenuItem value={'fr'}>FR</MenuItem>
        </Select>
      ) : (
        <NativeSelect
          id="language-customized-select-native"
          style={{ width: '100%' }}
          value={language}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          <option value={'en'}>EN</option>
          <option value={'fr'}>FR</option>
        </NativeSelect>
      )}
      {/* <Select
            native
            style={{width: '100%'}}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            onChange={handleChange}
            >
            <MenuItem value={'en'}>EN</MenuItem>
            <MenuItem value={'fr'}>FF</MenuItem>
        </Select> */}

    </div>
  );
};
{/* <MenuItem value={'en'}><img src={EngLogo} alt="Eng Logo" style={{height: 21, width: 28}}/></MenuItem>
<MenuItem value={'fr'}><img src={FranceLogo} alt="France Logo" style={{height: 21, width: 28}}/></MenuItem> */}

export default LanguageComponent;
