import React, { Fragment, useEffect, useState, useContext, useMemo } from 'react';
import { useSnackbar, withSnackbar } from 'notistack';
import Layout from "../../../layouts/Default";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import TextField from "@material-ui/core/TextField/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import Switch from '@material-ui/core/Switch';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TransferList from '../../../components/Contract/TransferList/edit';
import axios from "../../../../config/axios";
import { useDispatch, useSelector } from "react-redux";
import { authActions, authSelectors } from "../../../../state/modules/auth";
import {
    GET_BUSSINESS_MODELS,
    GET_CALCULATION,
    CURRENCY,
    GET_EVALUATED_OPTIONS,
    GET_MG_APPLIED,
    GET_PRICING,
    GET_STATUS,
    GET_VIEWER_SESSION
} from '../../../../constants';
import { NumericFormat } from "react-number-format";
import './index.css';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import PricingLadder from "../../../components/Contract/PricingLadder";
import LoadingLayout from "../../../components/Common/LoadingLayout";
import Checkbox from "@material-ui/core/Checkbox";
import LinearProgress from "@material-ui/core/LinearProgress";
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffTwoTone";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import HistoryTab from "./HistoryTab/HistoryTab";
import LoadingContext from "../../../../context/loading/LoadingContext"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { convertAmountToNumber } from '../../../../util';
import GeneralInfo from '../../../components/Royalties/GeneralInfo';
const useStyle = makeStyles({
    root: {
        marginTop: '1.5rem'
    },
    moneyInput: {
        width: '120px',
    },

    selectMonetized: {
        padding: '10px',
    }
});

const useNoBorderStyles = makeStyles({
    root: {
        padding: '0px',
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        }
    }
});

const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        marginTop: '8px',
        height: '39.5px',
        "& $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&:hover $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&$focused $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-input": {
            padding: '18.5px 0px',
        }
    },
    focused: {},
    notchedOutline: {}
}));

const TabPanel = (props) => {
    const { children, valueTab, index, ...other } = props;
    const { t, i18n } = useTranslation();
    return (
        <div
            role="tabpanel"
            hidden={valueTab !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {valueTab === index && (
                <div>

                    {children}
                </div>
            )}
        </div>
    );
}

const EditContract = () => {
    const { t, i18n } = useTranslation();
    const BUSSINESS_MODELS = useMemo(() => GET_BUSSINESS_MODELS(t), [t]);
    const EVALUATED_OPTIONS = useMemo(() => GET_EVALUATED_OPTIONS(t), [t]);
    const VIEWER_SESSION = useMemo(() => GET_VIEWER_SESSION(t), [t]);
    const PRICING = useMemo(() => GET_PRICING(t), [t]);
    const STATUS = useMemo(() => GET_STATUS(t), [t]);
    const CALCULATION = useMemo(() => GET_CALCULATION(t), [t]);
    const MG_APPLIED = useMemo(() => GET_MG_APPLIED(t), [t]);
    let { id, type } = useParams();
    const dispatch = useDispatch();
    const { showLoading, hideLoading } = useContext(LoadingContext);
    const classes = useStyle();
    const noBorderClasses = useNoBorderStyles();
    const outlinedInputClasses = useOutlinedInputStyles();
    const [valueTab, setValueTab] = React.useState(0);
    const [messageKey, setMessageKey] = useState('');
    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    };
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#696969',
            color: '#dbdbdb',
            marginBottom: 0,
            fontSize: 14,
        },
    }))(Tooltip);

    useEffect(() => {
        if (localStorage.getItem('id') && localStorage.getItem('token')) {
            dispatch(authActions.getProfile({ id: localStorage.getItem('id') }));
        }
    }, []);

    const handleClickRestrictButton = (value) => {
        setIsInclude(value)
    }

    const { enqueueSnackbar } = useSnackbar();

    let history = useHistory();

    const selector = useSelector((state) => authSelectors.user(state));
    const [user, setUserTracking] = useState(selector);
    const [openRegisterPopup, setOpenRegisterPopup] = useState(false);
    const [openTerminatePopup, setOpenTerminatePopup] = useState(false);
    const [openReEditPopup, setOpenReEditPopup] = useState(false);
    const [openRejectPopup, setOpenRejectPopup] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState(null);
    const [isInclude, setIsInclude] = useState(true)
    const [isSaveButtonDisable, setIsSaveButtonDisable] = useState(false)
    const [sellerName, setSellerName] = useState(null);
    const [initializePricingLadder, setInitializePricingLadder] = useState([{ from: 1, to: 2, value: 0, minimum: 0 }]);
    const [importPreviousLogs, setImportPreviousLogs] = useState(false);
    const [contractStartedDateToFetchLogs, setContractStartedDateToFetchLogs] = useState(null);
    const [importLoadingScreen, setImportLoadingScreen] = useState(false);
    const [isPDFPreview, setIsPDFPreview] = useState(false);
    const [pdfS3, setPDFS3] = useState(null);
    const [previewPDFURL, setPreviewPDFURL] = React.useState(null);
    const [pickSellerLogo, setPickSellerLogo] = useState(null);
    const [isPricingLadderValid, setIsPricingLadderValid] = useState(true);
    const [pdfFileName, setPdfFileName] = useState('');
    const [appendPDFFile, setAppendPDFFile] = React.useState(null);
    const [contract, setContract] = useState(null);
    const [countries, setCountries] = useState(null);
    const [listSeller, setListSeller] = useState(null);
    const [listFilterSeller, setListFilterSeller] = useState(null);
    const [currentSeller, setCurrentSeller] = useState(null);
    const [contentSeller, setContentSeller] = useState(null);
    const [monetized, setMonetized] = useState(null); // set businessModel
    const [monetizedValue, setMonetizedValue] = useState(null);
    const [exclusive, setExclusive] = useState(true);
    const [errorStartDate, setErrorStartDate] = useState(false);
    const [errorEndDate, setErrorEndDate] = useState(false);
    const [isMarketShare, setIsMarketShare] = React.useState(false);
    const [listPermission, setListPermission] = useState([]);
    useEffect(() => {
        setUserTracking(selector);
    }, [selector]);

    useEffect(() => {
        if(user) setListPermission(user.get('permissions').toArray());
    }, [user]);

    useEffect(() => {
        (async function getOneContract() {
            try {
                showLoading();
                const { data: contract, status } = await axios.get(`/contract/get-one/${id}`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

                if (status === 200) {
                    setContractStartedDateToFetchLogs(contract.startDate);
                    setContract({
                        ...contract,
                        startDate: contract.startDate ? contract.startDate.slice(0, -8) : '',
                        endDate: contract.endDate ? contract.endDate.slice(0, -8) : '',
                    });
                    setIsMarketShare(contract.isWeightOff)
                    setIsInclude(contract.countryRestriction)
                    setMonetized(contract.businessModel);
                    setCurrentSeller(contract.Seller ? contract.Seller : '');
                    setExclusive(contract.exclusive);
                    setPdfFileName(contract.pdfFileName ? contract.pdfFileName : '');
                    setPDFS3(contract.pdfUrlS3 ? contract.pdfUrlS3 : null);

                    if (contract.businessModel !== 6 && contract.pricingOption === 2) {
                        let initializePricingLadder = [];
                        if (contract.pricingLadderRange.length === 1) {
                            let from = +contract.pricingLadderRange[0];
                            let to = +contract.pricingLadderRange[0] + 1;
                            initializePricingLadder.push({ from: from, to: to, value: contract.pricingLadderBilledRange[0], minimum: contract.pricingLadderMinimum[0] })
                        } else {
                            for (let i = 0; i < contract.pricingLadderRange.length; i++) {
                                let from = contract.pricingLadderRange[i];
                                let to = (i === contract.pricingLadderRange.length - 1) ? +contract.pricingLadderRange[i] + 1 : contract.pricingLadderRange[i + 1] - 1;
                                initializePricingLadder.push({ from: from, to: to, value: contract.pricingLadderBilledRange[i], minimum: contract.pricingLadderMinimum[i] })
                            }
                        }
                        setInitializePricingLadder(initializePricingLadder);
                    }

                    if (contract.Status === 3) {
                        if (contract.anchorDate) {
                            const anchorDateArr = contract.anchorDate.split("T");
                            const hours = anchorDateArr[1].split(".");
                            setNotificationMessage(t("contract.validate_on", { date: anchorDateArr[0], hours: hours[0] }));
                        }
                    } else if (contract.Status === 4) {
                        if (contract.anchorDate) {
                            const anchorDateArr = contract.anchorDate.split("T");
                            const hours = anchorDateArr[1].split(".");
                            setNotificationMessage(t("contract.ended_on", { date: anchorDateArr[0], hours: hours[0] }));
                        }
                    } else if (contract.Status === 5) {
                        if (contract.infringedAt) {
                            const infringedDateArr = contract.infringedAt.split("T");
                            const hours = infringedDateArr[1].split(".");
                            setNotificationMessage(t("contract.infringed_on", { date: infringedDateArr[0], hours: hours[0] }));
                        }
                    }
                }
                setMessageKey("");
                hideLoading();
            } catch (e) {
                setMessageKey("common.content_not_available");
                hideLoading();
            }
        })();
    }, [id]);

    useEffect(() => {
        if (localStorage.getItem('id') && localStorage.getItem('token')) {
            (async function getListOrg() {
                showLoading();
                const listOrgResponse = await axios.get('/org/get-list-orgs', {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

                const { data, status } = listOrgResponse;

                if (status === 200) {
                    setListSeller(data);
                }
                hideLoading();
            })();
        }
    }, []);

    useEffect(() => {
        if (selector) {
            if (localStorage.getItem('id') && localStorage.getItem('token')) {
                (async function getListOrg() {
                    showLoading();
                    const listOrgResponse = await axios.get(`/org/get-list-connected-orgs/${selector.get('BelongsTo')}`, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });

                    const { data, status } = listOrgResponse;

                    if (status === 200) {
                        setListFilterSeller(data);
                    }
                    hideLoading();
                })();
            }
        }
    }, [selector]);

    useEffect(() => {
        if (user && contract && contract.Seller) {
            (async function getListContentSeller() {
                showLoading();
                const sellerContentResponse = await axios.get(`/content/get-list-avail-contents?orgUid=${contract.Seller}`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

                const { data, status } = sellerContentResponse;

                if (status === 200) {
                    setContentSeller(data);
                }
                hideLoading();
            })();

            (async function getSellerName() {
                showLoading();
                const sellerContentResponse = await axios.get(`/org/get-one/${contract.Seller}`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

                const { data, status } = sellerContentResponse;

                if (status === 200) {
                    setSellerName(data.Name);
                }
                hideLoading();
            })();
        }
    }, [currentSeller]);

    useEffect(() => {
        (async function getListCountry() {
            showLoading();
            const listCountryResponse = await axios.get('/country/get-list-countries', {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            const { data, status } = listCountryResponse;

            if (status === 200) {
                setCountries(data);
            }
            hideLoading();
        })();
    }, []);

    const handleLicensedContentChosen = (list) => {
        const listLicensedContents = list ? list.map(content => content.ikastUID) : [];

        setContract({
            ...contract,
            Content: listLicensedContents
        })
    };

    useEffect(() => {
        if (monetized) {
            switch (monetized) {
                case 1:
                    setMonetizedValue([1, 12, 13, 14]);
                    return;
                case 2:
                    setMonetizedValue(!contract.mgApplied || contract.mgApplied === 1 ? [1, 2, 12, 13, 14, 7] : [1, 15, 12, 13, 14, 7]);
                    return;
                case 3:
                    setMonetizedValue(!contract.mgApplied || contract.mgApplied === 1 ? [1, 2, 12, 13, 14, 5] : [1, 15, 12, 13, 14, 5]);
                    return;
                case 4:
                    setMonetizedValue(!contract.mgApplied || contract.mgApplied === 1 ? [1, 2, 9, 10, 11, 12, 13, 14, 5] : [1, 15, 9, 10, 11, 12, 13, 14, 5]);
                    return;
                case 5:
                    setMonetizedValue(!contract.mgApplied || contract.mgApplied === 1 ? [1, 2, 9, 10, 11, 12, 13, 14, 3] : [1, 15, 9, 10, 11, 12, 13, 14, 3]);
                    return;
                case 6:
                    setMonetizedValue([1, 2, 6, 8]);
                    return;
                default:
                    return;
            }
        }
    }, [monetized]);

    const handleChangeExclusive = (e) => {
        setExclusive(e.target.checked)
    };

    const handleInputChangeText = (e) => {
        const { name, value } = e.target;
        if (name === 'Title' && value.trim().length > 0) {
            setIsSaveButtonDisable(false)
        }
        if (name === 'Title' && value.trim().length === 0) {
            setIsSaveButtonDisable(true)
        }
        console.log('Set Title:', value);
        setContract({
            ...contract,
            [name]: value
        })
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setContract({
            ...contract,
            [name]: convertAmountToNumber(value)
        })
    };

    const handleAutoCompleteChange = (e, value) => {
        setCurrentSeller(value.ikastUID);
        setPickSellerLogo(value.orgLogo);
        setContract({
            ...contract,
            Seller: value.ikastUID,
            Content: []
        });
    };

    const handleChangeMonetizeChange = (e) => {
        const { value } = e.target;
        setMonetized(value);

        setContract({
            ...contract,
            businessModel: value,
            flatRate: "0",
            MG: "0",
            RevPerSubs: "0",
            RevPerTransaction: "0",
            RevPerViewerCount: "0",
            durationUniqueView: "0",
            RevPerAdsServed: 0,
        });
    };

    const handleChangeTerritories = (e, value) => {
        const countries = value.map(country => country.ikastUID);
        setContract({
            ...contract,
            countries
        })
    };

    const handleChangeDatetime = (e) => {
        const { name, value } = e.target;
        let statusErrorDate = false;
        if (value === "") {
            statusErrorDate = true;
        } else {
            const dateArray = value.split('-');
            if (parseInt(dateArray[0]) < 1970) {
                statusErrorDate = true;;
            } else {
                statusErrorDate = false;
            }
        }
        if (name === 'startDate') {
            setErrorStartDate(statusErrorDate);
        } else {
            setErrorEndDate(statusErrorDate);
        }
        setContract({
            ...contract,
            [name]: value
        });
    };

    const handleUpdateComments = (e) => {
        const { name, value } = e.target;

        setContract({
            ...contract,
            [name]: value
        })
    };

    const handleUpdateContract = async () => {
        // Validate startDate < endDate
        if (checkValueDate()) return;
        let validStartDate = new Date(contract.startDate);
        let validEndDate = new Date(contract.endDate);
        if (validStartDate.getTime() > validEndDate.getTime()) {
            enqueueSnackbar(t("contract.endDate_greater_startDate"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            return;
        }

        if (contract.bussinessModel !== 6 && !isPricingLadderValid) {
            enqueueSnackbar(t("contract.invalid_pricing_ladder"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            return;
        }

        try {
            let contractPayload = {
                uuid: contract.ikastUID,
                status: contract.Status,
                exclusive,
                MG: (typeof contract.MG === "number") ? contract.MG : contract.MG ? Number.parseFloat(contract.MG) : 0,
                flatRate: (typeof contract.flatRate === "number") ? contract.flatRate : contract.flatRate ? Number.parseFloat(contract.flatRate) : 0,
                viewerCount: (typeof contract.RevPerViewerCount === "number") ? contract.RevPerViewerCount : contract.RevPerViewerCount ? Number.parseFloat(contract.RevPerViewerCount) : 0,
                minutesWatched: (typeof contract.durationUniqueView === "number") ? contract.durationUniqueView : contract.durationUniqueView ? Number.parseFloat(contract.durationUniqueView) : 0,
                revPerSubs: (typeof contract.RevPerSubs === "number") ? contract.RevPerSubs : contract.RevPerSubs ? Number.parseFloat(contract.RevPerSubs) : 0,
                revPerAds: (typeof contract.revPerAds === "number") ? contract.revPerAds : contract.revPerAds ? Number.parseFloat(contract.revPerAds) : 0,
                nbAdsServed: (typeof contract.RevPerAdsServed === "number") ? contract.RevPerAdsServed : contract.RevPerAdsServed ? Number.parseFloat(contract.RevPerAdsServed) : 0,
                nbTransaction: (typeof contract.RevPerTransaction === "number") ? contract.RevPerTransaction : contract.RevPerTransaction ? Number.parseFloat(contract.RevPerTransaction) : 0,
                businessModel: (typeof contract.businessModel === "number") ? contract.businessModel : contract.businessModel ? Number.parseInt(contract.businessModel) : 0,
                buyer: contract.Buyer,
                title: contract.Title,
                seller: contract.Seller,
                contents: contract.Content,
                countries: contract.countries,
                startDate: contract.startDate,
                endDate: contract.endDate,
                countryRestriction: isInclude,
                comments: contract.comments,
                durationMode: contract.durationMode,
                conditionValue: contract.conditionValue,
                pricingOption: contract.pricingOption,
                calculationsDone: contract.calculationsDone,
                mgApplied: contract.mgApplied,
                pricingLadderRange: contract.pricingLadderRange,
                pricingLadderBilledRange: contract.pricingLadderBilledRange,
                pricingLadderMinimum: contract.pricingLadderMinimum,
                pdfFileName: contract.pdfFileName,
                contentType: contract.contentType,
                isWeightOff: isMarketShare,
                marketShareBonusValue: isMarketShare ? contract.marketShareBonusValue : contract.marketShareBonusValue ? Number.parseFloat(contract.marketShareBonusValue) : 0,
            };

            showLoading();
            const contractUpdated = await axios.put('/contract/update', contractPayload, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            })

            const { status } = contractUpdated;

            if (status === 200) {
                enqueueSnackbar(t("contract.update_contract_success"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });

                if (appendPDFFile) {
                    const urlToUpload = contractUpdated.data.updatedPDFS3;
                    const options = { headers: { 'Content-Type': appendPDFFile.type } };
                    const uploadPDF = await axios.put(urlToUpload, appendPDFFile, options);
                }
                hideLoading();

                history.push('/contracts');
            } else {
                hideLoading();
                enqueueSnackbar(t("contract.update_contract_fail"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }

        } catch (e) {
            hideLoading();
            enqueueSnackbar(t("contract.update_contract_fail"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                variant: 'error'
            });
        }
    };

    const openRegisterPopupHandler = async () => {
        setOpenRegisterPopup(true);
    }

    const handleRegisterDealClose = () => {
        setOpenRegisterPopup(false);
    };

    const validateTime = () => {
        // 0001-12-24T00:00
        if (contract.startDate.split('-')[0] < 1970 || contract.endDate.split('-')[0] < 1970) {
            enqueueSnackbar(t("contract.invalid_date"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                variant: 'error'
            });
            handleRegisterDealClose();
            return;
        }
        // Validate startDate < endDate
        let validStartDate = new Date(contract.startDate);
        let validEndDate = new Date(contract.endDate);
        return validStartDate.getTime() > validEndDate.getTime();
    }

    const handleRegisterDeal = async () => {
        try {
            var isBuyer = (contract.Buyer === user.getIn(['org', 'ikastUID']));
            var isSeller = (contract.Seller === user.getIn(['org', 'ikastUID']));

            if (!contract) {
                enqueueSnackbar(t("contract.fetch_contract_error"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error'
                });

                handleRegisterDealClose();

                return;
            }

            if (contract.Status === 1 || contract.Status === 0) {
                if (isBuyer) {
                    isSeller = false;
                }
            } else if (contract.Status === 2) {
                if (isSeller) {
                    isBuyer = false;
                }
            }

            // Check buyer
            if (isBuyer && contract && contract.Status > 2) {
                enqueueSnackbar(t("contract.contract_not_started"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error'
                });

                handleRegisterDealClose();

                return;
            }

            // Check buyer
            if (isBuyer && contract && (contract.Status === 1 || contract.Status === 0) && (!contract.Seller || contract.Seller === "")) {
                enqueueSnackbar(t("contract.can_not_register_without_seller"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error'
                });

                handleRegisterDealClose();
                return;
            }

            // Check seller
            if (isSeller && contract && (contract.Status === 1 || contract.Status === 0)) {
                enqueueSnackbar(t("contract.can_not_register_contract_not_pending"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error'
                });

                handleRegisterDealClose();

                return;
            }

            // Check seller
            if (isSeller && contract && contract.Status > 3) {
                enqueueSnackbar(t("contract.can_not_register_contract_not_started_pending"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error'
                });

                handleRegisterDealClose();
                return;
            }

            let contractPayload = {
                uuid: contract.ikastUID,
                status: isBuyer ? 2 : (isSeller ? 3 : 1)
            };

            if (isSeller) {
                const timeElapsed = Date.now();
                const today = new Date(timeElapsed);
                contractPayload["anchorDate"] = today.toISOString();
            }

            if (validateTime()) {
                enqueueSnackbar(t("contract.endDate_greater_startDate"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
                return;
            }

            if (contract.bussinessModel !== 6 && !isPricingLadderValid) {
                enqueueSnackbar(t("contract.invalid_pricing_ladder"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
                return;
            }

            try {
                if (contract.Status === 2) {
                    const contractRegistered = await axios.put('/contract/register-deal', contractPayload, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    })

                    const { status } = contractRegistered;

                    if (status === 200) {
                        // Update previous logs if have
                        try {
                            showLoading();
                            const response = await axios.get(`/contract-update-previous-logs?contractUid=${contract.ikastUID}`, {
                                headers: {
                                    'x-access-token': localStorage.getItem('token')
                                }
                            });
                            hideLoading();

                        } catch (error) {
                            hideLoading();
                            console.log(error);
                        }

                        if (isSeller && contractPayload.status === 3 && importPreviousLogs) {
                            const dataToFetch = {
                                "contractUid": contract.ikastUID,
                                "startDate": contractStartedDateToFetchLogs.split('.')[0],
                                "endDate": (new Date().toISOString()).split('.')[0]
                            }
                            try {
                                handleRegisterDealClose();
                                enqueueSnackbar(t("contract.register_success_import_logs"), {
                                    anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right',
                                    },

                                    variant: 'info'
                                });

                                history.push('/contracts');
                                showLoading();
                                const response = await axios.post(`/viewinglog/fetch-viewinglogs`, dataToFetch
                                    , {
                                        headers: {
                                            common: {
                                                'x-access-token': localStorage.getItem('token')
                                            },
                                        }
                                    });

                                const { data, status } = response;
                                hideLoading();
                            } catch (error) {
                                hideLoading();
                                console.log(error);
                            }
                        } else {
                            enqueueSnackbar(t("contract.register_deal_success"), {
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },

                                variant: 'success'
                            });

                            if (isBuyer) {
                                // Create validation contract message to the seller.
                                let validateContractMessage = {
                                    "type": 2,
                                    "contractID": contract.ikastUID,
                                    "header": "Contract validation request",
                                    "body": "Contract validation request",
                                    "from": contract.Buyer,
                                    "receiver": contract.Seller,
                                }
                                const newMessage = await axios.post('/message/', validateContractMessage, {
                                    headers: {
                                        'x-access-token': localStorage.getItem('token')
                                    }
                                });
                            } else {
                                // Create validation contract message to the buyer.
                                let validateContractMessage = {
                                    "type": 8,
                                    "contractID": contract.ikastUID,
                                    "header": "Contract validation has been accepted",
                                    "body": "Contract validation has been accepted",
                                    "from": contract.Seller,
                                    "receiver": contract.Buyer,
                                }
                                const newMessage = await axios.post('/message/', validateContractMessage, {
                                    headers: {
                                        'x-access-token': localStorage.getItem('token')
                                    }
                                });
                            }
                            history.push('/contracts');
                        }
                    } else {
                        enqueueSnackbar(t("contract.register_deal_fail"), {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },

                            variant: 'error'
                        });
                    }
                } else {
                    let contractUpdatePayload = {
                        uuid: contract.ikastUID,
                        status: contract.Status,
                        exclusive,
                        MMG: (typeof contract.MG === "number") ? contract.MG : contract.MG ? Number.parseFloat(contract.MG) : 0,
                        flatRate: (typeof contract.flatRate === "number") ? contract.flatRate : contract.flatRate ? Number.parseFloat(contract.flatRate) : 0,
                        viewerCount: (typeof contract.RevPerViewerCount === "number") ? contract.RevPerViewerCount : contract.RevPerViewerCount ? Number.parseFloat(contract.RevPerViewerCount) : 0,
                        minutesWatched: (typeof contract.durationUniqueView === "number") ? contract.durationUniqueView : contract.durationUniqueView ? Number.parseFloat(contract.durationUniqueView) : 0,
                        revPerSubs: (typeof contract.RevPerSubs === "number") ? contract.RevPerSubs : contract.RevPerSubs ? Number.parseFloat(contract.RevPerSubs) : 0,
                        revPerAds: (typeof contract.revPerAds === "number") ? contract.revPerAds : contract.revPerAds ? Number.parseFloat(contract.revPerAds) : 0,
                        nbAdsServed: (typeof contract.RevPerAdsServed === "number") ? contract.RevPerAdsServed : contract.RevPerAdsServed ? Number.parseFloat(contract.RevPerAdsServed) : 0,
                        nbTransaction: (typeof contract.RevPerTransaction === "number") ? contract.RevPerTransaction : contract.RevPerTransaction ? Number.parseFloat(contract.RevPerTransaction) : 0,
                        businessModel: (typeof contract.businessModel === "number") ? contract.businessModel : contract.businessModel ? Number.parseInt(contract.businessModel) : 0,
                        buyer: contract.Buyer,
                        title: contract.Title,
                        seller: contract.Seller,
                        contents: contract.Content,
                        countries: contract.countries,
                        startDate: contract.startDate,
                        endDate: contract.endDate,
                        countryRestriction: isInclude,
                        comments: contract.comments,
                        durationMode: contract.durationMode,
                        conditionValue: contract.conditionValue,
                        pricingOption: contract.pricingOption,
                        calculationsDone: contract.calculationsDone,
                        mgApplied: contract.mgApplied,
                        pricingLadderRange: contract.pricingLadderRange,
                        pricingLadderBilledRange: contract.pricingLadderBilledRange,
                        pricingLadderMinimum: contract.pricingLadderMinimum,
                        pdfFileName: contract.pdfFileName,
                        contentType: contract.contentType,
                        isWeightOff: isMarketShare,
                        marketShareBonusValue: isMarketShare ? contract.marketShareBonusValue : contract.marketShareBonusValue ? Number.parseFloat(contract.marketShareBonusValue) : 0,
                    };
                    const contractUpdated = await axios.put('/contract/update', contractUpdatePayload, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    })

                    const { status } = contractUpdated;

                    if (status === 200) {
                        if (appendPDFFile) {
                            const urlToUpload = contractUpdated.data.updatedPDFS3;
                            const options = { headers: { 'Content-Type': appendPDFFile.type } };
                            const uploadPDF = await axios.put(urlToUpload, appendPDFFile, options);
                        }

                        const contractRegistered = await axios.put('/contract/register-deal', contractPayload, {
                            headers: {
                                'x-access-token': localStorage.getItem('token')
                            }
                        })

                        const { status } = contractRegistered;

                        if (status === 200) {
                            if (isSeller && contractPayload.status === 3 && importPreviousLogs) {
                                const dataToFetch = {
                                    "contractUid": contract.ikastUID,
                                    "startDate": contractStartedDateToFetchLogs.split('.')[0],
                                    "endDate": (new Date().toISOString()).split('.')[0]
                                }
                                try {
                                    handleRegisterDealClose();
                                    enqueueSnackbar(t("contract.register_success_import_logs"), {
                                        anchorOrigin: {
                                            vertical: 'top',
                                            horizontal: 'right',
                                        },

                                        variant: 'info'
                                    });

                                    history.push('/contracts');
                                    showLoading();
                                    const response = await axios.post(`/viewinglog/fetch-viewinglogs`, dataToFetch
                                        , {
                                            headers: {
                                                common: {
                                                    'x-access-token': localStorage.getItem('token')
                                                },
                                            }
                                        });

                                    const { data, status } = response;
                                    hideLoading();
                                } catch (error) {
                                    hideLoading();
                                    console.log(error);
                                }
                            } else {
                                enqueueSnackbar(t("contract.register_deal_success"), {
                                    anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right',
                                    },

                                    variant: 'success'
                                });

                                if (isBuyer) {
                                    // Create validation contract message to the seller.
                                    let validateContractMessage = {
                                        "type": 2,
                                        "contractID": contract.ikastUID,
                                        "header": "Contract validation request",
                                        "body": "Contract validation request",
                                        "from": contract.Buyer,
                                        "receiver": contract.Seller,
                                    }
                                    const newMessage = await axios.post('/message/', validateContractMessage, {
                                        headers: {
                                            'x-access-token': localStorage.getItem('token')
                                        }
                                    });
                                } else {
                                    // Create validation contract message to the buyer.
                                    let validateContractMessage = {
                                        "type": 8,
                                        "contractID": contract.ikastUID,
                                        "header": "Contract validation has been accepted",
                                        "body": "Contract validation has been accepted",
                                        "from": contract.Seller,
                                        "receiver": contract.Buyer,
                                    }
                                    const newMessage = await axios.post('/message/', validateContractMessage, {
                                        headers: {
                                            'x-access-token': localStorage.getItem('token')
                                        }
                                    });
                                }
                                history.push('/contracts');
                            }
                        } else {
                            enqueueSnackbar(t("contract.register_deal_fail"), {
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },

                                variant: 'error'
                            });
                        }
                    }
                }
            } catch (e) {
                enqueueSnackbar(t("contract.register_deal_fail"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }
        } catch (e) {
            enqueueSnackbar(t("contract.register_deal_fail"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
    };

    const renderPopupRegisterConfirm = () => {
        return (
            <Dialog
                open={openRegisterPopup}
                onClose={handleRegisterDealClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t("contract.register_deal_confirm")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {contract && contract.Seller && user && user.get('org') && contract.Seller === user.getIn(['org', 'ikastUID']) && contract.Status === 2 && new Date() >= new Date(contract.startDate) ? <div style={{ marginLeft: '-11px' }}>
                            <Checkbox
                                checked={importPreviousLogs}
                                onChange={() => setImportPreviousLogs(!importPreviousLogs)}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': 'test label' }}
                            />
                            {t("contract.import_logs")}
                        </div> : null}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRegisterDealClose} color="primary">
                        {t("common.cancel")}
                    </Button>
                    <Button onClick={() => handleRegisterDeal()} color="primary" autoFocus>
                        {t("common.yes")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    };

    const handleChangeMarketShare = (event) => {
        setIsMarketShare(event.target.checked);
    }

    const openTerminatePopupHandler = async () => {
        setOpenTerminatePopup(true);
    };

    const openReEditPopupHandler = async () => {
        setOpenReEditPopup(true);
    };

    const openRejectPopupHandler = async () => {
        setOpenRejectPopup(true);
    }

    const terminateHandler = async () => {
        try {
            if (contract.Status !== 3) {
                enqueueSnackbar(t("contract.terminate_not_running"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error'
                });

                handleRegisterDealClose();

                return;
            }

            let contractPayload = {
                uuid: contract.ikastUID,
                status: 4
            };

            const timeElapsed = Date.now();
            const today = new Date(timeElapsed);
            contractPayload["anchorDate"] = today.toISOString();

            showLoading();
            const contractUpdated = await axios.put('/contract/register-deal', contractPayload, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            })

            const { status } = contractUpdated;
            hideLoading();
            if (status === 200) {
                enqueueSnackbar(t("contract.terminate_success"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });

                if (contract.Buyer !== contract.Seller) {
                    // Create validation contract message to the seller.
                    let terminateContractMessageToSeller = {
                        "type": 9,
                        "contractID": contract.ikastUID,
                        "header": "Contract has been terminated",
                        "body": "Contract has been terminated",
                        "from": contract.Buyer,
                        "receiver": contract.Seller,
                    }
                    const newMessage1 = await axios.post('/message/', terminateContractMessageToSeller, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });

                    // Create validation contract message to the buyer.
                    let terminateContractMessageToBuyer = {
                        "type": 9,
                        "contractID": contract.ikastUID,
                        "header": "Contract has been terminated",
                        "body": "Contract has been terminated",
                        "from": contract.Seller,
                        "receiver": contract.Buyer,
                    }
                    const newMessage2 = await axios.post('/message/', terminateContractMessageToBuyer, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });
                } else {
                    // Create validation contract message to the seller.
                    let terminateContractMessageToSeller = {
                        "type": 9,
                        "contractID": contract.ikastUID,
                        "header": "Contract has been terminated",
                        "body": "Contract has been terminated",
                        "from": contract.Buyer,
                        "receiver": contract.Seller,
                    }
                    const newMessage = await axios.post('/message/', terminateContractMessageToSeller, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });
                }

                history.push('/contracts');
            } else {
                enqueueSnackbar(t("contract.terminate_fail"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }

        } catch (e) {
            enqueueSnackbar(t("contract.terminate_fail"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
    };

    const reEditHandler = async () => {
        try {

            let contractPayload = {
                uuid: contract.ikastUID,
                status: 1,
                isReEdit: true
            };

            const timeElapsed = Date.now();
            const today = new Date(timeElapsed);
            contractPayload["anchorDate"] = today.toISOString();

            showLoading();
            const contractUpdated = await axios.put('/contract/update', contractPayload, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            })

            const { status } = contractUpdated;
            hideLoading();
            if (status === 200) {
                setOpenReEditPopup(false);
                if (contract.Buyer !== contract.Seller) {
                    // Create validation contract message to the seller.
                    let terminateContractMessageToSeller = {
                        "type": 10,
                        "contractID": contract.ikastUID,
                        "header": "Contract has been re-edited",
                        "body": "Contract has been re-edited",
                        "from": contract.Buyer,
                        "receiver": contract.Seller,
                    }
                    const newMessage1 = await axios.post('/message/', terminateContractMessageToSeller, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });

                    // Create validation contract message to the buyer.
                    let terminateContractMessageToBuyer = {
                        "type": 10,
                        "contractID": contract.ikastUID,
                        "header": "Contract has been re-edited",
                        "body": "Contract has been re-edited",
                        "from": contract.Seller,
                        "receiver": contract.Buyer,
                    }
                    const newMessage2 = await axios.post('/message/', terminateContractMessageToBuyer, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });
                } else {
                    // Create validation contract message to the seller.
                    let terminateContractMessageToSeller = {
                        "type": 10,
                        "contractID": contract.ikastUID,
                        "header": "Contract has been re-edited",
                        "body": "Contract has been re-edited",
                        "from": contract.Buyer,
                        "receiver": contract.Seller,
                    }
                    const newMessage = await axios.post('/message/', terminateContractMessageToSeller, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });
                }

                window.location.reload();
            } else {
                enqueueSnackbar(t("contract.terminate_fail"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }

        } catch (e) {
            enqueueSnackbar(t("contract.terminate_fail"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
    };

    const rejectHandler = async () => {
        try {
            if (contract.Status !== 2) {
                enqueueSnackbar(t("contract.terminate_pending"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error'
                });

                handleRegisterDealClose();

                return;
            }

            let contractPayload = {
                uuid: contract.ikastUID,
                status: 0
            };

            const contractUpdated = await axios.put('/contract/register-deal', contractPayload, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            })

            const { status } = contractUpdated;

            if (status === 200) {
                enqueueSnackbar(t("contract.reject_contract_success"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });

                // Create reject contract message to the buyer.
                let rejectContractMessage = {
                    "type": 4,
                    "contractID": contract.ikastUID,
                    "header": "Contract validation has been rejected",
                    "body": "Contract validation has been rejected",
                }

                rejectContractMessage["from"] = contract.Seller;
                rejectContractMessage["receiver"] = contract.Buyer;

                const newMessage = await axios.post('/message/', rejectContractMessage, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

                history.push('/contracts');
            } else {
                enqueueSnackbar(t("contract.reject_contract_fail"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }

        } catch (e) {
            enqueueSnackbar(t("contract.reject_contract_fail"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
    };

    const handleTerminateClose = () => {
        setOpenTerminatePopup(false);
    };

    const handleReEditClose = () => {
        setOpenReEditPopup(false);
    };

    const handleRejectClose = () => {
        setOpenRejectPopup(false);
    }

    const renderPopupTerminateConfirm = () => {
        return (
            <Dialog
                open={openTerminatePopup}
                onClose={handleTerminateClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t("contract.terminate_contract")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("contract.do_want_continue")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleTerminateClose} color="primary">
                        {t("common.cancel")}
                    </Button>
                    <Button onClick={() => terminateHandler()} color="primary" autoFocus>
                        {t("common.yes")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    };

    const renderPopupReEditConfirm = () => {
        return (
            <Dialog
                open={openReEditPopup}
                onClose={handleReEditClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t("contract.re_edit_contract")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("contract.re_edit_warning_message")}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        {t("contract.do_want_continue")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleReEditClose} color="primary">
                        {t("common.cancel")}
                    </Button>
                    <Button onClick={() => reEditHandler()} color="primary" autoFocus>
                        {t("common.yes")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    };

    const renderPopupRejectConfirm = () => {
        return (
            <Dialog
                open={openRejectPopup}
                onClose={handleRejectClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Reject Contract"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("contract.do_want_continue")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRejectClose} color="primary">
                        {t("common.cancel")}
                    </Button>
                    <Button onClick={() => rejectHandler()} color="primary" autoFocus>
                        {t("common.yes")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    };

    const loggedInUserOrg = user && user.get('org') ? user.getIn(['org', 'Name']) : '';
    var buyerName = loggedInUserOrg;
    if (listSeller && contract) {
        const buyerOrg = listSeller.filter(oneOrg => oneOrg.ikastUID === contract.Buyer);
        buyerName = buyerOrg ? buyerOrg[0].Name : loggedInUserOrg;
    }

    var screenDisable = false;
    if (type === "update") {
        if ((contract && contract.Status > 1) ||
            (contract && contract.Status === 0 && user && user.get('org') && contract.Seller === user.getIn(['org', 'ikastUID']) && contract.Seller !== contract.Buyer)) {
            screenDisable = true;
        }
    }

    const CURRENCY_UNIT = (user && user.get('org')) ? CURRENCY.find(c => c.id === user.getIn(['org', 'mainCurrency'])).value : "";

    const generateRegisterButton = () => {
        if (contract.startDate !== "" && contract.endDate !== "" && contract.Content.length >= 1 && contract.countries.length >= 1 && parseInt(contract.startDate.split('-')[0]) > 1970 && parseInt(contract.endDate.split('-')[0]) > 1970) {
            return (
                <Button
                    className="no-border-button"
                    onClick={openRegisterPopupHandler}
                    style={{
                        backgroundColor: '#D05E5A',
                        color: 'white',
                        fontSize: '0.75rem',
                        padding: '0 2rem'
                    }}
                >
                    {t("contract.register_deal")}
                </Button>)
        } else {
            return (
                <Button
                    className="no-border-button"
                    style={{
                        backgroundColor: '#a79c9c85',
                        color: 'white',
                        fontSize: '0.75rem',
                        padding: '0 2rem'
                    }}
                >
                    {t("contract.register_deal")}
                </Button>)
        }
    }

    const handleChangeViewerSession = (e) => {
        const conditionValue = e.target.value === 1 ? 5 : 0;
        setContract({
            ...contract,
            conditionValue: conditionValue,
            durationMode: e.target.value
        })
    }

    const handleChangeConditionValue = (e) => {
        setContract({
            ...contract,
            conditionValue: convertAmountToNumber(e.target.value)
        })
    }

    const handlePricingChange = (e) => {
        setContract({
            ...contract,
            pricingOption: e.target.value
        })
    }

    const handleChangeMGApplied = (e) => {
        setContract({
            ...contract,
            mgApplied: e.target.value
        })

        if (e.target.value !== 1) {
            const tempMentionAsValueItem = monetizedValue.filter(item => item !== 2);
            tempMentionAsValueItem.push(15);
            setMonetizedValue(tempMentionAsValueItem);
        } else {
            const tempMentionAsValueItem = monetizedValue.filter(item => item !== 15);
            if (contract.bussinessModel !== 1 && contract.bussinessModel !== 6) {
                tempMentionAsValueItem.push(2);
                setMonetizedValue(tempMentionAsValueItem);
            }
        }
    }

    const changeCalculationDones = (e) => {
        setContract({
            ...contract,
            calculationsDone: e.target.value
        })
    }

    const handlePricingLadder = (formValue) => {
        pricingLadderValidation(formValue);
        setInitializePricingLadder(formValue);
        let pricingLadderRange = [];
        let pricingLadderBilledRange = [];
        let pricingLadderMinimum = [];

        formValue.forEach((item) => {
            pricingLadderRange.push(item.from);
            pricingLadderBilledRange.push(item.value);
            pricingLadderMinimum.push(item.minimum);
        });
        setContract({
            ...contract,
            pricingLadderRange: pricingLadderRange,
            pricingLadderBilledRange: pricingLadderBilledRange,
            pricingLadderMinimum: pricingLadderMinimum,
        })
    }

    const pricingLadderValidation = (formValue) => {
        if (formValue.length > 1) {
            for (let i = 0; i < formValue.length - 1; i++) {
                if (+formValue[i].to <= +formValue[i].from || ((i <= formValue.length - 2) && (+formValue[i + 1].from !== +formValue[i].to + 1))) {
                    setIsPricingLadderValid(false);
                    break;
                } else {
                    setIsPricingLadderValid(true);
                }
            }
        }
    }

    const uploadPDFFile = (e) => {
        if (e.length === 0) {
            setAppendPDFFile(null);
            setContract({
                ...contract,
                pdfFileName: '',
                contentType: null
            })
            return;
        }

        if (e.target.files[0].type !== 'application/pdf') {
            enqueueSnackbar(t("contract.only_pdf"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            return;
        }
        setAppendPDFFile(e.target.files[0]);
        setPreviewPDFURL(URL.createObjectURL(e.target.files[0]));
        setPdfFileName(e.target.files[0].name);
        setPDFS3(null);
        setContract({
            ...contract,
            pdfFileName: e.target.files[0].name,
            contentType: e.target.files[0].type
        })
    }

    const removePDFFile = () => {
        setPdfFileName('');
        setAppendPDFFile(null);
        setPDFS3(null);
        setContract({
            ...contract,
            pdfFileName: '',
            contentType: null
        })
    }

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const checkValueDate = () => {
        let statusErrorStartDate = false;
        let statusErrorEndDate = false;
        if (contract.startDate === "") {
            statusErrorStartDate = true;
        } else {
            const dateArray = contract.startDate.split('-');
            if (parseInt(dateArray[0]) < 1970) {
                statusErrorStartDate = true;;
            } else {
                statusErrorStartDate = false;
            }
        }

        if (contract.endDate === "") {
            statusErrorEndDate = true;
        } else {
            const dateArray = contract.endDate.split('-');
            if (parseInt(dateArray[0]) < 1970) {
                statusErrorEndDate = true;
            } else {
                statusErrorEndDate = false;
            }
        }
        if (statusErrorStartDate && statusErrorEndDate) {
            enqueueSnackbar(t("contract.end_start_date_require"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        };
        if (statusErrorStartDate && !statusErrorEndDate) {
            enqueueSnackbar(t("contract.start_date_require"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
        if (!statusErrorStartDate && statusErrorEndDate) {
            enqueueSnackbar(t("contract.end_date_require"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
        setErrorStartDate(statusErrorStartDate);
        setErrorEndDate(statusErrorEndDate);
        if (statusErrorStartDate || statusErrorEndDate) {
            return true
        } else return false;
    }

    const handleCreateNewContract = async () => {
        if (checkValueDate()) return;
        let validStartDate = new Date(contract.startDate);
        let validEndDate = new Date(contract.endDate);
        if (validStartDate.getTime() > validEndDate.getTime()) {
            enqueueSnackbar(t("contract.endDate_greater_startDate"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            return;
        }

        if (contract.bussinessModel !== 6 && !isPricingLadderValid) {
            enqueueSnackbar(t("contract.invalid_pricing_ladder"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            return;
        }

        try {
            let formattedNewContract = {
                status: 1,
                exclusive,
                MG: (typeof contract.MG === "number") ? contract.MG : contract.MG ? Number.parseFloat(contract.MG) : 0,
                flatRate: (typeof contract.flatRate === "number") ? contract.flatRate : contract.flatRate ? Number.parseFloat(contract.flatRate) : 0,
                viewerCount: (typeof contract.RevPerViewerCount === "number") ? contract.RevPerViewerCount : contract.RevPerViewerCount ? Number.parseFloat(contract.RevPerViewerCount) : 0,
                minutesWatched: (typeof contract.durationUniqueView === "number") ? contract.durationUniqueView : contract.durationUniqueView ? Number.parseFloat(contract.durationUniqueView) : 0,
                revPerSubs: (typeof contract.RevPerSubs === "number") ? contract.RevPerSubs : contract.RevPerSubs ? Number.parseFloat(contract.RevPerSubs) : 0,
                revPerAds: (typeof contract.revPerAds === "number") ? contract.revPerAds : contract.revPerAds ? Number.parseFloat(contract.revPerAds) : 0,
                nbAdsServed: (typeof contract.RevPerAdsServed === "number") ? contract.RevPerAdsServed : contract.RevPerAdsServed ? Number.parseFloat(contract.RevPerAdsServed) : 0,
                nbTransaction: (typeof contract.RevPerTransaction === "number") ? contract.RevPerTransaction : contract.RevPerTransaction ? Number.parseFloat(contract.RevPerTransaction) : 0,
                businessModel: (typeof contract.businessModel === "number") ? contract.businessModel : contract.businessModel ? Number.parseInt(contract.businessModel) : 0,
                buyer: contract.Buyer,
                title: contract.Title,
                seller: contract.Seller,
                contents: contract.Content,
                countries: contract.countries,
                startDate: contract.startDate,
                endDate: contract.endDate,
                countryRestriction: isInclude,
                comments: contract.comments,
                durationMode: contract.durationMode,
                conditionValue: contract.conditionValue,
                pricingOption: contract.pricingOption,
                calculationsDone: contract.calculationsDone,
                mgApplied: contract.mgApplied,
                pricingLadderRange: contract.pricingLadderRange,
                pricingLadderBilledRange: contract.pricingLadderBilledRange,
                pricingLadderMinimum: contract.pricingLadderMinimum,
                pdfFileName: contract.pdfFileName,
                contentType: contract.contentType,
                isWeightOff: isMarketShare,
            };

            if (formattedNewContract.countries && formattedNewContract.countries.length === 0) {
                formattedNewContract.countries = undefined
            }

            if (formattedNewContract.contents && formattedNewContract.contents.length === 0) {
                formattedNewContract.contents = undefined
            }

            const contractResponse = await axios.post('/contract', formattedNewContract, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            const { status } = contractResponse;

            if (status === 200) {
                enqueueSnackbar(t("contract.duplicate_success"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });

                if (appendPDFFile) {
                    const urlToUpload = contractResponse.data.record.uploadPDFUrl;
                    const options = { headers: { 'Content-Type': appendPDFFile.type } };
                    const uploadPDF = await axios.put(urlToUpload, appendPDFFile, options);
                }

                history.push('/contracts');
            } else {
                enqueueSnackbar(t("notify.something_wrong"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }
        } catch (e) {
            enqueueSnackbar(`${t("notify.something_wrong")}: ${e.message}`, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
    };

    const goToDuplicatePage = () => {
        history.push("/contracts/duplicate/" + id);
    }

    return contract ? (
        <Layout title="Add Content">
            <div className='flex justify-between content-center'>
                {type === "duplicate" ? (
                    <div>
                        {t("contract.breadcrumb_duplicate_contracts")}
                        <p style={{ fontSize: '24px' }}>
                            {t("contract.duplicate_contract")}
                        </p>
                    </div>
                ) : (
                    <div>
                        {t("contract.breadcrumb_update_contracts")}
                        <p style={{ fontSize: '24px' }}>
                            {t("contract.update_contract")}
                        </p>
                    </div>
                )}
                {type === 'duplicate' ? (<div className='flex' style={{
                    height: '36px'
                }}>
                    <Button
                        disabled={isSaveButtonDisable}
                        onClick={handleCreateNewContract}
                        style={{
                            height: '100%',
                            padding: '6px 16px',
                            backgroundColor: '#D05E5A',
                            marginLeft: '0.5rem',
                            color: 'white',
                            fontSize: '0.75rem'
                        }}>
                        {t("common.create")}
                    </Button>
                </div>) : (
                    <div className='flex' style={{
                        height: '36px'
                    }}>
                        {listPermission.includes('IKAST_CONTRACT_DUPLICATE') ? 
                            <Button
                                onClick={goToDuplicatePage}
                                // disabled={isSaveButtonDisable}
                                style={{
                                    height: '100%',
                                    padding: '6px 16px',
                                    backgroundColor: '#D05E5A',
                                    marginRight: '0.5rem',
                                    color: 'white',
                                    fontSize: '0.75rem'
                                }}>
                                {t("common.duplicate")}
                            </Button>
                        : null}
                        {(contract.Status === 3 || contract.Status === 5) && user && user.get('org') && contract.Seller === user.getIn(['org', 'ikastUID'])  ?
                            <Button
                                onClick={openReEditPopupHandler}
                                style={{
                                    backgroundColor: '#D05E5A',
                                    color: 'white',
                                    fontSize: '0.75rem',
                                    padding: '0 2rem',
                                    marginRight: '0.5rem',
                                }}
                            >
                                {t("contract.reEdit")}
                            </Button>
                            :
                            (
                                <></>
                            )}
                        {(contract.Status === 1 || contract.Status === 0 || (contract.Status === 2 && user && user.get('org') && contract.Buyer === contract.Seller && contract.Buyer === user.getIn(['org', 'ikastUID'])) || (contract.Status === 2 && contract.Buyer !== contract.Seller && user && user.get('org') && contract.Seller === user.getIn(['org', 'ikastUID']))) ? (
                            generateRegisterButton()) : (contract.Status === 3 || contract.Status === 5) ? (
                                <Button
                                    onClick={openTerminatePopupHandler}
                                    style={{
                                        backgroundColor: '#D05E5A',
                                        color: 'white',
                                        fontSize: '0.75rem',
                                        padding: '0 2rem'
                                    }}
                                >
                                    {t("contract.terminate")}
                                </Button>
                            ) :
                            (
                                <></>
                            )
                        }
                        {(contract.Status === 1
                            || (contract.Status === 0 && contract.Buyer === contract.Seller)
                            || (contract.Status === 0 && contract.Buyer !== contract.Seller && user && user.get('org') && contract.Buyer === user.getIn(['org', 'ikastUID']))) ? (
                            <Button
                                onClick={handleUpdateContract}
                                disabled={isSaveButtonDisable}
                                style={{
                                    height: '100%',
                                    padding: '6px 16px',
                                    backgroundColor: '#D05E5A',
                                    marginLeft: '0.5rem',
                                    color: 'white',
                                    fontSize: '0.75rem'
                                }}>
                                {t("contract.update")}
                            </Button>
                        ) : (contract.Status === 2) ? (
                            <Button
                                onClick={openRejectPopupHandler}
                                style={{
                                    height: '100%',
                                    padding: '6px 16px',
                                    backgroundColor: '#D05E5A',
                                    marginLeft: '0.5rem',
                                    color: 'white',
                                    fontSize: '0.75rem'
                                }}>
                                {t("contract.reject")}
                            </Button>
                        ) : (
                            <></>
                        )}
                    </div>
                )}

            </div>

            <Tabs value={valueTab} onChange={handleChange} aria-label="basic tabs example">
                <Tab label={<b>{t("contract.identification")}</b>} {...a11yProps(0)} />
                <Tab label={<b>{t("contract.business_rules")}</b>} {...a11yProps(1)} />
                <Tab label={<b>{t("contract.history")}</b>} {...a11yProps(2)} />
                <Tab label={<b>{t("contract.comments")}</b>} {...a11yProps(3)} />
            </Tabs>
            <TabPanel valueTab={valueTab} index={0}>
                <div className='bg-white px-8 py-8'>
                    <div className='flex justify-between content-center w-full pb-4' style={{ marginTop: '20px' }}>
                        <div className='add-contracts-top flex w-full'>
                            <div className='add-contracts-title w-2/4'>
                                <div className='font-bold'>{t("contract.title")}</div>
                                <div className='w-full'>
                                    <TextField
                                        className={noBorderClasses.root}
                                        disabled={screenDisable}
                                        onChange={handleInputChangeText}
                                        defaultValue={contract.Title}
                                        id='add-contract-title'
                                        style={{ width: '100%' }}
                                        variant="outlined"
                                        maxLength="255"
                                        name='Title'
                                        type="text"
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <div className='add-contracts-status ml-3 w-2/4'>
                                <div className='font-bold'>{t("contract.status")}</div>
                                <div id="contract-status" className='flex items-center'>
                                    <span id='status' className='rounded-full inline-block m-1' style={{
                                        backgroundColor: STATUS.find(s => s.id === (type === "duplicate" ? 1 : contract.Status)).color,
                                        height: '15px',
                                        width: '15px',
                                    }} />
                                    <label className=''>
                                        {STATUS.find(s => s.id === (type === "duplicate" ? 1 : contract.Status)).display}
                                    </label>
                                    <div style={{ marginLeft: 30 }}>
                                        <GeneralInfo contract={contract} updateContractScreen={true} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='flex justify-between content-center w-full pb-4'
                        style={{
                            marginTop: '10px'
                        }}>
                        <div className='add-contracts-top flex w-full' style={{ alignItems: 'center', marginLeft: '-8px' }}>
                            <input
                                disabled={screenDisable}
                                accept="application/pdf"
                                id="contained-button-file"
                                type="file"
                                style={{ display: 'none' }}
                                onClick={e => (e.target.value = null)}
                                onChange={uploadPDFFile}
                            />
                            <label htmlFor="contained-button-file">
                                <Button disabled={screenDisable} variant="contained" component="span" style={{
                                    height: '100%',
                                    padding: '6px 16px',
                                    backgroundColor: screenDisable ? 'rgb(163 163 163)' : '#D05E5A',
                                    marginLeft: '0.5rem',
                                    color: 'white',
                                    fontSize: '0.75rem'
                                }}>
                                    {t("contract.attach_file")}
                                </Button>
                            </label>
                            {pdfFileName !== '' ?
                                <div component="span" style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '24px',
                                    borderRadius: '16px',
                                    backgroundColor: '#e0e0e0',
                                    marginLeft: '1rem',
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    fontSize: '1rem'
                                }}>
                                    <span style={{ paddingLeft: '8px', paddingRight: '8px' }}>{pdfFileName}</span>
                                    <LightTooltip title={t("contract.remove")} placement="top">
                                        <span>
                                            <button disabled={screenDisable} onClick={removePDFFile}>
                                                <HighlightOffTwoToneIcon
                                                    style={{
                                                        fontSize: '1.75rem',
                                                        color: '#454f6059',
                                                        paddingRight: '8px'
                                                    }}>
                                                </HighlightOffTwoToneIcon>
                                            </button>
                                        </span>
                                    </LightTooltip>

                                    {pdfS3 || previewPDFURL ?
                                        <LightTooltip title={t("contract.view")} placement="top">
                                            <button onClick={() => { setIsPDFPreview(true) }}>
                                                <VisibilityIcon
                                                    style={{
                                                        fontSize: '1.75rem',
                                                        color: '#454f6059',
                                                        paddingRight: '7px'
                                                    }}>
                                                </VisibilityIcon >
                                            </button>
                                        </LightTooltip> : <CircularProgress
                                            style={{ marginRight: '7px' }}
                                            size={16}
                                        />
                                    }

                                </div> : <span style={{
                                    fontSize: '13px',
                                    color: '#454f6059',
                                    paddingLeft: '15px'
                                }}>{t("contract.no_file_attach")}</span>}
                        </div>
                    </div>

                    <div className='grid grid-cols-3'>
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)', marginTop: 'auto', width: '60%'
                            }}>
                                {t("contract.buyer")}
                            </InputLabel>
                            <OutlinedInput
                                classes={outlinedInputClasses}
                                inputProps={{
                                    autoComplete: "off"
                                }}
                                disabled
                                style={{ marginTop: 'auto', width: '60%' }}
                                defaultValue={buyerName}
                                startAdornment={
                                    <InputAdornment position="start" style={{ marginLeft: '-13px' }}>
                                        {contract && contract.BuyerLogo ? <img
                                            style={{
                                                height: '38px',
                                                width: '38px',
                                                maxWidth: 'none',
                                                borderTopLeftRadius: '3px',
                                                borderBottomLeftRadius: '3px',
                                            }}
                                            src={contract.BuyerLogo} alt='logo' />
                                            : ''}
                                    </InputAdornment>}
                            />
                        </div>
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)', marginTop: 'auto', width: '60%' }}>
                                {t("contract.seller")}
                            </InputLabel>
                            {
                                listFilterSeller &&
                                <Autocomplete
                                    disabled={screenDisable}
                                    onChange={handleAutoCompleteChange}
                                    value={listFilterSeller.find(seller => seller.ikastUID === contract.Seller)}
                                    options={listFilterSeller}
                                    getOptionLabel={(e) => e.Name}
                                    style={{ marginTop: 'auto', width: '60%' }}
                                    size='small'
                                    disableClearable
                                    className='mt-2'
                                    classes={{
                                        root: noBorderClasses.root
                                    }}
                                    renderOption={option => {
                                        return (
                                            <Fragment>
                                                {option.orgLogo ? <img
                                                    style={{
                                                        marginLeft: '-5px',
                                                        height: '38px',
                                                        width: '38px',
                                                        maxWidth: 'none',
                                                        borderTopLeftRadius: '3px',
                                                        borderBottomLeftRadius: '3px',
                                                    }}
                                                    src={option.orgLogo} alt='logo' />
                                                    : ''}
                                                <p className='ml-2'>{option.Name}</p>
                                            </Fragment>
                                        )
                                    }}
                                    renderInput={(params) => <TextField {...params} variant="outlined" InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {contract.Seller && (listFilterSeller.find(seller => seller.ikastUID === contract.Seller)).orgLogo ? <img
                                                    style={{
                                                        height: '38px',
                                                        width: '38px',
                                                        maxWidth: 'none',
                                                        borderTopLeftRadius: '3px',
                                                        borderBottomLeftRadius: '3px',
                                                    }}
                                                    src={(listFilterSeller.find(seller => seller.ikastUID === contract.Seller)).orgLogo} alt='logo' />
                                                    : ''}
                                            </InputAdornment>
                                        )
                                    }} />}
                                />
                            }
                        </div>

                        <div className='items-center mt-4 flex'>
                            <div>
                                <Switch
                                    disabled={screenDisable}
                                    onChange={handleChangeExclusive}
                                    checked={exclusive}
                                    className={classes.root}
                                    name="exclusive"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            </div>

                            <div>
                                {t("contract.deal_exclusive")}
                            </div>
                        </div>
                    </div>
                    <div className='my-4 pb-8'>
                        <div className='profile-title pb-2 pt-2' style={{ marginTop: '10px', borderBottom: '1px solid #D05E5A' }}>
                            <b>{t("contract.content_package")}</b>
                        </div>
                        <TransferList
                            sellerName={sellerName}
                            disabled={screenDisable}
                            listContent={contentSeller}
                            pickedContent={contract.Content}
                            licensedContent={handleLicensedContentChosen} />
                    </div>

                    <Dialog
                        fullScreen={true}
                        open={isPDFPreview}
                        onClose={() => { setIsPDFPreview(false) }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle>
                            {<button onClick={() => { setIsPDFPreview(false) }} style={{ display: 'flex', alignItems: 'center', color: '#454f6059' }}>
                                <ArrowBackIosIcon style={{ color: '#454f6059' }} /><span>{t("contract.back")}</span></button>}
                        </DialogTitle>
                        <DialogContent>
                            {/*{pdfS3 ? <object width='100%' height='100%' data={pdfS3} /> : ''}*/}
                            {pdfS3 ? <embed width='100%' height='100%' src={pdfS3} /> : ''}
                            {previewPDFURL ? <embed src={previewPDFURL} width='100%' height='100%' /> : ''}
                        </DialogContent>
                    </Dialog>
                </div>
            </TabPanel>
            <TabPanel valueTab={valueTab} index={1}>
                <div className='bg-white px-8 py-8'>
                    <div className='add-contracts-licensing mt-2'>
                        <div className='grid grid-cols-3'>
                            <div className='items-center mt-4'>
                                <InputLabel
                                    shrink
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.54)',
                                        marginBottom: '0.5rem',
                                        width: '60%'
                                    }}
                                >
                                    {t("contract.monetized_as")}
                                </InputLabel>

                                <TextField
                                    id="select"
                                    disabled={screenDisable}
                                    value={BUSSINESS_MODELS.find(businessModel => businessModel.id === contract.businessModel).id}
                                    style={{
                                        marginTop: 'auto',
                                        width: '60%',
                                    }}
                                    className={noBorderClasses.root}
                                    onChange={handleChangeMonetizeChange}
                                    variant='outlined'
                                    select>
                                    {
                                        BUSSINESS_MODELS && BUSSINESS_MODELS.map((value, key) => {
                                            return (
                                                <MenuItem key={key} value={value.id}>{value.value}</MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </div>
                            {
                                monetizedValue && monetizedValue.includes(1) &&
                                <div className='items-center mt-4'>
                                    <InputLabel shrink style={{
                                        color: 'rgba(0, 0, 0, 0.54)',
                                        width: '60%',
                                    }}>
                                        {t("contract.flat")}
                                    </InputLabel>
                                    <NumericFormat
                                        disabled={screenDisable}
                                        onChange={handleInputChange}
                                        value={Number.parseFloat(contract.flatRate)}
                                        className='global-id text-right'
                                        style={{
                                            width: '60%'
                                        }}
                                        // decimalScale={3}
                                        name='flatRate'
                                        thousandSeparator={t('common.thousand_separator')}
                                        decimalSeparator={t('common.decimal_separator')}
                                        allowNegative={false}
                                    />
                                    <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>
                                        {(user && user.get('org')) && CURRENCY.find(c => c.id === user.getIn(['org', 'mainCurrency'])).value}</span>
                                </div>
                            }
                            {
                                monetizedValue && monetizedValue.includes(15) &&
                                <div className='items-center mt-4'>
                                    <InputLabel shrink style={{
                                        color: 'rgba(0, 0, 0, 0.54)'
                                    }}>
                                    </InputLabel>
                                </div>
                            }
                            {
                                monetizedValue && monetizedValue.includes(2) &&
                                <div className='items-center mt-4'>
                                    <InputLabel shrink style={{
                                        color: 'rgba(0, 0, 0, 0.54)',
                                        width: '60%'
                                    }}>
                                        {t("contract.minimum_guaranteed")}
                                    </InputLabel>
                                    {
                                        <NumericFormat
                                            disabled={screenDisable}
                                            onChange={handleInputChange}
                                            value={Number.parseFloat(contract.MG)}
                                            className='global-id text-right'
                                            style={{
                                                width: '60%'
                                            }}
                                            decimalScale={3}
                                            name='MG'
                                            thousandSeparator={t('common.thousand_separator')}
                                            decimalSeparator={t('common.decimal_separator')}
                                            allowNegative={false}
                                        />
                                    }
                                    <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>
                                        {(user && user.get('org')) && CURRENCY.find(c => c.id === user.getIn(['org', 'mainCurrency'])).value}</span>
                                </div>
                            }
                            {
                                monetizedValue && monetizedValue.includes(9) &&
                                <div className='items-center mt-4'>
                                    <InputLabel shrink style={{
                                        color: 'rgba(0, 0, 0, 0.54)'
                                    }}>
                                        {t("contract.view_evaluated")}
                                    </InputLabel>
                                    <TextField
                                        id="selectEvaluatedOption"
                                        style={{
                                            marginTop: '0.5rem',
                                            width: '60%'
                                        }}
                                        className={classes.root}
                                        disabled={screenDisable}
                                        variant='outlined'
                                        defaultValue={EVALUATED_OPTIONS.find(b => b.default).id}
                                        select>
                                        {
                                            EVALUATED_OPTIONS && EVALUATED_OPTIONS.map((value, key) => {
                                                return (
                                                    <MenuItem key={key} value={value.id}>{value.value}</MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </div>
                            }
                            {
                                monetizedValue && monetizedValue.includes(10) &&
                                <div className='items-center mt-4'>
                                    <InputLabel shrink style={{
                                        color: 'rgba(0, 0, 0, 0.54)'
                                    }}>
                                        {t("contract.where_viewer_session")}
                                    </InputLabel>
                                    <TextField
                                        id="durationMode"
                                        disabled={screenDisable}
                                        style={{
                                            marginTop: '0.5rem',
                                            width: '60%'
                                        }}
                                        value={contract.durationMode ? contract.durationMode : VIEWER_SESSION.find(b => b.default).id}
                                        className={classes.root}
                                        onChange={handleChangeViewerSession}
                                        variant='outlined'
                                        select>
                                        {
                                            VIEWER_SESSION && VIEWER_SESSION.map((value, key) => {
                                                return (
                                                    <MenuItem key={key} value={value.id}>{value.value}</MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </div>
                            }
                            {
                                monetizedValue && monetizedValue.includes(11) &&
                                <div className='items-center mt-4'>
                                    <InputLabel shrink style={{
                                        color: 'rgba(0, 0, 0, 0.54)'
                                    }}>
                                        {t("contract.condition")}
                                    </InputLabel>
                                    <NumericFormat
                                        className='global-id text-right'
                                        disabled={screenDisable}
                                        style={{
                                            width: '60%'
                                        }}
                                        onChange={handleChangeConditionValue}
                                        name='condition'
                                        value={contract.conditionValue ? Number.parseFloat(contract.conditionValue) : 0}
                                        decimalScale={4}
                                        id='condition'
                                        thousandSeparator={t('common.thousand_separator')}
                                        decimalSeparator={t('common.decimal_separator')}
                                        allowNegative={false}
                                    />
                                    {contract.durationMode ? <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>
                                        {VIEWER_SESSION.find(b => b.id === contract.durationMode).unit}</span> : <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>seconds</span>}

                                </div>
                            }

                            {
                                monetizedValue && monetizedValue.includes(12) &&
                                <div className='items-center mt-4'>
                                    <InputLabel shrink style={{
                                        color: 'rgba(0, 0, 0, 0.54)'
                                    }}>
                                        {t("contract.pricing")}
                                    </InputLabel>
                                    <TextField
                                        id="selectPricingOption"
                                        disabled={screenDisable}
                                        style={{
                                            marginTop: '0.5rem',
                                            width: '60%'
                                        }}
                                        className={classes.root}
                                        onChange={handlePricingChange}
                                        variant='outlined'
                                        value={contract.pricingOption ? contract.pricingOption : PRICING.find(b => b.default).id}
                                        select>
                                        {
                                            PRICING && PRICING.map((value, key) => {
                                                return (
                                                    <MenuItem key={key} value={value.id}>{value.value}</MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </div>
                            }
                            {
                                monetizedValue && monetizedValue.includes(13) &&
                                <div className='items-center mt-4'>
                                    <InputLabel shrink style={{
                                        color: 'rgba(0, 0, 0, 0.54)'
                                    }}>
                                        {t("contract.calculations_done")}
                                    </InputLabel>
                                    <TextField
                                        id="selectCalculationOption"
                                        disabled={screenDisable}
                                        style={{
                                            marginTop: '0.5rem',
                                            width: '60%'
                                        }}
                                        className={classes.root}
                                        onChange={changeCalculationDones}
                                        variant='outlined'
                                        value={contract.calculationsDone ? contract.calculationsDone : CALCULATION.find(b => b.default).id}
                                        select>
                                        {
                                            CALCULATION && CALCULATION.map((value, key) => {
                                                return (
                                                    <MenuItem key={key} value={value.id}>{value.value}</MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </div>
                            }
                            {
                                monetizedValue && monetizedValue.includes(14) &&
                                <div className='items-center mt-4'>
                                    <InputLabel shrink style={{
                                        color: 'rgba(0, 0, 0, 0.54)'
                                    }}>
                                        {t("contract.mg_applied")}
                                    </InputLabel>
                                    <TextField
                                        id="selectMGAppliedOption"
                                        disabled={screenDisable}
                                        style={{
                                            marginTop: '0.5rem',
                                            width: '60%'
                                        }}
                                        className={classes.root}
                                        onChange={handleChangeMGApplied}
                                        variant='outlined'
                                        value={contract.mgApplied ? contract.mgApplied : MG_APPLIED.find(b => b.default).id}
                                        select>
                                        {
                                            MG_APPLIED && MG_APPLIED.map((value, key) => {
                                                return (
                                                    <MenuItem key={key} value={value.id}>{value.value}</MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </div>
                            }

                            {
                                monetizedValue && monetizedValue.includes(3) && contract.pricingOption === 1 &&
                                <div className='items-center mt-4'>
                                    <InputLabel shrink style={{
                                        color: 'rgba(0, 0, 0, 0.54)',
                                        width: '60%'
                                    }}>
                                        {t("contract.each_view_billed")}
                                    </InputLabel>
                                    {
                                        <NumericFormat
                                            disabled={screenDisable}
                                            onChange={handleInputChange}
                                            value={Number.parseFloat(contract.RevPerViewerCount)}
                                            className='global-id text-right'
                                            style={{
                                                width: '60%'
                                            }}
                                            name='RevPerViewerCount'
                                            decimalScale={4}
                                            thousandSeparator={t('common.thousand_separator')}
                                            decimalSeparator={t('common.decimal_separator')}
                                            allowNegative={false}
                                        />
                                    }

                                    <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>
                                        {(user && user.get('org')) && CURRENCY.find(c => c.id === user.getIn(['org', 'mainCurrency'])).value}</span>
                                </div>
                            }

                            {
                                monetizedValue && monetizedValue.includes(4) &&
                                <div className='items-center mt-4'>
                                    <InputLabel shrink style={{
                                        color: 'rgba(0, 0, 0, 0.54)',
                                        width: '60%'
                                    }}>
                                        {t("contract.minutes_watched")}
                                    </InputLabel>
                                    {
                                        <NumericFormat
                                            disabled={screenDisable}
                                            onChange={handleInputChange}
                                            value={Number.parseFloat(contract.durationUniqueView)}
                                            className='global-id text-right'
                                            style={{
                                                width: '60%'
                                            }}
                                            name='durationUniqueView'
                                            decimalScale={4}
                                            thousandSeparator={t('common.thousand_separator')}
                                            decimalSeparator={t('common.decimal_separator')}
                                            allowNegative={false}
                                        />
                                    }
                                    <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>
                                        {(user && user.get('org')) && CURRENCY.find(c => c.id === user.getIn(['org', 'mainCurrency'])).value}</span>
                                </div>
                            }
                            {
                                monetizedValue && monetizedValue.includes(5) && contract.pricingOption === 1 &&
                                <div className='items-center mt-4'>
                                    <InputLabel shrink style={{
                                        color: 'rgba(0, 0, 0, 0.54)',
                                        width: '60%'
                                    }}>
                                        {t("contract.revenue_transaction")}
                                    </InputLabel>
                                    {
                                        <input
                                            disabled={screenDisable}
                                            onChange={handleInputChangeText}
                                            value={contract.RevPerTransaction}
                                            className='global-id text-right'
                                            style={{
                                                width: '60%'
                                            }}
                                            min='0'
                                            step='1'
                                            name='RevPerTransaction'
                                            type="number"
                                        />

                                    }
                                    <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>
                                        {(user && user.get('org')) && CURRENCY.find(c => c.id === user.getIn(['org', 'mainCurrency'])).value}</span>
                                </div>
                            }
                            {
                                monetizedValue && monetizedValue.includes(6) &&
                                <div className='items-center mt-4'>
                                    <InputLabel shrink style={{
                                        color: 'rgba(0, 0, 0, 0.54)',
                                        width: '60%'
                                    }}>
                                        {t("contract.per_nb_of_ads_Served")}
                                    </InputLabel>
                                    {
                                        <input
                                            disabled={screenDisable}
                                            onChange={handleInputChange}
                                            value={contract.RevPerAdsServed}
                                            className='global-id text-right'
                                            min='0'
                                            step='1'
                                            style={{
                                                width: '60%'
                                            }}
                                            name='RevPerAdsServed'
                                            type="number"
                                        />
                                    }
                                </div>
                            }
                            {
                                monetizedValue && monetizedValue.includes(7) &&
                                <div className='items-center mt-4'>
                                    <InputLabel shrink style={{
                                        color: 'rgba(0, 0, 0, 0.54)',
                                        width: '60%'
                                    }}>
                                        {t("contract.revenue_subs")}
                                    </InputLabel>
                                    {
                                        <NumericFormat
                                            disabled={screenDisable}
                                            onChange={handleInputChange}
                                            value={Number.parseFloat(contract.RevPerSubs)}
                                            className='global-id text-right'
                                            style={{
                                                width: '60%'
                                            }}
                                            name='RevPerSubs'
                                            decimalScale={3}
                                            thousandSeparator={t('common.thousand_separator')}
                                            decimalSeparator={t('common.decimal_separator')}
                                            allowNegative={false}
                                            isAllowed={
                                                (values) => {
                                                    const { formattedValue, floatValue } = values;
                                                    return formattedValue === "" || floatValue <= 100;
                                                }
                                            }
                                        />
                                    }
                                    <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>%</span>
                                </div>
                            }
                            {
                                monetizedValue && monetizedValue.includes(8) &&
                                <div className='items-center mt-4'>
                                    <InputLabel shrink style={{
                                        color: 'rgba(0, 0, 0, 0.54)',
                                        width: '60%'
                                    }}>
                                        {t("contract.revenue_ads")}
                                    </InputLabel>
                                    {
                                        <NumericFormat
                                            disabled={screenDisable}
                                            onChange={handleInputChange}
                                            // value={Number(contract.revPerAds)}
                                            value='0'
                                            className='global-id text-right'
                                            style={{
                                                width: '60%'
                                            }}
                                            name='revPerAds'
                                            decimalScale={3}
                                            thousandSeparator={t('common.thousand_separator')}
                                            decimalSeparator={t('common.decimal_separator')}
                                            allowNegative={false}
                                            isAllowed={
                                                (values) => {
                                                    const { formattedValue, floatValue } = values;
                                                    return formattedValue === "" || floatValue <= 100;
                                                }
                                            }
                                        />
                                    }
                                    <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>%</span>
                                </div>
                            }
                        </div>
                    </div>
                    {contract && contract.pricingOption === 2 &&
                        <PricingLadder
                            disabled={screenDisable}
                            initializePricingLadder={initializePricingLadder}
                            pricingLadder={handlePricingLadder}
                            mgApplied={contract.mgApplied}
                            currency={(user && user.get('org')) && CURRENCY.find(c => c.id === user.getIn(['org', 'mainCurrency'])).value}
                        />
                    }

                    {(contract.businessModel === 4 || contract.businessModel === 5 || contract.businessModel === 6) &&
                        <FormControlLabel style={{ marginTop: 10 }}
                            control={<Switch checked={isMarketShare} disabled={screenDisable} onClick={handleChangeMarketShare} name="gilad" />}
                            label={t("contract.add_market_share")}
                        />
                    }

                    <div className='grid grid-cols-3'>
                        {
                            isMarketShare &&
                            <div className='items-center mt-4'>
                                <InputLabel shrink style={{
                                    color: 'rgba(0, 0, 0, 0.54)'
                                }}>
                                    {t("contract.market_share_bonus_value")}
                                </InputLabel>
                                <NumericFormat
                                    className='global-id text-right'
                                    style={{
                                        width: '60%'
                                    }}
                                    disabled={screenDisable}
                                    value={contract.marketShareBonusValue}
                                    onChange={handleInputChange}
                                    name='marketShareBonusValue'
                                    decimalScale={3}
                                    thousandSeparator={t('common.thousand_separator')}
                                    decimalSeparator={t('common.decimal_separator')}
                                    allowNegative={false}
                                    isAllowed={(values) => {
                                        const { formattedValue, floatValue } = values;
                                        return formattedValue === "" || floatValue <= 100;
                                    }
                                    }
                                />
                                <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>%</span>
                            </div>
                        }
                    </div>

                    <div className='profile-title pb-2 pt-2' style={{ marginTop: '10px', borderBottom: '1px solid #D05E5A' }}>
                        <b>{t("contract.restrictions")}</b>
                    </div>
                    <div style={{ paddingBottom: '30px' }}>
                        <div>
                            <div className='mt-4'>
                                <div className="grid grid-cols-2 gap-20">
                                    <div style={{ marginRight: '43px' }}>
                                        <ButtonGroup size="small" color="primary" aria-label="outlined primary button group" style={{ marginBottom: '20px', border: 'none' }}>
                                            <Button disabled={screenDisable} onClick={() => handleClickRestrictButton(true)} style={isInclude ? { backgroundColor: '#d05e5a', color: '#fff', border: 'none' } : { color: '#d05e5a' }}>{t("contract.include")}</Button>
                                            <Button disabled={screenDisable} onClick={() => handleClickRestrictButton(false)} style={!isInclude ? { backgroundColor: '#d05e5a', color: '#fff', border: 'none' } : { color: '#d05e5a' }}>{t("contract.exclude")}</Button>
                                        </ButtonGroup>
                                        {contract.countryInfringeLog ? <div>
                                            <div className="grid grid-cols-12">
                                                <div className="col-span-6" style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                    </InputLabel>
                                                </div>
                                                <div className="col-span-6" style={{
                                                    height: '30px',
                                                    backgroundColor: 'rgb(208, 94, 90)',
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                    color: 'white'
                                                }}>
                                                    <InputLabel style={{ color: 'white', marginRight: '10px' }}>
                                                        {contract.countryInfringeLog.logs > 1 ? (contract.countryInfringeLog.logs + " infringements") : (contract.countryInfringeLog.logs + " infringement")}
                                                    </InputLabel>
                                                </div>
                                            </div>
                                            <div className="col-span-12" style={{ border: 'rgb(208, 94, 90) solid 2px' }}>
                                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)', marginTop: '8px', marginLeft: '4px' }}>
                                                    {t("contract.territories")}
                                                </InputLabel>
                                                {
                                                    countries &&
                                                    <Autocomplete
                                                        disabled={screenDisable}
                                                        onChange={handleChangeTerritories}
                                                        value={countries.filter(c => contract.countries.includes(c.ikastUID))}
                                                        options={countries}
                                                        getOptionLabel={(e) => e.name_translation}
                                                        multiple
                                                        style={{
                                                            width: '90%',
                                                            marginLeft: '4px'
                                                        }}
                                                        size='small'
                                                        disableClearable
                                                        className='mt-2'
                                                        classes={{
                                                            root: noBorderClasses.root
                                                        }}
                                                        renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t("contract.add_placeholder")} />}
                                                    />
                                                }
                                                <InputLabel shrink style={{ color: 'transparent', marginBottom: '10px' }}>
                                                    &nbsp
                                                </InputLabel>
                                            </div>
                                            <div className="grid grid-cols-12">
                                                <div className="col-span-6" style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                    </InputLabel>
                                                </div>
                                                <div className="col-span-6" style={{
                                                    height: '30px',
                                                    backgroundColor: 'rgb(208, 94, 90)',
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                    color: 'white'
                                                }}>
                                                    <InputLabel style={{ color: 'white', marginRight: '10px' }}>
                                                        <NumericFormat
                                                            value={Number.parseFloat(contract.countryInfringeLog.royalty)}
                                                            displayType={'text'}
                                                            thousandSeparator={t('common.thousand_separator')}
                                                            decimalSeparator={t('common.decimal_separator')}
                                                            allowNegative={false}
                                                        /> {CURRENCY_UNIT}
                                                    </InputLabel>
                                                </div>
                                            </div>
                                        </div> : <div className="col-span-12">
                                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)', marginTop: '8px', marginLeft: '4px' }}>
                                                {t("contract.territories")}
                                            </InputLabel>
                                            {
                                                countries &&
                                                <Autocomplete
                                                    disabled={screenDisable}
                                                    onChange={handleChangeTerritories}
                                                    value={countries.filter(c => contract.countries.includes(c.ikastUID))}
                                                    options={countries}
                                                    getOptionLabel={(e) => e.name_translation}
                                                    multiple
                                                    style={{
                                                        width: '90%',
                                                        marginLeft: '4px'
                                                    }}
                                                    size='small'
                                                    disableClearable
                                                    className='mt-2'
                                                    classes={{
                                                        root: noBorderClasses.root
                                                    }}
                                                    renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t("contract.add_placeholder")} />}
                                                />
                                            }
                                            <InputLabel shrink style={{ color: 'transparent', marginBottom: '10px' }}>
                                                &nbsp
                                            </InputLabel>
                                        </div>}
                                    </div>
                                    {contract.dateInfringeLog ?
                                        <div>
                                            <div className='grid grid-cols-12' style={{ marginTop: '51px' }}>
                                                <div className="col-span-6" style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                    </InputLabel>
                                                </div>
                                                <div className="col-span-6" style={{
                                                    height: '30px',
                                                    backgroundColor: 'rgb(208, 94, 90)',
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                    color: 'white'
                                                }}>
                                                    <InputLabel style={{ color: 'white', marginRight: '10px' }}>
                                                        {contract.dateInfringeLog.logs > 1 ? (contract.dateInfringeLog.logs + " infringements") : (contract.dateInfringeLog.logs + " infringement")}
                                                    </InputLabel>
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-12' style={{
                                                paddingTop: '10px',
                                                paddingLeft: '4px',
                                                paddingBottom: '30px',
                                                border: 'rgb(208, 94, 90) solid 2px'
                                            }}>
                                                <div className="col-span-5">
                                                    <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                        {t("contract.start_date")}
                                                    </InputLabel>
                                                    <TextField
                                                        className={noBorderClasses.root}
                                                        disabled={screenDisable}
                                                        onChange={handleChangeDatetime}
                                                        value={contract.startDate}
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '0.5rem'
                                                        }}
                                                        name='startDate'
                                                        id="datetime-local"
                                                        type="datetime-local"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        inputProps={{
                                                            style: {
                                                                padding: '10px'
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-1" />
                                                <div className="col-span-5">
                                                    <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                        {t("contract.end_date")}
                                                    </InputLabel>
                                                    <TextField
                                                        className={noBorderClasses.root}
                                                        disabled={screenDisable}
                                                        value={contract.endDate}
                                                        onChange={handleChangeDatetime}
                                                        style={{
                                                            marginTop: '0.5rem',
                                                            width: '100%'
                                                        }}
                                                        name='endDate'
                                                        id="datetime-local"
                                                        type="datetime-local"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        inputProps={{
                                                            style: {
                                                                padding: '10px'
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-1" />
                                            </div>
                                            <div className="grid grid-cols-12">
                                                <div className="col-span-6" style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                    </InputLabel>
                                                </div>
                                                <div className="col-span-6" style={{
                                                    height: '30px',
                                                    backgroundColor: 'rgb(208, 94, 90)',
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                    color: 'white'
                                                }}>
                                                    <InputLabel style={{ color: 'white', marginRight: '10px' }}>
                                                        <NumericFormat
                                                            value={Number.parseFloat(contract.dateInfringeLog.royalty)}
                                                            displayType={'text'}
                                                            thousandSeparator={t('common.thousand_separator')}
                                                            decimalSeparator={t('common.decimal_separator')}
                                                            allowNegative={false}
                                                        /> {CURRENCY_UNIT}
                                                    </InputLabel>
                                                </div>
                                            </div>
                                        </div> : <div>
                                            <div className='grid grid-cols-2 gap-2' style={{ marginTop: '56px' }}>
                                                <div>
                                                    <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                        {t("contract.start_date")}
                                                    </InputLabel>
                                                    <TextField
                                                        className={noBorderClasses.root}
                                                        disabled={screenDisable}
                                                        onChange={handleChangeDatetime}
                                                        value={contract.startDate}
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '0.5rem'
                                                        }}
                                                        name='startDate'
                                                        id="datetime-local"
                                                        type="datetime-local"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        inputProps={{
                                                            style: {
                                                                padding: '10px',
                                                                border: `${errorStartDate ? '2px solid red' : 'none'}`
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                        {t("contract.end_date")}
                                                    </InputLabel>
                                                    <TextField
                                                        className={noBorderClasses.root}
                                                        disabled={screenDisable}
                                                        value={contract.endDate}
                                                        onChange={handleChangeDatetime}
                                                        style={{
                                                            marginTop: '0.5rem',
                                                            width: '100%'
                                                        }}
                                                        name='endDate'
                                                        id="datetime-local"
                                                        type="datetime-local"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        inputProps={{
                                                            style: {
                                                                padding: '10px',
                                                                border: `${errorEndDate ? '2px solid red' : 'none'}`
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel valueTab={valueTab} index={2}>
                <div className='bg-white px-8 py-8'>
                    <HistoryTab contract={contract} />
                </div>
            </TabPanel>
            <TabPanel valueTab={valueTab} index={3}>
                <div className='bg-white px-8 py-8'>
                    <div className='w-full'>
                        <textarea
                            disabled={screenDisable}
                            className='w-full'
                            name='comments'
                            maxLength='3000'
                            rows='10'
                            value={contract.comments ? contract.comments : ""}
                            style={{
                                height: '300px',
                                borderRadius: '4px',
                                border: '1px solid #ced4da',
                                backgroundColor: 'white',
                                paddingLeft: '10px'
                            }}
                            onChange={handleUpdateComments}>
                        </textarea>
                    </div>
                </div>
            </TabPanel>
            {renderPopupRegisterConfirm()}
            {renderPopupReEditConfirm()}
            {renderPopupTerminateConfirm()}
            {renderPopupRejectConfirm()}
            <Dialog
                open={importLoadingScreen}
                onClose={() => { setImportLoadingScreen(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogTitle >{t("contract.register_import")}</DialogTitle>
                <DialogContent>
                    <LinearProgress />
                </DialogContent>
            </Dialog>
        </Layout>
    ) : <Layout><LoadingLayout messageKey={messageKey} /></Layout>
};

export default withSnackbar(EditContract)
