const LOGIN = 'auth/LOGIN';
const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'auth/LOGIN_FAIL';
const STORE_AUTH = 'auth/STORE_AUTH';
const GET_PROFILE_SUCCESS = 'auth/GET_PROFILE_SUCCESS';
const GET_PROFILE = 'auth/GET_PROFILE';
const GET_ORGANIZATION = 'auth/GET_ORGANIZATION';
const GET_ORGANIZATION_SUCCESS = 'auth/GET_ORGANIZATION_SUCCESS';
const UPDATE_PROFILE = 'auth/UPDATE_PROFILE';
const UPDATE_PROFILE_SUCCESS = 'auth/UPDATE_PROFILE_SUCCESS';
const UPDATE_PROFILE_ERROR = 'auth/UPDATE_PROFILE_ERROR';
const SESSION_EXPIRED = 'auth/SESSION_EXPIRED';

export default {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    STORE_AUTH,
    GET_PROFILE_SUCCESS,
    GET_PROFILE,
    GET_ORGANIZATION,
    GET_ORGANIZATION_SUCCESS,
    UPDATE_PROFILE,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_ERROR,
    SESSION_EXPIRED,
};
