import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

import types from './types';
// import enums from '~/utils/enums';


//= ============== REDUCER ===============//
const initState = fromJS({
    loadStatus: 'Loading',
    user: null,
    isSessionExpired: null,
});

//= ============== SELECTOR ===============//
const loadStatus = state => state.getIn(['auth', 'loadStatus']);
const user = state => state.getIn(['auth', 'user']);
const isSessionExpired = state => state.getIn(['auth', 'isSessionExpired']);

export const selectors = {
    loadStatus,
    user,
    isSessionExpired
};

const loading = state => state.set('loadStatus', 'Loading');
const loadSuccess = (state) => state.set('loadStatus', 'Success');
const loadFail = state => state.set('loadStatus', 'Error');
// const storeData = (state, action) => state.set('user', fromJS(action.payload));
const storeProfile = (state, action) => state.set('user', fromJS(action.payload));
const storeOrganization = (state, action) => state.mergeIn(['user', 'org'], fromJS(action.payload));
const updateProfileUser = (state, action) => state.set('user', fromJS(action.payload));
const sessionExpired =(state, action) => state.set('isSessionExpired', fromJS(action.payload));

const reducer = handleActions({
    [types.LOGIN]: loading,
    [types.LOGIN_SUCCESS]: loadSuccess,
    [types.LOGIN_FAIL]: loadFail,
    // [types.STORE_AUTH]: storeData,
    [types.GET_PROFILE_SUCCESS]: storeProfile,
    [types.GET_ORGANIZATION_SUCCESS]: storeOrganization,
    [types.UPDATE_PROFILE_SUCCESS]: updateProfileUser,
    [types.SESSION_EXPIRED]: sessionExpired,
}, initState);

export default reducer;
