import PropTypes from 'prop-types';
import React from 'react';
import './index.css';

export default class WrapperMap extends React.Component {

    static propTypes = {
        additionalClasses: PropTypes.string,
        children: PropTypes.element.isRequired,
        mapStyle: PropTypes.object
    };

    render() {
        return (
            <div className="wrapper-content-map">
                <div
                    className={`wrapper-map ${this.props.additionalClasses}`}
                    style={this.props.mapStyle}
                >
                    {React.Children.only(this.props.children)}
                </div>
            </div>
        );
    }

}
