import React, { Fragment, useEffect, useState, useContext, useMemo } from 'react';
import Layout from '../../../layouts/Default';
import Button from "@material-ui/core/Button";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from "@material-ui/core/TextField/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import './index.css';
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import { useSnackbar, withSnackbar } from 'notistack';
import { useParams } from 'react-router';
import axios from '../../../../config/axios';
import { GET_CONTENT_STATUS, GET_ENTITIES, EXTRA_FORMAT } from '../../../../constants';
import { Link } from 'react-router-dom';
import InputAdornment from "@material-ui/core/InputAdornment";
import MovieCreationRoundedIcon from '@material-ui/icons/MovieCreationRounded';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import VideocamIcon from '@material-ui/icons/Videocam';
import TheatersIcon from '@material-ui/icons/Theaters';
import { useHistory } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Item from "../../../components/Contract/Item";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { DropzoneDialog } from "material-ui-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import LoadingContext from "../../../../context/loading/LoadingContext";
import LoadingLayout from "../../../components/Common/LoadingLayout";
import { useTranslation } from 'react-i18next';
const useNoBorderStyles = makeStyles({
    root: {
        padding: '0px',
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        }
    }
});

const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        "& $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&:hover $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&$focused $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-input": {
            padding: '18.5px 0px',
        }
    },
    focused: {},
    notchedOutline: {}
}));

const TabPanel = (props) => {
    const { children, valueTab, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={valueTab !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {valueTab === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

const EditContent = () => {
    const { t, i18n } = useTranslation();
    const ENTITIES = useMemo(() => GET_ENTITIES(t), [t]);
    const CONTENT_STATUS = useMemo(() => GET_CONTENT_STATUS(t), [t]);
    const [messageKey, setMessageKey] = useState('');
    const classes = useNoBorderStyles();
    const outlinedInputClasses = useOutlinedInputStyles();
    const [content, setContent] = useState();
    const [durationClone, setDurationClone] = useState('00:00:00');
    const [organizations, setOrganizations] = useState(null);
    const [statusContent, setStatusContent] = useState(null);
    const [statusDisable, setStatusDisable] = useState(false);
    const [isanCode, setISANCode] = useState('');
    const [errValidateIsanCode, setErrIsanCode] = useState('');
    const [contracts, setContracts] = useState({});
    const [contentId, setContentId] = useState({});
    const [user, setUser] = useState(null);
    const [page, setPage] = useState(1);
    const [isLogoHovering, setIsLogoHovering] = React.useState(false);
    const [openDropzoneIllustration, setOpenDropzoneIllustration] = React.useState(false);
    const [isImageNotFound, setIsImageNotFound] = React.useState(false);
    const [valueTab, setValueTab] = React.useState(0);
    const [listPermission, setListPermission] = useState([]);
    const { showLoading, hideLoading } = useContext(LoadingContext);
    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    };

    const { id } = useParams();

    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory();

    useEffect(() => {
        (async function getListOrg() {
            // showLoading();
            const responseOrg = await axios.get('/org/get-list-orgs', {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });
            hideLoading();
            if (responseOrg) {
                setOrganizations(responseOrg.data);
            }
        })();
    }, []);

    useEffect(() => {
        if (localStorage.getItem('id') && localStorage.getItem('token')) {
            (async function getUser() {
                showLoading();
                try {
                    const userResponse = await axios.get(`/user/${localStorage.getItem('id')}`, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });

                    setUser(userResponse.data);
                    setListPermission(userResponse.data.permissions);
                    hideLoading();
                } catch (e) {
                    hideLoading();
                    console.log(e);
                }
            })();
        }
    }, []);

    useEffect(() => {
        (async function getContent() {
            try {

                showLoading();
                const response = await axios.get(`/content/get-one/${id}`, {
                    headers: {
                        common: {
                            'x-access-token': localStorage.getItem('token')
                        },
                    }
                });
    
                if (response.status === 200) {
                    const contentData = response.data;
                    let contentDuration = new Date(response.data.duration * 1000).toISOString().substr(11, 8);
                    setContent({
                        ...contentData,
                        duration: contentDuration
                    });
                    setISANCode(response.data.isanCode ? response.data.isanCode.replace('ISAN ', '') : '');
    
                    setDurationClone(contentDuration);
                    setStatusContent(contentData.status)
                    if (!response.data) {
                        return;
                    }
    
                    const orgID = response.data.belongsTo;
                    const contentID = response.data.ikastUID;
    
                    setContentId(response.data.ikastUID);
    
                    if (!(orgID && contentID)) {
                        return;
                    }
                    const contentContractList = await axios.get(`/content/get-contracted-contents?orgUid=${orgID}&contentUid=${contentID}&pageNo=1`, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });
    
                    if (contentContractList.status !== 200) {
                        return;
                    }
    
                    if (contentContractList.data && contentContractList.data.contractList && contentContractList.data.contractList.length > 0) {
                        setContracts(contentContractList.data);
                        setStatusDisable(true);
                    }
                }
                hideLoading();
                setMessageKey("");
            } catch (e) {
                setMessageKey("common.content_not_available");
                hideLoading();
            }
        })();
    }, [id]);

    useEffect(() => {
        if (localStorage.getItem('id') && localStorage.getItem('token')) {
            (async function getUser() {
                showLoading();
                try {
                    const userResponse = await axios.get(`/user/${localStorage.getItem('id')}`, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });
                    if (userResponse)
                        setUser(userResponse.data)
                    hideLoading();
                } catch (e) {
                    hideLoading();
                    console.log(e);
                }
            })();
        }
    }, []);

    const handleChangeType = (e, value) => {
        setContent({
            ...content,
            format: value.id
            // format: e.target.value
        });
    };

    const handleChangeExtraFormat = (e, value) => {
        setContent({
            ...content,
            extraFormat: value.id
        });
    };

    const handleChangeUpdateOrg = (e, value) => {
        setContent({
            ...content,
            belongsTo: value.ikastUID,
            // belongsTo: e.target.value
        })
    };

    const handleDurationBlur = (e) => {
        handleChangeUpdateContent(e);
        // let e = document.getElementById('update-content-duration');

        if (!e.target.value.match(/^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/)) {
            e.target.value = durationClone;

            setContent({
                ...content,
                duration: e.target.value
            });
            alert(t("content.invalid_duration"));
        };
    };

    const handleChangeUpdateContent = (e) => {
        setContent({
            ...content,
            duration: e.target.value
        })
    };

    const handleChangeUpdateInput = (e) => {
        setContent({
            ...content,
            [e.target.name]: e.target.value
        })
    };

    const handleUpdateInputISANChange = (e) => {
        const { name, value } = e.target;

        if (value.trim().length === 0) {
            updateISANCodeValue('');
            return;
        }

        let tempValue = value.replace(/[^\w\s]/gi, '');
        if (tempValue.length <= 4) {
            updateISANCodeValue(tempValue);
        } else if (tempValue.length > 4 && tempValue.length <= 8) {
            let format = tempValue.slice(0, 4) + '-' + tempValue.slice(4);
            updateISANCodeValue(format);
        } else if (tempValue.length > 8 && tempValue.length <= 12) {
            let format = tempValue.slice(0, 4) + '-'
                + tempValue.slice(4, 8) + '-'
                + tempValue.slice(8);
            updateISANCodeValue(format);
        } else if (tempValue.length > 12 && tempValue.length <= 16) {
            let format = tempValue.slice(0, 4) + '-'
                + tempValue.slice(4, 8) + '-'
                + tempValue.slice(8, 12) + '-'
                + tempValue.slice(12);
            updateISANCodeValue(format);
        } else if (tempValue.length > 16 && tempValue.length <= 17) {
            let format = tempValue.slice(0, 4) + '-'
                + tempValue.slice(4, 8) + '-'
                + tempValue.slice(8, 12) + '-'
                + tempValue.slice(12, 16) + '-'
                + tempValue.slice(16);
            updateISANCodeValue(format);
        } else if (tempValue.length > 17 && tempValue.length <= 21) {
            let format = tempValue.slice(0, 4) + '-'
                + tempValue.slice(4, 8) + '-'
                + tempValue.slice(8, 12) + '-'
                + tempValue.slice(12, 16) + '-'
                + tempValue.slice(16, 17) + '-'
                + tempValue.slice(17);
            updateISANCodeValue(format);
        } else if (tempValue.length > 21 && tempValue.length <= 25) {
            let format = tempValue.slice(0, 4) + '-'
                + tempValue.slice(4, 8) + '-'
                + tempValue.slice(8, 12) + '-'
                + tempValue.slice(12, 16) + '-'
                + tempValue.slice(16, 17) + '-'
                + tempValue.slice(17, 21) + '-'
                + tempValue.slice(21);
            updateISANCodeValue(format);
        } else if (tempValue.length > 25) {
            let format = tempValue.slice(0, 4) + '-'
                + tempValue.slice(4, 8) + '-'
                + tempValue.slice(8, 12) + '-'
                + tempValue.slice(12, 16) + '-'
                + tempValue.slice(16, 17) + '-'
                + tempValue.slice(17, 21) + '-'
                + tempValue.slice(21, 25) + '-'
                + tempValue.slice(25, 26);
            updateISANCodeValue(format);
        }
    }

    const validateISANCode = (e) => {
        handleUpdateInputISANChange(e);
        const val = e.target.value;
        if (val.trim().length !== 0) {
            let regex = /^(ISAN\s[\d\w]{4,4}-[\d\w]{4,4}-[\d\w]{4,4}-[\d\w]{4,4}-[\d\w]{1,1}-[\d\w]{4,4}-[\d\w]{4,4}-[\d\w]{1,1})$/
            if (!regex.test('ISAN ' + val)) {
                setErrIsanCode(t("content.wrong_format"));
            } else {
                setErrIsanCode('');
            }
        } else {
            setErrIsanCode('');
        }
    };

    const updateISANCodeValue = (value) => {
        setISANCode(value);
        setContent({
            ...content,
            isanCode: value.length !== 0 ? 'ISAN ' + value : ''
        });
    }

    const handleUpdateContent = async () => {
        if (errValidateIsanCode !== '') {
            enqueueSnackbar(t("content.invalid_isan"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            return;
        }

        try {
            const contentData = {
                ...content,
                uuid: content.ikastUID
            }
            const contentUpdatedResponse = await axios.put('/content/update', contentData, {
                headers: {
                    common: {
                        'x-access-token': localStorage.getItem('token')
                    },
                }
            });

            if (contentUpdatedResponse.status === 200) {
                enqueueSnackbar(t("content.create_content_success"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });

                history.push('/contents');
            } else {
                enqueueSnackbar(t("notify.something_wrong"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }
        } catch (e) {
            enqueueSnackbar(t("notify.something_wrong"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
    };

    const renderIconForType = (type) => {
        switch (type) {
            case 1:
                return (
                    <MovieCreationRoundedIcon />
                );
            case 2:
                return (
                    <VideocamIcon />
                );
            case 3:
                return (
                    <OndemandVideoIcon />
                );
            case 4:
                return (
                    <ArtTrackIcon />
                );
            case 5:
                return (
                    <TheatersIcon />
                );
            default:

                return (
                    <MovieCreationRoundedIcon />
                );
        }
    };

    const pagingHandleChange = (e, value) => {
        setPage(value);
    };

    const onMouseEnterHandler = (e) => {
        setIsLogoHovering(true)
    };

    const onMouseLeaveHandler = (e) => {
        setIsLogoHovering(false)
    };

    const [filterListContentStatus, setFilterListContentStatus] = useState([]);

    useEffect(() => {
        setFilterListContentStatus(CONTENT_STATUS.filter(c => !c.disableUI))
    }, [statusContent]);

    const handleChangeStatus = async (e) => {
        try {
            if (content.status === 1) {
                // If status is available - check contract
                // Get contract list
                showLoading();
                const { status, data } = await axios.get(`/content/get-contracted-contents?orgUid=${content.belongsTo}&contentUid=${id}&pageNo=1`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

                if (status === 202 || (data && data.message)) {
                    setContent({
                        ...content,
                        status: e.target.value
                    });
                }
            }

            if (content.status === 2) {
                setContent({
                    ...content,
                    status: e.target.value
                });
            }
            hideLoading();
        } catch (err) {

        }
    };

    let isDisable = false;
    let disabledOrganization = true;
    if (content && content.status == 1) {
        isDisable = true;
    }

    const uploadIllustration = async (files) => {
        if (files.length === 0) {
            return;
        }
        const dataToUpload = {
            uuid: contentId,
            illustration: files[0].name,
            illustrationType: files[0].type
        }
        setOpenDropzoneIllustration(false);

        try {
            showLoading();
            const response = await axios.put(`/content/update-illustration`, dataToUpload
                , {
                    headers: {
                        common: {
                            'x-access-token': localStorage.getItem('token')
                        },
                    }
                });

            const { data, status } = response;

            if (status === 200) {
                const urlToUpload = data.updatedPictureUrl;
                const options = { headers: { 'Content-Type': files[0].type } };
                const uploadToS3 = await axios.put(urlToUpload, files[0], options);
                enqueueSnackbar(t("content.upload_illus_success"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });
            }
            hideLoading();
        } catch (error) {
            hideLoading();
            console.log(error);
        }
        window.location.reload();
    }

    const handleImageNotFound = () => {
        setIsImageNotFound(true);
    }

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (content && organizations) ? (
        <Layout title="Edit Content">
            <div className='flex justify-between content-center'>
                <div>
                {t("content.breadcrumb_update")}
                    <p style={{ fontSize: '24px' }}>
                    {t("content.update_content")}
                    </p>
                </div>
                <div className='flex' style={{
                    height: '30px'
                }}>
                    <Button style={{
                        padding: '6px 16px',
                        backgroundColor: '#D05E5A',
                        color: 'white',
                        fontSize: '0.75rem',
                    }}

                        onClick={handleUpdateContent}
                    >
                         {t("common.update")}
                    </Button>
                    <Link to={'/contents'}>
                        <Button style={{
                            height: '100%',
                            padding: '6px 16px',
                            backgroundColor: '#D05E5A',
                            marginLeft: '0.5rem',
                            color: 'white',
                            fontSize: '0.75rem'
                        }}>
                            {t("common.cancel")}
                        </Button>
                    </Link>
                </div>
            </div>
            <Tabs value={valueTab} onChange={handleChange} aria-label="basic tabs example">
                <Tab label={<b> {t("content.identification")}</b>} {...a11yProps(0)} />
                <Tab label={<b>{t("content.licensing")} : {(contracts && contracts.contractList) ? contracts.contractList.length : 0} {t("content.contracts")}</b>} {...a11yProps(1)} />
            </Tabs>
            <TabPanel valueTab={valueTab} index={0}>
                <div className='bg-white px-8 py-8'>
                    {/* <div className='profile-title pb-2 pt-2' style={{ marginTop: '-30px', borderBottom: '1px solid #D05E5A' }}>
                    <b>Identification</b>
                </div> */}
                    <div className='flex justify-between content-center' style={{ marginTop: '10px' }}>
                        <div className='content-add-type' style={{
                            width: '20%'
                        }}>
                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                            {t("content.type")}
                            </InputLabel>

                            <Autocomplete
                                classes={{
                                    root: classes.root
                                }}
                                id="content-add-type"
                                value={ENTITIES.find(e => e.id === content.format)}
                                disabled={isDisable}
                                options={ENTITIES}
                                size='small'
                                disableClearable
                                getOptionLabel={(e) => e.value_creation}
                                onChange={handleChangeType}
                                className='mt-2'
                                renderOption={option => {
                                    return (
                                        <Fragment>
                                            {renderIconForType(option.id)}
                                            <p className='ml-2'>{option.value_creation}</p>
                                        </Fragment>
                                    )
                                }}
                                renderInput={(params) => <TextField {...params} variant="outlined" InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            {renderIconForType(content.format)}
                                        </InputAdornment>
                                    )
                                }} />}
                            />
                        </div>
                        <div className='content-add-format' style={{
                            width: '20%'
                        }}>
                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                            {t("content.video_format")}
                            </InputLabel>
                            <Autocomplete
                                classes={{
                                    root: classes.root
                                }}
                                id="content-add-format"
                                value={EXTRA_FORMAT.find(e => e.id === content.extraFormat)}
                                disabled={isDisable}
                                onChange={handleChangeExtraFormat}
                                size='small'
                                disableClearable
                                className='mt-2'
                                options={EXTRA_FORMAT}
                                getOptionLabel={(e) => e.value_creation}
                                renderOption={option => {
                                    return (
                                        <Fragment>
                                            <p className='ml-2'>{option.value_creation}</p>
                                        </Fragment>
                                    )
                                }}
                                renderInput={(params) => <TextField {...params} variant="outlined" InputProps={{
                                    ...params.InputProps,
                                }} />}
                            />
                        </div>
                        <div className='content-add-org' style={{
                            width: '20.5%'
                        }}>
                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                            {t("content.org")}
                            </InputLabel>

                            <Autocomplete
                                value={organizations.find(o => o.ikastUID === content.belongsTo)}
                                disabled={disabledOrganization}
                                onChange={handleChangeUpdateOrg}
                                size='small'
                                disableClearable
                                className='mt-2'
                                classes={{
                                    root: classes.root
                                }}
                                options={organizations}
                                getOptionLabel={(org) => org.Name}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />
                        </div>
                        <div className='content-add-global' style={{
                            width: '37.1%',
                            marginLeft: '0.2%'
                        }}>
                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)', marginBottom: '8px' }}>
                            {t("content.isan_code")}
                            </InputLabel>
                            <div className='w-full'>
                                <OutlinedInput
                                    classes={outlinedInputClasses}
                                    inputProps={{
                                        autoComplete: "off"
                                    }}
                                    style={{ height: '40px', marginLeft: '-2px' }}
                                    size='small'
                                    value={isanCode}
                                    className='w-full'
                                    onInput={(e) => {
                                        e.target.value = e.target.value.slice(0, 33)
                                    }}
                                    placeholder='xxxx-xxxx-xxxx-xxxx-x-xxxx-xxxx-x'
                                    name='isanCode'
                                    // onChange={handleUpdateInputISANChange}
                                    startAdornment={<InputAdornment position="start" style={{ marginLeft: '-8px' }}>ISAN</InputAdornment>}
                                    type="text"
                                    id="outlined-size-small"
                                    error={!!errValidateIsanCode}
                                    onBlur={validateISANCode}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-between content-center' style={{ marginTop: '20px' }}>
                        <div className='content-add-global' style={{
                            width: '62.2%'
                        }}>
                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                            {t("content.title")}
                            </InputLabel>
                            <div className='w-full'>
                                <TextField
                                    classes={{
                                        root: classes.root
                                    }}
                                    onBlur={handleChangeUpdateInput}
                                    disabled={isDisable}
                                    defaultValue={content.title}
                                    name='title'
                                    size='small'
                                    type='text'
                                    className='w-full'
                                    variant="outlined"
                                    placeholder={t("content.title_placeholder")}
                                />
                            </div>
                        </div>

                        <div className='content-add-global' style={{
                            width: '37.1%'
                        }}>
                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                            {t("content.custom_id")}
                            </InputLabel>
                            <div className='w-full'>
                                <input
                                    style={{ marginTop: '0rem' }}
                                    onBlur={handleChangeUpdateInput}
                                    disabled={isDisable}
                                    defaultValue={content.globalID ? content.globalID : ''}
                                    name='globalID'
                                    className='w-full'
                                    type="text"
                                    id='global-id' />
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-between content-center'>
                        <div style={{
                            width: '62.2%'
                        }}>
                            <div className='mt-4'>
                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                {t("content.short_description")}
                                </InputLabel>
                                <div className='w-full'>
                                    <textarea onBlur={handleChangeUpdateInput} disabled={isDisable} maxLength='255' defaultValue={content.shortDescription ? content.shortDescription : ""} name='shortDescription' rows='5' id='add-content-description' className='w-full' placeholder='Description of the content...' />
                                </div>
                            </div>
                            <div>
                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                {t("content.web_link")}
                                </InputLabel>
                                <div className='w-full'>
                                    <TextField
                                        classes={{
                                            root: classes.root
                                        }}
                                        maxLength='1000'
                                        disabled={isDisable}
                                        onBlur={handleChangeUpdateInput}
                                        defaultValue={content.webLink ? content.webLink : ''}
                                        name='webLink'
                                        size='small'
                                        type='text'
                                        className='w-full'
                                        variant="outlined"
                                        placeholder= {t("content.web_link_placeholder")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='m-auto text-center' style={{
                            width: '37.1%',
                            marginLeft: '1%',
                            textAlign: 'center'
                        }}>
                            <button style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '0.75rem', marginTop: '11px', height: '19px' }}>
                            {t("content.illustration")}
                            </button>
                            <div className='mt-2'
                                style={{
                                    justifyContent: 'center',
                                    display: 'flex',
                                    height: '215px',
                                    background: 'rgb(255 255 255)',
                                    borderRadius: '4px'
                                }}>
                                {content.illustrationUrlS3 !== null && !isImageNotFound ?
                                    <div>
                                        <img
                                            onMouseEnter={onMouseEnterHandler}
                                            onMouseLeave={onMouseLeaveHandler}
                                            onClick={() => setOpenDropzoneIllustration(true)}
                                            src={content.illustrationUrlS3}
                                            onError={handleImageNotFound}
                                            style={{
                                                height: '198px',
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'top center',
                                                borderRadius: '1%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                backgroundImage: isLogoHovering ? ' linear-gradient(to bottom right, #002f4b, #dc4225)' : '',
                                                // opacity: isLogoHovering ? '.5' : ''
                                                filter: isLogoHovering ? 'brightness(0.6)' : ''
                                            }} alt='illustration' />
                                        {isLogoHovering ? <EditRoundedIcon
                                            onMouseEnter={onMouseEnterHandler}
                                            onMouseLeave={onMouseLeaveHandler}
                                            onClick={() => setOpenDropzoneIllustration(true)}
                                            style={{
                                                position: 'absolute',
                                                marginTop: '-115px',
                                                marginLeft: '-10px',
                                                color: 'white'
                                            }}
                                        /> : null} </div> :
                                    <button
                                        onClick={() => setOpenDropzoneIllustration(true)}
                                        style={{
                                            borderRadius: '4px',
                                            border: 'dashed 2px lightgray',
                                            height: isImageNotFound ? '200px' : '80%',
                                            color: 'lightgray',
                                            width: '80%'
                                        }}>
                                        <div style={{ marginBottom: '4%' }}>Add Illustration here</div>
                                        <CloudUploadIcon style={{ color: 'rgb(208, 94, 90)', fontSize: '30px' }} />
                                    </button>
                                }
                            </div>
                            <DropzoneDialog
                                dialogTitle={t("content.upload_illus")}
                                acceptedFiles={['image/*']}
                                cancelButtonText={t('common.cancel')}
                                submitButtonText={t('common.submit')}
                                maxFileSize={5000000}
                                filesLimit={1}
                                open={openDropzoneIllustration}
                                onClose={() => setOpenDropzoneIllustration(false)}
                                onSave={uploadIllustration}
                                showPreviews={true}
                                showFileNamesInPreview={true}
                            />
                        </div>
                    </div>

                    <div className='mt-4'>
                        <div>
                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                {t("content.duration")}
                            </InputLabel>
                            <div>
                                <input disabled={isDisable} onBlur={handleDurationBlur} defaultValue={content.duration} name='duration' type='text' id='update-content-duration' />
                            </div>
                        </div>
                    </div>

                    <div className='mt-4' style={{ paddingBottom: '40px' }}>
                        <TextField
                            disabled={statusDisable}
                            // className='content-update-status'
                            className={classes.root}
                            onChange={handleChangeStatus}
                            value={CONTENT_STATUS && CONTENT_STATUS.find(e => e.id === content.status).id}
                            style={{
                                marginTop: 'auto',
                                width: '25%',
                                height: '2.75rem'
                            }}
                            variant='outlined'
                            select>
                            {
                                filterListContentStatus && filterListContentStatus.map((value, key) => {
                                    return (
                                        <MenuItem key={key} value={value.id}>
                                            <div className='flex items-center'>
                                                <span id='status' className='rounded-full block m-1' style={{
                                                    backgroundColor: value.color,
                                                    height: '15px',
                                                    width: '15px',
                                                }} />
                                                <span className='ml-1'>{value.value}</span>
                                            </div>
                                        </MenuItem>
                                    )
                                })
                            }
                        </TextField>
                    </div>
                </div>
            </TabPanel>

            {/* <div className='mt-4'>
                    <div>
                        <div className='profile-title pb-2 pt-2' style={{ marginTop: '10px', borderBottom: '1px solid #D05E5A' }}>
                            <b>Licensing : {(contracts && contracts.contractList) ? contracts.contractList.length : 0} Contracts</b>
                        </div>
                    </div>
                </div> */}
            <TabPanel valueTab={valueTab} index={1}>
                <div className='bg-white px-8 py-16'>
                    <div className='grid grid-cols-12 gap-2 justify-items-center text-center'>
                        <div className='font-bold col-span-1 flex justify-center text-center' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        </div>
                        <div className='font-bold col-span-1 flex justify-center text-center' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t("common.status")}
                        </div>
                        <div className='font-bold col-span-2 text-center' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t("table.title")}
                        </div>
                        <div className='font-bold col-span-1 text-center' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t("table.with")}
                        </div>
                        <div className='font-bold col-span-1 flex justify-center text-center' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t("table.pricing")}
                        </div>
                        <div className='font-bold col-span-1 flex justify-center text-center' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t("table.contents")}
                        </div>
                        <div className='font-bold col-span-2 flex justify-center text-center' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                           {t("table.end_date")}
                        </div>
                        <div className='font-bold col-span-2 flex justify-center text-center' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t("table.last_update")}
                        </div>
                    </div>
                    <Fragment>
                        {contracts.contractList && contracts.contractList.map((value, key) => {
                            return (
                                <Item isContentUpdating={true} contract={value} key={key} listPermission={listPermission} />
                            )
                        })}
                    </Fragment>
                    {(contracts && contracts.contractList) ?
                        <div className='flex flex-row justify-center items-center py-4'>
                            <Pagination count={contracts.totalPages} page={page} onChange={pagingHandleChange} />
                        </div> : null}
                </div>
            </TabPanel>
        </Layout >
    ) : <Layout><LoadingLayout messageKey={messageKey}/></Layout>;
};

export default withSnackbar(EditContent);
