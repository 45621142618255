import React, { useEffect, useState, useRef, Fragment, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import Switch from "@material-ui/core/Switch";
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import './index.css';
import { withSnackbar } from 'notistack';
import { authActions, authSelectors } from '../../../state/modules/auth';
import Pagination from "@material-ui/lab/Pagination/Pagination";
import SortContentIconRender from "../../components/Royalties/SortContentIconRender";
import { GET_ROLES, GET_SYSTEM_ROLES } from '../../../constants';
import * as moment from "moment";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MessageItem from "../../components/Messages/MessageItem";
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Checkbox from '@material-ui/core/Checkbox';
import LoadingContext from '../../../context/loading/LoadingContext';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from "../../../config/axios";
import defaultAvatar from "../../../assets/images/user.png"
import openSocket from 'socket.io-client';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
// const socket = openSocket(process.env.REACT_APP_SERVER_API_URL ? process.env.REACT_APP_SERVER_API_URL : 'http://localhost:8089')

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const useStyles = makeStyles({
    root: {
        height: '40px !important',
        padding: '0px',
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        },
    }
});

const UserManagement = () => {
    const { t, i18n } = useTranslation();
    const [page, setPage] = useState(1);
    const selector = useSelector((state) => authSelectors.user(state));
    const [user, setUserTracking] = useState(selector);
    const [listUsers, setListUsers] = useState(null);
    const prevData = usePrevious(selector);
    const [isHover, setIsHover] = useState(false);
    const [sortName, setSortName] = useState(null);
    const [sortEmail, setSortEmail] = useState(null);
    const [sortRole, setSortRole] = useState(null);
    const [sortProfile, setSortProfile] = useState(null);
    const [sortLastUpdate, setSortLastUpdate] = useState(null);
    const ROLES = useMemo(() => GET_ROLES(t), [t]);
    const dataSystemRoles = useMemo(() => GET_SYSTEM_ROLES(t), [t]);
    // const SYSTEM_ROLES = dataSystemRoles.filter((item) => item.id !== "Super Admin");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [SYSTEM_ROLES, setSystemRole] = useState([]);
    const [columnName, setColumnName] = useState(null);
    const [columnEmail, setColumnEmail] = useState(null);
    const [columnRole, setColumnRole] = useState(null);
    const [columnProfile, setColumnProfile] = useState(null);
    const [columnLastUpdate, setColumnLastUpdate] = useState(null);
    const [isCheckall, setIsCheckall] = useState(false);
    const [listItemChecked, setListItemChecked] = useState([]);
    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const [listPermission, setListPermission] = useState([]);
    const [openDialogUser, setOpenDialogUser] = useState(false);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [currentSystemRole, setCurrentSystemRole] = useState(null);
    const [currentUserRole, setCurrentUserRole] = useState(null);
    const [isEdit, setIsEdit] = useState(true);
    const [currentUserEdit, setCurrentUserEdit] = useState({});
    const [maxAllowUsers, setMaxAllowUsers] = useState(3);
    const [listOrg, setListOrg] = useState([]);
    const [currentOrg, setCurrentOrg] = useState(null);
    const [errorForm, setErrorForm] = useState({
        firstName: false,
        lastName: false,
        email: false,
        password: false,
        userRole: false,
        systemRole: false
    });

    const { showLoading, hideLoading } = useContext(LoadingContext);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const BootstrapInput = withStyles((theme) => ({
        root: {
            width: '100%',
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            width: '100%',
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.common.white,
            border: '1px solid #ced4da',
            fontSize: 16,
            padding: '10px 12px',
        },
    }))(InputBase);

    useEffect(() => {
        if (user && user.get('org') && localStorage.getItem('token')) {
            const orgID = (user.get('systemRole') === "Super Admin") ? (currentOrg && currentOrg.ikastUID ? currentOrg.ikastUID : user.get('BelongsTo')) : user.get('BelongsTo');
            (async function getListMessages() {
                showLoading();
                const contentResponse = await axios.get(`/org/get-list-users-per-page?orgUid=${orgID}&pageNo=${page}&itemPerPage=10&sortBy=createdAt&sortOrder=1`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

                const { data, status } = contentResponse;

                if (status === 200) {
                    onLoadUserList(data);
                    
                }
                hideLoading();
            })();
        }

    }, [currentOrg, user, page]);
    useEffect(() => {
        if (user && user.get('org') && localStorage.getItem('token')) {
            (async function getListMessages() {
                showLoading();
                const contentResponse = await axios.get(`/org/get-list-orgs`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

                const { data, status } = contentResponse;

                if (status === 200) {
                    setListOrg(data);
                    data.forEach(item => {
                        if(item.ikastUID === user.get('BelongsTo')) {
                            setCurrentOrg(item);
                        }
                    })
                }
                hideLoading();
            })();
        }

    }, []);

    useEffect(() => {
        if (user && user.get('org')) {
            const org = user.get('org');
            setMaxAllowUsers(org.get('maxAllowUsers'));
            setListPermission(user.get('permissions').toArray());
            if (user.get('systemRole') === "Super Admin") {
                setIsSuperAdmin(true);
                setSystemRole(dataSystemRoles);
            } else {
                const systemRoles = dataSystemRoles.filter((item) => item.id !== "Super Admin");
                setIsSuperAdmin(false);
                setSystemRole(systemRoles);
            }
        }
    }, [user]);

    useEffect(() => {
        if (selector) {
            setUserTracking(selector);
        }
    }, [selector]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(authActions.getProfile({ id: localStorage.getItem('id') }));
    }, []);

    const pagingHandleChange = (e, value) => {
        setPage(value);
    };

    const addStatusCheckboxForPage = (dataListUsers) => {
        if (!dataListUsers.records || dataListUsers.records.length === 0) {
            setListItemChecked([]);
            setIsCheckall(false);
        }
        let data = [];
        // if (isSuperAdmin) {
        //     data = dataListUsers.records.map((obj, index) => ({ ...obj, checked: false }));
        // } else {
            data = dataListUsers.records.map((obj, index) => {
                if (obj.systemRole !== "Super Admin") {
                    return ({ ...obj, checked: false })
                } else {
                    return ({ ...obj })
                }
            });
        // }
        dataListUsers.records = data;
        return dataListUsers;
    }

    const handleChangeCheckAll = () => {
        const dataListUsers = JSON.parse(JSON.stringify(listUsers));
        const oldStatus = JSON.parse(JSON.stringify(isCheckall));
        let dataRecord = [];
        // if (isSuperAdmin) {
        //     dataRecord = dataListUsers.records.map((obj, index) => ({ ...obj, checked: !oldStatus }));
        // } else {
            dataRecord = dataListUsers.records.map((obj, index) => {
                if (obj.systemRole !== "Super Admin") {
                    return ({ ...obj, checked: !oldStatus })
                } else {
                    return ({ ...obj })
                }
            });
        // }
        let listCheckedItem = [];
        dataRecord.forEach(item => {
            if (item.checked === true) {
                listCheckedItem.push(item);
            }
        });
        setListItemChecked(listCheckedItem);
        // if (!oldStatus) {
        //     const abc = dataListUsers.records.map((obj) => {
        //         console.log(obj)
        //         if (obj.checked && obj.checked === true) {
        //             console.log('aaaaaaaaaaaaaaaaaaaaaa', abc);
        //             return ({ ...obj})
        //         }
        //     });
        //     setListItemChecked(abc);
        // } else {
        //     setListItemChecked([]);
        // }
        dataListUsers.records = dataRecord;
        setListUsers(dataListUsers);
        setIsCheckall(!oldStatus);
    }

    const handleChangeCheckItem = (data) => {
        const dataListUsers = JSON.parse(JSON.stringify(listUsers));
        const dataListItemChecked = JSON.parse(JSON.stringify(listItemChecked));
        dataListUsers.records.find(p => p.ikastUID === data.ikastUID && (p.checked = !data.checked, true));
        if (!data.checked) {
            const indexItemConflict = dataListItemChecked.findIndex(item => item.ikastUID === data.ikastUID);
            if (indexItemConflict === -1) {
                dataListItemChecked.push(data);
                setListItemChecked(dataListItemChecked);
            }
        } else {
            const indexItemConflict = dataListItemChecked.findIndex(item => item.ikastUID === data.ikastUID);
            if (indexItemConflict > -1) {
                dataListItemChecked.splice(indexItemConflict, 1);
                setListItemChecked(dataListItemChecked);
            }
        }
        setListUsers(dataListUsers);
    }

    const onLoadUserList = (data) => {
        if (columnName) {
            if (sortName === 'asc') {
                sortNameAscendingOrder();
            } else if (sortName === 'des') {
                sortNameDescendingOrder()
            }
        } else if (columnEmail) {
            if (sortEmail === 'asc') {
                sortEmailAscendingOrder();
            } else if (sortEmail === 'des') {
                sortEmailDescendingOrder()
            }
        } else if (columnRole) {
            if (sortRole === 'asc') {
                sortRoleAscendingOrder();
            } else if (sortRole === 'des') {
                sortRoleDescendingOrder()
            }
        } else if (columnProfile) {
            if (sortProfile === 'asc') {
                sortProfileAscendingOrder();
            } else if (sortProfile === 'des') {
                sortProfileDescendingOrder()
            }
        } else if (columnLastUpdate) {
            if (sortLastUpdate === 'asc') {
                sortLastUpdateAscendingOrder();
            } else if (sortLastUpdate === 'des') {
                sortLastUpdateDescendingOrder();
            }
        } else {
            setListUsers(addStatusCheckboxForPage(data));
        }
    }

    const sortingHandle = async (sortBy, sortOrder) => {
        const messagesResponse =
            await axios.get(`/org/get-list-users-per-page?orgUid=${user.get('BelongsTo')}&pageNo=${page}&itemPerPage=10&sortBy=${sortBy}&sortOrder=${sortOrder}`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

        const { data, status } = messagesResponse;

        if (status === 200) {
            setListUsers(addStatusCheckboxForPage(data));
        }
    };

    const sortNameAscendingOrder = () => {
        sortingHandle('First Name', 0).then(r => { });
    }

    const sortNameDescendingOrder = () => {
        sortingHandle('First Name', 1).then(r => { });
    }

    const sortEmailAscendingOrder = () => {
        sortingHandle('Email', 0).then(r => { });
    }

    const sortEmailDescendingOrder = () => {
        sortingHandle('Email', 1).then(r => { });
    }

    const sortRoleAscendingOrder = () => {
        sortingHandle('Role', 0).then(r => { });
    }

    const sortRoleDescendingOrder = () => {
        sortingHandle('Role', 1).then(r => { });
    }

    const sortProfileAscendingOrder = () => {
        sortingHandle('systemRole', 0).then(r => { });
    }

    const sortProfileDescendingOrder = () => {
        sortingHandle('systemRole', 1).then(r => { });
    }


    const sortLastUpdateAscendingOrder = () => {
        sortingHandle('createdAt', 0).then(r => { });
    }

    const sortLastUpdateDescendingOrder = () => {
        sortingHandle('createdAt', 1).then(r => { });
    }

    const sortByName = () => {
        setColumnName(true);
        setColumnEmail(false);
        setColumnRole(false);
        setColumnProfile(false);
        setColumnLastUpdate(false);
        if (!sortName) {
            sortNameAscendingOrder();
            setSortName('asc');
        }

        if (sortName && sortName === 'asc') {
            sortNameDescendingOrder()
            setSortName('des');
        }

        if (sortName && sortName === 'des') {
            sortNameAscendingOrder();
            setSortName('asc');
        }
    };

    const sortByEmail = () => {
        setColumnName(false);
        setColumnEmail(true);
        setColumnRole(false);
        setColumnProfile(false);
        setColumnLastUpdate(false);
        if (!sortEmail) {
            sortEmailAscendingOrder();
            setSortEmail('asc');
        }

        if (sortEmail && sortEmail === 'des') {
            sortEmailAscendingOrder();
            setSortEmail('asc');
        }

        if (sortEmail && sortEmail === 'asc') {
            sortEmailDescendingOrder();
            setSortEmail('des');
        }
    };

    const sortByRole = () => {
        setColumnName(false);
        setColumnEmail(false);
        setColumnRole(true);
        setColumnProfile(false);
        setColumnLastUpdate(false);
        if (!sortRole) {
            sortRoleAscendingOrder();
            setSortRole('asc');
        }

        if (sortRole && sortRole === 'des') {
            sortRoleAscendingOrder();
            setSortRole('asc');
        }

        if (sortRole && sortRole === 'asc') {
            sortRoleDescendingOrder();
            setSortRole('des');
        }
    };

    const sortByProfile = () => {
        setColumnName(false);
        setColumnEmail(false);
        setColumnRole(false);
        setColumnProfile(true);
        setColumnLastUpdate(false);
        if (!sortProfile) {
            sortProfileAscendingOrder();
            setSortProfile('asc');
        }

        if (sortProfile && sortProfile === 'des') {
            sortProfileAscendingOrder();
            setSortProfile('asc');
        }

        if (sortProfile && sortProfile === 'asc') {
            sortProfileDescendingOrder();
            setSortProfile('des');
        }
    };

    const sortByLastUpdate = () => {
        setColumnName(false);
        setColumnEmail(false);
        setColumnRole(false);
        setColumnLastUpdate(true);
        if (!sortLastUpdate) {
            sortLastUpdateAscendingOrder();
            setSortLastUpdate('asc');
        }

        if (sortLastUpdate && sortLastUpdate === 'des') {
            sortLastUpdateAscendingOrder();
            setSortLastUpdate('asc');
        }

        if (sortLastUpdate && sortLastUpdate === 'asc') {
            sortLastUpdateDescendingOrder();
            setSortLastUpdate('des');
        }
    };

    const handleRefreshUserList = async () => {
        (async function getListMessages() {
            const orgID = (user.get('systemRole') === "Super Admin") ? (currentOrg && currentOrg.ikastUID ? currentOrg.ikastUID : user.get('BelongsTo')) : user.get('BelongsTo');
            const contentResponse = await axios.get(`/org/get-list-users-per-page?orgUid=${orgID}&pageNo=${page}&itemPerPage=10&sortBy=createdAt&sortOrder=1`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            const { data, status } = contentResponse;

            if (status === 200) {
                onLoadUserList(data);
            }
        })();

    };

    const openDeleteDialog = () => {
        setOpenDialogDelete(true);
    }

    const handleCloseDialogDelete = () => {
        setOpenDialogDelete(false);
    }

    const handleDelete = async () => {
        const uuidList = [];
        listItemChecked.forEach(item => {
            uuidList.push(item.ikastUID);
        });

        const data = JSON.stringify({
            "uuidList": uuidList
        });


        var config = {
            method: 'delete',
            url: '/users/delete',
            headers: {
                'x-access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: data
        };


        (async function deleteUsers() {
            const contentResponse = await axios(config);
            handleCloseDialogDelete();
            const { status } = contentResponse;

            if (status === 200) {
                enqueueSnackbar(t("notify.delete_success"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'success'
                });
                if (isCheckall) {
                    setPage(1);
                    setIsCheckall(false);
                }
                setListItemChecked([]);
                handleRefreshUserList();
            } else {
                enqueueSnackbar(t("notify.something_wrong"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }
        })();
    }

    const resetDataUserDialog = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPassword('');
        setCurrentUserRole(null);
        setCurrentSystemRole(null);
    }

    const mapDataEdit = (item) => {
        setFirstName(item['First Name']);
        setLastName(item['Name']);
        setEmail(item['Email']);
        setPassword('');
        setCurrentUserRole(ROLES.find(r => r.id === item.Role));
        setCurrentSystemRole(SYSTEM_ROLES.find(r => r.id === item.systemRole));
    }

    const openEditDialog = (item) => {
        resetValidate();
        setCurrentUserEdit(item);
        mapDataEdit(item)
        setOpenDialogUser(true);
        setIsEdit(true);
    }

    const openCreateDialog = () => {
        resetValidate();
        setOpenDialogUser(true);
        setIsEdit(false);
    }

    const handleCloseDialogUser = () => {
        resetDataUserDialog();
        setOpenDialogUser(false);
    }

    const checkValidate = () => {
        const error = JSON.parse(JSON.stringify(errorForm));
        if (lastName.trim().length > 0) {
            error.lastName = false
        } else error.lastName = true;
        if (firstName.trim().length > 0) {
            error.firstName = false
        } else error.firstName = true;
        if (email.trim().length > 0) {
            error.email = false
        } else error.email = true;
        if (isEdit || password.trim().length > 0) {
            error.password = false
        } else error.password = true;
        if (currentUserRole && currentUserRole.id) {
            error.userRole = false
        } else error.userRole = true;
        if (currentSystemRole && currentSystemRole.id) {
            error.systemRole = false
        } else error.systemRole = true;
        setErrorForm(error);
        if (!isEdit && (error.firstName || error.lastName || error.email || error.password || error.userRole || error.systemRole)) {
            return false;
        } else if (isEdit && (error.firstName || error.lastName || error.email || error.userRole || error.systemRole)) {
            return false;
        }
        else return true;
    }

    const handleAction = async () => {
        if (!checkValidate()) {
            enqueueSnackbar(t("profile.require_info"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            return
        }
        if (!isEdit) {
            const dataUser = {
                "email": email,
                "password": password,
                "role": currentUserRole.id || '',
                "name": lastName,
                "firstName": firstName,
                "orgUuid": isSuperAdmin ? currentOrg.ikastUID : user.get('BelongsTo'),
                "ProfilePictureUrl": "",
                "autoSendEmail": false,
                "lang": "en",
                "systemRole": currentSystemRole.id,
                "isSuperAdmin": false
            }
            try {
                const requestNewUser = await axios.post('/auth/signup', dataUser, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });
                const { status } = requestNewUser;
                setOpenDialogUser(false);
                if (status === 200) {
                    enqueueSnackbar(t("profile.create_user_success"), {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'success'
                    });
                    resetDataUserDialog();
                    handleRefreshUserList();
                } else {
                    resetDataUserDialog();
                    enqueueSnackbar(t("notify.something_wrong"), {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
    
                        variant: 'error'
                    });
                }
            } catch (error) {
                enqueueSnackbar(t("notify.something_wrong"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }
        } else {
            const dataUser = {
                "email": email,
                "role": currentUserRole.id,
                "name": lastName,
                "firstName": firstName,
                "systemRole": currentSystemRole.id,
            }
            const requestUpdateUser = await axios.put('/user/update-info', dataUser, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });
            const { status } = requestUpdateUser;
            setOpenDialogUser(false);
            if (status === 200) {
                if (password.trim().length > 0) {
                    const responsePassChange = await axios.put(`/user/change-password`, {
                        email: email,
                        password: password,
                    }, {
                        headers: {
                            common: {
                                'x-access-token': localStorage.getItem('token')
                            },
                        }
                    });

                    if (responsePassChange.status === 200) {
                        enqueueSnackbar(t('profile.update_pass_success'), {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },

                            variant: 'success'
                        });
                    } else {
                        enqueueSnackbar(t('profile.update_pass_fail'), {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },

                            variant: 'error'
                        });
                    }
                }
                enqueueSnackbar(t("profile.update_user_success"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'success'
                });
                resetDataUserDialog();
                handleRefreshUserList();
            } else {
                resetDataUserDialog();
                enqueueSnackbar(t("notify.something_wrong"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }
        }

    }

    const addDefaultSrc = (ev) => {
        ev.target.src = defaultAvatar
    }

    const renderItemUser = (item, key) => {
        const roleObj = ROLES.find(r => r.id === (user.get('Role') || 1));
        const showTime = moment(item.createdAt).local().format('DD/MM/YYYY HH:mm:ss');
        return (
            <div
                className='grid grid-cols-12 gap-2 text-center mt-3 rounded-lg items-center justify-items-center item-user'
                key={key}>
                {listPermission.includes('IKAST_SETTING_DELETE_USER') && item.hasOwnProperty('checked') ?
                    <div className='col-span-1'>
                        <Checkbox checked={item.checked} onChange={() => handleChangeCheckItem(item)} />
                    </div>
                    : <div className='col-span-1'></div>}
                <div className='m-auto col-span-1 flex justify-center text-center'>
                    {item.profilePictureUrl ? (
                        <img alt='avatar'
                            onError={(e) => addDefaultSrc(e)}
                            src={item.profilePictureUrl}
                            style={{
                                width: '40px',
                                height: '40px',
                                backgroundSize: 'cover',
                                backgroundPosition: 'top center',
                                borderRadius: '50%',
                            }}></img>
                    ) : (
                        <img alt='avatar'
                            src={defaultAvatar}
                            style={{
                                width: '40px',
                                height: '40px',
                                backgroundSize: 'cover',
                                backgroundPosition: 'top center',
                                borderRadius: '50%',
                            }}></img>
                    )}
                </div>
                <div className='col-span-2 justify-left text-left text-overflow' title={item['First Name'] + ' ' + item['Name']}>
                    {item['First Name'] + ' ' + item['Name']}
                </div>
                <div className='col-span-2 justify-left text-left text-overflow' title={item.Email}>
                    {item.Email}
                </div>
                <div className='col-span-1 text-overflow' title={roleObj.name}>
                    {roleObj.name}
                </div>
                <div className='col-span-2 text-overflow' title={item.systemRole}>
                    {item.systemRole}
                </div>
                <div className='col-span-2 text-overflow' title={item.createdAt ? showTime : ""}>
                    {item.createdAt ? showTime : ""}
                </div>
                <div className='col-span-1 justify-center text-center'>
                    {(!isSuperAdmin && item.systemRole === "Super Admin") || !listPermission.includes('IKAST_SETTING_EDIT_USER') ? null : (
                        <EditRoundedIcon onClick={() => openEditDialog(item)} style={{
                            cursor: 'pointer',
                            width: 30
                        }} />
                    )}

                </div>
            </div>
        )
    }

    const handleChangeValueProfile = (e) => {
        const { name, value } = e.target;
        const error = JSON.parse(JSON.stringify(errorForm));
        if (name === "First Name") {
            setFirstName(value);
            if (value.trim().length > 0) {
                error.firstName = false;
                setErrorForm(error);
            }
        } else if (name === "Name") {
            setLastName(value);
            if (value.trim().length > 0) {
                error.lastName = false;
                setErrorForm(error);
            }
        }
    }

    const handleChangeRole = (e, value) => {
        const error = JSON.parse(JSON.stringify(errorForm));
        error.userRole = false;
        setErrorForm(error);
        setCurrentUserRole(value);
    }

    const handleChangeOrg = (e, value) => {
        setMaxAllowUsers(value.maxAllowUsers);
        setCurrentOrg(value);
    }
    const handleInputPasswordChange = (event) => {
        const error = JSON.parse(JSON.stringify(errorForm));
        if (event.target.value.length > 0) {
            error.password = false;
            setErrorForm(error);
        }
        setPassword(event.target.value);
    }

    const handleChangeSystemRole = (e, value) => {
        const error = JSON.parse(JSON.stringify(errorForm));
        error.systemRole = false;
        setErrorForm(error);
        setCurrentSystemRole(value);
    }

    const handleChangeValueEmail = (event) => {
        const error = JSON.parse(JSON.stringify(errorForm));
        if (event.target.value.length > 0) {
            error.email = false;
            setErrorForm(error);
        }
        setEmail(event.target.value);
    }


    const resetValidate = () => {
        setErrorForm({
            firstName: false,
            lastName: false,
            email: false,
            password: false,
            userRole: false,
            systemRole: false
        })
    }

    const renderDialogUserInfo = () => {
        return (
            <Dialog
                open={openDialogUser}
                className="widthPopup"
                onClose={handleCloseDialogUser}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"> {isEdit ? t("profile.edit_user") : t("profile.create_user")}</DialogTitle>
                <DialogContent id="user-dialog-content">
                    {/* <DialogContentText id="alert-dialog-description">
                            {listItemChecked.length > 1 ? t("profile.delete_multi_user") : t("profile.delete_one_user")}
                        </DialogContentText> */}
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <FormControl className='w-full'>
                                <InputLabel shrink className='input-color'>
                                    {t('profile.first_name')} <span style={{ color: "red" }}> *</span>
                                </InputLabel>
                                <input
                                    style={{ color: 'black', border: errorForm.firstName ? '1px solid red' : '1px solid #ced4da' }}
                                    onChange={handleChangeValueProfile}
                                    id='first_name'
                                    name='First Name'
                                    type='text'
                                    value={firstName} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl className='w-full'>
                                <InputLabel shrink className='input-color'>
                                    {t('profile.last_name')} <span style={{ color: "red" }}> *</span>
                                </InputLabel>
                                <input
                                    style={{ color: 'black', border: errorForm.lastName ? '1px solid red' : '1px solid #ced4da' }}
                                    onChange={handleChangeValueProfile}
                                    id='last_name' name='Name'
                                    value={lastName}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel shrink className='input-color'>
                                {t('profile.role')} <span style={{ color: "red" }}> *</span>
                            </InputLabel>
                            <Autocomplete
                                name='roles'
                                onChange={handleChangeRole}
                                disableClearable
                                value={currentUserRole}
                                className='mt-2 autocomplete-role'
                                style={{ color: 'black', borderRadius: 4, border: errorForm.userRole ? '1px solid red' : 'none' }}
                                classes={{
                                    root: classes.root
                                }}
                                getOptionLabel={(role) => role.name}
                                options={ROLES}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <FormControl className='w-full'>
                                <InputLabel shrink className='input-color'>
                                    {t('profile.email')} {!isEdit ? <span style={{ color: "red" }}> *</span> : null}
                                </InputLabel>
                                <input
                                    disabled={isEdit}
                                    style={{ color: 'black', border: errorForm.email ? '1px solid red' : '1px solid #ced4da' }}
                                    onChange={handleChangeValueEmail}
                                    id='email_profile'
                                    name='Email'
                                    type='text'
                                    value={email} />
                            </FormControl>
                        </Grid>
                        {/* border: errorPass ? '1px solid red' : '1px solid #ced4da' */}
                        <Grid item xs={4}>
                            <FormControl className='w-full'>
                                <InputLabel shrink className='input-color'>
                                    {t('profile.password')} {!isEdit ? <span style={{ color: "red" }}> *</span> : null}
                                </InputLabel>
                                <input
                                    style={{ color: 'black', border: errorForm.password ? '1px solid red' : '1px solid #ced4da' }}
                                    id='password_profile'
                                    className='text-gray-500'
                                    placeholder="************"
                                    name='Password'
                                    type='password'
                                    value={password}
                                    onChange={(event) => handleInputPasswordChange(event)} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel shrink className='input-color'>
                                {t('profile.system_role')} <span style={{ color: "red" }}> *</span>
                            </InputLabel>
                            <Autocomplete
                                name='systemRoles'
                                onChange={handleChangeSystemRole}
                                disableClearable
                                value={currentSystemRole}
                                className='mt-2 autocomplete-role'
                                style={{ color: 'black', borderRadius: 4, border: errorForm.systemRole ? '1px solid red' : 'none' }}
                                classes={{
                                    root: classes.root
                                }}
                                getOptionLabel={(role) => role.name}
                                options={SYSTEM_ROLES}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogUser} color="primary">
                        {t("common.cancel")}
                    </Button>
                    <Button color="primary" onClick={handleAction} autoFocus>
                        {isEdit ? t("common.save") : t("common.create")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const disableCreate = () => {
        return !isSuperAdmin && listUsers && listUsers.totalItems && listUsers.totalItems >= maxAllowUsers;
    }

    return (
        <div>
            <div className='bg-white py-8'>
                <div className='flex justify-between' style={{ marginBottom: 20 }}>
                    {
                        isSuperAdmin ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span>Organization: </span>
                                <Autocomplete
                                    name='roles'
                                    onChange={handleChangeOrg}
                                    disableClearable
                                    value={currentOrg}
                                    className='mt-2 autocomplete-role'
                                    style={{ color: 'black', borderRadius: 4, width: 200, marginLeft: 20 }}
                                    classes={{
                                        root: classes.root
                                    }}
                                    getOptionLabel={(org) => org.Name}
                                    options={listOrg}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                            </div>
                        ) : (<div></div>)
                    }
                    <div className='flex justify-end content-center py-2'>
                        <div>
                            {listPermission.includes('IKAST_SETTING_DELETE_USER') ?
                                <Button
                                    disabled={listItemChecked.length === 0}
                                    onClick={openDeleteDialog}
                                    style={{
                                        padding: '10px 16px',
                                        backgroundColor: '#D05E5A',
                                        marginBottom: '0.5rem',
                                        color: 'white',
                                        fontSize: '0.75rem'
                                    }}>
                                    {t("common.delete")}
                                </Button>
                                : null}
                            {listPermission.includes('IKAST_SETTING_ADD_USER') ?
                                <Button
                                    disabled={disableCreate()}
                                    onClick={openCreateDialog}
                                    title={t("profile.max_user", { maxAllowUsers: maxAllowUsers })}
                                    style={{
                                        padding: '10px 16px',
                                        backgroundColor: '#D05E5A',
                                        marginBottom: '0.5rem',
                                        color: 'white',
                                        fontSize: '0.75rem',
                                        marginLeft: '20px'
                                    }}>
                                    {t("common.create")}
                                </Button>
                                : null}
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-12 gap-2 justify-items-center text-center'>
                    {listPermission.includes('IKAST_SETTING_DELETE_USER') ?
                        <div className='font-bold col-span-1 justify-center text-center column-header'>
                            {listUsers && listUsers?.records.length > 0 ? (<Checkbox checked={isCheckall} onChange={handleChangeCheckAll} />) : null}
                        </div>
                        : null}
                    <div className='font-bold col-span-1 justify-center text-center column-header'>
                        {t("table.avatar")}
                        {/* {columnName ? <SortContentIconRender sortOrder={sortName} /> : null} */}
                    </div>
                    <div className='font-bold col-span-2 flex justify-center text-center column-header'
                        onClick={sortByName}
                    >
                        {t("table.name")}
                        {columnName ? <SortContentIconRender sortOrder={sortName} /> : null}
                    </div>
                    <div className='font-bold col-span-2 flex justify-center text-center column-header'
                        onClick={sortByEmail}
                    >
                        {t("table.email")}
                        {columnEmail ? <SortContentIconRender sortOrder={sortEmail} /> : null}
                    </div>
                    <div className='font-bold col-span-1 flex justify-center text-center column-header'
                        onClick={sortByRole}
                    >
                        {t("table.role")}
                        {columnRole ? <SortContentIconRender sortOrder={sortRole} /> : null}
                    </div>
                    <div className='font-bold col-span-2 flex justify-center text-center column-header'
                        onClick={sortByProfile}
                    >
                        {t("table.profile")}
                        {columnProfile ? <SortContentIconRender sortOrder={sortProfile} /> : null}
                    </div>
                    <div className='font-bold col-span-2 flex justify-center text-center column-header' onClick={sortByLastUpdate}>
                        {t("table.create_at")}
                        {columnLastUpdate ? <SortContentIconRender sortOrder={sortLastUpdate} /> : null}
                    </div>
                    <div className='font-bold col-span-1 flex justify-center text-center column-header'>
                    </div>
                </div>

                <Fragment>
                    {listUsers && listUsers.records && listUsers.records.map((value, key) => {
                        return (
                            renderItemUser(value, key)
                        )
                    })}
                </Fragment>

                <div className='flex flex-row justify-center items-center py-4' style={{ marginTop: '20px' }}>
                    <Pagination
                        count={listUsers ? listUsers.totalPages : 0}
                        page={page}
                        onChange={pagingHandleChange} />
                </div>
                <Dialog
                    open={openDialogDelete}
                    onClose={handleCloseDialogDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title"> {t("profile.delete_user")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {listItemChecked.length > 1 ? t("profile.delete_multi_user") : t("profile.delete_one_user")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialogDelete} color="primary">
                            {t("common.cancel")}
                        </Button>
                        <Button color="primary" onClick={handleDelete} autoFocus>
                            {t("common.yes")}
                        </Button>
                    </DialogActions>
                </Dialog>
                {renderDialogUserInfo()}
            </div>
        </div>
    )
};

export default withSnackbar(UserManagement);
