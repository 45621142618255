import React, { useLayoutEffect, useState } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import TextField from "@material-ui/core/TextField/TextField";
import Switch from "@material-ui/core/Switch";
import {LIST_COLOR_CHART} from "../../../../constants"
import Layout from "../../../layouts/Default";
import { useTranslation } from 'react-i18next';
import './index.css';
am4core.useTheme(am4themes_animated);



const listPieSeriesColor = () => {
    let listColor = [];
    LIST_COLOR_CHART.forEach(color => {
        listColor.push(am4core.color(color))
    });
    return listColor;
}

const getColorBarChart = (i) => {
    if(LIST_COLOR_CHART[i]) {
        return am4core.color(LIST_COLOR_CHART[i]);
    } else {
        const randomColor = '#' + Math.floor(Math.random()*16777215).toString(16);
        return am4core.color(randomColor);
    }
}

const ChartSection = (
    {
        handleChangeStartDate,
        // handleChangeStartDateView,
        handleChangeEndDate,
        // handleChangeEndDateView,
        handleChangeBarChartView,
        startDate,
        endDate,
        pieChartData,
        barChartData,
        barChartViewMode,
        totalRevenue,
        currency,
        barChartHeight }) => {
    const renderBarChart = (inBarChartData) => {
        // Themes begin
        am4core.useTheme(am4themes_material);
        am4core.useTheme(am4themes_animated);
        // Themes end

        // Create chart instance
        var chart = am4core.create("barchartdiv", am4charts.XYChart);
        chart.scrollbarX = new am4core.Scrollbar();

        chart.colors.step = 8;
        chart.maskBullets = false;

        for (let i = 0; i < inBarChartData.length; i++) {
            for (const key in inBarChartData[i]) {
                if (key !== 'date' && key !== 'total' && key !== "pareto") {
                    if (inBarChartData[i][key] == 0) {
                        inBarChartData[i][key] = null;
                    }
                }
            }
        }

        chart.data = inBarChartData;

        prepareParetoData();

        function prepareParetoData() {
            var sum = 0;
            for (var i = 0; i < chart.data.length; i++) {
                var value = chart.data[i].total;
                sum += value;
                chart.data[i].pareto = sum;
            }
        }

        //Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "date";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 80;
        categoryAxis.tooltip.disabled = true;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 50;
        valueAxis.min = 0;
        valueAxis.cursorTooltipEnabled = false;

        var itemList = [];
        for (const key in chart.data[0]) {
            if (key !== 'date' && key !== 'total' && key !== "pareto") {
                itemList.push(key);
            }
        }
        for (let i = 0; i < itemList.length; i++) {
            // Create series
            var series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = itemList[i];
            series.dataFields.categoryX = "date";
            series.name = itemList[i];
            series.tooltipText = barChartViewMode ? "{name}: {valueY.formatNumber('#.00')}" + ` ${currency}` + "[/]" : "{name}: {valueY}" + ` ${t("table.views")}`;
            series.stacked = true;
            series.columns.template.fill = getColorBarChart(i);
            series.stroke = am4core.color("white").brighten(0.2 * Number(i + 1));
            // series.columns.template.fill = am4core.color("black").brighten(0.2 * Number(i + 1));

            if (i === itemList.length - 1) {
                series.columns.template.column.cornerRadiusTopLeft = 3;
                series.columns.template.column.cornerRadiusTopRight = 3;
            }
        }

        var paretoValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        paretoValueAxis.renderer.opposite = true;
        paretoValueAxis.strictMinMax = true;
        paretoValueAxis.renderer.grid.template.disabled = true;
        paretoValueAxis.numberFormatter = new am4core.NumberFormatter();
        paretoValueAxis.numberFormatter.numberFormat = "#'" + ` ${currency}'`;
        paretoValueAxis.cursorTooltipEnabled = false;

        var paretoSeries = chart.series.push(new am4charts.LineSeries())
        paretoSeries.dataFields.valueY = "pareto";
        paretoSeries.dataFields.categoryX = "date";
        paretoSeries.yAxis = paretoValueAxis;
        paretoSeries.name = "Cumulative";
        paretoSeries.tooltipText = "Cumulative: {valueY.formatNumber('#.00')}" + ` ${currency}` + "[/]";
        paretoSeries.strokeWidth = 3;
        paretoSeries.stroke = new am4core.InterfaceColorSet().getFor("alternativeBackground");
        paretoSeries.stroke = am4core.color("#000");
        paretoSeries.strokeOpacity = 0.5;

        // Cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "panX";

        // Add legend
        chart.legend = new am4charts.Legend();
        chart.logo.disabled = true;
    }

    const renderPieChart = (inPieChartData) => {
        // Create chart instance
        var chart = am4core.create("chartdiv", am4charts.PieChart);

        // Add data
        chart.data = inPieChartData;

        const totalRevenueFormat = Number(totalRevenue).toLocaleString();
        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        var label = pieSeries.createChild(am4core.Label);
        label.text = `${totalRevenueFormat} ${currency}`;
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.fontSize = 16;
        label.fill = am4core.color("#fff")
        pieSeries.dataFields.value = "revenue";
        pieSeries.dataFields.category = "title";
        pieSeries.slices.template.propertyFields.fill = "color";
        pieSeries.labels.template.fontSize = 9;
        pieSeries.labels.template.text = "{value.percent.formatNumber('#.#')} %";
        pieSeries.labels.template.fill = am4core.color("#ae323c");
        chart.radius = am4core.percent(70);
        chart.logo.disabled = true;
        pieSeries.alignLabels = false;
        pieSeries.colors.list = listPieSeriesColor();

        return () => {
            chart.dispose();
        };
    }
    const { t, i18n } = useTranslation();

    useLayoutEffect(() => {
        if (!currency) return;
        renderBarChart(barChartData);
        renderPieChart(pieChartData);

    }, [pieChartData, barChartData, currency]);

    const [startDateLocal, setStartDateLocal] = useState(startDate);
    const handleChangeStartDateView = (e) => {
        const { value } = e.target;
        const newDate = new Date(value);
        setStartDateLocal(newDate.toISOString().split('T')[0]);
    }

    const [endDateLoacal, setEndDateLoacal] = useState(endDate);
    const handleChangeEndDateView = (e) => {
        const { value } = e.target;
        const newDate = new Date(value);
        setEndDateLoacal(newDate.toISOString().split('T')[0]);
    }

    return (
        <div>
            <div className='grid grid-cols-1 gap-2'>
                <div className='grid grid-cols-6 gap-2'>
                    <div />
                    <div />
                    <div>
                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t("contract.start_date")}
                        </InputLabel>
                        <TextField
                            onChange={(e) => handleChangeStartDateView(e)}
                            onBlur={(e) => handleChangeStartDate(e)}
                            value={startDateLocal}
                            style={{
                                width: '100%',
                            }}
                            name='contentStartDate'
                            id="datetime-local"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            size='small'
                            variant='outlined'
                            inputProps={{
                                style: {
                                    padding: '10px',
                                    fontSize: 11,
                                }
                            }}
                        />
                    </div>
                    <div>
                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t("contract.end_date")}
                        </InputLabel>
                        <TextField
                            value={endDateLoacal}
                            onChange={(e) => handleChangeEndDateView(e)}
                            onBlur={(e) => handleChangeEndDate(e)}
                            style={{
                                // marginTop: '0.5rem',
                                width: '100%'
                            }}
                            name='contentEndDate'
                            id="datetime-local"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            size='medium'
                            variant='outlined'
                            inputProps={{
                                style: {
                                    padding: '10px',
                                    fontSize: 11,
                                }
                            }}
                        />
                    </div>

                    <div className="flex justify-end" style={{ marginTop: '15px' }}>
                        <Switch
                            onChange={handleChangeBarChartView}
                            checked={barChartViewMode}
                            style={{ color: '#ae323c', alignItems: 'center' }}
                            name="barChartViewMode"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </div>
                    <div className="flex justify-start"
                        style={{ fontSize: 'small', alignItems: 'center', marginTop: '15px' }}>
                        {barChartViewMode ? t("royalties.revenue") : t("royalties.views")}
                    </div>
                    <div />
                </div>
            </div>
            <div className="flex mt-8">
                <div id="chartdiv" className="w-1/3" style={{ height: "350px" }}></div>
                <div id="barchartdiv" className="w-2/3" style={{ height: barChartHeight }}></div>
            </div>
        </div >
    );
};

export default ChartSection;
