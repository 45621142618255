import axios from 'axios';
import {authActions, authSelectors} from "../state/modules/auth";
import {getStore} from '../state/configureStore/index';

let instance;

export default instance = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_API_URL ? process.env.REACT_APP_SERVER_API_URL : 'http://localhost:8089'}${process.env.REACT_APP_SERVER_API_PREFIX ? process.env.REACT_APP_SERVER_API_PREFIX : '/api'}`
});

instance.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            let isSessionExpired = getStore().getState().get('auth').get('isSessionExpired');
            if (!isSessionExpired || (isSessionExpired && isSessionExpired.get('sessionExpired') !== '1')) {
                getStore().dispatch(authActions.sessionExpired({sessionExpired: '1'}));
            }
        }
    return Promise.reject(error);
});
