import React from 'react';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const AscendingIcon = () => {
        return (
            <ArrowDropUpIcon />
        );
};

export default AscendingIcon;
