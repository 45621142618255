import LIST_COUNTRY_CODE from "./ListCountryCode";

const GET_ROLES = (t) => ([
    {
        id: 1,
        name: t('constant.buyer')
    },
    {
        id: 2,
        name: t('constant.seller')
    },
    {
        id: 3,
        name: t('constant.representative')
    },
    {
        id: 4,
        name: t('constant.delegate')
    },
    {
        id: 5,
        name: t('constant.finance')
    },
]);

const GET_SYSTEM_ROLES = (t) => ([
    {
        id: "Super Admin",
        name: t('constant.super_admin')
    },
    {
        id: "Organization Admin",
        name: t('constant.organization_admin')
    },
    {
        id: "Content Manager",
        name: t('constant.content_manager')
    },
    {
        id: "Licensing Manager",
        name: t('constant.licensing_manager')
    },
    {
        id: "Analyst",
        name: t('constant.analyst')
    },
]);

const GET_DEVICES = (t) => ([
    {
        id: 1,
        name: t('constant.desktop'),
        default: true
    },
    {
        id: 2,
        name:  t('constant.Mobile')
    },
    {
        id: 3,
        name:  t('constant.tv')
    },
    {
        id: 4,
        name:  t('constant.console')
    },
    {
        id: 5,
        name:  t('constant.stb')
    }
]);

const GET_BUSINESSTYPES = (t) => ([
    {
        id: 1,
        value: 'PROD',
        description: t('constant.Producer')
    },
    {
        id: 2,
        value: 'DIST',
        description: t('constant.distributor'),
        default: true
    },
    {
        id: 3,
        value: 'STD',
        description: t('constant.studio')
    },
    {
        id: 4,
        value: 'FEST',
        description: t('constant.festival')
    },
    {
        id: 5,
        value: 'TVC',
        description: t('constant.tv_channel')
    },
    {
        id: 6,
        value: 'INDE',
        description: t('constant.independent')
    },
    {
        id: 7,
        value: 'CS',
        description: t('constant.collection_society')
    },
    {
        id: 8,
        value: 'UNION',
        description: t('constant.union')
    },
    {
        id: 9,
        value: 'OTT',
        description: t('constant.ott_platform')
    },
    {
        id: 10,
        value: 'FAST',
        description: t('constant.fast_channel')
    },
    {
        id: 11,
        value: 'IPTV',
        description: t('constant.iptv_channel')
    }
]);

const GET_REPORTINGTOOLS = (t) => ([
    {
        id: 1,
        value: t('constant.direct_player')
    },
    {
        id: 2,
        value: t('constant.direct_api')
    },
    {
        id: 3,
        value: t('constant.indirect_npaw')
    },
    {
        id: 4,
        value: t('constant.indirect_okast')
    },
    {
        id: 5,
        value: t('constant.raw_file'),
        default: true
    }
]);

const CURRENCY = [
    {
        id: 1,
        value: 'EUR',
        default: true
    },
    {
        id: 2,
        value: 'USD'
    },
    {
        id: 3,
        value: 'GBP'
    },
    {
        id: 4,
        value: 'RMB'
    },
    {
        id: 5,
        value: 'JPY'
    },
    {
        id: 6,
        value: 'CHF'
    }
];

const GET_ENTITIES = (t) => ([
    {
        id: 1,
        value_creation: t('constant.video_file'),
        value_view: 'MovieCreation',
        default: true
    },
    {
        id: 2,
        value_creation: t('constant.live_stream'),
        value_view: 'Videocam'
    },
    {
        id: 3,
        value_creation: t('constant.tv_channel'),
        value_view: 'OndemandVideo'
    },
    {
        id: 4,
        value_creation: t('constant.podcast'),
        value_view: 'Audiotrack'
    }
]);

const EXTRA_FORMAT = [
    {
        id: 0,
        value_creation: 'HD',
        value_view: 'HD',
        default: true
    },
    {
        id: 1,
        value_creation: 'SD',
        value_view: 'SD'
    },
    {
        id: 2,
        value_creation: '4K',
        value_view: '4K'
    }
];

const GET_BUSSINESS_MODELS = (t) => ([
    {
        id: 1,
        value: 'FTA',
        description: t('constant.free_to_air')
    },
    {
        id: 2,
        value: 'PTV',
        description: t('constant.pay_tv')
    },
    {
        id: 3,
        value: 'EST',
        description: t('constant.electronic_sell_through')
    },
    {
        id: 4,
        value: 'TVOD',
        description: t('constant.transaction_vod')
    },
    {
        id: 5,
        value: 'SVOD',
        description: t('constant.subscription_vod'),
        default: true
    },
    {
        id: 6,
        value: 'AVOD',
        description: t('constant.advertised_vod')
    }
]);

const GET_STATUS = (t) => ([
    {
        id: 0,
        value: 'rejected',
        display: t('constant.rejected'),
        color: 'black'
    },
    {
        id: 1,
        value: 'started',
        display: t('constant.started'),
        color: 'black',
        default: true
    },
    {
        id: 2,
        value: 'pending',
        display: t('constant.pending'),
        color: 'grey'
    },
    {
        id: 3,
        value: 'running',
        display: t('constant.running'),
        color: 'green'
    },
    {
        id: 4,
        value: 'ended',
        display: t('constant.ended'),
        color: 'grey'
    },
    {
        id: 5,
        value: 'infringed',
        display: t('constant.infringed'),
        color: 'red'
    },
    {
        id: 6,
        value: 'revoked',
        display: t('constant.revoked'),
        color: 'red'
    },
]);

const GET_CONTENT_STATUS = (t) => ([
    {
        id: 1,
        color: 'green',
        value: t('constant.available'),
    },
    {
        id: 2,
        color: 'black',
        value: t('constant.new'),
        default: true,
    },
    {
        id: 3,
        color: 'red',
        value: t('constant.blocked'),
        disableUI: true,
    },
    {
        id: 4,
        color: 'gray',
        value: t('constant.private'),
    }
]);

const GET_PARTNER_STATUS = (t) => ([
    {
        id: 1,
        color: 'black',
        value:  t('constant.Disconnected'),
    },
    {
        id: 2,
        color: 'green',
        value:  t('constant.Connected'),
    },
    {
        id: 0,
        color: 'grey',
        value:  t('constant.pending'),
    }
]);

const GET_EVALUATED_OPTIONS = (t) => ([
    {
        id: 1,
        value: t('constant.per_duration'),
        default: true
    }
]);

const GET_VIEWER_SESSION = (t) => ([
    {
        id: 1,
        value: t('constant.value_total_duration'),
        unit: t('constant.unit_total_duration')
    },
    {
        id: 2,
        value: t('constant.value_seconds'),
        unit: t('constant.unit_seconds'),
        default: true
    }
]);

const GET_PRICING = (t) => ([
    {
        id: 1,
        value: t('constant.flat'),
        default: true
        // unit: '% total duration'
    },
    {
        id: 2,
        // unit: 'seconds',
        value: t('constant.ladder'),
    }
]);

const GET_CALCULATION = (t) => ([
    {
        id: 1,
        value: t('constant.contract_lifespan'),
        default: true
    },
    {
        id: 2,
        value: t('constant.every_month'),
    },
    {
        id: 3,
        value: t('constant.every_year'),
    },
]);

const GET_MG_APPLIED = (t) => ([
    {
        id: 1,
        value: t('constant.none'),
        default: true
        // unit: '% total duration'
    },
    {
        id: 2,
        // unit: 'seconds',
        value: t('constant.threshold'),
    },
    {
        id: 3,
        // unit: 'seconds',
        value: t('constant.fixed'),
    }
]);

const GET_AUTHORIZATION_ROLES = (t) => ([
    {
        id: 0,
        value: t('constant.user'),
        identification: 'User',
        default: true
    },
    {
        id: 1,
        identification: 'Super Admin',
        value: t('constant.super_admin')
    },
    {
        id: 2,
        identification: 'Admin Per Partner',
        value: t('constant.admin_per_partner'),
    }
]);

const LIST_COLOR_CHART = ['#E30B5C', '#FF0000', '#C71585', '#FF007F', '#E0115F', '#FA8072', '#92000A', '#C0C0C0', '#D2B48C', '#E52B50', 
    '#FFBF00', '#9966CC', '#FBCEB1', '#7FFFD4', '#89CFF0', '#CB4154', '#000000', '#0000FF', '#0095B6', '#CC7722', '#FF4500', '#B57EDC',
    '#DE5D83', '#CD7F32', '#993300', '#800020', '#702963', '#DE3163', '#007BA7', '#F7E7CE', '#7FFF00', '#7B3F00', '#0047AB', '#B87333',
    '#FF7F50', '#DC143C', '#00FFFF', '#EDC9Af', '#50C878', '#00FF3F', '#FFD700', '#BEBEBE', '#008001', '#4B0082', '#00A86B'];


const { REACT_APP_SERVER_API_URL = 'http://localhost:8089' } = process.env;
const FETCHING_URL = `${REACT_APP_SERVER_API_URL}/uploads/`;
export {
    GET_ROLES,
    GET_BUSINESSTYPES,
    GET_REPORTINGTOOLS,
    CURRENCY,
    GET_ENTITIES,
    GET_BUSSINESS_MODELS,
    GET_STATUS,
    GET_CONTENT_STATUS,
    FETCHING_URL,
    GET_PARTNER_STATUS,
    EXTRA_FORMAT,
    GET_DEVICES,
    GET_EVALUATED_OPTIONS,
    GET_VIEWER_SESSION,
    GET_PRICING,
    GET_CALCULATION,
    GET_MG_APPLIED,
    GET_AUTHORIZATION_ROLES,
    GET_SYSTEM_ROLES,
    LIST_COLOR_CHART,
    LIST_COUNTRY_CODE
}
