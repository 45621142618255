import React, { useEffect } from 'react';
import loadingImage from "../../../../assets/images/empty-state-charts.svg";
import { useTranslation } from 'react-i18next';
const LoadingLayout = ({ messageKey }) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('language'));
    }, []);
    return (
        <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -150,
            marginLeft: -150,
            width: 500,
            height: 300,
            textAlign: 'center'
        }}>
            <img src={loadingImage} alt="loading" style={{ margin: 'auto', marginBottom: 15 }} />
            {messageKey ? t(messageKey) : ''}
        </div>
    );
}

export default LoadingLayout;
