import React, { useEffect, useState, useRef, Fragment, useMemo } from 'react';
import Layout from '../../../layouts/Default';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Autocomplete from "@material-ui/lab/Autocomplete";
import './index.css';
import {
    GET_BUSINESSTYPES,
    GET_REPORTINGTOOLS,
    CURRENCY
} from '../../../../constants';
import { useSnackbar, withSnackbar } from 'notistack';
import { authActions, authSelectors } from '../../../../state/modules/auth';
import { DropzoneArea } from 'material-ui-dropzone';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import axios from "../../../../config/axios";
import LoadingLayout from "../../../components/Common/LoadingLayout";
import { useTranslation } from 'react-i18next';
function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const useStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px",
        },
        "& .MuiOutlinedInput-input": {
            padding: '0',
        }
    }
});

const useNoBorderName = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        }
    }
});

const AddOrganization = () => {
    const classes = useStyles();
    const nameClasses = useNoBorderName();
    const { t, i18n } = useTranslation();
    const BUSINESSTYPES = useMemo(() => GET_BUSINESSTYPES(t), [t]);
    const REPORTINGTOOLS = useMemo(() => GET_REPORTINGTOOLS(t), [t]);
    const selector = useSelector((state) => authSelectors.user(state));

    const [user, setUserTracking] = useState(selector);
    const [countries, setCountries] = useState(null);
    const [validName, setValidName] = useState(true);
    const [reportingTool, setReportingTool] = useState(null);
    const [isImagePreviewed, setIsImagePreviewed] = React.useState(false);
    const [appendPictureFile, setAppendPictureFile] = React.useState(null);
    const [previewImage, setPreviewImage] = React.useState(null);
    const [isPictureHovering, setIsPictureHovering] = React.useState(false);

    const prevData = usePrevious(selector);

    const orgAddingInit = {
        name: "",
        businessType: 2,
        countryCorp: 250,
        city: "",
        address: "",
        zipCode: "",
        mainCurrency: 1,
        reportingTool: 5,
        logo: null,
        status: 0,
        foreignKey: "",
        comments: "",
        smartListForeignKey: "",
        webLink: "",
        VATCode: ""
    };

    const [newOrg, setNewOrg] = useState(orgAddingInit);

    useEffect(() => {
        (async function getListCountry() {
            const listCountryResponse = await axios.get('/country/get-list-countries', {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            const { data, status } = listCountryResponse;

            if (status === 200) {
                setCountries(data);
            }
        })();
    }, []);

    useEffect(() => {
        setUserTracking(selector);
        if (!user) return;
        setNewOrg({
            ...newOrg,
            reportingToolParams: user.get('reportingToolDetailId')
        });
    }, [selector, prevData]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(authActions.getProfile({ id: localStorage.getItem('id') }));
    }, []);

    const { enqueueSnackbar } = useSnackbar();

    const handleCreateNewOrg = async () => {
        if (!newOrg.name || newOrg.name === '') {
            enqueueSnackbar(t('organization.org_require'), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            return;
        }

        try {
            const response = await axios.post('/org', newOrg, {
                headers: {
                    common: {
                        'x-access-token': localStorage.getItem('token')
                    },
                }
            });

            if (response.status === 200) {
                enqueueSnackbar(t('organization.add_org_success'), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });
                if (response.data.record.uploadPictureUrl) {
                    const urlToUpload = response.data.record.uploadPictureUrl;
                    const options = { headers: { 'Content-Type': appendPictureFile.type } };
                    const uploadLogo = await axios.put(urlToUpload, appendPictureFile, options);
                }
                window.location.href = '/business';
            } else {
                enqueueSnackbar(t('notify.something_wrong'), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }
        } catch (e) {
            enqueueSnackbar(t('notify.something_wrong'), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
    }

    const onMouseEnterHandler = (e) => {
        setIsPictureHovering(true)
    };

    const onMouseLeaveHandler = (e) => {
        setIsPictureHovering(false)
    };

    const handleChangeInputBusinessType = (e, value) => {
        setNewOrg({
            ...newOrg,
            businessType: value.id
        });
    };

    const handleChangeInputCurrency = (e, value) => {
        setNewOrg({
            ...newOrg,
            mainCurrency: value.id
        });
    };

    const handleChangeInputReportingTool = (e, value) => {
        setNewOrg({
            ...newOrg,
            reportingTool: value.id
        });

        setReportingTool(value.id);
    };

    const handleChangeCountry = (e, value) => {
        setNewOrg({
            ...newOrg,
            countryCorp: value.code
        });
    }

    const handleValidateName = (e) => {
        const name = e.target.value;
        if (!name) {
            setValidName(false);
        } else {
            setValidName(true);
        }
    };

    const handleChangeInput = (e) => {
        const { name, value } = e.target;

        setNewOrg({
            ...newOrg,
            [name]: value
        });
    }

    const uploadLogo = (files) => {
        if (files.length === 0) {
            setAppendPictureFile(null);
            setNewOrg({
                ...newOrg,
                logo: '',
                contentType: ''
            });
            return;
        }
        setAppendPictureFile(files[0]);
        setIsImagePreviewed(true);
        setPreviewImage(URL.createObjectURL(files[0]));

        setNewOrg({
            ...newOrg,
            logo: files[0].name,
            contentType: files[0].type
        });
    }

    const handleRemovePreviewPicture = () => {
        setAppendPictureFile(null);
        setNewOrg({
            ...newOrg,
            logo: '',
            contentType: ''
        });
        setIsImagePreviewed(false);
    }

    return user ? (
        <Layout title="New Organization" highlightedItem={5}>
            <div className='flex justify-between content-center'>
                <div>
                    {t("organization.org")} > {t("organization.new")}
                    <p style={{ fontSize: '24px' }}>
                        {t("organization.create_new_org")}
                    </p>
                </div>
                <div className='flex' style={{
                    height: '36px'
                }}>
                    <Button
                        onClick={() => handleCreateNewOrg()}
                        style={{
                            height: '100%',
                            padding: '6px 16px',
                            backgroundColor: '#D05E5A',
                            marginLeft: '0.5rem',
                            color: 'white',
                            fontSize: '0.75rem'
                        }}>
                        {t('common.save')}
                    </Button>
                </div>
            </div>

            <div className='profile-content bg-white p-4' style={{ marginTop: '10px' }}>
                <div className='profile-info-organization mt-8'>
                    <Grid container spacing={3}>
                        <Grid item xs={9}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <FormControl className='w-full'>
                                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                            {t("organization.org")}
                                        </InputLabel>
                                        <TextField
                                            id='add-org-name'
                                            className={nameClasses.root}
                                            style={{ width: '100%', marginTop: '24px' }}
                                            variant="outlined"
                                            maxLength="255"
                                            name='name'
                                            type="text"
                                            error={!validName}
                                            onBlur={handleValidateName}
                                            autoComplete="off"
                                            helperText={validName ? '' : 'Organization must be required'}
                                            onChange={handleChangeInput}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl className='w-full'>
                                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                            {t("organization.business_type")}
                                        </InputLabel>
                                        <Autocomplete
                                            classes={{
                                                root: classes.root
                                            }}
                                            style={{
                                                width: '100%',
                                                borderRadius: 4,
                                                position: 'relative',
                                                marginTop: '24px',
                                                fontSize: 16,
                                            }}
                                            id="business-type"
                                            name="businessType"
                                            onChange={handleChangeInputBusinessType}
                                            size='small'
                                            defaultValue={BUSINESSTYPES.find(b => b.default)}
                                            disableClearable
                                            className='mt-2'
                                            options={BUSINESSTYPES}
                                            getOptionLabel={(ev) => ev.description}
                                            renderOption={options => {
                                                return (
                                                    <Fragment>
                                                        <p className='ml-2'>{options.description}</p>
                                                    </Fragment>
                                                )
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" InputProps={{
                                                ...params.InputProps,
                                            }} />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl className='w-full'>
                                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                            {t("organization.currency")}
                                        </InputLabel>
                                        <Autocomplete
                                            classes={{
                                                root: classes.root
                                            }}
                                            style={{
                                                width: '100%',
                                                borderRadius: 4,
                                                position: 'relative',
                                                marginTop: '24px',
                                                fontSize: 16,
                                            }}
                                            id="main-currency"
                                            onChange={handleChangeInputCurrency}
                                            name='mainCurrency'
                                            size='small'
                                            defaultValue={CURRENCY.find(ev => ev.default)}
                                            disableClearable
                                            className='mt-2'
                                            options={CURRENCY}
                                            getOptionLabel={(ev) => ev.value}
                                            renderOption={options => {
                                                return (
                                                    <Fragment>
                                                        <p className='ml-2'>{options.value}</p>
                                                    </Fragment>
                                                )
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" InputProps={{
                                                ...params.InputProps,
                                            }} />}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <FormControl className='w-full'>
                                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                            {t("organization.reporting_tool")}
                                        </InputLabel>
                                        <Autocomplete
                                            classes={{
                                                root: classes.root
                                            }}
                                            style={{
                                                width: '100%',
                                                borderRadius: 4,
                                                position: 'relative',
                                                marginTop: '24px',
                                                fontSize: 16,
                                            }}
                                            id="reporting-tool"
                                            onChange={handleChangeInputReportingTool}
                                            name='reportingTool'
                                            size='small'
                                            defaultValue={REPORTINGTOOLS.find(ev => ev.default)}
                                            disableClearable
                                            className='mt-2'
                                            options={REPORTINGTOOLS}
                                            getOptionLabel={(ev) => ev.value}
                                            renderOption={options => {
                                                return (
                                                    <Fragment>
                                                        <p className='ml-2'>{options.value}</p>
                                                    </Fragment>
                                                )
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" InputProps={{
                                                ...params.InputProps,
                                            }} />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    {reportingTool === 3 || reportingTool === 4 ?
                                        <FormControl className='w-full'>
                                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                {t("organization.org_key")}
                                            </InputLabel>
                                            <TextField
                                                id='add-org-foreignKey'
                                                className={nameClasses.root}
                                                style={{ width: '100%', marginTop: '24px' }}
                                                variant="outlined"
                                                maxLength="255"
                                                name='foreignKey'
                                                type="text"
                                                autoComplete="off"
                                                onChange={handleChangeInput}
                                            />
                                        </FormControl>
                                        : null}
                                </Grid>
                                <Grid item xs={4}>
                                    {reportingTool === 3 || reportingTool === 4 ?
                                        <FormControl className='w-full'>
                                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                {t("organization.smartList_key")}
                                            </InputLabel>
                                            <TextField
                                                id='add-org-smartListForeignKey'
                                                className={nameClasses.root}
                                                style={{ width: '100%', marginTop: '24px' }}
                                                variant="outlined"
                                                maxLength="255"
                                                name='smartListForeignKey'
                                                type="text"
                                                autoComplete="off"
                                                onChange={handleChangeInput}
                                            />
                                        </FormControl>
                                        : null}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {
                                !isImagePreviewed ? <DropzoneArea
                                    filesLimit={1}
                                    acceptedFiles={['image/*']}
                                    dropzoneText={t("common.drag_drop")}
                                    onChange={uploadLogo}
                                    onDelete={uploadLogo}
                                /> : <div>
                                    <img
                                        onMouseEnter={onMouseEnterHandler}
                                        onMouseLeave={onMouseLeaveHandler}
                                        onClick={() => handleRemovePreviewPicture()}
                                        src={previewImage}
                                        style={{
                                            height: '215px',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'top center',
                                            borderRadius: '1%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundImage: isPictureHovering ? ' linear-gradient(to bottom right, #002f4b, #dc4225)' : '',
                                            filter: isPictureHovering ? 'brightness(0.6)' : ''
                                        }} alt='org-logo' />
                                    {isPictureHovering ? <DeleteRoundedIcon
                                        onMouseEnter={onMouseEnterHandler}
                                        onMouseLeave={onMouseLeaveHandler}
                                        onClick={() => handleRemovePreviewPicture()}
                                        style={{
                                            position: 'absolute',
                                            marginTop: '-120px',
                                            marginLeft: '100px',
                                            color: 'white'
                                        }}
                                    /> : null} </div>
                            }
                        </Grid>
                    </Grid>

                    <Grid container className='mt-4'>
                        <Grid item xs={9}>
                            <FormControl className='w-full'>
                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                    {t("organization.address")}
                                </InputLabel>
                                <TextField
                                    id='add-org-address'
                                    className={nameClasses.root}
                                    style={{ width: '100%', marginTop: '24px' }}
                                    variant="outlined"
                                    maxLength="255"
                                    name='address'
                                    type="text"
                                    autoComplete="off"
                                    onChange={handleChangeInput}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                        <Grid item xs={3}>
                            <FormControl className='w-full'>
                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                    {t("organization.zip_code")}
                                </InputLabel>
                                <TextField
                                    id='add-org-zipCode'
                                    className={nameClasses.root}
                                    style={{ width: '100%', marginTop: '24px' }}
                                    variant="outlined"
                                    inputProps={{ maxLength: 5 }}
                                    name='zipCode'
                                    type="text"
                                    autoComplete="off"
                                    onChange={handleChangeInput}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl className='w-full'>
                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                    {t("organization.city")}
                                </InputLabel>
                                <TextField
                                    id='add-org-city'
                                    className={nameClasses.root}
                                    style={{ width: '100%', marginTop: '24px' }}
                                    variant="outlined"
                                    maxLength="255"
                                    name='city'
                                    type="text"
                                    autoComplete="off"
                                    onChange={handleChangeInput}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl className='w-full'>
                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                    {t("organization.country")}
                                </InputLabel>
                                {
                                    countries &&
                                    <Autocomplete
                                        onChange={handleChangeCountry}
                                        defaultValue={countries ? countries.find(r => r.code === newOrg.countryCorp) : ''}
                                        options={countries}
                                        getOptionLabel={(e) => e.name_translation}
                                        style={{
                                            width: '100%',
                                            borderRadius: 4,
                                            position: 'relative',
                                            marginTop: '24px',
                                            fontSize: 16
                                        }}
                                        size='small'
                                        disableClearable
                                        className='mt-2'
                                        classes={{
                                            root: classes.root
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t('organization.add_placeholder')} />}
                                    />
                                }
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container className='mt-4'>
                        <Grid item xs={9}>
                            <FormControl className='w-full'>
                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                    {t("organization.web_link")}
                                </InputLabel>
                                <TextField
                                    id='add-org-webLink'
                                    className={nameClasses.root}
                                    style={{ width: '100%', marginTop: '24px' }}
                                    variant="outlined"
                                    maxLength="255"
                                    name='webLink'
                                    type="text"
                                    autoComplete="off"
                                    onChange={handleChangeInput}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                        <Grid item xs={3}>
                            <FormControl className='w-full'>
                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                    {t("organization.vat_code")}
                                </InputLabel>
                                <TextField
                                    id='add-org-VATCode'
                                    className={nameClasses.root}
                                    style={{ width: '100%', marginTop: '24px' }}
                                    variant="outlined"
                                    maxLength="255"
                                    name='VATCode'
                                    type="text"
                                    autoComplete="off"
                                    onChange={handleChangeInput}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                        <Grid item xs={12}>
                            <FormControl className='w-full'>
                                <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                    {t("organization.comments")}
                                </InputLabel>
                                <textarea
                                    name='comments'
                                    maxLength='3000'
                                    rows='5'
                                    style={{
                                        color: 'rgb(76 76 76)',
                                        height: '100px',
                                        marginTop: '20px',
                                        borderRadius: '4px',
                                        border: '1px solid #ced4da',
                                        backgroundColor: 'white',
                                        paddingLeft: '10px'
                                    }}
                                    onChange={handleChangeInput}>
                                </textarea>
                            </FormControl>
                        </Grid>
                    </Grid>

                </div>
            </div>
        </Layout>
    ) : <LoadingLayout />
};

export default withSnackbar(AddOrganization);
