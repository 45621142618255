import React from 'react';
import { NumericFormat } from "react-number-format";
import { showStaticNumber } from '../../../../util';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from 'react-i18next';
const TotalSection = ({ totalRoyalties, MG, flatRate, totalRevenue, currencyUnit, marketShareValue, marketSharePercentage, finalRoyalties, isWeightOff }) => {
    const { t, i18n } = useTranslation();
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#696969',
            color: '#dbdbdb',
            marginBottom: 0,
            fontSize: 14,
        },
    }))(Tooltip);
    return (
        <div>
            <div
                className='grid grid-cols-12 text-left rounded-lg items-center justify-items-center'
            >
                <div className='col-span-8 pl-2 font-semibold'>
                </div>
                <div
                    className='col-span-2 pl-2 text-sm'
                    style={{ borderTop: '2px solid #d05e5a' }}
                >
                    {t("royalties.total_royalties")}
                </div>
                <div className='col-span-2 text-sm text-right pr-2'
                    style={{ borderTop: '2px solid #d05e5a' }}
                >
                    <NumericFormat
                        value={totalRoyalties ? showStaticNumber(totalRoyalties) : t('common.zero_text')}
                        displayType={'text'}
                        thousandSeparator={t('common.thousand_separator')} 
                        decimalSeparator={t('common.decimal_separator')}
                        allowNegative={false}
                        decimalScale={2}
                    /> {currencyUnit}
                </div>
            </div >
            {isWeightOff ?
                <div
                    className='grid grid-cols-12 text-left rounded-lg items-center justify-items-center'
                >
                    <div className='col-span-8 pl-2 font-semibold'>
                    </div>
                    <div
                        className='col-span-2 pl-2 text-sm'
                        style={{ borderTop: '2px solid #d05e5a' }}
                    >
                        {t("royalties.market_share")}
                    </div>
                    <div className='col-span-2 text-sm text-right pr-2'
                        style={{ borderTop: '2px solid #d05e5a' }}
                    >
                        <LightTooltip title={(Number(marketSharePercentage)*100).toFixed(2) + '%'} placement="top">
                            <InfoIcon style={{ fontSize: 16, marginRight: 3, color: '#d05e5a' }} />
                        </LightTooltip>
                        <NumericFormat
                            value={marketShareValue ? showStaticNumber(marketShareValue) : t('common.zero_text') }
                            displayType={'text'}
                            thousandSeparator={t('common.thousand_separator')}
                            decimalSeparator={t('common.decimal_separator')}
                            decimalScale={3}
                            allowNegative={false}
                        /> {currencyUnit}
                    </div>
                </div >
                : null}

            {isWeightOff ?
                <div
                    className='grid grid-cols-12 text-left rounded-lg items-center justify-items-center'
                >
                    <div className='col-span-8 pl-2 font-semibold'>
                    </div>
                    <div
                        className='col-span-2 pl-2 text-sm'
                        style={{ borderTop: '2px solid #d05e5a' }}
                    >
                        {t("royalties.final_royalties")}
                    </div>
                    <div className='col-span-2 text-sm text-right pr-2'
                        style={{ borderTop: '2px solid #d05e5a' }}
                    >
                        <NumericFormat
                            value={finalRoyalties ? showStaticNumber(finalRoyalties) : t('common.zero_text') }
                            displayType={'text'}
                            thousandSeparator={t('common.thousand_separator')} 
                            decimalSeparator={t('common.decimal_separator')}
                            allowNegative={false}
                            decimalScale={2}
                        /> {currencyUnit}
                    </div>
                </div> : null
            }

            <div
                className='grid grid-cols-12 text-left rounded-lg items-center justify-items-center'
            >
                <div className='col-span-8 pl-2 font-semibold'>
                </div>
                {MG ? <div
                    className='col-span-2 pl-2 text-sm'
                    style={{ borderTop: '2px solid #d05e5a' }}
                >
                     {t("royalties.minimum_guaranteed")}
                </div> : <div
                    className='col-span-2 pl-2 text-sm'
                    style={{ borderTop: '1px solid #d05e5a' }}
                >
                </div>}
                {MG ? <div className='col-span-2 text-sm text-right pr-2'
                    style={{ borderTop: '2px solid #d05e5a' }}
                >
                    <NumericFormat
                        value={MG ? showStaticNumber(MG): t('common.zero_text') }
                        displayType={'text'}
                        thousandSeparator={t('common.thousand_separator')} 
                        decimalSeparator={t('common.decimal_separator')}
                        allowNegative={false}
                        decimalScale={2}
                    /> {currencyUnit}
                </div> : <div
                    className='col-span-2 pl-2 text-sm'
                    style={{ borderTop: '1px solid #d05e5a' }}
                ></div>}
            </div >
            <div
                className='grid grid-cols-12 text-left rounded-lg items-center justify-items-center'
            >
                <div className='col-span-8 pl-2 font-semibold'>
                </div>
                {flatRate ? <div
                    className='col-span-2 pl-2 text-sm'
                    style={{ borderTop: '2px solid #d05e5a' }}
                >
                     {t("royalties.flat_fee")}
                </div> : null}
                {flatRate ? <div className='col-span-2 text-sm text-right pr-2'
                    style={{ borderTop: '2px solid #d05e5a' }}
                >
                    <NumericFormat
                        value={showStaticNumber(flatRate)}
                        displayType={'text'}
                        thousandSeparator={t('common.thousand_separator')} 
                        decimalSeparator={t('common.decimal_separator')}
                        allowNegative={false}
                        decimalScale={2}
                    /> {currencyUnit}
                </div> : null}
            </div >
            <div
                className='grid grid-cols-12 text-left rounded-lg items-center justify-items-center'
            >
                <div className='col-span-8 pl-2 font-semibold'>
                </div>
                {totalRevenue ? <div
                    className='col-span-2 pl-2 text-sm font-bold'
                    style={{ borderTop: '2px solid #d05e5a' }}
                >
                    {t("royalties.total_revenue")}
                </div> : null}
                {totalRevenue ? <div className='col-span-2 text-sm text-right pr-2 font-bold'
                    style={{ borderTop: '2px solid #d05e5a' }}
                >
                    <NumericFormat
                        value={showStaticNumber(totalRevenue)}
                        displayType={'text'}
                        thousandSeparator={t('common.thousand_separator')} 
                        decimalSeparator={t('common.decimal_separator')}
                        allowNegative={false}
                        decimalScale={2}
                    /> {currencyUnit}
                </div> : null}
            </div >
        </div>
    );
};

export default TotalSection;
