import React from 'react';
import AscendingIcon from "../AscendingIcon";
import DescendingIcon from "../DescendingIcon";

const SortContentIconRender = ({sortOrder}) => {
    let asc = sortOrder === 'asc';
    return (asc ? <AscendingIcon/> : <DescendingIcon/>);
};

export default SortContentIconRender;
