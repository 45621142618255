import Profile from '../views/pages/Profile';
import Business from '../views/pages/Business';
import Login from '../views/pages/Login';
import AddContent from '../views/pages/Content/Add';
import Content from '../views/pages/Content';
import EditContent from '../views/pages/Content/Edit';
import Contract from '../views/pages/Contract';
import AddContract from '../views/pages/Contract/Add';
import EditContract from '../views/pages/Contract/Edit';
import Royalties from '../views/pages/Royalties';
import RoyaltiesDetail from '../views/pages/Royalties/Detail'
import Messages from "../views/pages/Messages";
import Dashboard from "../views/pages/Dashboard";
import ForgotPassword from "../views/pages/ForgotPassword";
import ChangePassword from "../views/pages/ChangePassword";
import Organization from "../views/admin/Organization/Add";
import AddOrganization from "../views/admin/Organization/Add";
import EditOrganization from "../views/admin/Organization/Edit";
import Synchronization from '../views/pages/Content/Synchronization';

const routers = [
    {
        path: '/login',
        component: Login,
        permission: ''
    },
    {
        path: '/profile',
        component: Profile,
        permission: 'IKAST_SETTING_VIEW'
    },
    {
        path: '/business',
        component: Business,
        permission: 'IKAST_PARTNER_VIEW'
    },
    {
        path: '/contents/catalogue/add',
        component: AddContent,
        permission: 'IKAST_CONTENT_ADD'
    },
    {
        path: '/contents',
        component: Content,
        permission: 'IKAST_CONTENT_VIEW'
    },
    {
        path: '/contents/catalogue/update/:id',
        component: EditContent,
        permission: 'IKAST_CONTENT_EDIT'
    },
    {
        path: '/contracts',
        component: Contract,
        permission: 'IKAST_CONTRACT_VIEW'
    },
    {
        path: '/contracts/add',
        component: AddContract,
        permission: 'IKAST_CONTRACT_ADD'
    },
    // {
    //     path: '/contracts/:type/:id',
    //     component: EditContract,
    //     permission: 'IKAST_CONTRACT_EDIT'
    // },
    {
        path: '/contracts/:type/:id',
        component: EditContract,
        permission: 'IKAST_CONTRACT_EDIT'
    },
    {
        path: '/royalties',
        component: Royalties,
        permission: 'IKAST_ROYALTIES_VIEW'
    },
    {
        path: '/royalties/:id',
        component: RoyaltiesDetail,
        permission: 'IKAST_ROYALTIES_VIEW'
    },
    {
        path: '/messages',
        component: Messages,
        permission: 'IKAST_MESSAGE_VIEW'
    },
    {
        path: '/dashboard',
        component: Dashboard,
        permission: 'IKAST_DASHBOARD_VIEW'
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        permission: 'IKAST_SETTING_VIEW'
    },
    {
        path: '/change-password/:id',
        component: ChangePassword,
        permission: 'IKAST_SETTING_VIEW'
    },
    {
        path: '/admin/newOrganization',
        component: AddOrganization,
        permission: 'IKAST_PARTNER_ADD'
    },
    {
        path: '/admin/organization/edit/:id',
        component: EditOrganization,
        permission: 'IKAST_PARTNER_ADD'
    },
    {
        path: '/admin/organization/view/:id',
        component: EditOrganization,
        permission: 'IKAST_PARTNER_VIEW'
    },
    {
        path: '/contents/okast-synchronize',
        component: Synchronization,
        permission: 'IKAST_CONTENT_SYNCHRONIZE'
    },
];

export default routers
