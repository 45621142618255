import React, { Fragment, useEffect, useState } from 'react';
import ContentRow from '../../Royalties/ContentRow';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from "react-number-format";
import { showStaticNumber } from '../../../../util';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import './index.css'
const renderRows = (countryCode, infringed, contractId, arrayInput, isSeller, countries, countryInfringe) => {
    if (!infringed) {
        return (
            <Fragment>
                {
                    arrayInput.map((value, key) => {
                        return (
                            <ContentRow
                                countries={countries}
                                countryCode={countryCode}
                                infringed={infringed}
                                contractId={contractId}
                                key={key}
                                royalty={value.validContentViewlog} />
                        )
                    })
                }
            </Fragment>
        )
    } else {
        return (
            <Fragment>
                {
                    arrayInput.map((value, key) => {
                        return (
                            <ContentRow
                                countries={countries}
                                infringed={infringed}
                                countryCode={countryCode}
                                countryInfringe={countryInfringe}
                                contractId={contractId}
                                key={key}
                                royalty={value.infringedContentViewlog}
                                isSeller={isSeller} />
                        )
                    })
                }
            </Fragment>
        )
    }
}

const RoyaltiesDetailSection = ({ countryCode, infringed, contractId, arrayInput, isSeller, countries, currencyUnit }) => {
    const { t, i18n } = useTranslation();
    const [dataContentCountry, setDataContentCountry] = useState([]);


    useEffect(() => {
        let dataListContent = []
        if (arrayInput && arrayInput.length > 0) {
            arrayInput.forEach(data => {
                dataListContent.push({
                    countryName: data.countryName,
                    countryCode: data.countryCode,
                    contentList: data.contentList,
                    isOpen: true
                })
            });
        }
        setDataContentCountry(dataListContent);
    }, [JSON.stringify(arrayInput)])


    const getTotalRevenueByCountry = (listData) => {
        let total = 0;
        if (listData) {
            listData.forEach(data => {
                if (data.validContentViewlog) {
                    total += parseFloat(data.validContentViewlog.revenue || 0);
                } else {
                    total += parseFloat(data.infringedContentViewlog.revenue || 0);
                }
            });
            return total
        }
    }

    const onChangeAccordion = (i) => {
        let dataListContent = JSON.parse(JSON.stringify(dataContentCountry));
        if (dataListContent[i]) {
            dataListContent[i].isOpen = !dataListContent[i].isOpen;
        }
        setDataContentCountry(dataListContent);
    }

    return (
        <div>
            <div className='grid grid-cols-12 mt-4 gap-2 justify-items-left text-left'>
                <div className='font-bold col-span-6 flex justify-left text-left' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {t("table.contents")}
                </div>
                <div className='font-bold col-span-2 text-center' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {t("table.duration")}
                </div>
                <div className='font-bold col-span-2 text-center' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {t("royalties.view_valid_view")}
                </div>
                <div className='font-bold col-span-2 text-right pr-4' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {t("table.revenue")}
                </div>
            </div>
            {dataContentCountry.length > 0 && dataContentCountry.map((item, i) => {
                return (
                    <>
                        {
                            item.contentList.length > 0 ? (
                                <div style={{ marginTop: 10 }} key={i}>
                                    <Accordion expanded={item.isOpen} onChange={() => onChangeAccordion(i)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <div className='headAccordion'>
                                                <div>{item.countryName}</div>
                                                <div>
                                                    <NumericFormat
                                                        value={getTotalRevenueByCountry(item.contentList) ? showStaticNumber(getTotalRevenueByCountry(item.contentList)) : t('common.zero_text')}
                                                        displayType={'text'}
                                                        thousandSeparator={t('common.thousand_separator')}
                                                        decimalSeparator={t('common.decimal_separator')}
                                                        decimalScale={2}
                                                        allowNegative={false}
                                                    /> {currencyUnit}</div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div style={{ width: '100%' }}>
                                                {renderRows(countryCode, infringed, contractId, item.contentList, isSeller, countries, item.countryCode)}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            ) : null
                        }
                    </>
                )
            })}
        </div >

    );
};

export default RoyaltiesDetailSection;
