import React, { useState, useMemo } from 'react';
import { CURRENCY, GET_DEVICES } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { authActions, authSelectors } from "../../../../state/modules/auth";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PostAddIcon from '@material-ui/icons/PostAdd';
import LayersClearIcon from '@material-ui/icons/LayersClear';
import Button from "@material-ui/core/Button";
import axios from "../../../../config/axios";
import { NumericFormat } from "react-number-format";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";
import { useHistory } from 'react-router-dom';
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";
import { showStaticNumber } from '../../../../util';
import './index.css';
import noPicture from '../../../../assets/images/noPicture.jpg';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        "& $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&:hover $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&$focused $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-input": {
            padding: '18.5px 0px',
        },
        "& .MuiInputBase-input": {
            padding: "6px 7px"
        },
        "& .MuiAutocomplete-inputRoot": {
            height: '36px',
            padding: '0 4px !important'
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        }
    },
    focused: {},
    notchedOutline: {}
}));

const useStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-root": {
            height: '36px'
        }
    }
});

const ContentRow = ({ countryCode, infringed, contractId, royalty, isSeller, countries, countryInfringe }) => {
    const { t, i18n } = useTranslation();
    const DEVICES = useMemo(() => GET_DEVICES(t), [t]);
    const classes = useOutlinedInputStyles();
    const classesText = useStyles();
    const user = useSelector((state) => authSelectors.user(state));
    // const [user, setUserTracking] = useState(selector);
    const [openViewLogModal, setOpenViewLogModal] = useState(false);
    const [openResolveInfringementModal, setOpenResolveInfringementModal] = useState(false);
    const [errNbOfView, setErrNbOfView] = useState('');
    const [errDuration, setErrDuration] = useState('');
    const [listPermission, setListPermission] = useState([]);
    // const [countries, setCountries] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#696969',
            color: '#dbdbdb',
            marginBottom: 0,
            fontSize: 14,
        },
    }))(Tooltip);

    useEffect(() => {
        if(user) {
            setListPermission(user.get('permissions').toArray());
        }
    }, [user])

    // useEffect(() => {
    //     if (localStorage.getItem('id') && localStorage.getItem('token')) {
    //         dispatch(authActions.getProfile({ id: localStorage.getItem('id') }));
    //     }
    // }, []);

    // useEffect(() => {
    //     let isMounted = true;
    //     (async function getListCountry() {
    //         const listCountryResponse = await axios.get('/country/get-list-countries', {
    //             headers: {
    //                 'x-access-token': localStorage.getItem('token')
    //             }
    //         });

    //         const { data, status } = listCountryResponse;

    //         if (status === 200) {
    //             if (isMounted) setCountries(data);

    //         }
    //     })();
    //     return () => { isMounted = false };
    // }, []);

    // useEffect(() => {
    //     setUserTracking(selector);
    // }, [selector]);

    // set startDate for the Dialog
    let startDateS = (new Date()).getFullYear() + '-'
        + ((new Date()).getMonth() + 1 >= 10 ? (new Date()).getMonth() + 1 : '0' + ((new Date()).getMonth() + 1)) + '-'
        + ((new Date()).getDate() >= 10 ? (new Date()).getDate() : '0' + (new Date()).getDate()) + 'T00:00';

    const viewLogInit = {
        contractUid: contractId,
        contentUid: royalty["contentUID"],
        nbView: 0,
        startDate: startDateS,
        duration: '00:00:00',
        countryCode: countryCode,
        device: 1,
    };

    const [newViewingLog, setNewViewingLog] = useState(viewLogInit);

    let seconds = royalty["duration"] ? Math.floor(royalty["duration"] / 1000) % 60 : 0;
    let minutes = royalty["duration"] ? Math.floor(royalty["duration"] / (1000 * 60)) % 60 : 0;
    let hours = royalty["duration"] ? Math.floor(royalty["duration"] / (1000 * 60 * 60)) % 24 : 0;

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    const uploadFile = (e, contentUID) => {
        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "viewlogCsvFile",
            e.target.files[0],
            e.target.files[0].name
        );

        const uploadFileS3 = e.target.files[0];

        // Request made to the backend api
        // Send formData object
        if (localStorage.getItem('id') && localStorage.getItem('token')) {
            (async function uploadCSVLog() {
                try {
                    //import csv log into database.
                    const userResponse = await axios.post(`/viewinglog/import-csv-viewlog/${contractId}/${contentUID}`, formData, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });

                    const { data, status } = userResponse;

                    if (status === 200) {
                        // Put the fileType in the headers for the upload.
                        // Upload csv file and store in S3.
                        var options = {
                            headers: {
                                'x-access-token': localStorage.getItem('token'),
                                'Content-Type': 'csv'
                            }
                        };
                        const uploadToS3 = await axios.put(data.preSignedUrl, uploadFileS3, options)

                        // Create import CSV Content successful message.
                        let importCSVLogMessage = {
                            "type": 12,
                            "header": "CSV file of viewing logs has been successfully imported",
                            "body": "CSV file of viewing logs has been successfully imported",
                            "from": user.get('BelongsTo'),
                            "receiver": user.get('BelongsTo'),
                        }
                        const newMessage = await axios.post('/message/', importCSVLogMessage, {
                            headers: {
                                'x-access-token': localStorage.getItem('token')
                            }
                        });
                    }
                    alert(t("royalties.import_success"));
                    window.location.reload();
                } catch (e) {
                    alert(t("royalties.import_fail"));
                    window.location.reload();
                    console.log(e);
                }
            })();
        }
    }

    const handleClickOpenViewLogModal = () => {
        setOpenViewLogModal(true);
    };

    const handleClickOpenResolveInfringementModal = () => {
        setOpenResolveInfringementModal(true);
    }

    const handleCloseResolveInfringementModal = () => {
        setOpenResolveInfringementModal(false);
    }

    const handleCloseViewLogModal = () => {
        // set startDate for the Dialog
        let startDateS = (new Date()).getFullYear() + '-'
            + ((new Date()).getMonth() + 1 >= 10 ? (new Date()).getMonth() + 1 : '0' + ((new Date()).getMonth() + 1)) + '-'
            + ((new Date()).getDate() >= 10 ? (new Date()).getDate() : '0' + (new Date()).getDate()) + 'T00:00';
        const viewLogInit = {
            contractUid: contractId,
            contentUid: royalty["contentUID"],
            nbView: 0,
            startDate: startDateS,
            duration: '00:00:00',
            countryCode: countryCode,
            device: 1,
        };
        setNewViewingLog(viewLogInit);
        setOpenViewLogModal(false);
    };

    const handleCreateNewViewingLog = async () => {
        if (newViewingLog.nbView === 0) {
            enqueueSnackbar(t("royalties.number_greater_zero"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                variant: 'error'
            });
            return;
        }

        if (newViewingLog.duration === '00:00:00') {
            enqueueSnackbar(t("royalties.duration_greater_zero"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                variant: 'error'
            });
            return;
        }

        if (newViewingLog.countryCode === '') {
            enqueueSnackbar(t("royalties.country_require"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                variant: 'error'
            });
            return;
        }

        try {
            const response = await axios.post('/viewinglog', newViewingLog, {
                headers: {
                    common: {
                        'x-access-token': localStorage.getItem('token')
                    },
                }
            });

            if (response.status === 200) {
                enqueueSnackbar(t("royalties.add_view_log_success"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });

                handleCloseViewLogModal();
                window.location.reload();
            } else {
                enqueueSnackbar(t("notify.something_wrong"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
                handleCloseViewLogModal();
            }
        } catch (e) {
            enqueueSnackbar(t("notify.something_wrong"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            handleCloseViewLogModal();
        }
    }

    const handleChangeCountry = (e, value) => {
        setNewViewingLog({
            ...newViewingLog,
            countryCode: value.alpha2
        })
    };

    const handleChangeDuration = (e) => {
        const { name, value } = e.target;
        setNewViewingLog({
            ...newViewingLog,
            [name]: value
        });
    }

    const handleChangeDevice = (e, value) => {
        setNewViewingLog({
            ...newViewingLog,
            device: value.id
        });
    };

    const handleChangeDate = (e) => {
        const { name, value } = e.target;
        const now = new Date();
        const selectedDate = new Date(value);
        if (now < selectedDate) {
            enqueueSnackbar(t("royalties.start_date_not_future"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                variant: 'error'
            });
        } else {
            setNewViewingLog({
                ...newViewingLog,
                [name]: value
            });
        }
    }

    const validateNbOfView = (e) => {
        const val = e.target.value;
        if (Number(val) <= 0) {
            setErrNbOfView(t("royalties.number_greater_zero"));
        } else if (val === '') {
            setErrNbOfView(t("royalties.number_views_require"));
        } else {
            setErrNbOfView('');
        }
    };

    const handleChangeNbOfView = (e) => {
        const { name, value } = e.target;
        setNewViewingLog({
            ...newViewingLog,
            [name]: Number(value)
        });
    }

    const handleDurationBlur = (e) => {
        let val = e.target.value;

        if (!val.match(/^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/)) {
            val = '00:00:00';
            setNewViewingLog({
                ...newViewingLog,
                duration: val
            });
            setErrDuration(t("royalties.invalid_duration"));
            enqueueSnackbar(t("royalties.invalid_duration"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                variant: 'error'
            });
        } else {
            setErrDuration('');
        }
    };

    const handleResolveInfringementLogs = async () => {
        try {
            const response = await axios.delete(`/resolve-infringement/${contractId}/${royalty["contentUID"]}/${countryInfringe}`, {
                headers: {
                    common: {
                        'x-access-token': localStorage.getItem('token')
                    },
                }
            });

            if (response.status === 200) {
                enqueueSnackbar(t("royalties.resolve_infring_success"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });

                handleCloseViewLogModal();
                window.location.reload();
            } else {
                enqueueSnackbar(t("notify.something_wrong"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
                handleCloseResolveInfringementModal();
            }
        } catch (e) {
            enqueueSnackbar(t("notify.something_wrong"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            handleCloseResolveInfringementModal();
        }
    };
    const history = useHistory();
    const showContent = (contentUID) => {
        history.push("/contents/catalogue/update/" + contentUID);
    }

    const validLogsTooltip = (+royalty["viewLogCount"] + royalty["invalidViewLogCount"]) ? (+royalty["viewLogCount"]/(+royalty["viewLogCount"] + +royalty["invalidViewLogCount"])*100) < 100 ? (+royalty["viewLogCount"]/(+royalty["viewLogCount"] + +royalty["invalidViewLogCount"])*100).toFixed(2) +'% valid view logs' : 100 + '% valid view logs' : '0% valid view logs'

    return (
        <div
            className='grid grid-cols-12 gap-2 text-left mt-3 pl-2 rounded-lg items-center justify-items-center py-2' style={{
                background: 'rgb(242, 242, 242)',
            }}>
            {infringed ?
                <div
                    className='col-span-6 pl-2 font-semibold flex align-middle'
                    style={{ display: 'flex', alignItems: 'center' }}>
                    {listPermission.includes("IKAST_ROYALTIES_RESOLVE_INFRINGE") ? 
                        <LightTooltip title={t("royalties.resolve_infringement")} placement="top">
                            <Button
                                className='no-border-button'
                                style={{ boxShadow: 'none', display: 'flex', justifyContent: 'center' }}>
                                <LayersClearIcon style={{ color: 'rgb(208, 94, 90)' }} onClick={() => handleClickOpenResolveInfringementModal()} />
                            </Button>
                        </LightTooltip>
                    : null}
                    <img alt="" src={royalty["illustrationUrlS3"] ? royalty["illustrationUrlS3"] : noPicture} style={{ height: 30, width: 'auto', marginRight: 10 }} />
                    <span className='hover_click' onClick={() => showContent(royalty["contentUID"])}>{royalty["title"] ? royalty["title"] : ""}</span>
                </div> : ''}

            {!infringed && user && user.getIn(['org', 'reportingTool']) === 5 ?
                <div className='col-span-6 pl-2 font-semibold flex align-middle' style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        className='no-border-button'
                        style={{ boxShadow: 'none', display: 'flex', justifyContent: 'center' }}>
                        <PostAddIcon style={{ color: 'rgb(208, 94, 90)' }} onClick={() => handleClickOpenViewLogModal()} />
                    </Button>

                    <input
                        accept="text/csv/*"
                        id={"contained-button-file-" + royalty["contentUID"]}
                        multiple
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => uploadFile(e, royalty["contentUID"])}
                    />
                    <label htmlFor={"contained-button-file-" + royalty["contentUID"]}>
                        <Button
                            component="span"
                            className='no-border-button'
                            style={{ boxShadow: 'none', display: 'flex', justifyContent: 'center' }}>
                            <CloudUploadIcon style={{ color: 'rgb(208, 94, 90)' }} />
                        </Button>
                    </label>
                    <img alt="" src={royalty["illustrationUrlS3"] ? royalty["illustrationUrlS3"] : noPicture} style={{ height: 30, width: 'auto', marginRight: 10 }} />
                    <span className='hover_click' onClick={() => showContent(royalty["contentUID"])}>{royalty["title"] ? royalty["title"] : ""}</span>
                </div> : ''}

             {(!infringed || !isSeller) && (infringed || !(user && user.getIn(['org', 'reportingTool']) === 5)) ?
                <div className='col-span-6 pl-2 font-semibold flex align-middle'
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <img alt="" src={royalty["illustrationUrlS3"] ? royalty["illustrationUrlS3"] : noPicture} style={{ height: 30, width: 'auto', marginRight: 10 }} />
                    <span className='hover_click' onClick={() => showContent(royalty["contentUID"])}>{royalty["title"] ? royalty["title"] : ""}</span>
                </div> : ''
            }

            <div className='col-span-2 text-center'>
                {`${hours}:${minutes}:${seconds}`}
            </div>
            <LightTooltip title={validLogsTooltip ? validLogsTooltip : ''} placement="top">
            <div className='col-span-2 text-center'>
                { +royalty["viewLogCount"] + +royalty["invalidViewLogCount"] } / { royalty["viewLogCount"] ? royalty["viewLogCount"] : 0}
            </div>
            </LightTooltip>
            <div className='col-span-2 text-right pr-4'>
                <NumericFormat
                    value={royalty["revenue"] ?  showStaticNumber(royalty["revenue"]) : t('common.zero_text')}
                    displayType={'text'}
                    thousandSeparator={t('common.thousand_separator')} 
                    decimalSeparator={t('common.decimal_separator')}
                    allowNegative={false}
                />
                <span style={{ color: 'rgb(113, 111, 111)', fontSize: '13px' }}> {CURRENCY.find(c => c.id.toString() === localStorage.getItem('mainCurrency')).value}</span>
            </div>

            <Dialog
                open={openViewLogModal}
                onClose={() => handleCloseViewLogModal()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id={royalty["title"]} style={{ textAlign: 'center' }}>{"ADD NEW VIEWING LOG"}</DialogTitle>
                <DialogContent>
                    <div style={{ padding: '0 25px' }}>
                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)', marginBottom: '0.5rem' }} className='my-4'>
                        {t("royalties.number_view")}
                        </InputLabel>
                        <OutlinedInput
                            name='nbView'
                            className='w-full'
                            id="outlined-size-small1"
                            style={{ height: '36px', background: 'white', width: '220px' }}
                            classes={classes}
                            value={newViewingLog.nbView}
                            inputProps={{
                                autoComplete: "off",
                                min: 0
                            }}
                            type='number'
                            error={!!errNbOfView}
                            onBlur={validateNbOfView}
                            onChange={handleChangeNbOfView}
                        />
                    </div>
                    <div style={{ padding: '0 25px' }}>
                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)', marginBottom: '0.5rem' }} className='my-4'>
                        {t("contract.start_date")}
                        </InputLabel>
                        <TextField
                            className={classesText.root}
                            onChange={handleChangeDate}
                            style={{
                                width: '220px',
                            }}
                            value={newViewingLog.startDate}
                            name='startDate'
                            id="datetime-local"
                            type="datetime-local"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            size='small'
                            variant='outlined'
                            inputProps={{
                                style: {
                                    padding: '10px',
                                }
                            }}
                        />
                    </div>
                    <div style={{ padding: '0 25px' }}>
                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)', marginBottom: '0.5rem' }} className='my-4'>
                        {t("table.duration")}
                        </InputLabel>
                        <OutlinedInput
                            name='duration'
                            className='w-full'
                            id="outlined-size-small3"
                            classes={classes}
                            value={newViewingLog.duration}
                            inputProps={{
                                autoComplete: "off"
                            }}
                            style={{
                                height: '36px',
                                width: '220px',
                            }}
                            error={!!errDuration}
                            onBlur={handleDurationBlur}
                            onChange={handleChangeDuration}
                        />
                    </div>
                    <div style={{ padding: '0 25px' }}>
                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)', marginBottom: '0.5rem' }} className='my-4'>
                        {t("royalties.device")}
                        </InputLabel>
                        <Autocomplete
                            name='device'
                            onChange={handleChangeDevice}
                            disableClearable
                            value={DEVICES.find(r => r.id === newViewingLog.device)}
                            className='mt-2 autocomplete-role'
                            classes={{
                                root: classes.root
                            }}
                            style={{ height: '36px', background: 'white', width: '220px' }}
                            getOptionLabel={(role) => role.name}
                            options={DEVICES}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                    </div>
                    <div style={{ padding: '15px 25px' }}>
                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)', marginBottom: '0.5rem' }}>
                        {t("royalties.country")}
                        </InputLabel>
                        {
                            countries &&
                            <Autocomplete
                                onChange={handleChangeCountry}
                                options={countries}
                                getOptionLabel={(e) => e.name_translation}
                                defaultValue={countries ? countries.find(r => r.alpha2 === countryCode) : ''}
                                style={{
                                    width: '100%'
                                }}
                                size='small'
                                disableClearable
                                className='mt-2'
                                classes={{
                                    root: classes.root
                                }}
                                renderInput={(params) => <TextField {...params} variant="outlined" placeholder='Add...' />}
                            />
                        }
                    </div>
                    <InputLabel style={{ fontSize: '13px', marginTop: '10%' }}>
                    {t("royalties.warning")}<br /><br />
                    {t("royalties.entry")}
                    </InputLabel>
                </DialogContent>
                <DialogActions>
                    <Button className="no-border-button" onClick={() => handleCloseViewLogModal()} color="primary">
                    {t("common.cancel")}
                    </Button>
                    <Button className="no-border-button" onClick={() => handleCreateNewViewingLog()} color="primary" autoFocus>
                    {t("common.confirm")}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openResolveInfringementModal}
                onClose={() => handleCloseResolveInfringementModal()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id={royalty["title"] + 'resolveInfringement'} style={{ textAlign: 'center' }}>{"CONFIRMATION"}</DialogTitle>
                <DialogContent>
                    <InputLabel style={{ fontSize: '13px' }}>
                    {t("royalties.confirm_infring", {revenue: royalty["revenue"] ? royalty["revenue"] : 0 , curency: (user && user.get('org')) && CURRENCY.find(c => c.id === user.getIn(['org', 'mainCurrency'])).value})}
                    </InputLabel>
                </DialogContent>
                <DialogActions>
                    <Button className="no-border-button" onClick={() => handleCloseResolveInfringementModal()} color="primary">
                    {t("common.cancel")}
                    </Button>
                    <Button className="no-border-button" color="primary" autoFocus onClick={() => handleResolveInfringementLogs()}>
                    {t("common.confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default ContentRow;
