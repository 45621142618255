const LOAD_EXAMPLES = 'example/LOAD_EXAMPLES';
const LOAD_EXAMPLES_SUCCESS = 'example/LOAD_EXAMPLES_SUCCESS';
const LOAD_EXAMPLES_FAIL = 'example/LOAD_EXAMPLES_FAIL';
const STORE_EXAMPLES = 'example/STORE_EXAMPLES';
const STORE_PAGINATE = 'example/STORE_PAGINATE';

export default {
    LOAD_EXAMPLES,
    LOAD_EXAMPLES_SUCCESS,
    LOAD_EXAMPLES_FAIL,
    STORE_EXAMPLES,
    STORE_PAGINATE
};
