import React, { useEffect, useState, useContext } from 'react';
import Layout from "../../../layouts/Default";
import { authSelectors } from "../../../../state/modules/auth";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "../../../../config/axios";
import { useSelector } from "react-redux";
import { CURRENCY } from '../../../../constants';
import { useParams } from 'react-router';
import Button from "@material-ui/core/Button";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import LoadingLayout from "../../../components/Common/LoadingLayout";
import { useSnackbar } from "notistack";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import Identification from './Identification';
import Revenue from './Revenue';
import RoyaltiesDetailsTab from './RoyaltiesDetails';
import './index.css';
import LoadingContext from '../../../../context/loading/LoadingContext';
import HistoryTab from '../../Contract/Edit/HistoryTab/HistoryTab';
import { showStaticNumber } from '../../../../util';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from "react-number-format";
const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        marginTop: '8px',
        height: '39.5px',
        "& $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&:hover $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&$focused $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-input": {
            padding: '18.5px 0px',
        }
    },
    focused: {},
    notchedOutline: {}
}));


const TabPanel = (props) => {
    const { children, valueTab, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={valueTab !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {valueTab === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

const RoyaltiesDetail = () => {
    const { t, i18n } = useTranslation();
    let { id } = useParams();
    const useStyle = makeStyles({
        root: {
            marginTop: '1.5rem'
        },
        moneyInput: {
            width: '120px',
        },

        selectMonetized: {
            padding: '10px',
        }
    });
    const outlinedInputClasses = useOutlinedInputStyles();
    const [contract, setContract] = useState(null);
    const [listSeller, setListSeller] = useState(null);
    const [exclusive, setExclusive] = useState(true);
    const [monetized, setMonetized] = useState(null); // set businessModel
    const [monetizedValue, setMonetizedValue] = useState(null);
    const [countries, setCountries] = useState(null);
    const [isInclude, setIsInclude] = useState(true);
    const nowDateTime = new Date();
    const [contentEndDate, setContentEndDate] = useState(nowDateTime.toISOString().split('T')[0]);
    const [contentEndDateView, setContentEndDateView] = useState(nowDateTime.toISOString().split('T')[0]);
    nowDateTime.setDate(1);
    const [contentStartDate, setContentStartDate] = useState(nowDateTime.toISOString().split('T')[0]);
    const [contentStartDateView, setContentStartDateView] = useState(nowDateTime.toISOString().split('T')[0]);
    const [pieChartData, setPieChartData] = useState(null);
    const [barChartData, setBarChartData] = useState(null);
    const [isAnyInfringedLogs, setIsAnyInfringedLogs] = useState(false);
    const [infringeContentList, setInfringeContentList] = useState(null);
    const [dataTotalRevennue, setDataTotalRevennue] = useState(null);
    const [validContentList, setValidContentList] = useState(null);
    const [totalSectionData, setTotalSectionData] = useState(null);
    const [initializePricingLadder, setInitializePricingLadder] = useState(null);
    const [viewBarChartMode, setViewBarChartMode] = useState(true);
    const [importLoadingScreen, setImportLoadingScreen] = useState(false);
    const [pdfFileName, setPdfFileName] = useState('');
    const [pdfS3, setPDFS3] = useState(null);
    const [isMarketShare, setIsMarketShare] = React.useState(false);
    const { showLoading, hideLoading } = useContext(LoadingContext);
    const [messageKey, setMessageKey] = useState('');
    const [valueTab, setValueTab] = React.useState(0);
    const [CURRENCY_UNIT, setCurrencyUnit] = React.useState(null);
    const [countryCode, setCountryCode] = React.useState(null);
    const selector = useSelector((state) => authSelectors.user(state));
    const [user, setUserTracking] = useState(selector);
    const [listPermission, setListPermission] = useState([]);

    useEffect(() => {
        setTimeout(() => { setUserTracking(selector)}, 500)
    }, [selector]);

    useEffect(() => {
        setCurrencyUnit(CURRENCY.find(c => c.id.toString() === localStorage.getItem('mainCurrency')).value);
        setCountryCode((user && user.get('countryAlpha2')) ? user.get('countryAlpha2') : "");
        if(user) {
            setListPermission(user.get('permissions').toArray());
        }
    }, [user]);
    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    };
    const classes = useStyle();

    var screenDisable = true;

    const loggedInUserOrg = user && user.Name ? user.Name : '';

    var buyerName = loggedInUserOrg;
    if (listSeller && contract) {
        const buyerOrg = listSeller.filter(oneOrg => oneOrg.ikastUID === contract.Buyer);
        buyerName = buyerOrg ? buyerOrg[0].Name : loggedInUserOrg;
    }

    const handleChangeStartDate = (e) => {
        const { value } = e.target;
        const newDate = new Date(value);
        setContentStartDate(newDate.toISOString().split('T')[0]);
        setContentStartDateView(newDate.toISOString().split('T')[0]);

    };

    const handleChangeMarketShare = (event) => {
        setIsMarketShare(event.target.checked);
    }

    // const handleChangeStartDateView = (e) => {
    //     const { value } = e.target;
    //     const newDate = new Date(value);
    //     setContentStartDateView(newDate.toISOString().split('T')[0]);
    // }

    const handleChangeEndDate = (e) => {
        const { value } = e.target;
        const newDate = new Date(value);
        setContentEndDate(newDate.toISOString().split('T')[0]);
        setContentEndDateView(newDate.toISOString().split('T')[0]);
    };

    // const handleChangeEndDateView = (e) => {
    //     const { value } = e.target;
    //     const newDate = new Date(value);
    //     setContentEndDateView(newDate.toISOString().split('T')[0]);
    // }

    // useEffect(() => {
    //     let isMounted = true;
    //     (async function getOneContract() {
    //         try {
    //             const { data: contract, status } = await axios.get(`/contract/get-one/${id}`, {
    //                 headers: {
    //                     'x-access-token': localStorage.getItem('token')
    //                 }
    //             });
    //             if (status === 200 && isMounted) {
    //                 setContract({
    //                     ...contract
    //                 });

    //                 setMonetized(contract.businessModel);
    //                 setExclusive(contract.exclusive);
    //                 setIsInclude(contract.countryRestriction)
    //             }

    //             if (contract.businessModel !== 6 && contract.pricingOption === 2) {
    //                 let initializePricingLadder = [];
    //                 if (contract.pricingLadderRange.length === 1) {
    //                     let from = +contract.pricingLadderRange[0];
    //                     let to = +contract.pricingLadderRange[0] + 1;
    //                     initializePricingLadder.push({ from: from, to: to, value: contract.pricingLadderBilledRange[0], minimum: contract.pricingLadderMinimum[0] })
    //                 } else {
    //                     for (let i = 0; i < contract.pricingLadderRange.length; i++) {
    //                         let from = contract.pricingLadderRange[i];
    //                         let to = (i === contract.pricingLadderRange.length - 1) ? +contract.pricingLadderRange[i] + 1 : contract.pricingLadderRange[i + 1] - 1;
    //                         initializePricingLadder.push({ from: from, to: to, value: contract.pricingLadderBilledRange[i], minimum: contract.pricingLadderMinimum[i] })
    //                     }
    //                 }
    //                 setInitializePricingLadder(initializePricingLadder);
    //             }

    //             const listRoyaltiesResponse = await axios.get(`/royalty/get-list-royalties-by-content?contractUid=${contract.ikastUID}&fromDate=${contentStartDate}&toDate=${contentEndDate}`, {
    //                 headers: {
    //                     'x-access-token': localStorage.getItem('token')
    //                 }
    //             });
    //             if (listRoyaltiesResponse.status === 200) {
    //                 setTotalSectionData(listRoyaltiesResponse.data);

    //                 var infringedContentList = [];
    //                 listRoyaltiesResponse.data.records.forEach(item => {
    //                     if (item.infringedContentViewlog) {
    //                         infringedContentList.push(item.infringedContentViewlog);
    //                     }
    //                 });
    //                 setInfringeContentList(infringedContentList);
    //                 setIsAnyInfringedLogs(infringedContentList.length > 0 ? true : false);

    //                 if (listRoyaltiesResponse.data && listRoyaltiesResponse.data.records && listRoyaltiesResponse.data.records.length > 0) {
    //                     var pieChartDataTemp = await Promise.all(
    //                         listRoyaltiesResponse.data.records.map(
    //                             async (oneItem) => {
    //                                 return {
    //                                     revenue: oneItem.validContentViewlog.revenue ? oneItem.validContentViewlog.revenue : 0,
    //                                     title: oneItem.validContentViewlog.title ? oneItem.validContentViewlog.title : ""
    //                                 };
    //                             }
    //                         )
    //                     )
    //                     setPieChartData(pieChartDataTemp);
    //                 }
    //             }

    //             const barChartDatas = await axios.get(`/royalty/get-list-royalties-by-content-per-day?contractUid=${contract.ikastUID}&fromDate=${contentStartDate}&toDate=${contentEndDate}`, {
    //                 headers: {
    //                     'x-access-token': localStorage.getItem('token')
    //                 }
    //             });

    //             if (barChartDatas.status === 200) {
    //                 if (barChartDatas.data && barChartDatas.data.results && barChartDatas.data.results.length > 0) {
    //                     setBarChartData(barChartDatas.data.results);
    //                 }
    //             }

    //         } catch (e) {
    //             console.log(e);
    //         }
    //     })();
    //     return () => { isMounted = false };
    // }, []);

    useEffect(() => {
        let isMounted = true;
        (async function getListCountry() {
            showLoading();
            const listCountryResponse = await axios.get('/country/get-list-countries', {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            const { data, status } = listCountryResponse;

            if (status === 200) {
                if (isMounted) setCountries(data);
            }
            hideLoading();
        })();
        return () => { isMounted = false };
    }, []);

    useEffect(() => {
        if (monetized) {
            switch (monetized) {
                case 1:
                    setMonetizedValue([1]);
                    return;
                case 2:
                    setMonetizedValue([1, 2, 7]);
                    return;
                case 3:
                    setMonetizedValue([1, 2, 5]);
                    return;
                case 4:
                    setMonetizedValue([1, 2, 9, 10, 11, 12, 13, 14, 5]);
                    return;
                case 5:
                    setMonetizedValue([1, 2, 9, 10, 11, 12, 13, 14, 3]);
                    return;
                case 6:
                    setMonetizedValue([1, 2, 6, 8]);
                    return;
                default:
                    return;
            }
        }
    }, [monetized]);

    useEffect(() => {
        let isMounted = true;
        if (selector) {
            if (localStorage.getItem('id') && localStorage.getItem('token')) {
                (async function getListOrg() {
                    showLoading();
                    const listOrgResponse = await axios.get(`/org/get-list-connected-orgs/${selector.get('BelongsTo')}`, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });

                    const { data, status } = listOrgResponse;

                    if (status === 200) {
                        if (isMounted) setListSeller(data);
                    }
                    hideLoading();
                })();
                return () => { isMounted = false };
            }
        }
    }, [selector]);

    useEffect(() => {
        (async function getOneContract() {
            try {
                showLoading();
                const { data: contract, status } = await axios.get(`/contract/get-one/${id}`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });
                if (status === 200) {
                    setContract({
                        ...contract
                    });
                    setIsMarketShare(contract.isWeightOff);
                    setMonetized(contract.businessModel);
                    setExclusive(contract.exclusive);
                    setIsInclude(contract.countryRestriction)
                    setPdfFileName(contract.pdfFileName ? contract.pdfFileName : '');
                    setPDFS3(contract.pdfUrlS3 ? contract.pdfUrlS3 : null);
                }

                if (contract.businessModel !== 6 && contract.pricingOption === 2) {
                    let initializePricingLadder = [];
                    if (contract.pricingLadderRange.length === 1) {
                        let from = +contract.pricingLadderRange[0];
                        let to = +contract.pricingLadderRange[0] + 1;
                        initializePricingLadder.push({ from: from, to: to, value: contract.pricingLadderBilledRange[0], minimum: contract.pricingLadderMinimum[0] })
                    } else {
                        for (let i = 0; i < contract.pricingLadderRange.length; i++) {
                            let from = contract.pricingLadderRange[i];
                            let to = (i === contract.pricingLadderRange.length - 1) ? +contract.pricingLadderRange[i] + 1 : contract.pricingLadderRange[i + 1] - 1;
                            initializePricingLadder.push({ from: from, to: to, value: contract.pricingLadderBilledRange[i], minimum: contract.pricingLadderMinimum[i] })
                        }
                    }
                    setInitializePricingLadder(initializePricingLadder);
                }

                const listRoyaltiesResponse = await axios.get(`/royalty/get-list-royalties-by-content?contractUid=${contract.ikastUID}&fromDate=${contentStartDate}&toDate=${contentEndDate}`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });
                if (listRoyaltiesResponse.status === 200) {
                    setTotalSectionData(listRoyaltiesResponse.data);

                    // var infringedContentList = [];
                    // listRoyaltiesResponse.data.records.forEach(item => {
                    //     if (item.infringedContentViewlog) {
                    //         infringedContentList.push(item.infringedContentViewlog);
                    //     }
                    // });
                    // setInfringeContentList(infringedContentList);
                    // setIsAnyInfringedLogs(infringedContentList.length > 0 ? true : false);

                    if (listRoyaltiesResponse.data && listRoyaltiesResponse.data.records && listRoyaltiesResponse.data.records.length > 0) {
                        var pieChartDataTemp = await Promise.all(
                            listRoyaltiesResponse.data.records.map(
                                async (oneItem) => {
                                    return {
                                        revenue: oneItem.validContentViewlog.revenue ? oneItem.validContentViewlog.revenue : 0,
                                        title: oneItem.validContentViewlog.title ? oneItem.validContentViewlog.title : ""
                                    };
                                }
                            )
                        )
                        setPieChartData(pieChartDataTemp);
                    }
                }

                const listRoyaltiesByCountryResponse = await axios.get(`/royalty/get-list-royalties-by-content-per-country?contractUid=${contract.ikastUID}&fromDate=${contentStartDate}&toDate=${contentEndDate}`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });
                if (listRoyaltiesByCountryResponse.status === 200) {
                    const listContentsPerCountry = listRoyaltiesByCountryResponse.data.responseObject || [];
                    // const listContentsPerCountry = [
                    //     {
                    //       "countryName": "France",
                    //       "countryCode": "FR",
                    //       "contentList": [
                    //         {
                    //           "validContentViewlog": {
                    //             "contentUID": "11eca521-e1a0-fa57-a402-951d2e5e1c4d",
                    //             "illustrationUrlS3": "https://ikast-resources.s3.eu-central-1.amazonaws.com/contents/illustration/11eca521-e1a0-fa57-a402-951d2e5e1c4d/content-illustration.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3EPUFXNK7DVY7BO3%2F20230302%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20230302T074956Z&X-Amz-Expires=604800&X-Amz-Signature=7b6dfad015b890afea3ac9402f1fe09042dfe7ebbfe8923c20496b6f68d3ca7b&X-Amz-SignedHeaders=host",
                    //             "title": "Dans les prés d'en haut",
                    //             "customId": null,
                    //             "duration": "46052238",
                    //             "viewLogCount": "79",
                    //             "invalidViewLogCount": "31",
                    //             "revenue": "95.75"
                    //           },
                    //           "infringedContentViewlog": null
                    //         },
                    //         {
                    //           "validContentViewlog": {
                    //             "contentUID": "11eca521-e1a1-2161-a402-951d2e5e1c4d",
                    //             "illustrationUrlS3": "https://ikast-resources.s3.eu-central-1.amazonaws.com/contents/illustration/11eca521-e1a1-2161-a402-951d2e5e1c4d/content-illustration.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3EPUFXNK7DVY7BO3%2F20230302%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20230302T074956Z&X-Amz-Expires=604800&X-Amz-Signature=1a74ef2c357e6d07bce8274b1d90672ec9a0772f9c7c92e864d44f2c2978e19a&X-Amz-SignedHeaders=host",
                    //             "title": "Tant qu'on sème",
                    //             "customId": null,
                    //             "duration": "8512687",
                    //             "viewLogCount": "37",
                    //             "invalidViewLogCount": "6",
                    //             "revenue": "44.84"
                    //           },
                    //           "infringedContentViewlog": null
                    //         },
                    //         {
                    //           "validContentViewlog": {
                    //             "contentUID": "11eca521-e1a1-2160-a402-951d2e5e1c4d",
                    //             "illustrationUrlS3": "https://ikast-resources.s3.eu-central-1.amazonaws.com/contents/illustration/11eca521-e1a1-2160-a402-951d2e5e1c4d/content-illustration.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3EPUFXNK7DVY7BO3%2F20230302%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20230302T074956Z&X-Amz-Expires=604800&X-Amz-Signature=c5c00e6ed0fa6c8e0c471ab7a9f485f6c0491d3bc05f039252123a723b5f2292&X-Amz-SignedHeaders=host",
                    //             "title": "Champ des luttes",
                    //             "customId": null,
                    //             "duration": "62485393",
                    //             "viewLogCount": "73",
                    //             "invalidViewLogCount": "20",
                    //             "revenue": "88.48"
                    //           },
                    //           "infringedContentViewlog": null
                    //         }
                    //       ]
                    //     },
                    //     {
                    //       "countryName": "French Polynesia",
                    //       "countryCode": "PF",
                    //       "contentList": [
                    //         {
                    //           "validContentViewlog": null,
                    //           "infringedContentViewlog": {
                    //             "contentUID": "11eca521-e1a0-fa57-a402-951d2e5e1c4d",
                    //             "illustrationUrlS3": "https://ikast-resources.s3.eu-central-1.amazonaws.com/contents/illustration/11eca521-e1a0-fa57-a402-951d2e5e1c4d/content-illustration.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3EPUFXNK7DVY7BO3%2F20230302%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20230302T074956Z&X-Amz-Expires=604800&X-Amz-Signature=7b6dfad015b890afea3ac9402f1fe09042dfe7ebbfe8923c20496b6f68d3ca7b&X-Amz-SignedHeaders=host",
                    //             "title": "Dans les prés d'en haut",
                    //             "customId": null,
                    //             "duration": "44189",
                    //             "viewLogCount": "2",
                    //             "invalidViewLogCount": 0,
                    //             "revenue": "2.42"
                    //           }
                    //         },
                    //         {
                    //           "validContentViewlog": null,
                    //           "infringedContentViewlog": null
                    //         },
                    //         {
                    //           "validContentViewlog": null,
                    //           "infringedContentViewlog": null
                    //         }
                    //       ]
                    //     },
                    //     {
                    //       "countryName": "Switzerland",
                    //       "countryCode": "CH",
                    //       "contentList": [
                    //         {
                    //           "validContentViewlog": null,
                    //           "infringedContentViewlog": {
                    //             "contentUID": "11eca521-e1a0-fa57-a402-951d2e5e1c4d",
                    //             "illustrationUrlS3": "https://ikast-resources.s3.eu-central-1.amazonaws.com/contents/illustration/11eca521-e1a0-fa57-a402-951d2e5e1c4d/content-illustration.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3EPUFXNK7DVY7BO3%2F20230302%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20230302T074956Z&X-Amz-Expires=604800&X-Amz-Signature=7b6dfad015b890afea3ac9402f1fe09042dfe7ebbfe8923c20496b6f68d3ca7b&X-Amz-SignedHeaders=host",
                    //             "title": "Dans les prés d'en haut",
                    //             "customId": null,
                    //             "duration": "161245",
                    //             "viewLogCount": "1",
                    //             "invalidViewLogCount": 0,
                    //             "revenue": "1.21"
                    //           }
                    //         },
                    //         {
                    //           "validContentViewlog": null,
                    //           "infringedContentViewlog": null
                    //         },
                    //         {
                    //           "validContentViewlog": null,
                    //           "infringedContentViewlog": null
                    //         }
                    //       ]
                    //     }
                    // ];
                    const listDataValidPerCountry = [];
                    const listDataInfringedPerCountry = [];
                    if(listRoyaltiesByCountryResponse.data && listRoyaltiesByCountryResponse.data.responseObject) {
                        listContentsPerCountry.forEach(item => {
                            const listContentValid = [];
                            const listContentInfringed = [];
                            item.contentList.forEach(data => {
                                if(data.validContentViewlog) {
                                    listContentValid.push({validContentViewlog: data.validContentViewlog})
                                }
                                if(data.infringedContentViewlog) {
                                    listContentInfringed.push({infringedContentViewlog: data.infringedContentViewlog})
                                }
                            })
                            if(listContentValid.length > 0) {
                                listDataValidPerCountry.push({
                                    countryName: item.countryName,
                                    countryCode: item.countryCode,
                                    contentList: listContentValid
        
                                });
                            }
                            if(listContentInfringed.length > 0) {
                                listDataInfringedPerCountry.push({
                                    countryName: item.countryName,
                                    countryCode: item.countryCode,
                                    contentList: listContentInfringed
                                });
                            }
                           
                        });
                        setDataTotalRevennue(listRoyaltiesByCountryResponse.data)
                        setValidContentList(listDataValidPerCountry);
                        setInfringeContentList(listDataInfringedPerCountry);
                        setIsAnyInfringedLogs(listDataInfringedPerCountry.length > 0 ? true : false);
                    } else {
                        setDataTotalRevennue(null)
                        setValidContentList([]);
                        setInfringeContentList([]);
                        setIsAnyInfringedLogs(false);
                    }
                } else {
                    setDataTotalRevennue(null)
                    setValidContentList([]);
                    setInfringeContentList([]);
                    setIsAnyInfringedLogs(false);
                }

                const barChartDatas = viewBarChartMode ? await axios.get(`/royalty/get-list-royalties-by-content-per-day?contractUid=${contract.ikastUID}&fromDate=${contentStartDate}&toDate=${contentEndDate}`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                }) : await axios.get(`/royalty/get-list-views-by-content-per-day?contractUid=${contract.ikastUID}&fromDate=${contentStartDate}&toDate=${contentEndDate}`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

                if (barChartDatas.status === 200) {
                    if (barChartDatas.data && barChartDatas.data.results && barChartDatas.data.results.length > 0) {
                        setBarChartData(barChartDatas.data.results);
                    }
                }
                hideLoading();
                setMessageKey('');
            } catch (e) {
                hideLoading();
                setMessageKey("common.content_not_available");
                console.log(e);
            }
        })();
    }, [contentStartDate, contentEndDate, viewBarChartMode]);

    // useEffect(() => {
    //     let isMounted = true;
    //     (async function getListRoyaltiesByContent() {
    //         if (contract) {
    //             try {
    //                 const listRoyaltiesResponse = await axios.get(`/royalty/get-list-royalties-by-content?contractUid=${contract.ikastUID}&fromDate=${contentStartDate}&toDate=${contentEndDate}`, {
    //                     headers: {
    //                         'x-access-token': localStorage.getItem('token')
    //                     }
    //                 });

    //                 if (listRoyaltiesResponse.status === 200 && isMounted) {
    //                     setTotalSectionData(listRoyaltiesResponse.data);
    //                     var infringedContentList = [];
    //                     listRoyaltiesResponse.data.records.forEach(item => {
    //                         if (item.infringedContentViewlog) {
    //                             infringedContentList.push(item.infringedContentViewlog);
    //                         }
    //                     });
    //                     setInfringeContentList(infringedContentList);
    //                     setIsAnyInfringedLogs(infringedContentList.length > 0 ? true : false);

    //                     if (listRoyaltiesResponse.data && listRoyaltiesResponse.data.records && listRoyaltiesResponse.data.records.length > 0) {
    //                         var pieChartDataTemp = await Promise.all(
    //                             listRoyaltiesResponse.data.records.map(
    //                                 async (oneItem) => {
    //                                     return {
    //                                         revenue: oneItem.validContentViewlog.revenue ? oneItem.validContentViewlog.revenue : 0,
    //                                         title: oneItem.validContentViewlog.title ? oneItem.validContentViewlog.title : ""
    //                                     };
    //                                 }
    //                             )
    //                         )

    //                         setPieChartData(pieChartDataTemp);
    //                     }
    //                 }

    //                 const barChartDatas = viewBarChartMode ? await axios.get(`/royalty/get-list-royalties-by-content-per-day?contractUid=${contract.ikastUID}&fromDate=${contentStartDate}&toDate=${contentEndDate}`, {
    //                     headers: {
    //                         'x-access-token': localStorage.getItem('token')
    //                     }
    //                 }) : await axios.get(`/royalty/get-list-views-by-content-per-day?contractUid=${contract.ikastUID}&fromDate=${contentStartDate}&toDate=${contentEndDate}`, {
    //                     headers: {
    //                         'x-access-token': localStorage.getItem('token')
    //                     }
    //                 });

    //                 if (barChartDatas.status === 200 && isMounted) {
    //                     if (barChartDatas.data && barChartDatas.data.results && barChartDatas.data.results.length > 0) {
    //                         setBarChartData(barChartDatas.data.results);
    //                     }
    //                 }

    //             } catch (e) {
    //                 console.log(e);
    //             }
    //         }
    //     })();
    //     return () => { isMounted = false };
    // }, [contentStartDate, contentEndDate, viewBarChartMode]);

    // const CURRENCY_UNIT = (user && user.get('org')) ? CURRENCY.find(c => c.id === user.getIn(['org', 'mainCurrency'])).value : "";
    // const countryCode = (user && user.get('countryAlpha2')) ? user.get('countryAlpha2') : "";

    const [listActions, setListActions] = useState([]);

    useEffect(() => {
        if (contract) {
            (async function getListOrg() {
                const listHistoryContract = await axios.get(`/contract-history?contractUid=${contract.ikastUID}&pageNo=1`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

                const { data, status } = listHistoryContract;

                if (status === 200) {
                    setListActions(data.records);
                }
            })();
        }
    }, [contract]);

    const prepareTable = (sheet) => {
        let totalLogRevenue = sheet === 1 ? totalSectionData.totalValidRoyalty : totalSectionData.totalInfringeRoyalty;

        switch (sheet) {
            case 1:
                let bodyTableSheet1 = '';
                const anchorDate = contract.anchorDate ? contract.anchorDate : 'N/A';
                const pricingOption = contract.pricingOption == 1 ? t("constant.flat") : t("constant.ladder");
                let withCalculationDone = '';
                let mgApplied = '';
                switch (contract.calculationsDone) {
                    case 1:
                        withCalculationDone = t("constant.contract_lifespan");
                        break;
                    case 2:
                        withCalculationDone = t("constant.every_month");
                        break;
                    case 3:
                        withCalculationDone = t("constant.every_year");
                        break;
                };

                switch (contract.mgApplied) {
                    case 1:
                        mgApplied = t("constant.none");
                        break;
                    case 2:
                        mgApplied = t("constant.threshold");
                        break;
                    case 3:
                        mgApplied = t("constant.fixed");
                        break;
                }

                const viewerSession = contract.durationMode == 1 ? t("constant.value_total_duration") : t("constant.value_seconds");

                switch (contract.businessModel) {
                    case 1:
                        bodyTableSheet1 = + '<thead>'
                            + '    <tr style="background-color:#788496; color: #ffffff">'
                            + '      <th scope="col" colspan="2">'
                            + '        <div>'+t("contract.monetized_as")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.flat")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.pricing")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.calculations_done")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.mg_applied")+'</div>'
                            + '      </th>'
                            + '</thead>'
                            + ' <tbody>'
                            + '    <tr style="text-align: right">'
                            + '      <td style="width: 150px;" colspan="2">'
                            + '        <div>FTA</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + contract.flatRate + CURRENCY_UNIT + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + pricingOption + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + withCalculationDone + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + mgApplied + '</div>'
                            + '      </td>'
                            + '    </tr>'
                            + '  </tbody>'
                        break;
                    case 2:
                        bodyTableSheet1 = + '<thead>'
                            + '    <tr style="background-color:#788496; color: #ffffff">'
                            + '      <th scope="col" colspan="2">'
                            + '        <div>'+t("contract.monetized_as")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.flat")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.minimum_guaranteed")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.pricing")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.calculations_done")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.mg_applied")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.revenue_subs")+'</div>'
                            + '      </th>'
                            + '</thead>'
                            + ' <tbody>'
                            + '    <tr style="text-align: right">'
                            + '      <td style="width: 150px;" colspan="2">'
                            + '        <div>PTV</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + contract.flatRate + CURRENCY_UNIT + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + contract.MG + CURRENCY_UNIT + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + pricingOption + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + withCalculationDone + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + mgApplied + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + contract.RevPerSubs + '%' + '</div>'
                            + '      </td>'
                            + '    </tr>'
                            + '  </tbody>'
                        break;
                    case 3:
                        bodyTableSheet1 = + '<thead>'
                            + '    <tr style="background-color:#788496; color: #ffffff">'
                            + '      <th scope="col" colspan="2">'
                            + '        <div>'+t("contract.monetized_as")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.flat")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.minimum_guaranteed")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.pricing")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.calculations_done")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.mg_applied")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.revenue_transaction")+'</div>'
                            + '      </th>'
                            + '</thead>'
                            + ' <tbody>'
                            + '    <tr style="text-align: right">'
                            + '      <td style="width: 150px;" colspan="2">'
                            + '        <div>EST</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + contract.flatRate + CURRENCY_UNIT + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + contract.MG + CURRENCY_UNIT + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + pricingOption + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + withCalculationDone + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + mgApplied + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + contract.RevPerTransaction + CURRENCY_UNIT + '</div>'
                            + '      </td>'
                            + '    </tr>'
                            + '  </tbody>'
                        break;
                    case 4:
                        bodyTableSheet1 = + '<thead>'
                            + '    <tr style="background-color:#788496; color: #ffffff">'
                            + '      <th scope="col" colspan="2">'
                            + '        <div>'+t("contract.monetized_as")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.flat")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.minimum_guaranteed")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.view_evaluated")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.where_viewer_session")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.condition")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.pricing")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.calculations_done")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.mg_applied")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.revenue_transaction")+'</div>'
                            + '      </th>'
                            + '</thead>'
                            + ' <tbody>'
                            + '    <tr style="text-align: right">'
                            + '      <td style="width: 150px;">'
                            + '        <div>TVOD</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + contract.flatRate + CURRENCY_UNIT + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + contract.MG + CURRENCY_UNIT + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>'+ 'Per duration' + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + viewerSession + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + contract.conditionValue + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + pricingOption + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + withCalculationDone + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + mgApplied + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + contract.RevPerTransaction + CURRENCY_UNIT + '</div>'
                            + '      </td>'
                            + '    </tr>'
                            + '  </tbody>'
                        break;
                    case 5:
                        bodyTableSheet1 = + '<thead>'
                            + '    <tr style="background-color:#788496; color: #ffffff">'
                            + '      <th scope="col" colspan="2">'
                            + '        <div>'+t("contract.monetized_as")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.flat")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.minimum_guaranteed")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.view_evaluated")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.where_viewer_session")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.condition")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.pricing")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.calculations_done")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.mg_applied")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.each_view_billed")+'</div>'
                            + '      </th>'
                            + '</thead>'
                            + ' <tbody>'
                            + '    <tr style="text-align: right">'
                            + '      <td style="width: 150px;">'
                            + '        <div>SVOD</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + contract.flatRate + CURRENCY_UNIT + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + contract.MG + CURRENCY_UNIT + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>'+ 'Per duration' + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + viewerSession + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + contract.conditionValue + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + pricingOption + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + withCalculationDone + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + mgApplied + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + contract.RevPerViewerCount + CURRENCY_UNIT + '</div>'
                            + '      </td>'
                            + '    </tr>'
                            + '  </tbody>'
                        break;
                    case 6:
                        bodyTableSheet1 = + '<thead>'
                            + '    <tr style="background-color:#788496; color: #ffffff">'
                            + '      <th scope="col" colspan="2">'
                            + '        <div>'+t("contract.monetized_as")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.flat")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.minimum_guaranteed")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+t("contract.per_nb_of_ads_Served")+'</div>'
                            + '      </th>'
                            + '</thead>'
                            + ' <tbody>'
                            + '    <tr style="text-align: right">'
                            + '      <td style="width: 150px;">'
                            + '        <div>AVOD</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + contract.flatRate + CURRENCY_UNIT + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + contract.MG + CURRENCY_UNIT + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;">'
                            + '        <div>' + contract.RevPerAdsServed + '</div>'
                            + '      </td>'
                            + '    </tr>'
                            + '  </tbody>'
                        break;
                }

                const exportSheet = '<table border="1">'
                    + '<tr>'
                    + '<td style="text-align:center" colspan="3">'+ t("contract.start_date")+'</td>'
                    + '<td style="text-align:center" colspan="3">'+ t("contract.end_date") +'</td>'
                    + '</tr>'
                    + '<tr>'
                    + '<td style="text-align:left" colspan="3">' + contract.startDate + '</td>'
                    + '<td style="text-align:left" colspan="3">' + contract.endDate + '</td>'
                    + '</tr>'
                    + '<tr>'
                    + '<td style="text-align:left" colspan="3">' + t("contract.title") + '</td>'
                    + '<td style="text-align:left" colspan="3">' + contract.Title + '</td>'
                    + '</tr>'
                    + '<tr>'
                    + '<td style="text-align:left" colspan="3">'+ t("general_info.validated_on") +'</td>'
                    + '<td style="text-align:left" colspan="3">' + anchorDate + '</td>'
                    + '</tr>'
                    + '<tr>'
                    + '<td style="text-align:left" colspan="3">'+ t("contract.business_rules") +'</td>'
                    + '</tr>'
                    + bodyTableSheet1
                    + '</table></html>';

                return exportSheet;
                break;
            case 2:
                let pieChartSection = '';
                if (pieChartData.length > 0) {
                    pieChartData.forEach(content => {
                        let row = '    <tr style="text-align: right">'
                            + '      <td style="width: 150px;" colspan="3">'
                            + '        <div>' + content.title + '</div>'
                            + '      </td>'
                            + '      <td style="width: 150px;" colspan="3">'
                            + '        <div>' + content.revenue + '</div>'
                            + '      </td>'
                            + '    </tr>';
                        pieChartSection = pieChartSection + row;
                    });
                }
                const revenuePerContentSheet = '<table border="1">'
                    + '<tr>'
                    + '<td style="text-align:center" colspan="3">'+ t("contract.start_date")+'</td>'
                    + '<td style="text-align:center" colspan="3">'+ t("contract.end_date") +'</td>'
                    + '</tr>'
                    + '<tr>'
                    + '<td style="text-align:left" colspan="3">' + contentStartDateView + '</td>'
                    + '<td style="text-align:left" colspan="3">' + contentEndDateView + '</td>'
                    + '</tr>'
                    + '<tr>'
                    + '<td style="text-align:center" colspan="6">'+ t("royalties.revenue_per_content")+ '</td>'
                    + '</tr>'
                    + '<tr>'
                    + '<td style="text-align:left" colspan="3">'+ t("contract.title")+'</td>'
                    + '<td style="text-align:left" colspan="3">'+ t("royalties.revenue")+'</td>'
                    + '</tr>'
                    + pieChartSection
                    + '<tr>'
                    + '<tr>'
                    + '<td style="text-align:center" colspan="6">Revenue Per Content Per Day</td>'
                    + '</tr>'
                    + '</table></html>';

                return revenuePerContentSheet;
                break;
            case 3:
                let validLogSection = '';
                let infringeLogSection = '';
                let eachBodyTableSheet1 = '';
                let eachBodyTableSheet2 = '';

                validContentList.forEach(item => {
                    eachBodyTableSheet1 = '';
                    if(item.contentList.length > 0) {
                        let title = '';
                        let customId = '';
                        let viewLogCount = 0;
                        let validViewLogCount = 0;
                        let duration = '';
                        let revenue = '';

                        item.contentList.forEach(log => {
                                title = log.validContentViewlog.title;
                                customId = log.validContentViewlog.customId ? log.validContentViewlog.customId : 'N/A';
                                viewLogCount = Number(log.validContentViewlog.viewLogCount) + Number(log.validContentViewlog.invalidViewLogCount);
                                validViewLogCount = log.validContentViewlog.viewLogCount;
                                duration = log.validContentViewlog.duration;
                                revenue = log.validContentViewlog.revenue;

                            let row = '    <tr style="text-align: right">'
                                + '      <td style="width: 150px;" colspan="2">'
                                + '        <div>' + title + '</div>'
                                + '      </td>'
                                + '      <td style="width: 150px;">'
                                + '        <div>' + customId + '</div>'
                                + '      </td>'
                                + '      <td style="width: 150px;">'
                                + '        <div>' + viewLogCount + '</div>'
                                + '      </td>'
                                + '      <td style="width: 150px;">'
                                + '        <div>' + validViewLogCount + '</div>'
                                + '      </td>'
                                + '      <td style="width: 150px;">'
                                + '        <div>' + duration + '</div>'
                                + '      </td>'
                                + '      <td style="width: 150px;">'
                                + '        <div>' + revenue + '</div>'
                                + '      </td>'
                                + '    </tr>';

                            eachBodyTableSheet1 = eachBodyTableSheet1 + row;
                        })

                        let eachCountry = '<tr>'
                            + '<td style="text-align:center" colspan="7">'+ item.countryName +'</td>'
                            + '</tr>'
                        + '<thead>'
                        + '    <tr style="background-color:#788496; color: #ffffff">'
                        + '      <th scope="col" colspan="2">'
                        + '        <div>'+ t("content.title")+'</div>'
                        + '      </th>'
                        + '      <th scope="col">'
                        + '        <div>'+ t("content.custom_id")+'</div>'
                        + '      </th>'
                        + '      <th scope="col">'
                        + '        <div>'+ t("royalties.number_view")+'</div>'
                        + '      </th>'
                        + '      <th scope="col">'
                        + '        <div>Nb of valid views</div>'
                        + '      </th>'
                        + '      <th scope="col">'
                        + '        <div>Total duration of views</div>'
                        + '      </th>'
                        + '      <th scope="col">'
                        + '        <div>Cumulated Royalty</div>'
                        + '      </th>'
                        + '</thead>'
                        + '  <tbody>'
                        + eachBodyTableSheet1
                        + '  </tbody>'

                        validLogSection = validLogSection + eachCountry;
                    }
                    else {
                        validLogSection = '<tr>'
                            + '<td style="text-align:center" colspan="7"> N/A </td>'
                            + '</tr>'
                    }
                });

                infringeContentList.forEach(item => {
                    eachBodyTableSheet2 = '';
                    if(item.contentList.length > 0) {
                        let title = '';
                        let customId = '';
                        let viewLogCount = 0;
                        let validViewLogCount = 0;
                        let duration = '';
                        let revenue = '';

                        item.contentList.forEach(log => {
                            title = log.infringedContentViewlog.title;
                            customId = log.infringedContentViewlog.customId ? log.infringedContentViewlog.customId : 'N/A';
                            viewLogCount = Number(log.infringedContentViewlog.viewLogCount) + Number(log.infringedContentViewlog.invalidViewLogCount);
                            validViewLogCount = log.infringedContentViewlog.viewLogCount;
                            duration = log.infringedContentViewlog.duration;
                            revenue = log.infringedContentViewlog.revenue;

                            let row = '    <tr style="text-align: right">'
                                + '      <td style="width: 150px;" colspan="2">'
                                + '        <div>' + title + '</div>'
                                + '      </td>'
                                + '      <td style="width: 150px;">'
                                + '        <div>' + customId + '</div>'
                                + '      </td>'
                                + '      <td style="width: 150px;">'
                                + '        <div>' + viewLogCount + '</div>'
                                + '      </td>'
                                + '      <td style="width: 150px;">'
                                + '        <div>' + validViewLogCount + '</div>'
                                + '      </td>'
                                + '      <td style="width: 150px;">'
                                + '        <div>' + duration + '</div>'
                                + '      </td>'
                                + '      <td style="width: 150px;">'
                                + '        <div>' + revenue + '</div>'
                                + '      </td>'
                                + '    </tr>';

                            eachBodyTableSheet2 = eachBodyTableSheet2 + row;
                        })

                        let eachCountry = '<tr>'
                            + '<td style="text-align:center" colspan="7">'+ item.countryName +'</td>'
                            + '</tr>'
                            + '<thead>'
                            + '    <tr style="background-color:#788496; color: #ffffff">'
                            + '      <th scope="col" colspan="2">'
                            + '        <div>'+ t("content.title")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+ t("content.custom_id")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>'+ t("royalties.number_view")+'</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>Nb of valid views</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>Total duration of views</div>'
                            + '      </th>'
                            + '      <th scope="col">'
                            + '        <div>Cumulated Royalty</div>'
                            + '      </th>'
                            + '</thead>'
                            + '  <tbody>'
                            + eachBodyTableSheet2
                            + '  </tbody>'

                        infringeLogSection = infringeLogSection + eachCountry;
                    }
                    else {
                        infringeLogSection = '<tr>'
                            + '<td style="text-align:center" colspan="7"> N/A </td>'
                            + '</tr>'
                    }
                });

                let marketShare = totalSectionData.marketShareValue ? totalSectionData.marketShareValue.toFixed(2) : 0;
                let finalRoyalties = totalSectionData.finalRoyalties ? totalSectionData.finalRoyalties.toFixed(2) : 0;

                const overviewSheet = '<table border="1">'
                    + '<tr>'
                    + '<td style="text-align:center" colspan="3">'+ t("contract.start_date")+'</td>'
                    + '<td style="text-align:center" colspan="3">'+ t("contract.end_date") +'</td>'
                    + '</tr>'
                    + '<tr>'
                    + '<td style="text-align:left" colspan="3">' + contentStartDateView + '</td>'
                    + '<td style="text-align:left" colspan="3">' + contentEndDateView + '</td>'
                    + '</tr>'
                    + '<tr>'
                    + '<td style="text-align:left" colspan="3">'+ t("royalties.royalties") +'</td>'
                    + '</tr>'
                    + validLogSection
                    + '<tr>'
                    + '<td style="text-align:left" colspan="3">'+ t("royalties.infringed_royalties") +'</td>'
                    + '</tr>'
                    + infringeLogSection
                    + '<tr>'
                    + '<td style="text-align:center" colspan="2">'+ t("royalties.total_royalties") +'</td>'
                    + '<td style="text-align:center" colspan="2">' + totalLogRevenue + '</td>'
                    + '</tr>'
                    + '<tr>'
                    + '<td style="text-align:center" colspan="2">'+ t("royalties.minimum_guaranteed") +'</td>'
                    + '<td style="text-align:left" colspan="2">' + totalSectionData.MG + '</td>'
                    + '</tr>'
                    + '<tr>'
                    + '<td style="text-align:center" colspan="2">'+ t("royalties.flat_fee") +'</td>'
                    + '<td style="text-align:left" colspan="2">' + totalSectionData.flatPrice + '</td>'
                    + '</tr>'
                    + '<tr>'
                    + '<td style="text-align:center" colspan="2">'+ t("royalties.market_share") +'</td>'
                    + '<td style="text-align:left" colspan="2">' + marketShare + '</td>'
                    + '</tr>'
                    + '<tr>'
                    + '<td style="text-align:center" colspan="2">'+ t("royalties.final_royalties") +'</td>'
                    + '<td style="text-align:left" colspan="2">' + finalRoyalties + '</td>'
                    + '</tr>'
                    + '<tr>'
                    + '<td style="text-align:center" colspan="2">Grand total</td>'
                    + '<td style="text-align:left" colspan="2">' + totalSectionData.totalRevenue + '</td>'
                    + '</tr>'
                    + '</table></html>';

                return overviewSheet;
                break;
            case 4:
                let listActionView = '';
                let time = '';
                let actionContent = '';

                listActions.forEach(action => {
                    time = action.createdAt;
                    actionContent = action.header;

                    const actionTable =
                        '<tr>'
                        + '<td style="text-align:left" colspan="3">' + time + '</td>'
                        + '<td style="text-align:left" colspan="3">' + actionContent + '</td>'
                        + '</tr>';
                    listActionView = listActionView + actionTable;
                });

                const historySheet = '<table border="1">'
                    + '<tr>'
                    + '<td style="text-align:center" colspan="6">'+ t("contract.history") +'</td>'
                    + '</tr>'
                    + listActionView
                    + '</table></html>';

                return historySheet;
                break;
            case 5:
                const comment = contract.comments ? contract.comments : 'N/A';
                const commentSheet = '<table border="1">'
                    + '<tr>'
                    + '<td style="text-align:center" colspan="6">'+ t("contract.comments") +'</td>'
                    + '</tr>'
                    + '<tr>'
                    + '<td style="text-align:center" colspan="6">'+ comment +'</td>'
                    + '</tr>'
                    + '</table></html>';

                return commentSheet;
                break;
        }
    };

    const downloadExcel = () => {

        let ws1 = XLSX.read(prepareTable(1), { type: "string" }).Sheets.Sheet1;
        let ws2 = XLSX.read(prepareTable(2), { type: "string" }).Sheets.Sheet1;
        let ws3 = XLSX.read(prepareTable(3), { type: "string" }).Sheets.Sheet1;
        let ws4 = XLSX.read(prepareTable(4), { type: "string" }).Sheets.Sheet1;
        let ws5 = XLSX.read(prepareTable(5), { type: "string" }).Sheets.Sheet1;
        /* create a new blank workbook */
        let wb = XLSX.utils.book_new();

        /* create a worksheet for overview sheet */
        wb.SheetNames.push(t("royalties.identification")); wb.Sheets[t("royalties.identification")] = ws1;
        wb.SheetNames.push(t("royalties.revenue_per_content")); wb.Sheets[t("royalties.revenue_per_content")] = ws2;
        wb.SheetNames.push(t("royalties.royalties_details")); wb.Sheets[t("royalties.royalties_details")] = ws3;
        wb.SheetNames.push(t("royalties.history")); wb.Sheets[t("royalties.history")] = ws4;
        wb.SheetNames.push(t("royalties.comments")); wb.Sheets[t("royalties.comments")] = ws5;

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data1 = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data1, t("royalties.royalties_report") + ".xlsx");
    };

    const handleChangeBarChartView = () => {
        setViewBarChartMode(!viewBarChartMode);
    }

    const { enqueueSnackbar } = useSnackbar();

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const importPreviousLogs = async () => {
        const dataToFetch = {
            "contractUid": contract.ikastUID,
            "startDate": contract.startDate.split('.')[0],
            "endDate": (new Date().toISOString()).split('.')[0]
        }

        try {
            enqueueSnackbar(t("royalties.processing_import"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'info'
            });
            showLoading();
            const response = await axios.post(`/viewinglog/fetch-viewinglogs`, dataToFetch
                , {
                    headers: {
                        common: {
                            'x-access-token': localStorage.getItem('token')
                        },
                    }
                });

            const { data, status } = response;
            hideLoading();
            window.location.reload();

        } catch (error) {
            setImportLoadingScreen(false);
            console.log(error);
        }
    }

    // const MemoImageAndText = React.memo(imageAndText);

    return contract ? (
        <Layout title="Royalties-Detail">
            <div className='flex justify-between content-center'>
                <div>
                    {t("royalties.report_royalties")}
                </div>
                <div className='flex' style={{ height: '36px' }}>
                    {listPermission.includes('IKAST_ROYALTIES_IMPORT_LOGS') ?
                        <Button className='import-log-btn' onClick={() => importPreviousLogs()}>
                            {t("royalties.import_previous_logs")}
                        </Button> : null
                    }

                    <Button className='import-log-btn' onClick={() => downloadExcel()}>
                        {t("royalties.export")}
                    </Button>
                </div>
            </div>
            <Tabs value={valueTab} onChange={handleChange} aria-label="basic tabs example">
                <Tab label={<b>{t("royalties.identification")}</b>} {...a11yProps(0)} />
                <Tab label={<b>{t("royalties.revenue_per_content")}</b>} {...a11yProps(1)} />
                <Tab label={<b>{t("royalties.royalties_details")}
                    <span> (<NumericFormat
                        value={totalSectionData && totalSectionData.totalRevenue && totalSectionData.totalRevenue !== 'NaN' ? showStaticNumber(totalSectionData.totalRevenue) : t('common.zero_text')}
                        decimalScale={2} displayType={'text'} thousandSeparator={t('common.thousand_separator')} decimalSeparator={t('common.decimal_separator')} /> {CURRENCY_UNIT})</span>
                </b>} {...a11yProps(2)} />
                <Tab label={<b>{t("royalties.history")}</b>} {...a11yProps(3)} />
                <Tab label={<b>{t("royalties.comments")}</b>} {...a11yProps(4)} />
            </Tabs>
            <TabPanel valueTab={valueTab} index={0}>
                <Identification
                    contract={contract}
                    outlinedInputClasses={outlinedInputClasses}
                    buyerName={buyerName}
                    listSeller={listSeller}
                    screenDisable={screenDisable}
                    exclusive={exclusive}
                    classes={classes}
                    monetizedValue={monetizedValue}
                    user={user}
                    initializePricingLadder={initializePricingLadder}
                    isInclude={isInclude}
                    countries={countries}
                    CURRENCY_UNIT={CURRENCY_UNIT}
                    pdfFileName={pdfFileName}
                    pdfS3={pdfS3}
                    handleChangeMarketShare={handleChangeMarketShare}
                    isMarketShare={isMarketShare}
                />
            </TabPanel>
            <TabPanel valueTab={valueTab} index={1}>
                <Revenue
                    handleChangeStartDate={handleChangeStartDate}
                    // handleChangeStartDateView={handleChangeStartDateView}
                    // handleChangeEndDateView={handleChangeEndDateView}
                    handleChangeEndDate={handleChangeEndDate}
                    handleChangeBarChartView={handleChangeBarChartView}
                    contentStartDateView={contentStartDateView}
                    contentEndDateView={contentEndDateView}
                    pieChartData={pieChartData}
                    barChartData={barChartData}
                    viewBarChartMode={viewBarChartMode}
                    totalSectionData={totalSectionData}
                    dataTotalRevennue={dataTotalRevennue}
                    CURRENCY_UNIT={CURRENCY_UNIT}
                />
            </TabPanel>
            <TabPanel valueTab={valueTab} index={2}>
                <RoyaltiesDetailsTab
                    handleChangeStartDate={handleChangeStartDate}
                    handleChangeEndDate={handleChangeEndDate}
                    startDate={contentStartDateView}
                    endDate={contentEndDateView}
                    contract={contract}
                    totalSectionData={totalSectionData}
                    countryCode={countryCode}
                    isAnyInfringedLogs={isAnyInfringedLogs}
                    selector={selector}
                    countries={countries}
                    dataTotalRevennue={dataTotalRevennue}
                    validContentList={validContentList}
                    infringeContentList={infringeContentList}
                    CURRENCY_UNIT={CURRENCY_UNIT} />
            </TabPanel>
            <TabPanel valueTab={valueTab} index={3}>
                <div className='bg-white px-8 py-8'>
                    <HistoryTab contract={contract} />
                </div>
            </TabPanel>
            <TabPanel valueTab={valueTab} index={4}>
                <div className='bg-white px-8 py-8'>
                    <div className='w-full'>
                        <textarea
                            disabled={screenDisable}
                            className='w-full'
                            name='comments'
                            maxLength='3000'
                            rows='10'
                            value={contract.comments ? contract.comments : ""}
                            style={{
                                height: '300px',
                                borderRadius: '4px',
                                border: '1px solid #ced4da',
                                backgroundColor: 'white',
                                paddingLeft: '10px'
                            }}>
                        </textarea>
                    </div>
                </div>
            </TabPanel>
            <Dialog
                open={importLoadingScreen}
                onClose={() => { setImportLoadingScreen(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogTitle >{t("royalties.import")}</DialogTitle>
                <DialogContent>
                    <LinearProgress />
                </DialogContent>
            </Dialog>

        </Layout>
    ) : <Layout><LoadingLayout messageKey={messageKey} /></Layout>
};

export default RoyaltiesDetail
