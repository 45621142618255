import React, { useEffect, useState, useContext } from 'react';
import { useSnackbar, withSnackbar } from 'notistack';
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Layout from "../../../layouts/Default";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "../../../../config/axios";
import { useDispatch, useSelector } from "react-redux";
import { authActions, authSelectors } from "../../../../state/modules/auth";
import './index.css';
import { useHistory } from 'react-router-dom';
import Tooltip from "@material-ui/core/Tooltip";
import IdentificationTab from "../IdentificationTab";
import BusinessRuleTab from "../BusinessRuleTab";
import LoadingContext from "../../../../context/loading/LoadingContext";
import { useTranslation } from 'react-i18next';
import { convertAmountToNumber } from '../../../../util';
const useNoBorderStyles = makeStyles({
    root: {
        height: '40px !important',
        padding: '0px',
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        }
    }
});

const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        marginTop: '8px',
        height: '39.5px',
        "& $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&:hover $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&$focused $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-input": {
            padding: '18.5px 0px',
        }
    },
    focused: {},
    notchedOutline: {}
}));

const TabPanel = (props) => {
    const { children, valueTab, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={valueTab !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {valueTab === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

const AddContract = () => {
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#696969',
            color: '#dbdbdb',
            marginBottom: 0,
            fontSize: 14,
        },
    }))(Tooltip);
    const { t, i18n } = useTranslation();
    const classes = useNoBorderStyles();
    const { showLoading, hideLoading } = useContext(LoadingContext);
    const outlinedInputClasses = useOutlinedInputStyles();
    const dispatch = useDispatch();
    const [valueTab, setValueTab] = React.useState(0);
    const [isMarketShare, setIsMarketShare] = React.useState(false);
    const [errorStartDate, setErrorStartDate] = useState(false);
    const [errorEndDate, setErrorEndDate] = useState(false);
    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    };
    useEffect(() => {
        if (localStorage.getItem('id') && localStorage.getItem('token')) {
            dispatch(authActions.getProfile({ id: localStorage.getItem('id') }));
        }
    }, []);

    const { enqueueSnackbar } = useSnackbar();

    let history = useHistory();

    const contractAddingInit = {
        title: t("contract.contractsDefaultTitle"),
        status: 1,
        exclusive: true,
        MG: "0",
        flatRate: "0",
        nbAdsServed: "0",
        viewerCount: "0",
        minutesWatched: "0",
        revPerSubs: "0",
        nbTransaction: "0",
        revPerAds: "0",
        bussinessModel: 5,
        comments: "",
        contents: [],
        durationMode: 2,
        conditionValue: 0,
        pricingOption: 1,
        calculationsDone: 1,
        mgApplied: 1,
        pricingLadderRange: null,
        pricingLadderBilledRange: null,
        pricingLadderMinimum: null,
        marketShareValue: "0",
    };

    const [newContract, setNewContract] = useState(contractAddingInit);

    const [dataContract, setDataContract] = useState(contractAddingInit);

    const [exclusive, setExclusive] = useState(true);

    const [listOrg, setListOrg] = useState(null);

    const [pickSeller, setPickSeller] = useState(null);

    const [pickSellerLogo, setPickSellerLogo] = useState(null);

    const [listContentSeller, setListContentSeller] = useState([]);

    const [countries, setCountries] = useState(null);

    const selector = useSelector((state) => authSelectors.user(state));

    const [user, setUserTracking] = useState(selector);

    const [flatRate, setFlatRate] = useState(0);

    const [MG, setMG] = useState(0);

    const [nbAdsServed, setNbAdsServed] = useState(0);

    const [marketShareBonusValue, setMarketShareBonusValue] = useState(0);

    const [viewerCount, setViewerCount] = useState(0);

    const [minutesWatched, setMinutesWatched] = useState(0);

    const [revPerSubs, setRevPerSubs] = useState(0);

    const [nbTransaction, setNbTransaction] = useState(0);

    const [revPerAds, setRevPerAds] = useState(0);

    const [validTitle, setValidTitle] = useState(true);

    const [isInclude, setIsInclude] = useState(true);

    const [isSaveButtonDisable, setIsSaveButtonDisable] = useState(false);

    const [sellerName, setSellerName] = useState(null);

    const [viewerSession, setViewerSession] = useState(2);

    const [conditionValue, setConditionValue] = useState(0);

    const [pricingOption, setPricingOption] = useState(1);

    const [calculationsDone, setCalculationsDone] = useState(1);

    const [mgApplied, setMGApplied] = useState(1);

    const [initializePricingLadder, setInitializePricingLadder] = useState([{ from: 1, to: 2, value: 0, minimum: 0 }]);

    const [isPricingLadderValid, setIsPricingLadderValid] = useState(true);

    const [pdfFileName, setPdfFileName] = useState('');

    const [appendPDFFile, setAppendPDFFile] = React.useState(null);

    const [previewPDFURL, setPreviewPDFURL] = React.useState(null);

    const [isPreviewPDFURL, setIsPreviewPDFURL] = React.useState(false);

    const handleClickRestrictButton = (value) => {
        setIsInclude(value)
    }

    useEffect(() => {
        setUserTracking(selector);

        if (selector) {

            setNewContract({
                ...newContract,
                buyer: selector.get('BelongsTo')
            })
        }
    }, [selector]);

    useEffect(() => {
        if (selector) {
            if (localStorage.getItem('id') && localStorage.getItem('token')) {
                (async function getListOrg() {
                    showLoading();
                    const listOrgResponse = await axios.get(`/org/get-list-connected-orgs/${selector.get('BelongsTo')}`, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });

                    const { data, status } = listOrgResponse;

                    if (status === 200) {
                        setListOrg(data);
                    }
                    hideLoading();
                })();
            }
        }
    }, [selector]);

    useEffect(() => {
        (async function getListCountry() {
            showLoading();
            const listCountryResponse = await axios.get('/country/get-list-countries', {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            const { data, status } = listCountryResponse;

            if (status === 200) {
                setCountries(data);
            }
            hideLoading();
        })();
    }, []);

    const [mentionAsValueItem, setMentionAsValueItem] = useState([1, 2, 9, 10, 11, 12, 13, 14, 3]);



    useEffect(() => {
        setMentionAsValueItem(mentionAsValueItem)
    }, [mentionAsValueItem]);

    useEffect(() => {
        if (user) {
            (async function getListContentSeller() {
                showLoading();
                const sellerContentResponse = await axios.get(`/content/get-list-avail-contents?orgUid=${pickSeller}`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

                const { data, status } = sellerContentResponse;

                if (status === 200) {
                    setListContentSeller(data);
                }
                hideLoading();
            })();

            (async function getSellerName() {
                const sellerContentResponse = await axios.get(`/org/get-one/${pickSeller}`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

                const { data, status } = sellerContentResponse;

                if (status === 200) {
                    setSellerName(data.Name);
                }
                hideLoading();
            })();
        }
    }, [pickSeller]);

    const saveDataContract = (value, name) => {
        setDataContract({
            ...dataContract,
            [name]: value,
        });
    }

    const handleChangeMarketShare = (event) => {
        setIsMarketShare(event.target.checked);
    }

    const handleChangeSeller = (e, value) => {
        setPickSeller(value.ikastUID);
        setPickSellerLogo(value.orgLogo);
        setNewContract({
            ...newContract,
            seller: value.ikastUID,
            contents: []
        });
    };

    const handleLicensedContentChosen = (list) => {
        const listLicensedContents = list ? list.map(content => content.ikastUID) : [];
        setNewContract({
            ...newContract,
            contents: listLicensedContents
        })
    };

    const handlePricingLadder = (formValue) => {
        pricingLadderValidation(formValue);
        setInitializePricingLadder(formValue);
        let pricingLadderRange = [];
        let pricingLadderBilledRange = [];
        let pricingLadderMinimum = [];

        formValue.forEach((item) => {
            pricingLadderRange.push(item.from);
            pricingLadderBilledRange.push(item.value);
            pricingLadderMinimum.push(item.minimum);
        });
        setNewContract({
            ...newContract,
            pricingLadderRange: pricingLadderRange,
            pricingLadderBilledRange: pricingLadderBilledRange,
            pricingLadderMinimum: pricingLadderMinimum,
        })
    }

    const pricingLadderValidation = (formValue) => {
        if (formValue.length > 1) {
            for (let i = 0; i < formValue.length - 1; i++) {
                if (+formValue[i].to <= +formValue[i].from || ((i <= formValue.length - 2) && (+formValue[i + 1].from !== +formValue[i].to + 1))) {
                    setIsPricingLadderValid(false);
                    break;
                } else {
                    setIsPricingLadderValid(true);
                }
            }
        }
    }

    const mappingElementContract = (e, value) => {
        const monetized = e.target.value;
        switch (monetized) {
            case 1:
                setMentionAsValueItem([1, 12, 13, 14]);
                setNewContract({
                    ...newContract,
                    bussinessModel: monetized,
                    flatRate: 0,
                    MG: 0,
                    nbAdsServed: 0,
                    viewerCount: 0,
                    minutesWatched: 0,
                    revPerSubs: 0,
                    nbTransaction: 0,
                    revPerAds: 0,
                    durationMode: null,
                    conditionValue: null,
                    pricingOption: 1,
                    calculationsDone: 1,
                    mgApplied: mgApplied ? mgApplied : 1,
                    pricingLadderRange: null,
                    pricingLadderBilledRange: null,
                    pricingLadderMinimum: null
                });

                setFlatRate(0);
                setMG(0);
                setViewerCount(0);
                setMinutesWatched(0);
                setNbTransaction(0);
                setNbAdsServed(0);
                setRevPerSubs(0);
                setRevPerAds(0);
                setPricingOption(1);
                setCalculationsDone(1);
                setMGApplied(mgApplied ? mgApplied : 1);

                return;
            case 2:
                setMentionAsValueItem(!mgApplied || mgApplied === 1 ? [1, 2, 12, 13, 14, 7] : [1, 15, 12, 13, 14, 7]);
                setNewContract({
                    ...newContract,
                    bussinessModel: monetized,
                    flatRate: 0,
                    MG: 0,
                    nbAdsServed: 0,
                    viewerCount: 0,
                    minutesWatched: 0,
                    revPerSubs: 0,
                    nbTransaction: 0,
                    revPerAds: 0,
                    durationMode: null,
                    conditionValue: null,
                    pricingOption: 1,
                    calculationsDone: 1,
                    mgApplied: mgApplied ? mgApplied : 1,
                    pricingLadderRange: null,
                    pricingLadderBilledRange: null,
                    pricingLadderMinimum: null
                });

                setFlatRate(0);
                setMG(0);
                setViewerCount(0);
                setMinutesWatched(0);
                setNbTransaction(0);
                setNbAdsServed(0);
                setRevPerSubs(0);
                setRevPerAds(0);
                setPricingOption(1);
                setCalculationsDone(1);
                setMGApplied(mgApplied ? mgApplied : 1);

                return;
            case 3:
                setMentionAsValueItem(!mgApplied || mgApplied === 1 ? [1, 2, 12, 13, 14, 5] : [1, 15, 12, 13, 14, 5]);
                setNewContract({
                    ...newContract,
                    bussinessModel: monetized,
                    flatRate: 0,
                    MG: 0,
                    nbAdsServed: 0,
                    viewerCount: 0,
                    minutesWatched: 0,
                    revPerSubs: 0,
                    nbTransaction: 0,
                    revPerAds: 0,
                    durationMode: null,
                    conditionValue: null,
                    pricingOption: 1,
                    calculationsDone: 1,
                    mgApplied: mgApplied ? mgApplied : 1,
                    pricingLadderRange: null,
                    pricingLadderBilledRange: null,
                    pricingLadderMinimum: null
                });

                setFlatRate(0);
                setMG(0);
                setViewerCount(0);
                setMinutesWatched(0);
                setNbTransaction(0);
                setNbAdsServed(0);
                setRevPerSubs(0);
                setRevPerAds(0);
                setPricingOption(1);
                setCalculationsDone(1);
                setMGApplied(mgApplied ? mgApplied : 1);

                return;
            case 4:
                setMentionAsValueItem(!mgApplied || mgApplied === 1 ? [1, 2, 9, 10, 11, 12, 13, 14, 5] : [1, 15, 9, 10, 11, 12, 13, 14, 5]);
                setNewContract({
                    ...newContract,
                    bussinessModel: monetized,
                    flatRate: 0,
                    MG: 0,
                    nbAdsServed: 0,
                    viewerCount: 0,
                    minutesWatched: 0,
                    revPerSubs: 0,
                    nbTransaction: 0,
                    revPerAds: 0,
                    durationMode: 2,
                    conditionValue: 0,
                    pricingOption: 1,
                    calculationsDone: 1,
                    mgApplied: mgApplied ? mgApplied : 1,
                    pricingLadderRange: null,
                    pricingLadderBilledRange: null,
                    pricingLadderMinimum: null
                });

                setFlatRate(0);
                setMG(0);
                setViewerCount(0);
                setMinutesWatched(0);
                setNbTransaction(0);
                setNbAdsServed(0);
                setRevPerSubs(0);
                setRevPerAds(0);
                setViewerSession(2);
                setConditionValue(0);
                setPricingOption(1);
                setCalculationsDone(1);
                setMGApplied(mgApplied ? mgApplied : 1);

                return;
            case 5:
                setMentionAsValueItem(!mgApplied || mgApplied === 1 ? [1, 2, 9, 10, 11, 12, 13, 14, 3] : [1, 15, 9, 10, 11, 12, 13, 14, 3]);
                setNewContract({
                    ...newContract,
                    bussinessModel: monetized,
                    flatRate: 0,
                    MG: 0,
                    nbAdsServed: 0,
                    viewerCount: 0,
                    minutesWatched: 0,
                    revPerSubs: 0,
                    nbTransaction: 0,
                    revPerAds: 0,
                    durationMode: 2,
                    conditionValue: 0,
                    pricingOption: 1,
                    calculationsDone: 1,
                    mgApplied: mgApplied ? mgApplied : 1,
                    pricingLadderRange: null,
                    pricingLadderBilledRange: null,
                    pricingLadderMinimum: null
                });

                setFlatRate(0);
                setMG(0);
                setViewerCount(0);
                setMinutesWatched(0);
                setNbTransaction(0);
                setNbAdsServed(0);
                setRevPerSubs(0);
                setRevPerAds(0);
                setViewerSession(2);
                setConditionValue(0);
                setPricingOption(1);
                setCalculationsDone(1);
                setMGApplied(mgApplied ? mgApplied : 1);

                return;
            case 6:
                setMentionAsValueItem([1, 2, 6, 8]);
                setNewContract({
                    ...newContract,
                    bussinessModel: monetized,
                    flatRate: 0,
                    MG: 0,
                    nbAdsServed: 0,
                    viewerCount: 0,
                    minutesWatched: 0,
                    revPerSubs: 0,
                    nbTransaction: 0,
                    revPerAds: 0,
                    durationMode: null,
                    conditionValue: null,
                    pricingOption: null,
                    calculationsDone: null,
                    mgApplied: null,
                    pricingLadderRange: null,
                    pricingLadderBilledRange: null,
                    pricingLadderMinimum: null
                });

                setFlatRate(0);
                setMG(0);
                setViewerCount(0);
                setMinutesWatched(0);
                setNbTransaction(0);
                setNbAdsServed(0);
                setRevPerSubs(0);
                setRevPerAds(0);
                setPricingOption(null);
                setCalculationsDone(null);
                setMGApplied(null);

                return;

            default:
                setMentionAsValueItem([1, 2, 9, 10, 11, 12, 13, 14, 3]);
                setNewContract({
                    ...newContract,
                    bussinessModel: monetized,
                    flatRate: 0,
                    MG: 0,
                    nbAdsServed: 0,
                    viewerCount: 0,
                    minutesWatched: 0,
                    revPerSubs: 0,
                    nbTransaction: 0,
                    revPerAds: 0,
                    durationMode: 2,
                    conditionValue: 0,
                    pricingOption: 1,
                    calculationsDone: 1,
                    mgApplied: 1,
                    pricingLadderRange: null,
                    pricingLadderBilledRange: null,
                    pricingLadderMinimum: null
                });
                return;
        }
    };

    const handleChangeInputNewContract = (e) => {
        const { name, value } = e.target;
        if (value.trim().length > 0) {
            setIsSaveButtonDisable(false)
        }
        if (value.trim().length == 0) {
            setIsSaveButtonDisable(true)
        }
        if (value.trim().length <= 255) {
            setNewContract({
                ...newContract,
                [name]: value
            });
        }
    };

    const handleChangeFlatRate = (e) => {
        const { name, value } = e.target;
        setFlatRate(convertAmountToNumber(value));
        setNewContract({
            ...newContract,
            [name]: convertAmountToNumber(value)
        });
    };

    const handleChangeMG = (e) => {
        const { name, value } = e.target;

        setMG(convertAmountToNumber(value));

        setNewContract({
            ...newContract,
            [name]: convertAmountToNumber(value)
        });
    };

    const handleChangeNbTransaction = (e) => {
        const { name, value } = e.target;

        setNbTransaction(value);

        setNewContract({
            ...newContract,
            [name]: value
        });
    };

    const handleChangeViewerCount = (e) => {
        const { name, value } = e.target;

        setViewerCount(convertAmountToNumber(value));

        setNewContract({
            ...newContract,
            [name]: convertAmountToNumber(value)
        });
    };

    const handleChangeMinutesWatched = (e) => {
        const { name, value } = e.target;

        setMinutesWatched(convertAmountToNumber(value));

        setNewContract({
            ...newContract,
            [name]: convertAmountToNumber(value)
        });
    };

    const handleChangeExclusive = (e) => {
        setExclusive(e.target.checked);
        setNewContract({
            ...newContract,
            [e.target.name]: e.target.checked
        })
    };

    const handleChangeNbAdsServed = (e) => {
        const { name, value } = e.target;

        setNbAdsServed(value);

        setNewContract({
            ...newContract,
            [name]: value
        });
    };

    const handleChangeRevPerSubs = (e) => {
        const { name, value } = e.target;

        setRevPerSubs(convertAmountToNumber(value));

        setNewContract({
            ...newContract,
            [name]: convertAmountToNumber(value)
        });
    };

    const handleChangeMarketShareBonusValue = (e) => {
        const { name, value } = e.target;

        setMarketShareBonusValue(convertAmountToNumber(value));

        setNewContract({
            ...newContract,
            [name]: convertAmountToNumber(value)
        });
    }

    const handleChangeRevPerAds = (e) => {
        const { name, value } = e.target;

        setRevPerAds(convertAmountToNumber(value));

        setNewContract({
            ...newContract,
            [name]: convertAmountToNumber(value)
        });
    };

    const handleChangeTerritories = (e, value) => {
        const countries = value.map(country => country.ikastUID);

        setNewContract({
            ...newContract,
            countries
        })
    };

    const handleChangeDatetime = (e) => {
        const { name, value } = e.target;
        let statusErrorDate = false;
        if (value === "") {
            statusErrorDate = true;
        } else {
            const dateArray = value.split('-');
            if (parseInt(dateArray[0]) < 1970) {
                statusErrorDate = true;;
            } else {
                statusErrorDate = false;
            }
        }
        if (name === 'startDate') {
            setErrorStartDate(statusErrorDate);
        } else {
            setErrorEndDate(statusErrorDate);
        }
        setNewContract({
            ...newContract,
            [name]: value
        });
    };

    const checkValueDate = () => {
        let statusErrorStartDate = false;
        let statusErrorEndDate = false;
        if (!newContract.startDate) {
            statusErrorStartDate = true;
        } else {
            const dateArray = newContract.startDate.split('-');
            if (parseInt(dateArray[0]) < 1970) {
                statusErrorStartDate = true;
            } else {
                statusErrorStartDate = false;
            }
        }

        if (!newContract.endDate) {
            statusErrorEndDate = true;
        } else {
            const dateArray = newContract.endDate.split('-');
            if (parseInt(dateArray[0]) < 1970) {
                statusErrorEndDate = true;
            } else {
                statusErrorEndDate = false;
            }
        }
        setErrorStartDate(statusErrorStartDate);
        setErrorEndDate(statusErrorEndDate);
        if (statusErrorStartDate && statusErrorEndDate) {
            enqueueSnackbar(t("contract.end_start_date_require"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        };
        if (statusErrorStartDate && !statusErrorEndDate) {
            enqueueSnackbar(t("contract.start_date_require"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
        if (!statusErrorStartDate && statusErrorEndDate) {
            enqueueSnackbar(t("contract.end_date_require"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
        if (statusErrorStartDate || statusErrorEndDate) {
            return true
        } else return false;
    }

    const handleCommentsChange = (e) => {
        const { name, value } = e.target;

        setNewContract({
            ...newContract,
            [name]: value
        });
    };

    const handleCreateNewContract = async () => {
        if (checkValueDate()) return;
        let validStartDate = new Date(newContract.startDate);
        let validEndDate = new Date(newContract.endDate);
        if (validStartDate.getTime() > validEndDate.getTime()) {
            enqueueSnackbar(t("contract.endDate_greater_startDate"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            return;
        }

        if (newContract.bussinessModel !== 6 && !isPricingLadderValid) {
            enqueueSnackbar(t("contract.invalid_pricing_ladder"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            return;
        }

        try {
            let formattedNewContract = {
                ...newContract,
                MG: (newContract.MG && newContract.MG !== "") ? Number.parseFloat(newContract.MG) : 0,
                flatRate: (newContract.flatRate && newContract.flatRate !== "") ? Number.parseFloat(newContract.flatRate) : 0,
                viewerCount: (newContract.viewerCount && newContract.viewerCount !== "") ? Number.parseFloat(newContract.viewerCount) : 0,
                minutesWatched: (newContract.minutesWatched && newContract.minutesWatched !== "") ? Number.parseFloat(newContract.minutesWatched) : 0,
                revPerSubs: (newContract.revPerSubs && newContract.revPerSubs !== "") ? Number.parseFloat(newContract.revPerSubs) : 0,
                revPerAds: (newContract.revPerAds && newContract.revPerAds !== "") ? Number.parseFloat(newContract.revPerAds) : 0,
                ethContract: newContract.ethContract,
                foreignKey: newContract.foreignKey,
                anchorDate: newContract.anchorDate,
                devices: newContract.devices,
                businessModel: newContract.bussinessModel,
                countryRestriction: isInclude,
                comments: newContract.comments,
                isWeightOff: isMarketShare,
                marketShareBonusValue: (newContract.marketShareBonusValue && newContract.marketShareBonusValue !== "") ? Number.parseFloat(newContract.marketShareBonusValue) : 0,

            };

            if (formattedNewContract.countries && formattedNewContract.countries.length === 0) {
                formattedNewContract.countries = undefined
            }

            if (formattedNewContract.contents && formattedNewContract.contents.length === 0) {
                formattedNewContract.contents = undefined
            }

            const newContractResponse = await axios.post('/contract', formattedNewContract, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            const { status } = newContractResponse;

            if (status === 200) {
                enqueueSnackbar(t("contract.create_contract_success"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });

                if (appendPDFFile) {
                    const urlToUpload = newContractResponse.data.record.uploadPDFUrl;
                    const options = { headers: { 'Content-Type': appendPDFFile.type } };
                    const uploadPDF = await axios.put(urlToUpload, appendPDFFile, options);
                }

                history.push('/contracts');
            } else {
                enqueueSnackbar(t("notify.something_wrong"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }
        } catch (e) {
            enqueueSnackbar(`${t("notify.something_wrong")}: ${e.message}`, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
    };

    const handleValidateTitle = (e) => {
        const title = e.target.value;
        // handleChangeInputNewContract(e);
        if (!title) {
            setValidTitle(false);
        } else {
            setValidTitle(true);
        }
    };

    const handleChangeViewerSession = (e) => {
        setViewerSession(e.target.value);
        const conditionInitValue = e.target.value === 1 ? 5 : 0
        setConditionValue(conditionInitValue);
        setNewContract({
            ...newContract,
            durationMode: e.target.value,
            conditionValue: conditionInitValue
        });
    }

    const handleChangeConditionValue = (e) => {
        setConditionValue(e.target.value);
        setNewContract({
            ...newContract,
            conditionValue: convertAmountToNumber(e.target.value)
        })
    }

    const handlePricingChange = (e) => {
        setNewContract({
            ...newContract,
            pricingOption: e.target.value
        })
        setPricingOption(e.target.value);
    }

    const handleCalculationsChange = (e) => {
        setNewContract({
            ...newContract,
            calculationsDone: e.target.value
        })
        setCalculationsDone(e.target.value);
    }

    const handleChangeMGApplied = (e) => {
        setNewContract({
            ...newContract,
            mgApplied: e.target.value
        })
        setMGApplied(e.target.value);

        if (e.target.value !== 1) {
            const tempMentionAsValueItem = mentionAsValueItem.filter(item => item !== 2);
            tempMentionAsValueItem.push(15);
            setMentionAsValueItem(tempMentionAsValueItem);
        } else {
            const tempMentionAsValueItem = mentionAsValueItem.filter(item => item !== 15);
            if (newContract.bussinessModel !== 1 && newContract.bussinessModel !== 6) {
                tempMentionAsValueItem.push(2);
                setMentionAsValueItem(tempMentionAsValueItem);
            }
        }
    }

    const uploadPDFFile = (e) => {
        if (e.length === 0) {
            setAppendPDFFile(null);
            setNewContract({
                ...newContract,
                pdfFileName: '',
                contentType: ''
            });
            return;
        }

        if (e.target.files[0].type !== 'application/pdf') {
            enqueueSnackbar(t("contract.only_pdf"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            return;
        }
        setPreviewPDFURL(URL.createObjectURL(e.target.files[0]));
        setAppendPDFFile(e.target.files[0]);
        setPdfFileName(e.target.files[0].name);
        setNewContract({
            ...newContract,
            pdfFileName: e.target.files[0].name,
            contentType: e.target.files[0].type
        });
    }

    const removePDFFile = () => {
        setPdfFileName('');
        setAppendPDFFile(null);
        setNewContract({
            ...newContract,
            pdfFileName: '',
            contentType: ''
        });
    }

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <Layout title="Add Content">
            <div className='flex justify-between content-center'>
                <div>
                    {t("contract.breadcrumb_new")}
                    <p style={{ fontSize: '24px' }}>
                        {t("contract.create_new_contract")}
                    </p>
                </div>
                <div className='flex' style={{
                    height: '36px'
                }}>
                    <Button
                        disabled={isSaveButtonDisable}
                        onClick={handleCreateNewContract}
                        style={{
                            height: '100%',
                            padding: '6px 16px',
                            backgroundColor: '#D05E5A',
                            marginLeft: '0.5rem',
                            color: 'white',
                            fontSize: '0.75rem'
                        }}>
                        {t("common.save")}
                    </Button>
                </div>
            </div>
            <Tabs value={valueTab} onChange={handleChange} aria-label="basic tabs example">
                <Tab label={<b>{t("contract.identification")}</b>} {...a11yProps(0)} />
                <Tab label={<b>{t("contract.business_rules")}</b>} {...a11yProps(1)} />
                <Tab label={<b>{t("contract.comments")}</b>} {...a11yProps(2)} />
            </Tabs>
            <TabPanel valueTab={valueTab} index={0}>
                <IdentificationTab
                    screenDisable={false}
                    contractAddingInit={contractAddingInit}
                    saveDataContract={saveDataContract}
                    classes={classes}
                    newContract={newContract}
                    validTitle={validTitle}
                    handleValidateTitle={handleValidateTitle}
                    listOrg={listOrg}
                    handleChangeSeller={handleChangeSeller}
                    pickSellerLogo={pickSellerLogo}
                    exclusive={exclusive}
                    handleChangeExclusive={handleChangeExclusive}
                    previewPDFURL={previewPDFURL}
                    uploadPDFFile={uploadPDFFile}
                    pdfFileName={pdfFileName}
                    removePDFFile={removePDFFile}
                    setIsPreviewPDFURL={setIsPreviewPDFURL}
                    outlinedInputClasses={outlinedInputClasses}
                    user={user}
                    sellerName={sellerName}
                    listContentSeller={listContentSeller}
                    handleLicensedContentChosen={handleLicensedContentChosen}
                    isPreviewPDFURL={isPreviewPDFURL}
                    handleChangeInputNewContract={handleChangeInputNewContract}
                />
            </TabPanel>
            <TabPanel valueTab={valueTab} index={1}>
                <BusinessRuleTab
                    screenDisable={false}
                    classes={classes}
                    newContract={newContract}
                    mappingElementContract={mappingElementContract}
                    mentionAsValueItem={mentionAsValueItem}
                    handleChangeFlatRate={handleChangeFlatRate}
                    flatRate={flatRate}
                    user={user}
                    handleChangeMG={handleChangeMG}
                    MG={MG}
                    viewerSession={viewerSession}
                    handleChangeViewerSession={handleChangeViewerSession}
                    handleChangeConditionValue={handleChangeConditionValue}
                    conditionValue={conditionValue}
                    pricingOption={pricingOption}
                    handlePricingChange={handlePricingChange}
                    calculationsDone={calculationsDone}
                    handleCalculationsChange={handleCalculationsChange}
                    handleChangeMGApplied={handleChangeMGApplied}
                    handleChangeViewerCount={handleChangeViewerCount}
                    viewerCount={viewerCount}
                    handleChangeMinutesWatched={handleChangeMinutesWatched}
                    minutesWatched={minutesWatched}
                    nbTransaction={nbTransaction}
                    handleChangeNbTransaction={handleChangeNbTransaction}
                    handleChangeNbAdsServed={handleChangeNbAdsServed}
                    nbAdsServed={nbAdsServed}
                    revPerSubs={revPerSubs}
                    handleChangeRevPerSubs={handleChangeRevPerSubs}
                    handleChangeRevPerAds={handleChangeRevPerAds}
                    revPerAds={revPerAds}
                    initializePricingLadder={initializePricingLadder}
                    handlePricingLadder={handlePricingLadder}
                    mgApplied={mgApplied}
                    handleClickRestrictButton={handleClickRestrictButton}
                    isInclude={isInclude}
                    countries={countries}
                    handleChangeTerritories={handleChangeTerritories}
                    handleChangeDatetime={handleChangeDatetime}
                    isMarketShare={isMarketShare}
                    handleChangeMarketShare={handleChangeMarketShare}
                    marketShareBonusValue={marketShareBonusValue}
                    handleChangeMarketShareBonusValue={handleChangeMarketShareBonusValue}
                    errorEndDate={errorEndDate}
                    errorStartDate={errorStartDate}
                />
            </TabPanel>
            <TabPanel valueTab={valueTab} index={2}>
                <div className='bg-white px-8 py-8'>

                    <div style={{ paddingTop: '15px' }}>
                        <div className='w-full'>
                            <textarea
                                className='w-full'
                                name='comments'
                                maxLength='3000'
                                rows='10'
                                style={{
                                    height: '300px',
                                    borderRadius: '4px',
                                    border: '1px solid #ced4da',
                                    backgroundColor: 'white',
                                    paddingLeft: '10px'
                                }}
                                onChange={handleCommentsChange}>
                            </textarea>
                        </div>
                    </div>
                </div>

            </TabPanel>
        </Layout>
    )
};

export default withSnackbar(AddContract)
