import React, { Fragment, useMemo } from 'react';
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import TextField from "@material-ui/core/TextField/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import PricingLadder from "../../components/Contract/PricingLadder";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from '@material-ui/core/Switch';
import { useTranslation } from 'react-i18next';
import TransferList from '../../components/Contract/TransferList';
import {
    GET_STATUS
} from '../../../constants';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import './index.css';
const IdentificationTab = (props) => {
    const { t, i18n } = useTranslation();
    const STATUS = useMemo(() => GET_STATUS(t), [t]);
    const { screenDisable, classes, newContract, validTitle, handleValidateTitle, listOrg, handleChangeSeller, pickSellerLogo, exclusive, handleChangeExclusive, previewPDFURL, handleChangeInputNewContract,
        uploadPDFFile, pdfFileName, removePDFFile, setIsPreviewPDFURL, outlinedInputClasses, user, sellerName, listContentSeller, handleLicensedContentChosen, isPreviewPDFURL, } = props;

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#696969',
            color: '#dbdbdb',
            marginBottom: 0,
            fontSize: 14,
        },
    }))(Tooltip);

    const useStyle = makeStyles({
        root: {
            marginTop: '1.5rem'
        },
        moneyInput: {
            width: '120px',
        },
        selectMonetized: {
            padding: '10px',
        }
    });

    return (
        <div className='bg-white px-8 py-8'>
            <div className='flex justify-between content-center w-full pb-4'
                style={{
                    marginTop: '20px'
                }}>
                <div className='add-contracts-top flex w-full'>
                    <div className='add-contracts-title w-3/4'>
                        <div className='font-bold'>{t("contract.title")}</div>
                        <div className='w-full'>
                            <TextField
                                id='add-contract-title'
                                className={classes.root}
                                style={{ width: '100%' }}
                                variant="outlined"
                                maxLength="255"
                                name='title'
                                type="text"
                                key='add-contract-title'
                                disabled={screenDisable}
                                defaultValue={newContract.title}
                                error={!validTitle}
                                onBlur={handleValidateTitle}
                                autoComplete="off"
                                helperText={validTitle ? '' : t("contract.title_require")}
                                onChange={handleChangeInputNewContract}
                            />
                        </div>
                    </div>
                    <div className='add-contracts-status ml-3 w-1/4'>
                        <div className='font-bold'>{t("contract.status")}</div>
                        <div id="contract-status" className='flex items-center'>
                            <span id='status' className='rounded-full inline-block m-1' style={{
                                backgroundColor: STATUS.find(s => s.default).color,
                                height: '15px',
                                width: '15px',
                            }} />
                            <label className=''>
                                {STATUS.find(s => s.default).display}
                            </label>
                        </div>

                    </div>
                </div>
            </div>
            <div className='flex justify-between content-center w-full pb-4'
                style={{
                    marginTop: '10px'
                }}>
                <div className='add-contracts-top flex w-full' style={{ alignItems: 'center', marginLeft: '-8px' }}>
                    <input
                        disabled={screenDisable}
                        accept="application/pdf"
                        id="contained-button-file"
                        type="file"
                        style={{ display: 'none' }}
                        onClick={e => (e.target.value = null)}
                        onChange={uploadPDFFile}
                    />
                    <label htmlFor="contained-button-file">
                        <Button disabled={screenDisable} variant="contained" component="span" style={{
                            height: '100%',
                            padding: '6px 16px',
                            backgroundColor: '#D05E5A',
                            marginLeft: '0.5rem',
                            color: 'white',
                            fontSize: '0.75rem'
                        }}>
                            {t("contract.attach_file")}
                        </Button>
                    </label>
                    {pdfFileName !== '' ?
                        <div component="span" style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '24px',
                            borderRadius: '16px',
                            backgroundColor: '#e0e0e0',
                            marginLeft: '1rem',
                            color: 'rgba(0, 0, 0, 0.87)',
                            fontSize: '1rem'
                        }}>
                            <span style={{ paddingLeft: '8px', paddingRight: '8px' }}>{pdfFileName}</span>
                            <LightTooltip title={t("contract.remove")} placement="top">
                                <button disabled={screenDisable} onClick={removePDFFile}>
                                    <HighlightOffIcon
                                        style={{
                                            fontSize: '1.75rem',
                                            color: '#454f6059',
                                            paddingRight: '7px'
                                        }}>
                                    </HighlightOffIcon>
                                </button>
                            </LightTooltip>

                            <LightTooltip title={t("contract.preview")} placement="top">
                                <button onClick={() => { setIsPreviewPDFURL(true) }}>
                                    <VisibilityIcon
                                        style={{
                                            fontSize: '1.75rem',
                                            color: '#454f6059',
                                            paddingRight: '7px'
                                        }}>
                                    </VisibilityIcon>
                                </button>
                            </LightTooltip>
                        </div> : <span style={{
                            fontSize: '13px',
                            color: '#454f6059',
                            paddingLeft: '15px'
                        }}>{t("contract.no_file_attach")}</span>}
                </div>
            </div>
            <div className='grid grid-cols-3'>
                <div className='items-center mt-4'>
                    <InputLabel shrink style={{
                        color: 'rgba(0, 0, 0, 0.54)', marginTop: 'auto', width: '60%'
                    }}>
                        {t("contract.buyer")}
                    </InputLabel>
                    <OutlinedInput
                        classes={outlinedInputClasses}
                        inputProps={{
                            autoComplete: "off"
                        }}
                        disabled
                        style={{ marginTop: 'auto', width: '60%' }}
                        defaultValue={user ? user.getIn(['org', 'Name']) : ''}
                        startAdornment={
                            <InputAdornment position="start" style={{ marginLeft: '-13px' }}>
                                {user ? <img
                                    style={{
                                        height: '38px',
                                        width: '38px',
                                        maxWidth: 'none',
                                        borderTopLeftRadius: '3px',
                                        borderBottomLeftRadius: '3px',
                                    }}
                                    src={user.getIn(['org', 'logo'])} alt='logo' />
                                    : ''}
                            </InputAdornment>}
                    />
                </div>
                <div className='items-center mt-4'>
                    <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)', marginTop: 'auto', width: '60%' }}>
                        {t("contract.seller")}
                    </InputLabel>
                    {
                        listOrg &&
                        <Autocomplete
                            disabled={screenDisable}
                            onChange={handleChangeSeller}
                            options={listOrg}
                            value={listOrg.find(seller => seller.ikastUID === newContract.seller)}
                            getOptionLabel={(e) => e.Name ? e.Name : ''}
                            size='small'
                            disableClearable
                            className='mt-2'
                            style={{ marginTop: 'auto', width: '60%' }}
                            classes={{
                                root: classes.root
                            }}
                            renderOption={option => {
                                return (
                                    <Fragment>
                                        {option.orgLogo ? <img
                                            style={{
                                                marginLeft: '-5px',
                                                height: '38px',
                                                width: '38px',
                                                maxWidth: 'none',
                                                borderTopLeftRadius: '3px',
                                                borderBottomLeftRadius: '3px',
                                            }}
                                            src={option.orgLogo} alt='logo' />
                                            : ''}
                                        <p className='ml-2'>{option.Name}</p>
                                    </Fragment>
                                )
                            }}
                            renderInput={(params) => <TextField key={'seller'} {...params} variant="outlined" InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {pickSellerLogo ? <img
                                            style={{
                                                height: '38px',
                                                width: '38px',
                                                maxWidth: 'none',
                                                borderTopLeftRadius: '3px',
                                                borderBottomLeftRadius: '3px',
                                            }}
                                            src={pickSellerLogo} alt='logo' />
                                            : ''}
                                    </InputAdornment>
                                )
                            }} />}
                        />
                    }
                </div>

                <div className='items-center mt-4 flex'>
                    <div>
                        <Switch
                            classes={{
                                root: useStyle().root
                            }}
                            disabled={screenDisable}
                            checked={exclusive}
                            onChange={handleChangeExclusive}
                            name="exclusive"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </div>

                    <div>
                        {t("contract.deal_exclusive")}
                    </div>
                </div>
            </div>

            <div className='profile-title pb-2 pt-2' style={{ marginTop: '20px', borderBottom: '1px solid #D05E5A' }}>
                <b>{t("contract.content_package")}e</b>
            </div>
            <div className='my-4 pb-8'>
                <TransferList
                    disabled={screenDisable}
                    sellerName={sellerName}
                    listContent={listContentSeller}
                    pickedContent={newContract.contents}
                    licensedContent={handleLicensedContentChosen} />
            </div>
            <Dialog
                fullScreen={true}
                open={isPreviewPDFURL}
                onClose={() => { setIsPreviewPDFURL(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle>
                    {<button onClick={() => { setIsPreviewPDFURL(false) }} style={{ display: 'flex', alignItems: 'center', color: '#454f6059' }}>
                        <ArrowBackIosIcon style={{ color: '#454f6059' }} /><span>{t("contract.back")}</span></button>}
                </DialogTitle>
                <DialogContent>
                    {previewPDFURL ? <embed src={previewPDFURL} width='100%' height='100%' /> : ''}
                </DialogContent>
            </Dialog>
        </div>
    )
};

export default IdentificationTab;
