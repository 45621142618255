import React, { useEffect, useState, useMemo } from 'react';
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import TextField from "@material-ui/core/TextField/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import Switch from '@material-ui/core/Switch';
import {
    GET_BUSSINESS_MODELS,
    GET_CALCULATION,
    CURRENCY,
    GET_EVALUATED_OPTIONS, 
    GET_MG_APPLIED,
    GET_PRICING,
    GET_STATUS,
    GET_VIEWER_SESSION
} from '../../../../constants';
import { NumericFormat } from "react-number-format";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select/Select";
import Button from "@material-ui/core/Button";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import PricingLadder from "../../../components/Contract/PricingLadder";
import GeneralInfo from '../../../components/Royalties/GeneralInfo';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffTwoTone";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './index.css';
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from 'react-i18next';
const Identification = (props) => {
    const { t, i18n } = useTranslation();
    const BUSSINESS_MODELS = useMemo(() => GET_BUSSINESS_MODELS(t), [t]);
    const EVALUATED_OPTIONS = useMemo(() => GET_EVALUATED_OPTIONS(t), [t]);
    const VIEWER_SESSION = useMemo(() => GET_VIEWER_SESSION(t), [t]);
    const PRICING = useMemo(() => GET_PRICING(t), [t]);
    const STATUS = useMemo(() => GET_STATUS(t), [t]);
    const MG_APPLIED = useMemo(() => GET_MG_APPLIED(t), [t]);
    const CALCULATION = useMemo(() => GET_CALCULATION(t), [t]);
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#696969',
            color: '#dbdbdb',
            marginBottom: 0,
            fontSize: 14,
        },
    }))(Tooltip);

    const [isPDFPreview, setIsPDFPreview] = useState(false);
    const { isMarketShare, handleChangeMarketShare, contract, outlinedInputClasses, buyerName, listSeller, screenDisable, exclusive, classes, monetizedValue, user, initializePricingLadder, isInclude, countries, CURRENCY_UNIT, pdfFileName, pdfS3 } = props;
    useEffect(() => {
    }, []);

    return (
        <div>

            <div className='bg-white pt-4 pb-2 px-8'>
                {/* <div className='profile-title pb-2 pt-2 section-border-bottom'>
                    <b>Identification</b>
                </div> */}
                <div className='left-title mt-3 flex align-center'>
                    <div className='w50'>
                        <span className='inline-block' style={{ verticalAlign: 'middle', marginRight: '5px' }}>{contract.Title}</span>
                        <span id='status' className='inline-block rounded-full block m-2 status-icon' style={{
                            backgroundColor: STATUS.find(s => s.id === contract.Status).color,
                        }} />
                        {pdfFileName !== '' ?
                            <div component="span" style={{
                                marginLeft: '30px',
                                display: 'inline-flex',
                                alignItems: 'center',
                                height: '24px',
                                borderRadius: '16px',
                                backgroundColor: '#e0e0e0',
                                color: 'rgba(0, 0, 0, 0.87)',
                                fontSize: '1rem'
                            }}>
                                <span style={{ paddingLeft: '8px', paddingRight: '8px' }}>{pdfFileName}</span>
                                {pdfS3 ?
                                    <LightTooltip title='View' placement="top">
                                        <button onClick={() => { setIsPDFPreview(true) }}>
                                            <VisibilityIcon
                                                style={{
                                                    fontSize: '1.75rem',
                                                    color: '#454f6059',
                                                    paddingRight: '7px'
                                                }}>
                                            </VisibilityIcon >
                                        </button>
                                    </LightTooltip> : <CircularProgress
                                        style={{ marginRight: '7px' }}
                                        size={16}
                                    />
                                }

                            </div> : <span style={{
                                fontSize: '13px',
                                color: '#454f6059'
                            }}>{t("contract.no_file_attach")}</span>}
                    </div>
                    <div className='w50'>
                        <GeneralInfo contract={contract} updateContractScreen={true} />
                    </div>

                </div>
            </div>

            <Dialog
                fullScreen={true}
                open={isPDFPreview}
                onClose={() => { setIsPDFPreview(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle>
                    {<button onClick={() => { setIsPDFPreview(false) }} style={{ display: 'flex', alignItems: 'center', color: '#454f6059' }}>
                        <ArrowBackIosIcon style={{ color: '#454f6059' }} /><span>{t("contract.back")}</span></button>}
                </DialogTitle>
                <DialogContent>
                    {pdfS3 ? <object width='100%' height='100%' data={pdfS3} /> : ''}
                </DialogContent>
            </Dialog>

            <div className='bg-white px-8 pl-8'>
                <div className='profile-title pb-2 pt-2 section-border-bottom'>
                    <b>{t("contract.business_rules")}</b>
                </div>
                <div className='grid grid-cols-3 mt-4'>
                    <div className='items-center mt-4'>
                        <InputLabel shrink className='column-header' style={{ marginTop: 'auto', width: '60%' }}>
                        {t("contract.buyer")}
                        </InputLabel>
                        <OutlinedInput
                            classes={outlinedInputClasses}
                            inputProps={{ autoComplete: "off" }}
                            disabled
                            style={{ marginTop: 'auto', width: '60%' }}
                            defaultValue={buyerName}
                            startAdornment={
                                <InputAdornment position="start" style={{ marginLeft: '-13px' }}>
                                    {contract && contract.BuyerLogo ? <img className='buyer-logo' src={contract.BuyerLogo} alt='logo' />
                                        : <></>}
                                </InputAdornment>}
                        />
                    </div>
                    <div className='items-center mt-4'>
                        <InputLabel shrink className='column-header' style={{ marginTop: 'auto', width: '60%' }}>
                        {t("contract.seller")}
                        </InputLabel>
                        {
                            listSeller &&
                            <Autocomplete
                                disabled={screenDisable}
                                value={listSeller.find(seller => seller.ikastUID === contract.Seller)}
                                options={listSeller}
                                getOptionLabel={(e) => e.Name}
                                style={{ marginTop: 'auto', width: '60%' }}
                                size='small'
                                disableClearable
                                className='mt-2'
                                renderInput={(params) => <TextField {...params} variant="outlined" InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {(listSeller.find(seller => seller.ikastUID === contract.Seller)).orgLogo ?
                                                <img
                                                    className='buyer-logo'
                                                    src={(listSeller.find(seller => seller.ikastUID === contract.Seller)).orgLogo} alt='logo' />
                                                : ''}
                                        </InputAdornment>
                                    )
                                }} />}
                            />
                        }
                    </div>

                    <div className='items-center mt-4 flex'>
                        <div>
                            <Switch
                                checked={exclusive}
                                disabled={screenDisable}
                                className={classes.root}
                                name="exclusive"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </div>

                        <div>{t("contract.deal_exclusive")}</div>
                    </div>
                </div>

                <div className='grid grid-cols-3 mt-4'>
                    <div className='items-center mt-4'>
                        <InputLabel shrink className='column-header' style={{ marginBottom: '0.5rem', width: '60%' }}>
                        {t("contract.monetized_as")}
                        </InputLabel>

                        <Select
                            disabled={screenDisable}
                            value={BUSSINESS_MODELS.find(businessModel => businessModel.id === contract.businessModel).id}
                            style={{ marginTop: 'auto', width: '60%' }}
                            variant='outlined'
                            classes={{ select: classes.selectMonetized }}>
                            {
                                BUSSINESS_MODELS && BUSSINESS_MODELS.map((value, key) => {
                                    return (
                                        <MenuItem key={key} value={value.id}>{value.value}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    {
                        monetizedValue && monetizedValue.includes(1) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink className='column-header' style={{ width: '60%' }}>
                            {t("contract.flat")}
                            </InputLabel>
                            {
                                <NumericFormat
                                    disabled={screenDisable}
                                    value={Number.parseFloat(contract.flatRate)}
                                    className='global-id text-right'
                                    style={{ width: '60%' }}
                                    decimalScale={2}
                                    name='flatRate'
                                    thousandSeparator={t('common.thousand_separator')} 
                                    decimalSeparator={t('common.decimal_separator')}
                                    allowNegative={false}
                                />
                            }
                            <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>
                                {CURRENCY.find(c => c.id.toString() === localStorage.getItem('mainCurrency')).value}</span>
                        </div>
                    }
                    {
                        monetizedValue && monetizedValue.includes(2) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)',
                                width: '60%'
                            }}>
                                {t("contract.minimum_guaranteed")}
                            </InputLabel>
                            {
                                <NumericFormat
                                    disabled={screenDisable}
                                    value={Number.parseFloat(contract.MG)}
                                    className='global-id text-right'
                                    style={{
                                        width: '60%'
                                    }}
                                    decimalScale={3}
                                    name='MG'
                                    thousandSeparator={t('common.thousand_separator')} 
                                    decimalSeparator={t('common.decimal_separator')}
                                    allowNegative={false}
                                />
                            }
                            <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>
                                {CURRENCY.find(c => c.id.toString() === localStorage.getItem('mainCurrency')).value}</span>
                        </div>
                    }

                    {
                        monetizedValue && monetizedValue.includes(9) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {t("contract.view_evaluated")}
                            </InputLabel>
                            <TextField
                                id="selectEvaluatedOption"
                                disabled={screenDisable}
                                style={{
                                    marginTop: '0.5rem',
                                    width: '60%'
                                }}
                                className={classes.root}
                                variant='outlined'
                                defaultValue={EVALUATED_OPTIONS.find(b => b.default).id}
                                select>
                                {
                                    EVALUATED_OPTIONS && EVALUATED_OPTIONS.map((value, key) => {
                                        return (
                                            <MenuItem key={key} value={value.id}>{value.value}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </div>
                    }

                    {
                        monetizedValue && monetizedValue.includes(10) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                 {t("contract.where_viewer_session")}
                            </InputLabel>
                            <TextField
                                id="durationMode"
                                disabled={screenDisable}
                                style={{
                                    marginTop: '0.5rem',
                                    width: '60%'
                                }}
                                value={contract.durationMode ? contract.durationMode : VIEWER_SESSION.find(b => b.default).id}
                                className={classes.root}
                                variant='outlined'
                                select>
                                {
                                    VIEWER_SESSION && VIEWER_SESSION.map((value, key) => {
                                        return (
                                            <MenuItem key={key} value={value.id}>{value.value}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </div>
                    }

                    {
                        monetizedValue && monetizedValue.includes(11) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {t("contract.condition")}
                            </InputLabel>
                            <NumericFormat
                                className='global-id text-right'
                                disabled={screenDisable}
                                style={{
                                    width: '60%'
                                }}
                                name='condition'
                                value={Number.parseFloat(contract.conditionValue)}
                                decimalScale={4}
                                id='condition'
                                thousandSeparator={t('common.thousand_separator')} 
                                decimalSeparator={t('common.decimal_separator')}
                                allowNegative={false}
                            />
                            {contract.durationMode ? <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>
                                {VIEWER_SESSION.find(b => b.id === contract.durationMode).unit}</span> : <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>seconds</span>}

                        </div>
                    }

                    {
                        monetizedValue && monetizedValue.includes(12) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {t("contract.pricing")}
                            </InputLabel>
                            <TextField
                                id="selectPricingOption"
                                disabled={screenDisable}
                                style={{
                                    marginTop: '0.5rem',
                                    width: '60%'
                                }}
                                className={classes.root}
                                variant='outlined'
                                value={contract.pricingOption ? contract.pricingOption : PRICING.find(b => b.default).id}
                                select>
                                {
                                    PRICING && PRICING.map((value, key) => {
                                        return (
                                            <MenuItem key={key} value={value.id}>{value.value}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </div>
                    }
                    {
                        monetizedValue && monetizedValue.includes(13) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {t("contract.calculations_done")}
                            </InputLabel>
                            <TextField
                                id="selectCalculationOption"
                                disabled={screenDisable}
                                style={{
                                    marginTop: '0.5rem',
                                    width: '60%'
                                }}
                                className={classes.root}
                                variant='outlined'
                                value={contract.calculationsDone ? contract.calculationsDone : CALCULATION.find(b => b.default).id}
                                select>
                                {
                                    CALCULATION && CALCULATION.map((value, key) => {
                                        return (
                                            <MenuItem key={key} value={value.id}>{value.value}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </div>
                    }

                    {
                        monetizedValue && monetizedValue.includes(14) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {t("contract.mg_applied")}
                            </InputLabel>
                            <TextField
                                id="selectMGAppliedOption"
                                disabled={screenDisable}
                                style={{
                                    marginTop: '0.5rem',
                                    width: '60%'
                                }}
                                className={classes.root}
                                variant='outlined'
                                value={contract.mgApplied ? contract.mgApplied : MG_APPLIED.find(b => b.default).id}
                                select>
                                {
                                    MG_APPLIED && MG_APPLIED.map((value, key) => {
                                        return (
                                            <MenuItem key={key} value={value.id}>{value.value}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </div>
                    }

                    {
                        monetizedValue && monetizedValue.includes(3) && contract.pricingOption === 1 &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)',
                                marginBottom: '0.5rem',
                                width: '60%'
                            }}>
                                {t("royalties.viewer_count")}
                            </InputLabel>
                            {
                                <NumericFormat
                                    disabled={screenDisable}
                                    value={Number.parseFloat(contract.RevPerViewerCount)}
                                    className='global-id text-right'
                                    style={{
                                        width: '60%'
                                    }}
                                    name='RevPerViewerCount'
                                    decimalScale={3}
                                    thousandSeparator={t('common.thousand_separator')} 
                                    decimalSeparator={t('common.decimal_separator')}
                                    allowNegative={false}
                                />
                            }

                            <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>
                                {CURRENCY.find(c => c.id.toString() === localStorage.getItem('mainCurrency')).value}</span>
                        </div>
                    }

                    {
                        monetizedValue && monetizedValue.includes(4) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)',
                                marginBottom: '0.5rem',
                                width: '60%'
                            }}>
                                {t("royalties.minutes_watched")}
                            </InputLabel>
                            {
                                <NumericFormat
                                    disabled={screenDisable}
                                    value={Number.parseFloat(contract.durationUniqueView)}
                                    className='global-id text-right'
                                    style={{
                                        width: '60%'
                                    }}
                                    name='durationUniqueView'
                                    decimalScale={3}
                                    thousandSeparator={t('common.thousand_separator')} 
                                    decimalSeparator={t('common.decimal_separator')}
                                    allowNegative={false}
                                />
                            }
                            <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>
                                {CURRENCY.find(c => c.id.toString() === localStorage.getItem('mainCurrency')).value}</span>
                        </div>
                    }
                    {
                        monetizedValue && monetizedValue.includes(5) && contract.pricingOption === 1 &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)',
                                marginBottom: '0.5rem',
                                width: '60%'
                            }}>
                                {t("contract.revenue_transaction")}
                            </InputLabel>
                            {
                                <input
                                    disabled={screenDisable}
                                    value={contract.RevPerTransaction}
                                    className='global-id text-right'
                                    style={{
                                        width: '60%'
                                    }}
                                    min='0'
                                    step='1'
                                    name='RevPerTransaction'
                                    type="number"
                                />
                            }
                            <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>
                                {CURRENCY.find(c => c.id.toString() === localStorage.getItem('mainCurrency')).value}</span>
                        </div>
                    }
                    {
                        monetizedValue && monetizedValue.includes(6) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)',
                                marginBottom: '0.5rem',
                                width: '60%'
                            }}>
                                {t("contract.per_nb_of_ads_Served")}
                            </InputLabel>
                            {
                                <input
                                    disabled={screenDisable}
                                    value={contract.RevPerAdsServed}
                                    className='global-id text-right'
                                    min='0'
                                    step='1'
                                    style={{
                                        width: '60%'
                                    }} name='RevPerAdsServed'
                                    type="number"
                                />
                            }
                        </div>
                    }

                    {
                        monetizedValue && monetizedValue.includes(7) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)',
                                marginBottom: '0.5rem',
                                width: '60%'
                            }}>
                                {t("contract.revenue_subs")}
                            </InputLabel>
                            {
                                <NumericFormat
                                    disabled={screenDisable}
                                    value={Number.parseFloat(contract.RevPerSubs)}
                                    className='global-id text-right'
                                    style={{
                                        width: '60%'
                                    }}
                                    name='RevPerSubs'
                                    decimalScale={3}
                                    thousandSeparator={t('common.thousand_separator')} 
                                    decimalSeparator={t('common.decimal_separator')}
                                    allowNegative={false}
                                    isAllowed={
                                        (values) => {
                                            const { formattedValue, floatValue } = values;
                                            return formattedValue === "" || floatValue <= 100;
                                        }
                                    }
                                />
                            }
                            <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>%</span>
                        </div>
                    }

                    {
                        monetizedValue && monetizedValue.includes(8) &&
                        <div className='items-center mt-4'>
                            <InputLabel shrink style={{
                                color: 'rgba(0, 0, 0, 0.54)',
                                marginBottom: '0.5rem',
                                width: '60%'
                            }}>
                                {t("contract.revenue_ads")}
                            </InputLabel>
                            {
                                <NumericFormat
                                    disabled={screenDisable}
                                    value='0'
                                    className='global-id text-right'
                                    style={{
                                        width: '60%'
                                    }}
                                    name='revPerAds'
                                    decimalScale={3}
                                    thousandSeparator={t('common.thousand_separator')} 
                                    decimalSeparator={t('common.decimal_separator')}
                                    allowNegative={false}
                                    isAllowed={
                                        (values) => {
                                            const { formattedValue, floatValue } = values;
                                            return formattedValue === "" || floatValue <= 100;
                                        }
                                    }
                                />
                            }
                            <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 'small', marginLeft: '10px' }}>%</span>
                        </div>
                    }
                </div>

                {contract && contract.pricingOption === 2 &&
                    <PricingLadder
                        disabled={screenDisable}
                        initializePricingLadder={initializePricingLadder}
                        mgApplied={contract.mgApplied}
                        currency={CURRENCY.find(c => c.id.toString() === localStorage.getItem('mainCurrency')).value}
                    />}

                {(contract.businessModel === 4 || contract.businessModel === 5 || contract.businessModel === 6) &&
                    <FormControlLabel style={{ marginTop: 10 }}
                        control={<Switch checked={isMarketShare} disabled={screenDisable} onClick={handleChangeMarketShare} name="gilad" />}
                        label={t("contract.add_market_share")}
                    />
                }

            </div>

            <div className='bg-white px-8 pt-4 pl-8'>
                <div className='profile-title pb-2 pt-2' style={{ borderBottom: '1px solid #D05E5A' }}>
                    <b>{t("contract.restrictions")}</b>
                </div>
                <div>
                    <div className='mt-4'>
                        <div className="grid grid-cols-2 gap-32">
                            <div>
                                <ButtonGroup size="small" color="primary" aria-label="outlined primary button group" style={{ marginBottom: '20px', border: 'none' }}>
                                    <Button disabled={screenDisable} style={isInclude ? { backgroundColor: '#d05e5a', color: '#fff', border: 'none' } : { color: '#d05e5a' }}>{t("contract.include")}</Button>
                                    <Button disabled={screenDisable} style={!isInclude ? { backgroundColor: '#d05e5a', color: '#fff', border: 'none' } : { color: '#d05e5a' }}>{t("contract.exclude")}</Button>
                                </ButtonGroup>
                                {contract.countryInfringeLog ? <div>
                                    <div className="grid grid-cols-12">
                                        <div className="col-span-6" style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                            </InputLabel>
                                        </div>
                                        <div className="col-span-6" style={{
                                            height: '30px',
                                            backgroundColor: 'rgb(208, 94, 90)',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            color: 'white'
                                        }}>
                                            <InputLabel style={{ color: 'white', marginRight: '10px' }}>
                                                {contract.countryInfringeLog.logs > 1 ? (contract.countryInfringeLog.logs + t("royalties.infringements")) : (contract.countryInfringeLog.logs + t("royalties.infringements"))}
                                            </InputLabel>
                                        </div>
                                    </div>
                                    <div className="col-span-12" style={{ border: 'rgb(208, 94, 90) solid 2px' }}>
                                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)', marginTop: '8px', marginLeft: '4px' }}>
                                            {t("contract.territories")}
                                        </InputLabel>
                                        {
                                            countries &&
                                            <Autocomplete
                                                disabled={screenDisable}
                                                value={countries.filter(c => contract.countries.includes(c.ikastUID))}
                                                options={countries}
                                                getOptionLabel={(e) => e.name_translation}
                                                multiple
                                                style={{
                                                    width: '90%',
                                                    marginLeft: '4px'
                                                }}
                                                size='small'
                                                disableClearable
                                                className='mt-2'
                                                renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t("contract.add_placeholder")} />}
                                            />
                                        }
                                        <InputLabel shrink style={{ color: 'transparent', marginBottom: '10px' }}>
                                            &nbsp
                                        </InputLabel>
                                    </div>
                                    <div className="grid grid-cols-12">
                                        <div className="col-span-6" style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                            </InputLabel>
                                        </div>
                                        <div className="col-span-6" style={{
                                            height: '30px',
                                            backgroundColor: 'rgb(208, 94, 90)',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            color: 'white'
                                        }}>
                                            <InputLabel style={{ color: 'white', marginRight: '10px' }}>
                                                <NumericFormat
                                                    value={Number.parseFloat(contract.countryInfringeLog.royalty)}
                                                    displayType={'text'}
                                                    thousandSeparator={t('common.thousand_separator')} 
                                                    decimalSeparator={t('common.decimal_separator')}
                                                    allowNegative={false}
                                                /> {CURRENCY_UNIT}
                                            </InputLabel>
                                        </div>
                                    </div>
                                </div> : <div className="col-span-12">
                                    <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)', marginTop: '8px', marginLeft: '4px' }}>
                                        {t("contract.territories")}
                                    </InputLabel>
                                    {
                                        countries &&
                                        <Autocomplete
                                            disabled={screenDisable}
                                            value={countries.filter(c => contract.countries.includes(c.ikastUID))}
                                            options={countries}
                                            getOptionLabel={(e) => e.name_translation}
                                            multiple
                                            style={{
                                                width: '90%',
                                                marginLeft: '4px'
                                            }}
                                            size='small'
                                            disableClearable
                                            className='mt-2'
                                            renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t("contract.add_placeholder")} />}
                                        />
                                    }
                                    <InputLabel shrink style={{ color: 'transparent', marginBottom: '10px' }}>
                                        &nbsp
                                    </InputLabel>
                                </div>}
                            </div>
                            {contract.dateInfringeLog ?
                                <div>
                                    <div className='grid grid-cols-12' style={{ marginTop: '51px' }}>
                                        <div className="col-span-6" style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                            </InputLabel>
                                        </div>
                                        <div className="col-span-6" style={{
                                            height: '30px',
                                            backgroundColor: 'rgb(208, 94, 90)',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            color: 'white'
                                        }}>
                                            <InputLabel style={{ color: 'white', marginRight: '10px' }}>
                                                {contract.dateInfringeLog.logs > 1 ? (contract.dateInfringeLog.logs + t("royalties.infringements")) : (contract.dateInfringeLog.logs + t("royalties.infringements"))}
                                            </InputLabel>
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-12'
                                        style={{
                                            paddingTop: '10px',
                                            paddingLeft: '4px',
                                            paddingBottom: '30px',
                                            border: 'rgb(208, 94, 90) solid 2px'
                                        }}>
                                        <div className="col-span-5">
                                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                {t("contract.start_date")}
                                            </InputLabel>
                                            <TextField
                                                disabled={screenDisable}
                                                value={contract.startDate ? contract.startDate.slice(0, -8) : ''}
                                                style={{
                                                    width: '100%',
                                                    marginTop: '0.5rem',
                                                    fontSize: 'small'
                                                }}
                                                name='startDate'
                                                id="datetime-local"
                                                type="datetime-local"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                size='small'
                                                variant='outlined'
                                                inputProps={{
                                                    style: {
                                                        padding: '10px',
                                                        fontSize: 11,
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-1" />
                                        <div className="col-span-5">
                                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                            {t("contract.end_date")}
                                            </InputLabel>
                                            <TextField
                                                disabled={screenDisable}
                                                value={contract.endDate ? contract.endDate.slice(0, -8) : ''}
                                                style={{
                                                    marginTop: '0.5rem',
                                                    width: '100%',
                                                    fontSize: 'small'
                                                }}
                                                name='endDate'
                                                id="datetime-local"
                                                type="datetime-local"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                size='small'
                                                variant='outlined'
                                                inputProps={{
                                                    style: {
                                                        padding: '10px',
                                                        fontSize: 11,
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-1" />
                                    </div>
                                    <div className="grid grid-cols-12">
                                        <div className="col-span-6" style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                            </InputLabel>
                                        </div>
                                        <div className="col-span-6" style={{
                                            height: '30px',
                                            backgroundColor: 'rgb(208, 94, 90)',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            color: 'white'
                                        }}>
                                            <InputLabel style={{ color: 'white', marginRight: '10px' }}>
                                                <NumericFormat
                                                    value={Number.parseFloat(contract.dateInfringeLog.royalty)}
                                                    displayType={'text'}
                                                    thousandSeparator={t('common.thousand_separator')} 
                                                    decimalSeparator={t('common.decimal_separator')}
                                                    allowNegative={false}
                                                /> {CURRENCY_UNIT}
                                            </InputLabel>
                                        </div>
                                    </div>
                                </div> :
                                <div className='grid grid-cols-2 gap-2' style={{ marginTop: '56px' }}>
                                    <div>
                                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                        {t("contract.start_date")}
                                        </InputLabel>
                                        <TextField
                                            disabled={screenDisable}
                                            // onChange={handleChangeDatetime}
                                            value={contract.startDate ? contract.startDate.slice(0, -8) : ''}
                                            style={{
                                                width: '100%',
                                                marginTop: '0.5rem',
                                                fontSize: 'small'
                                            }}
                                            name='startDate'
                                            id="datetime-local"
                                            type="datetime-local"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            size='small'
                                            variant='outlined'
                                            inputProps={{
                                                style: {
                                                    padding: '10px',
                                                    fontSize: 11,
                                                }
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                        {t("contract.end_date")}
                                        </InputLabel>
                                        <TextField
                                            disabled={screenDisable}
                                            value={contract.endDate ? contract.endDate.slice(0, -8) : ''}
                                            // onChange={handleChangeDatetime}
                                            style={{
                                                marginTop: '0.5rem',
                                                width: '100%',
                                                fontSize: 'small'
                                            }}
                                            name='endDate'
                                            id="datetime-local"
                                            type="datetime-local"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            size='small'
                                            variant='outlined'
                                            inputProps={{
                                                style: {
                                                    padding: '10px',
                                                    fontSize: 11,
                                                }
                                            }}
                                        />
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Identification;
