import React, { useState, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {GET_PARTNER_STATUS, GET_REPORTINGTOOLS, GET_BUSINESSTYPES} from "../../../../constants";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import axios from "../../../../config/axios";
import {useSnackbar} from "notistack";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
const PartnerItem = ({ myOrgId, listPermission, item }) => {
    const [isHover, setIsHover] = useState(false);
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t, i18n } = useTranslation();
    const BUSINESSTYPES = useMemo(() => GET_BUSINESSTYPES(t), [t]);
    const REPORTINGTOOLS = useMemo(() => GET_REPORTINGTOOLS(t), [t]);
    const PARTNER_STATUS = useMemo(() => GET_PARTNER_STATUS(t), [t]);
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#696969',
            color: '#dbdbdb',
            marginBottom: 0,
            fontSize: 14,
        },
    }))(Tooltip);

    const handleClose = () => {
        setOpen(false);
    };

    const onMouseEnterHandler = (e) => {
        setIsHover(true)
    };

    const onMouseLeaveHandler = (e) => {
        setIsHover(false)
    };

    const requestConnectPartner =  (partnerId, status) => {
        if (status === 2) return;
        return async () => {
            const request = {
                "myOrgUid": myOrgId,
                "requestPartner": partnerId
            }

            const newRequest = await axios.post('/org/create-partnership', request, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            const { status } = newRequest;

            if (status === 200) {
                enqueueSnackbar(t('notify.request_success'), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });

                const createMessage = {
                    "type": 1,
                    "from": myOrgId,
                    "receiver": partnerId,
                    "header": "Connection request",
                    "body": "Connection request"
                }
                const newMessage = await axios.post('/message/', createMessage, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });
                window.location.reload();
            } else {
                enqueueSnackbar(t('notify.something_wrong'), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }
        }
    }

    const requestDisconnectPartner =  (partnerId, status, nbOfContracts) => {
        if (status < 2 || (status === 2 && nbOfContracts > 0)) return;
        return async () => {
            const request = {
                "myOrgUid": myOrgId,
                "requestPartner": partnerId,
                "status": 1
            }

            const newRequest = await axios.put('/org/update-partnership-status', request, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            const { status } = newRequest;

            if (status === 200) {
                enqueueSnackbar(t("notify.disconnected_success"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });
                if (myOrgId !== partnerId) {
                    const createMessageToYourPartner = {
                        "type": 7,
                        "from": myOrgId,
                        "receiver": partnerId,
                        "header": "Disconnected connection",
                        "body": "Disconnected connection"
                    }
                    const createMessageToYourself = {
                        "type": 7,
                        "from": partnerId,
                        "receiver": myOrgId,
                        "header": "Disconnected connection",
                        "body": "Disconnected connection"
                    }
                    const newMessageToYourPartner = await axios.post('/message/', createMessageToYourPartner, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });
                    const newMessageToYourself = await axios.post('/message/', createMessageToYourself, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });
                } else {
                    const createMessageToYourPartner = {
                        "type": 7,
                        "from": myOrgId,
                        "receiver": partnerId,
                        "header": "Disconnected connection",
                        "body": "Disconnected connection"
                    }
                    const newMessageToYourPartner = await axios.post('/message/', createMessageToYourPartner, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });
                }
                window.location.reload();
            } else {
                enqueueSnackbar(t('notify.something_wrong'), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }
        }
    }

    let displayTimeOrigin = item.lastUpdate ? item.lastUpdate : "";
    let displayTime = displayTimeOrigin.split("T");
    const displayTimeArr = displayTime[0] ? displayTime[0].split("-") : "";
    const logo = item.logo ? item.logo : null;

    const renderTooltipStatus = (status) => {
        switch (status) {
            case 1:
                return (
                    t('status.disconnected')
                );
            case 2:
                return (
                    t('status.connected')
                );
            case 0:
                return (
                    t('status.pending')
                );
            default:
                return (
                    t('status.disconnected')
                );
        }
    }

    return (
        <div
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            className='grid grid-cols-12 gap-2 text-center mt-3 rounded-lg items-center justify-items-center' style={{
            background: 'rgb(242, 242, 242)',
            border: isHover ? '1px solid red' : ''
        }}>
            <LightTooltip title={renderTooltipStatus(item.status)} placement="top">
                <div className='m-auto col-span-1 flex justify-center text-center'>
                <span id='status' className='rounded-full block m-1' style={{
                    backgroundColor: PARTNER_STATUS.find(s => s.id === item.status).color,
                    height: '15px',
                    width: '15px',
                }} />
                </div>
            </LightTooltip>
            <LightTooltip title={BUSINESSTYPES.find(s => s.id === item.type).description} placement="top">
            <div className='col-span-1 justify-center text-center'>
                {BUSINESSTYPES.find(s => s.id === item.type).value}
            </div>
            </LightTooltip>
            <div className='col-span-1 justify-left text-left'>
                <img
                    src={logo}
                    style={{
                        width: '60px',
                        height: '60px',
                        backgroundSize: 'cover',
                        backgroundPosition: 'top center',}}></img>
            </div>
            <div className='col-span-3 justify-left text-left'>
                {listPermission.includes('IKAST_PARTNER_ADD') ? 
                    <Link to={`/admin/organization/edit/${item.ikastUID}?nbContract=${item.numberOfContracts}&status=${item.status}&myOrgId=${myOrgId}&partnerId=${item.ikastUID}`}>{item.name} </Link> :
                    <Link to={`/admin/organization/view/${item.ikastUID}?nbContract=${item.numberOfContracts}&status=${item.status}&myOrgId=${myOrgId}&partnerId=${item.ikastUID}`}>{item.name} </Link>
                }
            </div>
            <div className='col-span-1 justify-center text-center'>{item.numberOfContents}</div>
            <div className='col-span-1 justify-center text-center'>{item.numberOfContracts}</div>
            <div className='col-span-2 justify-left text-left'>
                {(REPORTINGTOOLS.find(b => b.id === item.reportingTool)).value}
            </div>
            {isHover ? <div className='col-span-2' style={{fontSize: '22px'}}>
                    <button className='no-border-button'><AddIcon style={item.status === 1 ? { color: 'black' } : { color: 'lightgrey' }}
                                                                  onClick={requestConnectPartner(item.ikastUID, item.status)}
                    /></button>
                    <button className='no-border-button'><RemoveIcon
                        style={(item.status === 2 && item.numberOfContracts === 0) ? { color: 'black' } : { color: 'lightgrey' }}
                        onClick={requestDisconnectPartner(item.ikastUID, item.status, item.numberOfContracts)}
                    /></button>
                    {listPermission.includes('IKAST_PARTNER_ADD') ? <Link to={`/admin/organization/edit/${item.ikastUID}?nbContract=${item.numberOfContracts}&status=${item.status}&myOrgId=${myOrgId}&partnerId=${item.ikastUID}`}>
                        <EditRoundedIcon style={{cursor: 'pointer', fontSize: '20px', marginLeft: '5px'}}/>
                        </Link> : null}
                </div> :
                <div className='col-span-2'>
                    {displayTimeArr ? `${displayTimeArr[2]}/${displayTimeArr[1]}/${displayTimeArr[0]}` : ""}
                </div>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Content"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("partners.delete_content")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                    {t("common.cancel")}
                    </Button>
                    <Button color="primary" autoFocus>
                    {t("common.yes")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PartnerItem;
