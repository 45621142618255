import React, { useEffect, Suspense, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import routers from '../routers';
import { authSelectors, authActions } from "../state/modules/auth";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import ErrorPage from './pages/ErrorPage';
import LoadingLayout from './components/Common/LoadingLayout'
import Login from './pages/Login';
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";

const App = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => authSelectors.isSessionExpired(state));
    const user = useSelector((state) => authSelectors.user(state));
    const [listPermission, setListPermission] = useState([]);
    const { t, i18n } = useTranslation();
    console.disableYellowBox = true;
    useEffect(() => {
        if (user) {
            setListPermission(user.get('permissions').toArray());
        }
        if (selector && selector.get('sessionExpired') === '1') {
            alert(t("system_alert.session_expired"));
            window.location.href = '/login';
        }
    }, [selector, user]);

    useEffect(() => {
        if (!window.location.pathname.includes('login') && !window.location.pathname.includes('forgot-password') && !window.location.pathname.includes('change-password')) {
            dispatch(authActions.getProfile({ id: localStorage.getItem('id') }));
        }
    }, []);

    useEffect(() => {
        if (user && user.get('lang')) {
            i18n.changeLanguage(user.get('lang'));
            localStorage.setItem('language', user.get('lang'));
        }
        if (!localStorage.getItem("token")) {
            var language = window.navigator.userLanguage || window.navigator.language;
            if (language === 'fr-FR' || language === 'fr') {
                i18n.changeLanguage('fr');
            } else {
                i18n.changeLanguage('en');
            }
        }
    }, [user]);

    return (
        <>
            <Suspense fallback="loading">
                <div className="App h-full">
                    <Router>
                        <Switch>
                            <Route exact path='/'>
                                <Redirect to='/login' />
                            </Route>
                            <Route exact key={'login'} path={'/login'} render={props => (<Login />)} />
                            <Route exact key={'forgot-password'} path={'/forgot-password'} render={props => (<ForgotPassword />)} />
                            <Route exact key={'change-password'} path={'/change-password/:id'} render={props => (<ChangePassword />)} />
                            {listPermission.length > 0 ? (
                                <>
                                    {routers.map((route, i) => {
                                        if (!route.permission || (route.permission && listPermission.includes(route.permission))) {
                                            return <Route exact key={i} path={route.path} render={props => (<route.component {...props} routes={route.routes} />)} />
                                        } else {
                                            return <Route exact key={i} path={route.path} render={() => (<ErrorPage />)} />
                                        }
                                    })}
                                </>
                            ) : <LoadingLayout />}
                        </Switch>
                    </Router>
                </div>
            </Suspense>
        </>
    )
};

export default App;
