import React, { useState, Fragment, useEffect, useContext } from 'react';
import Layout from '../../layouts/Default';
import { Link } from 'react-router-dom';
import axios from '../../../config/axios';
import RowComponent from '../../components/Content/RowComponent';
import Pagination from '@material-ui/lab/Pagination';
import Button from "@material-ui/core/Button";
import _findIndex from 'lodash/findIndex';
import _cloneDeep from 'lodash/cloneDeep';
import Checkbox from '@material-ui/core/Checkbox';
import { useSnackbar, withSnackbar } from 'notistack';
import SortContentIconRender from "../../components/Royalties/SortContentIconRender";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import LoadingLayout from "../../components/Common/LoadingLayout";
import LoadingContext from "../../../context/loading/LoadingContext";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { useTranslation } from 'react-i18next';
const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        "& $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&:hover $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&$focused $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        }
    },
    focused: {},
    notchedOutline: {}
}));

const Content = () => {
    const { t, i18n } = useTranslation();
    const outlinedInputClasses = useOutlinedInputStyles();
    const [contents, setContents] = useState([]);
    const [totalContents, setTotalContents] = useState(null);
    const [page, setPage] = useState(1);
    const [user, setUser] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [sortStatus, setSortStatus] = useState(null);
    const [sortType, setSortType] = useState(null);
    const [sortTitle, setSortTitle] = useState(null);
    const [sortDuration, setSortDuration] = useState(null);
    const [sortContract, setSortContract] = useState(null);
    const [sortLastUpdate, setSortLastUpdate] = useState(null);
    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const [isCheckall, setIsCheckall] = useState(false);
    const [columnStatus, setColumnStatus] = useState(null);
    const [columnType, setColumnType] = useState(null);
    const [columnTitle, setColumnTitle] = useState(null);
    const [columnDuration, setColumnDuration] = useState(null);
    const [columnContract, setColumnContract] = useState(null);
    const [columnLastUpdate, setColumnLastUpdate] = useState(null);
    const [smartlistKey, setSmartlistKey] = useState(null);
    const { showLoading, hideLoading } = useContext(LoadingContext);
    const [searchValue, setSearchValue] = useState('');
    const [listItemChecked, setListItemChecked] = useState([]);
    const [listPermission, setListPermission] = useState([]);
    useEffect(() => {
        if (localStorage.getItem('id') && localStorage.getItem('token')) {
            (async function getUser() {
                showLoading();
                try {
                    const userResponse = await axios.get(`/user/${localStorage.getItem('id')}`, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });

                    setUser(userResponse.data)
                    setListPermission(userResponse.data.permissions);
                    hideLoading()
                } catch (e) {
                    hideLoading();
                    console.log(e);
                }
            })();
        }
    }, []);

    useEffect(() => {
        if (user) {
            (async function getUserOrg() {
                try {
                    showLoading();
                    const orgResponse = await axios.get(`/org/get-one/${user.BelongsTo}`, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });

                    setSmartlistKey(orgResponse.data.smartListForeignKey);
                    hideLoading();
                } catch (e) {
                    hideLoading();
                    console.log(e);
                }
            })();
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            const timeOutId = setTimeout(() => prepareToFetchContentList(searchValue), 50);
            return () => clearTimeout(timeOutId);
        }
    }, [searchValue, user, page]);

    const pagingHandleChange = (e, value) => {
        setPage(value);
    };

    const sortingHandle = async (sortBy, sortOrder, searchInput) => {
        showLoading();
        const contentsResponse =
            await axios.get(`/content/get-list-contents?orgUid=${user.BelongsTo}&pageNo=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchInput=${searchInput}`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

        const { data, status } = contentsResponse;

        if (status === 200) {
            if (searchInput.length === 0) {
                setTotalContents(data.totalItems);
            }
            setContents(addStatusCheckboxForPage(data));
        }
        hideLoading();
    };

    const sortStatusAscending = (searchInput) => {
        sortingHandle('status', 0, searchInput).then(r => { });
    }

    const sortStatusDescending = (searchInput) => {
        sortingHandle('status', 1, searchInput).then(r => { });
    }

    const sortTypeAscending = (searchInput) => {
        sortingHandle('Format', 0, searchInput).then(r => { });
    }

    const sortTypeDescending = (searchInput) => {
        sortingHandle('Format', 1, searchInput).then(r => { });
    }

    const sortTitleAscending = (searchInput) => {
        sortingHandle('Title', 0, searchInput).then(r => { });
    }

    const sortTitleDescending = (searchInput) => {
        sortingHandle('Title', 1, searchInput).then(r => { });
    }

    const sortDurationAscending = (searchInput) => {
        sortingHandle('duration', 0, searchInput).then(r => { });
    }

    const sortDurationDescending = (searchInput) => {
        sortingHandle('duration', 1, searchInput).then(r => { });
    }

    const sortContractsAscending = (searchInput) => {
        sortingHandle('numberOfDeals', 0, searchInput).then(r => { });
    }

    const sortContractsDescending = (searchInput) => {
        sortingHandle('numberOfDeals', 1, searchInput).then(r => { });
    }

    const sortLastUpdateAscending = (searchInput) => {
        sortingHandle('updatedAt', 0, searchInput).then(r => { });
    }

    const sortLastUpdateDescending = (searchInput) => {
        sortingHandle('updatedAt', 1, searchInput).then(r => { });
    }

    const sortByStatus = () => {
        setColumnStatus(true);
        setColumnType(false);
        setColumnTitle(false);
        setColumnDuration(false);
        setColumnContract(false);
        setColumnLastUpdate(false);
        if (!sortStatus) {
            sortStatusAscending(searchValue);
            setSortStatus('asc')
        }

        if (sortStatus && sortStatus === 'des') {
            sortStatusAscending(searchValue);
            setSortStatus('asc')
        }

        if (sortStatus && sortStatus === 'asc') {
            sortStatusDescending(searchValue);
            setSortStatus('des')
        }
    };

    const sortByType = () => {
        setColumnStatus(false);
        setColumnType(true);
        setColumnTitle(false);
        setColumnDuration(false);
        setColumnContract(false);
        setColumnLastUpdate(false);
        if (!sortType) {
            sortTypeAscending(searchValue);
            setSortType('asc');
        }

        if (sortType && sortType === 'asc') {
            sortTypeDescending(searchValue);
            setSortType('des');
        }

        if (sortType && sortType === 'des') {
            sortTypeAscending(searchValue);
            setSortType('asc');
        }
    };

    const sortByTitle = () => {
        setColumnStatus(false);
        setColumnType(false);
        setColumnTitle(true);
        setColumnDuration(false);
        setColumnContract(false);
        setColumnLastUpdate(false);
        if (!sortTitle) {
            sortTitleAscending(searchValue);
            setSortTitle('asc');
        }

        if (sortTitle && sortTitle === 'des') {
            sortTitleAscending(searchValue);
            setSortTitle('asc');
        }

        if (sortTitle && sortTitle === 'asc') {
            sortTitleDescending(searchValue);
            setSortTitle('des');
        }
    };

    const sortByDuration = () => {
        setColumnStatus(false);
        setColumnType(false);
        setColumnTitle(false);
        setColumnDuration(true);
        setColumnContract(false);
        setColumnLastUpdate(false);
        if (!sortDuration) {
            sortDurationAscending(searchValue);
            setSortDuration('asc');
        }

        if (sortDuration && sortDuration === 'asc') {
            sortDurationDescending(searchValue);
            setSortDuration('des');
        }

        if (sortDuration && sortDuration === 'des') {
            sortDurationAscending(searchValue);
            setSortDuration('asc');
        }
    };

    const sortByContract = () => {
        setColumnStatus(false);
        setColumnType(false);
        setColumnTitle(false);
        setColumnDuration(false);
        setColumnContract(true);
        setColumnLastUpdate(false);

        if (!sortContract) {
            sortContractsAscending(searchValue);
            setSortContract('asc');
        }

        if (sortContract && sortContract === 'asc') {
            sortContractsDescending(searchValue);
            setSortContract('des');
        }

        if (sortContract && sortContract === 'des') {
            sortContractsAscending(searchValue);
            setSortContract('asc');
        }
    };

    const sortByLastUpdate = () => {
        setColumnStatus(false);
        setColumnType(false);
        setColumnTitle(false);
        setColumnDuration(false);
        setColumnContract(false);
        setColumnLastUpdate(true);
        if (!sortLastUpdate) {
            sortLastUpdateAscending(searchValue);
            setSortLastUpdate('asc');
        }

        if (sortLastUpdate && sortLastUpdate === 'des') {
            sortLastUpdateAscending(searchValue);
            setSortLastUpdate('asc');
        }

        if (sortLastUpdate && sortLastUpdate === 'asc') {
            sortLastUpdateDescending(searchValue);
            setSortLastUpdate('des');
        }
    };

    const uploadFile = (e) => {
        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "contentCsvFile",
            e.target.files[0],
            e.target.files[0].name
        );

        // Request made to the backend api
        // Send formData object
        if (localStorage.getItem('id') && localStorage.getItem('token')) {
            (async function uploadCSVContent() {
                try {
                    const userResponse = await axios.post(`/content/import-csv-content/${user.BelongsTo}`, formData, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });

                    // Create import CSV Content successful message.
                    let importCSVContentMessage = {
                        "type": 11,
                        "header": "CSV file of contents has been successfully imported",
                        "body": "CSV file of contents has been successfully imported",
                        "from": user.BelongsTo,
                        "receiver": user.BelongsTo,
                    }
                    const newMessage = await axios.post('/message/', importCSVContentMessage, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });

                    alert(t("system_alert.import_success"));
                    window.location.href = '/contents';
                } catch (e) {
                    alert(e.response.data.message);
                    window.location.href = '/contents';
                }
            })();
        }
    }

    const handleClearSearchValue = async (e) => {
        setSearchValue('');
    }

    const handleChangeSearchValue = async (e) => {
        setSearchValue(e.target.value);
    }

    const prepareToFetchContentList = async (searchValue) => {
        try {
            if (columnStatus) {
                if (sortStatus === 'asc') {
                    sortStatusAscending(searchValue);
                } else if (sortStatus === 'des') {
                    sortStatusDescending(searchValue)
                }
            } else if (columnType) {
                if (sortType === 'asc') {
                    sortTypeAscending(searchValue);
                } else if (sortType === 'des') {
                    sortTypeDescending(searchValue);
                }
            } else if (columnTitle) {
                if (sortTitle === 'asc') {
                    sortTitleAscending(searchValue);
                } else if (sortTitle === 'des') {
                    sortTitleDescending(searchValue);
                }
            } else if (columnDuration) {
                if (sortDuration === 'asc') {
                    sortDurationAscending(searchValue);
                } else if (sortDuration === 'des') {
                    sortDurationDescending(searchValue);
                }
            } else if (columnContract) {
                if (sortContract === 'asc') {
                    sortContractsAscending(searchValue);
                } else if (sortContract === 'des') {
                    sortContractsDescending(searchValue);
                }
            } else if (columnLastUpdate) {
                if (sortLastUpdate === 'asc') {
                    sortLastUpdateAscending(searchValue);
                } else if (sortLastUpdate === 'des') {
                    sortLastUpdateDescending(searchValue);
                }
            } else {
                sortingHandle('', 0, searchValue).then(r => { });
            }
        } catch (e) {
            console.log(e);
        }
    }

    const addStatusCheckboxForPage = (dataContents) => {
        if (!dataContents.records || dataContents.records.length === 0) {
            setListItemChecked([]);
            setIsCheckall(false);
        }
        const data = dataContents.records.map((obj, index) => ({ ...obj, checked: false }));
        dataContents.records = data;
        return dataContents;
    }

    const handleChangeCheckAll = () => {
        const dataContents = JSON.parse(JSON.stringify(contents));
        const oldStatus = JSON.parse(JSON.stringify(isCheckall));
        const dataRecord = dataContents.records.map((obj, index) => ({ ...obj, checked: !oldStatus }));
        if (!oldStatus) {
            setListItemChecked(dataContents.records);
        } else {
            setListItemChecked([]);
        }
        dataContents.records = dataRecord;
        setContents(dataContents);
        setIsCheckall(!oldStatus);
    }

    const handleChangeCheckItem = (data) => {
        const dataContents = JSON.parse(JSON.stringify(contents));
        const dataListItemChecked = JSON.parse(JSON.stringify(listItemChecked));
        dataContents.records.find(p => p.ikastUID === data.ikastUID && (p.checked = !data.checked, true));
        if (!data.checked) {
            const indexItemConflict = dataListItemChecked.findIndex(item => item.ikastUID === data.ikastUID);
            if (indexItemConflict === -1) {
                dataListItemChecked.push(data);
                setListItemChecked(dataListItemChecked);
            }
        } else {
            const indexItemConflict = dataListItemChecked.findIndex(item => item.ikastUID === data.ikastUID);
            if (indexItemConflict > -1) {
                dataListItemChecked.splice(indexItemConflict, 1);
                setListItemChecked(dataListItemChecked);
            }
        }
        setContents(dataContents);
    }

    const openDeleteDialog = () => {
        setOpenDialogDelete(true);
    }

    const handleCloseDialogDelete = () => {
        setOpenDialogDelete(false);
    }

    const handleDelete = async () => {
        const uuidList = [];
        listItemChecked.forEach(item => {
            uuidList.push(item.ikastUID);
        });

        const data = JSON.stringify({
            "uuidList": uuidList
        });

        var config = {
            method: 'delete',
            url: '/contents/delete',
            headers: {
                'x-access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: data
        };

        (async function deleteMessages() {
            try {
                const contentResponse = await axios(config);
                handleCloseDialogDelete();
                const { status } = contentResponse;

                if (status === 200) {
                    enqueueSnackbar(t("notify.delete_success"), {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'success'
                    });

                    if (isCheckall) {
                        if (page > 1) {
                            setPage(1);
                        } else {
                            prepareToFetchContentList(searchValue);
                        }
                    } else {
                        prepareToFetchContentList(searchValue);
                    }
                } else {
                    prepareToFetchContentList(searchValue);
                    enqueueSnackbar(t("notify.something_wrong"), {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },

                        variant: 'error'
                    });
                }
            }
            catch (e) {
                handleCloseDialogDelete();
                enqueueSnackbar(t("content.delete_fail_content_available"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
                prepareToFetchContentList(searchValue);
            }
        })();
    }

    return contents ? (
        <Layout title='Content' highlightedItem={2}>
            <div className='flex justify-between content-center'>
                <div>
                    {t('content.breadcrumb_catalogue')}
                    <p style={{ fontSize: '24px' }}>
                        {t('content.contents')} ({!searchValue || searchValue.length === 0 ? totalContents : `${contents.totalItems}/${totalContents}`})
                    </p>
                </div>
                <div className='flex' style={{
                    height: '36px'
                }}>
                    <div>
                        {listPermission.includes("IKAST_CONTENT_DELETE") ?
                            <Button
                                disabled={listItemChecked.length === 0}
                                onClick={openDeleteDialog}
                                style={{
                                    height: '100%',
                                    padding: '6px 16px',
                                    backgroundColor: '#D05E5A',
                                    marginLeft: '0.5rem',
                                    color: 'white',
                                    fontSize: '0.75rem'
                                }}>
                                {t("common.delete")}
                            </Button>
                            : null}
                        {listPermission.includes("IKAST_CONTENT_SYNCHRONIZE") && smartlistKey && smartlistKey !== '' ? <Link to={`/contents/okast-synchronize`}>
                            <Button
                                style={{
                                    height: '100%',
                                    padding: '6px 16px',
                                    backgroundColor: '#D05E5A',
                                    marginLeft: '0.5rem',
                                    color: 'white',
                                    fontSize: '0.75rem'
                                }}>
                                {t('content.sync')}
                            </Button>
                        </Link> : null}
                        {listPermission.includes("IKAST_CONTENT_IMPORT_CONTENT") ?
                            <>
                                <input
                                    accept="text/csv/*"
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={uploadFile}
                                />
                                <label htmlFor="contained-button-file">
                                    <Button variant="contained" component="span" style={{
                                        height: '100%',
                                        padding: '6px 16px',
                                        backgroundColor: '#D05E5A',
                                        marginLeft: '0.5rem',
                                        color: 'white',
                                        fontSize: '0.75rem'
                                    }}>
                                        {t('content.import_csv')}
                                    </Button>
                                </label>
                            </>
                        : null}
                        {listPermission.includes("IKAST_CONTENT_ADD") ? 
                            <Link to={'/contents/catalogue/add'}>
                                <Button style={{
                                    height: '100%',
                                    padding: '6px 16px',
                                    backgroundColor: '#D05E5A',
                                    marginLeft: '0.5rem',
                                    color: 'white',
                                    fontSize: '0.75rem'
                                }}>
                                    {t('content.add_new_content')}
                                </Button>
                            </Link>
                        : null}
                    </div>
                </div>
            </div>

            <div className='grid grid-cols-12 gap-2'>
                <div className='font-bold col-span-9' />
                <div className='font-bold col-span-3'>
                    <OutlinedInput
                        id="outlined-size-small"
                        style={{ height: '36px', background: 'white' }}
                        classes={outlinedInputClasses}
                        placeholder={t('common.search')}
                        value={searchValue}
                        inputProps={{
                            autoComplete: "off"
                        }}
                        onChange={handleChangeSearchValue}
                        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                        endAdornment={<InputAdornment position="end">
                            <IconButton
                                className="no-border-button"
                                aria-label="toggle clear button"
                                onClick={handleClearSearchValue}
                            >
                                {searchValue !== '' ? <ClearIcon /> : ""}
                            </IconButton>
                        </InputAdornment>} />
                </div>
            </div>
            <div className='bg-white px-8 py-16 mt-8'>
                <div className='grid grid-cols-12 gap-2 justify-items-center text-center'>
                    <div className='font-bold col-span-1 justify-center text-center column-header'>
                        {contents && contents?.records?.length > 0 ? (<Checkbox checked={isCheckall} onChange={handleChangeCheckAll} />) : null}
                    </div>
                    <div className='font-bold col-span-1 flex justify-center' onClick={sortByStatus} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t('common.status')}
                        {columnStatus ? <SortContentIconRender sortOrder={sortStatus} /> : null}
                    </div>
                    <div className='font-bold col-span-1 flex justify-center' onClick={sortByType} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t('content.type')}
                        {columnType ? <SortContentIconRender sortOrder={sortType} /> : null}
                    </div>
                    <div className='font-bold col-span-1 flex justify-center' onClick={sortByType} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t('content.illustration')}
                    </div>
                    <div className='font-bold col-span-3 text-left' onClick={sortByTitle} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t('content.title')}
                        {columnTitle ? <SortContentIconRender sortOrder={sortTitle} /> : null}
                    </div>
                    <div className='font-bold col-span-1' onClick={sortByDuration} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t('content.duration')}
                        {columnDuration ? <SortContentIconRender sortOrder={sortDuration} /> : null}
                    </div>
                    <div className='font-bold col-span-2' onClick={sortByContract} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t('content.contracts')}
                        {columnContract ? <SortContentIconRender sortOrder={sortContract} /> : null}
                    </div>
                    <div className='font-bold col-span-2' onClick={sortByLastUpdate} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        {t('table.last_update')}
                        {columnLastUpdate ? <SortContentIconRender sortOrder={sortLastUpdate} /> : null}
                    </div>
                </div>
                <Fragment>
                    {contents.records && contents.records.map((value, key) => {
                        let contentDuration = new Date(value.duration * 1000).toISOString().substr(11, 8);

                        let content = {
                            ...value,
                            duration: contentDuration
                        };
                        return (
                            <RowComponent listPermission={listPermission} content={content} key={key} handleChangeCheckItem={handleChangeCheckItem} />
                        )
                    })}
                </Fragment>
                <div className='flex flex-row justify-center items-center py-4'>
                    <Pagination count={contents.totalPages} page={page} onChange={pagingHandleChange} />
                </div>
                <Dialog
                    open={openDialogDelete}
                    onClose={handleCloseDialogDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title"> {t("content.delete_content_title")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t("content.confirm_delete")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialogDelete} color="primary">
                            {t("common.cancel")}
                        </Button>
                        <Button color="primary" onClick={handleDelete} autoFocus>
                            {t("common.yes")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Layout>
    ) : <LoadingLayout />
};

export default withSnackbar(Content);
