import React, { useState, Fragment, useEffect, useContext, useMemo } from 'react';
import { useSnackbar, withSnackbar } from 'notistack';
import Layout from '../../../layouts/Default';
import axios from '../../../../config/axios';
import makeStyles from "@material-ui/core/styles/makeStyles";
import okastLogo from '../../../../assets/images/okast.png';
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import TransferList from "../../../components/Contract/TransferList";
import Button from "@material-ui/core/Button";
import {GET_CONTENT_STATUS} from "../../../../constants";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import LoadingContext from "../../../../context/loading/LoadingContext";
import { useTranslation } from 'react-i18next';
const useNoBorderStyles = makeStyles({
    root: {
        height: '40px !important',
        padding: '0px',
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        }
    }
});

const Synchronization = () => {
    const { t, i18n } = useTranslation();
    const CONTENT_STATUS = useMemo(() => GET_CONTENT_STATUS(t), [t]);
    const classes = useNoBorderStyles();
    const [user, setUser] = useState(null);
    const {enqueueSnackbar} = useSnackbar();
    const [smartList, setSmartList] = useState(null);
    const [contentList, setContentList] = useState([]);
    const [contentListToImport, setContentListToImport] = useState([]);
    const [numberOfExistContents, setNumberOfExistContents] = useState(0);
    const [smartlistKey, setSmartlistKey] = useState(null);
    const [filterListContentStatus, setFilterListContentStatus] = useState([]);
    const [contentStatus, setContentStatus] = useState(1);
    const { showLoading, hideLoading } = useContext(LoadingContext);
    useEffect(() => {
        if (localStorage.getItem('id') && localStorage.getItem('token')) {
            (async function getUser() {
                try {
                    const userResponse = await axios.get(`/user/${localStorage.getItem('id')}`, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });

                    setUser(userResponse.data)

                } catch (e) {
                    console.log(e);
                }
            })();
        }
    }, []);

    useEffect(() => {
        if (user) {
            (async function getUserOrg() {
                try {
                    const orgResponse = await axios.get(`/org/get-one/${user.BelongsTo}`, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });

                    setSmartlistKey(orgResponse.data.smartListForeignKey);

                } catch (e) {
                    console.log(e);
                }
            })();
        }
    }, [user]);

    useEffect(() => {
        if (smartlistKey) {
            (async function getSmartList() {
                const data = {
                    "smartListForeignKey": smartlistKey,
                }

                try {
                    const smartListResponse = await axios.post(`/content/okast-smartlist`, data, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });

                    if (smartListResponse.status === 200) {
                        setSmartList(smartListResponse.data.responseList);
                    }

                } catch (e) {
                    console.log(e);
                }
            })();
        }
    }, [smartlistKey]);

    const handleChangeSmartList = async (e, value) => {
        setNumberOfExistContents(value.numberOfExistContents);
        const data = {
            'uuid': value.uuid,
            "smartListForeignKey": smartlistKey,
        }

        try {
            const contentlistResponse = await axios.post(`/content/okast-analyze`, data, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            if (contentlistResponse.status === 200) {
                setContentList(contentlistResponse.data.contentList);
            }

        } catch (e) {
            console.log(e);
        }
    }

    const handleLicensedContentChosen = (list) => {
        setContentListToImport(list);
    };

    const handleImportContent = async () => {
        const data = {
            contentStatus: contentStatus,
            contentList: contentListToImport,
            orgUid: user.BelongsTo
        }

        try {
            const importResponse = await axios.post(`/content/okast-import`, data, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            if (importResponse.status === 200) {
                enqueueSnackbar(t("content.import_content_success"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });
                window.location.reload();
            } else {
                enqueueSnackbar(t("content.import_content_fail"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }
        } catch (e) {
            enqueueSnackbar(t("content.import_content_fail"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
    }

    const handleChangeStatus = async (e) => {
        setContentStatus(e.target.value)
    }

    useEffect(() => {
        setFilterListContentStatus(CONTENT_STATUS.filter(c => !c.disableUI))
    }, []);

    return (
        <Layout title='Synchronization' highlightedItem={2}>
            <div className='flex justify-between content-center'>
                <div>
                    {t("content.breadcrumb_sync")}
                </div>
            </div>

            <div className='bg-white px-8 py-16 mt-8'>
                <div className='profile-title pb-2 pt-2 flex justify-center' style={{marginTop: '-60px'}}>
                    <img src={okastLogo} width='97.5px' height='60px' alt='logo'/>
                </div>

                <div className='items-center col-span-1'>
                    <div className='items-center mt-2 flex' style={{alignItems: 'center'}}>
                        <button
                            style={{
                                    background: 'rgb(208, 94, 90)',
                                    color: 'rgb(251 248 248)',
                                    fontSize: '12px',
                                    width: '22px',
                                    height: '22px',
                                    borderRadius: '50%',
                                }}
                        >1</button>
                        <span style={{marginLeft: '10px', fontWeight: '600'}}>{t("content.select_smartlist")}</span>
                    </div>
                </div>
                <div className='add-contracts-top flex w-full py-6' style={{alignItems: 'center'}}>
                    <div className='add-contracts-title w-1/2'>
                        <div className='content-add-org ml-4 py-4'>
                            <Autocomplete
                                disabled={!smartList}
                                onChange={handleChangeSmartList}
                                options={smartList}
                                getOptionLabel={(e) => e.label ? e.label+' ('+e.numberOfTotalContents+' contents)' : '' }
                                size='small'
                                disableClearable
                                className='mt-2'
                                classes={{
                                    root: classes.root
                                }}
                                renderOption={option => {
                                    return (
                                        <Fragment>
                                            <p className='ml-2'>{option.label}</p>
                                        </Fragment>
                                    )
                                }}
                                renderInput={(params) => <TextField {...params} placeholder={smartList ?'Select smartlist' : 'Loading ...'} variant="outlined" InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!smartList ? (
                                                <CircularProgress
                                                    style={{marginLeft: '10px'}}
                                                    size={20}
                                                />
                                            ) : <img
                                                style={{
                                                    marginLeft: '5px',
                                                    height: '38px',
                                                    width: '38px',
                                                    maxWidth: 'none',
                                                    borderTopLeftRadius: '3px',
                                                    borderBottomLeftRadius: '3px',
                                                }}
                                                src={okastLogo} alt='logo'/>}
                                        </InputAdornment>
                                    ),
                                }} />}
                            />
                        </div>
                    </div>
                    <div className='add-contracts-status ml-3 w-1/2'>
                        {numberOfExistContents > 0 ? <div><span style={{fontWeight: '600'}}>{t("content.warning")} :</span> <span> {numberOfExistContents} {t("content.content_already_in_db")}</span></div> : null}
                    </div>
                </div>

                <div className='items-center col-span-1 py-2'>
                    <div className='items-center mt-2 flex' style={{alignItems: 'center'}}>
                        <button
                            style={{
                                background: 'rgb(208, 94, 90)',
                                color: 'rgb(251 248 248)',
                                fontSize: '12px',
                                width: '22px',
                                height: '22px',
                                borderRadius: '50%',
                            }}
                        >2</button>
                        <span style={{marginLeft: '10px', fontWeight: '600'}}>{t("content.select_content_import")}</span>
                    </div>
                </div>
                <div className='my-4 pb-6 pl-4 pr-4'>
                    <TransferList
                        sellerName={'Smartlist'}
                        listContent={contentList}
                        pickedContent={contentListToImport}
                        licensedContent={handleLicensedContentChosen} />
                </div>
                <div className='my-4 pb-6 pl-4 pr-4'>
                    <div className='mt-4' style={{paddingBottom: '20px'}}>
                        <InputLabel shrink style={{ color: 'rgba(0, 0, 0, 0.54)', paddingBottom: '15px' }}>
                        {t("common.status")}
                        </InputLabel>
                    <TextField
                        className={classes.root}
                        onChange={handleChangeStatus}
                        value={CONTENT_STATUS && CONTENT_STATUS.find(e => e.id === contentStatus).id}
                        style={{
                            marginTop: 'auto',
                            width: '25%',
                            height: '2.75rem'
                        }}
                        variant='outlined'
                        select>
                        {
                            filterListContentStatus && filterListContentStatus.map((value, key) => {
                                return (
                                    <MenuItem key={key} value={value.id}>
                                        <div className='flex items-center'>
                                            <span id='status' className='rounded-full block m-1' style={{
                                                backgroundColor: value.color,
                                                height: '15px',
                                                width: '15px',
                                            }} />
                                            <span className='ml-1'>{value.value}</span>
                                        </div>
                                    </MenuItem>
                                )
                            })
                        }
                    </TextField>
                </div>
                </div>
                <div className='flex justify-end content-center'>
                    <div className='flex' style={{
                        height: '36px'
                    }}>
                        <Button
                            onClick={handleImportContent}
                            disabled={contentListToImport.length === 0}
                            style={{
                            backgroundColor: contentListToImport.length === 0 ? 'lightgrey' : '#D05E5A',
                            color: 'white',
                            fontSize: '0.75rem',
                        }}
                        >
                            {t("common.import")}
                        </Button>
                    </div>
                </div>
            </div>


        </Layout>
    )
};

export default withSnackbar(Synchronization);
