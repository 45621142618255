import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const DescendingIcon = () => {
        return (
            <ArrowDropDownIcon />
        );
};

export default DescendingIcon;
