import React, { useEffect, useState } from 'react';
import './index.css';
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import TextField from "@material-ui/core/TextField";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { NumericFormat } from "react-number-format";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import RemoveIcon from '@material-ui/icons/Remove';
import Button from "@material-ui/core/Button";
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import Step from '@material-ui/core/Step';
import clsx from 'clsx';
import StepConnector from '@material-ui/core/StepConnector';
import { useSnackbar } from "notistack";
import { useTranslation } from 'react-i18next';
import { convertAmountToNumber } from '../../../../util';
const useStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        },
        "& .MuiOutlinedInput-root": {
            height: '34px',
            marginTop: '8px'
        }
    }
});

const useStylesStep = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: 0,
    },
}));

const PricingLadder = ({ initializePricingLadder, pricingLadder, mgApplied, currency, disabled }) => {
    const classesText = useStyles();
    const classesStep = useStylesStep()
    const [formValues, setFormValues] = useState([])
    const [isHover, setIsHover] = useState(false);
    const [isHoverIndex, setIsHoverIndex] = useState(null);
    const [openReport, setOpenReport] = useState(true);
    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setFormValues(initializePricingLadder);
    }, [initializePricingLadder]);

    const addFormFields = () => {
        if (formValues[formValues.length - 1].to <= formValues[formValues.length - 1].from) {
            enqueueSnackbar(t("contract.to_greater_from"), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
            return;
        }

        if (formValues.length < 15) {
            setFormValues([...formValues, { from: +formValues[formValues.length - 1].to + 1, to: +formValues[formValues.length - 1].to + 2, value: 0, minimum: 0 }]);
            pricingLadder([...formValues, { from: +formValues[formValues.length - 1].to + 1, to: +formValues[formValues.length - 1].to + 2, value: 0, minimum: 0 }]);
        }
    }

    const removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        if (newFormValues.length === 1) {
            newFormValues[0].from = 0;
        }
        setFormValues(newFormValues);
        pricingLadder(newFormValues);
    }

    const onMouseEnterHandler = (index) => {
        setIsHover(true);
        setIsHoverIndex(index);
    };

    const onMouseLeaveHandler = (index) => {
        setIsHover(false);
        setIsHoverIndex(index);
    };

    const handleChangeFromValue = (e, index) => {
        formValues[index].from = +e.target.value;
        if (index === formValues.length - 1) {
            formValues[index].to = +e.target.value + 1;
        }
        pricingLadder(formValues);
    }

    const handleChangeToValue = (e, index) => {
        formValues[index].to = +e.target.value;
        pricingLadder(formValues);
    }

    const handleChangeBilledValue = (e, index) => {
        formValues[index].value = +convertAmountToNumber(e.target.value);
        pricingLadder(formValues);
    }

    const handleChangeMinimumValue = (e, index) => {
        formValues[index].minimum = +convertAmountToNumber(e.target.value);
        pricingLadder(formValues);
    }

    const ColorlibConnector = withStyles({
        vertical: {
            padding: '0',
            marginLeft: 13
        },

        lineVertical: {
            minHeight: 50
        },

        line: {
            height: 0,
            border: 0,
            borderLeft: '3px solid rgb(208, 94, 90)',
        },
    })(StepConnector);




    const useColorlibStepIconStyles = makeStyles({
        root: {
            backgroundColor: 'rgb(208, 94, 90)',
            zIndex: 1,
            color: '#fff',
            width: 28,
            height: 28,
            display: 'flex',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
        },

    });

    const ColorlibStepIcon = (props) => {
        const classes = useColorlibStepIconStyles();
        return (
            <div
                className={clsx(classes.root)}
            >
                {props}
            </div>
        );
    }


    return (
        <div className='mt-4'>
            <button className='pricing-ladder-expand-button' style={{marginBottom: 35}}
                onClick={() => setOpenReport(!openReport)}>
                {openReport ? <ExpandLess className='expand-arrow' /> : <ExpandMore className='expand-arrow' />}
            </button>
            <Collapse in={openReport} timeout="auto" unmountOnExit>
                <div className={classesStep.root}>
                    <Stepper connector={<ColorlibConnector />} orientation="vertical">
                        {formValues && formValues.map((element, index) => (
                            <Step key={index}>
                                <StepLabel StepIconComponent={() => ColorlibStepIcon(index+1)}>

                                    <div className='grid grid-cols-12 gap-1 pricing-ladder-row'
                                        onMouseEnter={() => onMouseEnterHandler(index)}
                                        onMouseLeave={() => onMouseLeaveHandler(index)}
                                        key={index}>
                                        <div className='col-span-1' style={{marginTop: -32}}>
                                            <InputLabel shrink className='ladder-input'>
                                                {t("contract.from")}
                                            </InputLabel>

                                            <input
                                                className='global-id text-right ladder-input-value'
                                                disabled={disabled}
                                                onChange={(e) => handleChangeFromValue(e, index)}
                                                value={element.from || 0}
                                                id={`${index}-from-value`}
                                                min='0'
                                                name='fromValue'
                                                type="number"
                                            />
                                        </div>

                                        {formValues.length - 1 !== index ?
                                            <div className='col-span-1' style={{marginTop: -32}}>
                                                <InputLabel shrink className='ladder-input'>
                                                {t("contract.to")}
                                                </InputLabel>
                                                <input
                                                    className='global-id text-right ladder-input-value'
                                                    disabled={disabled}
                                                    onChange={(e) => handleChangeToValue(e, index)}
                                                    value={element.to || 0}
                                                    id={`${index}-to-value`}
                                                    min='0'
                                                    name='toValue'
                                                    type="number"
                                                />
                                            </div> :
                                            <div className='col-span-1' style={{ marginRight: '6px', marginTop: -32 }}>
                                                <InputLabel shrink className='ladder-input'>
                                                {t("contract.to")}
                                                </InputLabel>
                                                <TextField
                                                    className={classesText.root}
                                                    disabled={disabled}
                                                    style={{
                                                        height: '34px',
                                                        width: '50%'
                                                    }}
                                                    name='toValue'
                                                    value='Above'
                                                    id='to-value'
                                                    variant='outlined' />
                                            </div>
                                        }

                                        <div className='col-span-1' style={{marginTop: -32}}>
                                            <InputLabel shrink className='ladder-input'>
                                            {t("contract.each_view_billed")}
                                            </InputLabel>
                                            <NumericFormat
                                                className='global-id text-right ladder-bill-input-value'
                                                disabled={disabled}
                                                onChange={(e) => handleChangeBilledValue(e, index)}
                                                name='billedValue'
                                                value={Number.parseFloat(element.value) || 0}
                                                decimalScale={4}
                                                id={`${index}-billed-id`}
                                                thousandSeparator={t('common.thousand_separator')} 
                                                decimalSeparator={t('common.decimal_separator')}
                                                allowNegative={false}
                                            />
                                            <span className='ladder-currency'>{currency}</span>
                                        </div>

                                        {mgApplied === 2 ?
                                            <div className='col-span-1' style={{marginTop: -32}}>
                                                <InputLabel shrink className='ladder-input'>
                                                {t("contract.minimum_of")}
                                                </InputLabel>
                                                <NumericFormat
                                                    className='global-id text-right ladder-bill-input-value'
                                                    disabled={disabled}
                                                    onChange={(e) => handleChangeMinimumValue(e, index)}
                                                    name='minimumValue'
                                                    value={Number.parseFloat(element.minimum) || 0}
                                                    decimalScale={4}
                                                    id={`${index}-minimum-value`}
                                                    thousandSeparator={t('common.thousand_separator')} 
                                                    decimalSeparator={t('common.decimal_separator')}
                                                    allowNegative={false}
                                                />
                                                <span className='ladder-currency'>{currency}</span>
                                            </div> : ''
                                        }

                                        <div className='col-span-1' style={{marginTop: -32}}>
                                            {isHover && isHoverIndex === index && !disabled ?
                                                <Button className='ladder-adding-button'>
                                                    <AddRoundedIcon className='ladder-adding-icon' onClick={() => addFormFields(index)} /></Button>
                                                : ''}
                                        </div>

                                        <div className='items-center col-span-1' style={{marginTop: -32}}>
                                            {formValues.length > 1 && isHover && isHoverIndex === index && !disabled ?
                                                <Button className='ladder-remove-button'>
                                                    <RemoveIcon className='ladder-remove-icon' onClick={() => removeFormFields(index)} /></Button>
                                                : ''}
                                        </div>
                                    </div>
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
            </Collapse>
        </div>
    );
}

export default PricingLadder;

