import React from 'react';
import Layout from '../../layouts/Default';
import './index.css';
import { useTranslation } from 'react-i18next';
const ErrorPage = () => {
    const { t, i18n } = useTranslation();
    return (
       <Layout>
            <p  class="child" >{t('common.page_not_allow')}</p>
       </Layout>
    );
}

export default ErrorPage;
