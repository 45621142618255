import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useParams } from 'react-router';
import { useSnackbar, withSnackbar } from 'notistack';
import { authActions } from '../../../state/modules/auth';
import './index.css';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { InputLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import imgDistribute from '../../../assets/images/rights-distrib.png';
import logo from '../../../assets/images/logo.png';
import axios from "../../../config/axios";
import { useTranslation } from 'react-i18next';
const useButtonLoginStyles = makeStyles({
    root: {
        background: '#D05E5A',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        '&:hover': {
            background: '#D05E5A'
        },
        marginTop: '3rem'
    },
});

const useLabelStyles = makeStyles({
    root: {
        fontSize: '0.75rem',
    },
});

const useCopyrightStyles = makeStyles({
    root: {
        fontSize: '2.75rem',
        fontWeight: 'bold'
    },
});

const useTextFieldStyles = makeStyles({
    root: {
        marginTop: '0.25rem',
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        }
    }
})

function ChangePassword() {
    const [reveal, setReveal] = React.useState(false);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        password: '',
        errMessagePassword: null,
    });
    let { id } = useParams();
    const { t, i18n } = useTranslation();
    useEffect(() => {
        dispatch(authActions.sessionExpired({sessionExpired: '0'}));
    }, [dispatch])



    const handleChange = (e) => {
        const { id, value } = e.target;

        setState(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const disabled = !state.password;

    const validateInputPassword = (e) => {
        const password = e.target.value;
        if (!password) {
            setState(prevState => ({
                ...prevState,
                errMessagePassword: t('change_pass.pass_require')
            }));
        } else {
            setState(prevState => ({
                ...prevState,
                errMessagePassword: null
            }));
        }
    };

    const { enqueueSnackbar } = useSnackbar();

    const handleChangePassword = async () => {
        try {
            const dataRequest = {
                password: state.password,
                token: id
            }

            const sendStatus = await axios.post(`/user/change-password-token`, dataRequest);

            const {data, status} = sendStatus;

            if (status === 200) {
                enqueueSnackbar(t('change_pass.pass_change_success'), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'success'
                });
                window.location.href = '/login';
            }
        } catch (error) {
            enqueueSnackbar(t('notify.something_wrong'), {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
    };

    const revealPassword = () => {
        const password = document.querySelector('#password');
        // set icon
        setReveal(password.getAttribute('type') === 'password');

        // toggle the type attribute
        const type = password.getAttribute('type') === 'text' ? 'password' : 'text';
        password.setAttribute('type', type);

    }

    return (
        <div className='login h-screen'>
            <div className='flex h-screen'>
                <div className='w-1/4 bg-white login-left pt-32 px-8'>
                    <div className='login-logo flex justify-center'>
                        <div>
                            <img src={logo} width='195px' height='120px' alt='logo'/>

                            <InputLabel className='text-center mt-5 login-main-text'>{t('change_pass.enter_pass')}</InputLabel>
                        </div>
                    </div>
                    <form className='form-login mt-16'>
                        <div className='input-login-email'>
                            <InputLabel classes={{
                                root: useLabelStyles().root
                            }}>{t('change_pass.your_new_pass')}</InputLabel>
                            <TextField
                                autoComplete="off"
                                error={!!state.errMessagePassword}
                                className='w-full text-email'
                                classes={{
                                    root: useTextFieldStyles().root
                                }}
                                type='password'
                                id='password' onChange={handleChange}
                                value={state.password}
                                placeholder={t('change_pass.enter_pass')}
                                helperText={state.errMessagePassword ? state.errMessagePassword : null}
                                variant="outlined"
                                onBlur={validateInputPassword}
                                InputProps={{
                                    startAdornment: reveal ?
                                        <VisibilityOffIcon style={{color: 'grey', fontSize: '22px'}} onClick={revealPassword}/> :
                                        <VisibilityIcon style={{color: 'grey', fontSize: '22px'}} onClick={revealPassword}/>,
                                }}
                            />
                        </div>

                        <Button
                            onClick={handleChangePassword}
                            disabled={disabled}
                            className='login-button w-full mt-16'
                            classes={{
                                root: useButtonLoginStyles().root
                            }}
                        >
                            {t('change_pass.change_pass')}
                        </Button>


                    </form>
                </div>

                <div className='w-3/4 login-right h-screen flex justify-center content-center'>
                    <div className='m-auto'>
                        <img src={imgDistribute} alt='illustration'/>
                        <div className='login-text-copyright'>
                            <InputLabel classes={{
                                root: useCopyrightStyles().root
                            }} className='text-center'> {t('change_pass.copyrights')}</InputLabel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withSnackbar(ChangePassword);
