import React, { useState, useMemo } from 'react';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { Link } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import MovieCreationIcon from '@material-ui/icons/MovieCreation';
import VideocamIcon from '@material-ui/icons/Videocam';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import TheatersIcon from '@material-ui/icons/Theaters';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { GET_CONTENT_STATUS } from '../../../../constants';
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";
import noPicture from '../../../../assets/images/noPicture.jpg';
import './index.css';
const RowComponent = ({ content, listPermission, handleChangeCheckItem }) => {
    const { t, i18n } = useTranslation();
    const CONTENT_STATUS = useMemo(() => GET_CONTENT_STATUS(t), [t]);
    const [isHover, setIsHover] = useState(false);
    const [open, setOpen] = useState(false);
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#696969',
            color: '#dbdbdb',
            marginBottom: 0,
            fontSize: 14,
        },
    }))(Tooltip);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const renderIconForType = (type) => {
        switch (type) {
            case 1:
                return (
                    <MovieCreationIcon />
                );
            case 2:
                return (
                    <VideocamIcon />
                );
            case 3:
                return (
                    <OndemandVideoIcon />
                );
            case 4:
                return (
                    <ArtTrackIcon />
                );
            case 5:
                return (
                    <TheatersIcon />
                );
            default:

                return (
                    <MovieCreationIcon />
                );
        }
    };

    const onMouseEnterHandler = (e) => {
        setIsHover(true)
    };

    const onMouseLeaveHandler = (e) => {
        setIsHover(false)
    };

    let displayTimeOrigin = content.updatedAt ? content.updatedAt : "";
    let displayTime = displayTimeOrigin.split("T");
    const displayTimeArr = displayTime[0] ? displayTime[0].split("-") : "";

    const renderStatusTooltipTitle = (status) => {
        switch (status) {
            case 1:
                return (
                    t("status.available")
                );
            case 2:
                return (
                    t("status.new")
                );
            case 3:
                return (
                    t("status.block")
                );
            case 4:
                return (
                    t("status.private")
                );
            default:
                return (
                    t("status.available")
                );
        }
    }

    const renderStatusTooltipType = (type) => {
        switch (type) {
            case 1:
                return (
                    t("common.stock")
                );
            case 2:
                return (
                    t("common.live_stream")
                );
            case 3:
                return (
                    t("common.tv_chanel")
                );
            case 4:
                return (
                    t("common.dvd")
                );
            case 5:
                return (
                    t("common.theater")
                );
            default:
                return (
                    t("common.stock")
                );
        }
    }

    return (
        <div
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            className='grid grid-cols-12 gap-2 text-center mt-3 rounded-lg items-center justify-items-center py-4' style={{
                background: 'rgb(242, 242, 242)',
                border: isHover ? '1px solid red' : ''
            }}>
            <div className='col-span-1'>
                <Checkbox checked={content.checked} onChange={() => handleChangeCheckItem(content)} />
            </div>
            <LightTooltip title={renderStatusTooltipTitle(content.status)} placement="top">
                <div className='m-auto col-span-1'>
                    <span id='status' className='rounded-full block m-1' style={{
                        backgroundColor: CONTENT_STATUS.find(ct => ct.id === content.status).color,
                        height: '15px',
                        width: '15px',
                    }} />
                </div>
            </LightTooltip>

            <LightTooltip title={renderStatusTooltipType(content.Format)} placement="top">
                <div className='col-span-1 flex justify-center'>
                    {renderIconForType(content.Format ? content.Format : 0)}
                </div>
            </LightTooltip>
            <div className='col-span-1 flex justify-center'>
                <img
                    src={content.illustrationUrlS3 ? content.illustrationUrlS3 : noPicture}
                    style={{
                        height: '40px',
                        backgroundSize: 'cover',
                        backgroundPosition: 'top center',
                    }}></img>
            </div>
            <LightTooltip title={content.Title} placement="top">
                <div className='col-span-3 text-left eLlipsis_text'>
                    {`${content.Title}`}
                </div>
            </LightTooltip>
            <div className='col-span-1'>
                {content.duration}
            </div>
            <div className='col-span-2'>
                {content.numberOfDeals ? content.numberOfDeals : 0}
            </div>
            {listPermission.includes('IKAST_CONTENT_EDIT') && isHover ?
                (
                    <div className='flex m-auto col-span-2'>
                        <Link to={`/contents/catalogue/update/${content.ikastUID}`}>
                            <EditRoundedIcon style={{
                                cursor: 'pointer'
                            }} />
                        </Link>
                        {/* <DeleteRoundedIcon
                            onClick={() => handleClickOpen()}
                            style={{
                                marginLeft: 12,
                                cursor: 'pointer'
                            }} /> */}
                    </div>
                ) :
                (
                    <div className='col-span-2'>{displayTimeArr ? `${displayTimeArr[2]}/${displayTimeArr[1]}/${displayTimeArr[0]}` : ""} </div>
                )
            }
        </div>
    );
};

export default RowComponent;
