import React, { useState, useEffect, useContext } from 'react';
import logo from '../../../assets/images/logo.png';
import './index.css';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel, TextField } from '@material-ui/core';
import imgDistribute from '../../../assets/images/rights-distrib.png';
import { useHistory } from 'react-router-dom';
import { useSnackbar, withSnackbar } from 'notistack';
import { useDispatch } from "react-redux";
import { authActions } from '../../../state/modules/auth';
import LoadingContext from '../../../context/loading/LoadingContext';
import { useTranslation } from 'react-i18next';
const useButtonLoginStyles = makeStyles({
    root: {
        background: '#D05E5A',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        '&:hover': {
            background: '#D05E5A'
        },
        marginTop: '3rem'
    },
});

const useLabelStyles = makeStyles({
    root: {
        fontSize: '0.75rem',
    },
});

const useCopyrightStyles = makeStyles({
    root: {
        fontSize: '2.75rem',
        fontWeight: 'bold'
    },
});

const useTextFieldStyles = makeStyles({
    root: {
        marginTop: '0.25rem',
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderWidth: "1px"
        }
    }
})

function Login() {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
   
    useEffect(() => {
        dispatch(authActions.sessionExpired({sessionExpired: '0'}));
    }, [dispatch])

    useEffect(() => {
        const language = window.navigator.userLanguage || window.navigator.language;
        if(language === 'fr-FR' || language === 'fr') {
            i18n.changeLanguage('fr');
        } else {
            i18n.changeLanguage('en');
        }
    }, [])

    const { showLoading, hideLoading } = useContext(LoadingContext)

    const [state, setState] = useState({
        email: '',
        password: '',
        errMessageEmail: null,
        errMessagePassword: null,
        messageLoginFailed: null
    });

    const handleChange = (e) => {
        const { id, value } = e.target;

        setState(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    
    const disabled = !state.email || !state.password
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            if(!disabled) {
                handleClickLogin();
            }
        }
      }

    const validateInputEmail = (e) => {
        const email = e.target.value;
        if (!email) {
            setState(prevState => ({
                ...prevState,
                errMessageEmail: t('login.email_required')
            }));
            return false;
        } else {
            setState(prevState => ({
                ...prevState,
                errMessageEmail: null
            }));
            return true;
        }
    };

    const validateInputPassword = (e) => {
        const password = e.target.value;
        if (!password) {
            setState(prevState => ({
                ...prevState,
                errMessagePassword: t('login.password_required')
            }));
            return false;
        } else {
            setState(prevState => ({
                ...prevState,
                errMessagePassword: null
            }));
            return true;
        }
    };

    let history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const handleClickLogin = () => {
        showLoading();
        dispatch(authActions.login({ email: state.email, password: state.password, history, enqueueSnackbar }));
        hideLoading();
    };

    const navigateToForgotPassword = () => {
        window.location.href = '/forgot-password';
    }

    return (
        <div className='login h-screen'>
            <div className='flex h-screen'>
                <div className='w-1/4 bg-white login-left pt-32 px-8'>
                    <div className='login-logo flex justify-center'>
                        <div>
                            <img src={logo} width='120px' height='120px' alt='logo'/>
                            <InputLabel className='text-center mt-5 login-main-text'>{t('login.login')}</InputLabel>
                        </div>
                    </div>
                    <form className='form-login mt-16'>
                        <div className='input-login-email'>
                            <InputLabel classes={{
                                root: useLabelStyles().root
                            }}>{t('login.your_email_address')}</InputLabel>
                            <TextField
                                autoComplete="off"
                                error={state.errMessageEmail ? true : false}
                                className='w-full text-email'
                                classes={{
                                    root: useTextFieldStyles().root
                                }}
                                id='email' onChange={handleChange} value={state.email} placeholder={t('login.enter_your_email_address')}
                                helperText={state.errMessageEmail ? state.errMessageEmail : null}
                                variant="outlined"
                                onBlur={validateInputEmail}
                                onKeyDown={(e) => handleKeyDown(e)}
                                InputProps={{
                                    startAdornment: <PermIdentityIcon />,
                                }}
                            />
                        </div>

                        <div className='input-login-password mt-5'>
                            <InputLabel classes={{
                                root: useLabelStyles().root
                            }}>{t('login.your_password')}</InputLabel>
                            <TextField
                                autoComplete="off"
                                error={state.errMessagePassword ? true : false}
                                type='password'
                                className='w-full'
                                classes={{
                                    root: useTextFieldStyles().root
                                }}
                                onKeyDown={(e) => handleKeyDown(e)}
                                id='password' onChange={handleChange} value={state.password} placeholder={t('login.enter_your_password')}
                                helperText={state.errMessagePassword ? state.errMessagePassword : null}
                                variant="outlined"
                                onBlur={validateInputPassword}
                                InputProps={{
                                    startAdornment: <LockOpenIcon />
                                }}
                            />
                        </div>

                        <Button
                            onClick={handleClickLogin}
                            disabled={disabled}
                            className='login-button w-full mt-16'
                            classes={{
                                root: useButtonLoginStyles().root
                            }}
                        >
                            {t('login.login')}
                        </Button>

                    </form>
                    <button className="w-full mt-16 no-border-button forgot-password-navigation" onClick={navigateToForgotPassword}
                    >{t('login.forgot_password')}</button>
                </div>

                <div className='w-3/4 login-right h-screen flex justify-center content-center'>
                    {/* <div className='wrapper-lang '>
                        <LanguageComponent/>
                    </div> */}
                    <div className='m-auto'>
                        <img src={imgDistribute} alt='illustration'/>
                        <div className='login-text-copyright'>
                            <InputLabel classes={{
                                root: useCopyrightStyles().root
                            }} className='text-center'>{t('login.licensing_done')}</InputLabel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withSnackbar(Login);
