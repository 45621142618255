import { put, call, takeLatest } from 'redux-saga/effects';
import { createAction } from 'redux-actions';
import * as api from '../../../api/auth';
import axios from '../../../config/axios';
import types from './types';
// import handleError from '~/utils/ErrorHandler';

//= ============== ACTIONS ===============//
const login = createAction(types.LOGIN);
const loginSuccess = createAction(types.LOGIN_SUCCESS);
const loginFail = createAction(types.LOGIN_FAIL);
const storeAuth = createAction(types.STORE_AUTH);
const getProfile = createAction((types.GET_PROFILE));
const getProfileSuccess = createAction((types.GET_PROFILE_SUCCESS));
const getOrganization = createAction((types.GET_ORGANIZATION));
const getOrganizationSuccess = createAction((types.GET_ORGANIZATION_SUCCESS));
const updateProfile = createAction((types.UPDATE_PROFILE));
const updateProfileSuccess = createAction((types.UPDATE_PROFILE_SUCCESS));
const updateProfileError = createAction((types.UPDATE_PROFILE_ERROR));
const sessionExpired = createAction(types.SESSION_EXPIRED);

export const actions = {
    login,
    loginSuccess,
    loginFail,
    storeAuth,
    getProfile,
    getProfileSuccess,
    getOrganization,
    getOrganizationSuccess,
    updateProfile,
    updateProfileSuccess,
    updateProfileError,
    sessionExpired,
};

//= =============== SAGAS ===============//
export function* sagas() {
    yield takeLatest(types.LOGIN, fetchAuth);
    yield takeLatest(types.GET_PROFILE, getProfileUser);
    yield takeLatest(types.UPDATE_PROFILE, updateProfileUser);
}

function* fetchAuth({ payload }) {
    const lang = localStorage.getItem('language');
    try {
        const { email, password, history, enqueueSnackbar } = payload;
        const response = yield call(api.login, { email, password });

        const { data, status } = response;
        if (status === 200) {
            // yield put(storeAuth(response.data));
            yield put(loginSuccess());
            localStorage.setItem('token', data.accessToken);
            localStorage.setItem('id', data.ikastUID);
            localStorage.setItem('orgUid', data.orgUid);
            const responseProfile = yield axios.get(`/user/${data.ikastUID}`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            if (status === 200) {
                yield put(getProfileSuccess(responseProfile.data));
                history.push('/dashboard');
                const responseOrg = yield axios.get(`/org/get-one/${responseProfile.data.BelongsTo}`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                });

                if (responseOrg.status === 200) {
                    if (responseOrg.data.mainCurrency) {
                        localStorage.setItem('mainCurrency', responseOrg.data.mainCurrency)
                    }
                    yield put(getOrganizationSuccess(responseOrg.data));
                }
            }

            enqueueSnackbar(lang === 'en' ? 'Login successfully.!' : 'Bienvenue sur iKast !', {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'success'
            });
        } else {
            enqueueSnackbar(lang === 'en' ? 'Login failed.!' : 'Erreur de connection !', {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }
    } catch (error) {
        const { enqueueSnackbar } = payload;
        enqueueSnackbar(lang === 'en' ? 'Login failed.!' : 'Erreur de connection !', {
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },

            variant: 'error'
        });
        yield put(loginFail());
    }
}

function* getProfileUser({ payload }) {
    try {
        const response = yield axios.get(`/user/${payload.id}`, {
            headers: {
                'x-access-token': localStorage.getItem('token')
            }
        });

        const { data, status } = response;
        if (status === 200) {
            yield put(getProfileSuccess(data));
            const responseOrg = yield axios.get(`/org/get-one/${data.BelongsTo}`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            if (responseOrg.status === 200) {
                if (responseOrg.data.mainCurrency) {
                    localStorage.setItem('mainCurrency', responseOrg.data.mainCurrency)
                }
                yield put(getOrganizationSuccess(responseOrg.data));
            }
        }
    } catch (error) {
        console.log(error)
        // handleError(error);
        // yield put(loginFail());
    }
}

function* updateProfileUser({ payload }) {
    const lang = localStorage.getItem('language');
    try {
        const { dataUpdate, user, enqueueSnackbar } = payload;
        const response = yield axios.put(`/user/update-info`, dataUpdate, {
            headers: {
                common: {
                    'x-access-token': localStorage.getItem('token') ? localStorage.getItem('token') : null,
                },
            }
        });

        if (response.status === 200) {
            yield put(updateProfileSuccess(user.toJS()));
            localStorage.setItem('language', dataUpdate.lang);
            enqueueSnackbar && enqueueSnackbar(lang === 'en' ? 'Update successfully.!' : 'Mise à jour effectuée', {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'success'
            });
        } else {
            enqueueSnackbar && enqueueSnackbar(lang === 'en' ? 'Update failed.!' : 'Erreur dans la mise à jour', {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },

                variant: 'error'
            });
        }

    } catch (error) {
        const { enqueueSnackbar } = payload;
        enqueueSnackbar(lang === 'en' ? 'Update failed.!' : 'Erreur dans la mise à jour', {
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },

            variant: 'error'
        });
    }
}
