import React, { useEffect, useState, useRef, Fragment, useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";

import './index.css';
import { withSnackbar } from 'notistack';
import { authActions, authSelectors } from '../../../state/modules/auth';
import Pagination from "@material-ui/lab/Pagination/Pagination";
import SortContentIconRender from "../../components/Royalties/SortContentIconRender";
import LoadingLayout from "../../components/Common/LoadingLayout";
import MessageItem from "../../components/Messages/MessageItem";
import Layout from '../../layouts/Default';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import LoadingContext from '../../../context/loading/LoadingContext';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from "../../../config/axios";
import openSocket from 'socket.io-client';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
const socket = openSocket(process.env.REACT_APP_SERVER_API_URL ? process.env.REACT_APP_SERVER_API_URL : 'http://localhost:8089')

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const Messages = () => {
    const { t, i18n } = useTranslation();
    const [page, setPage] = useState(1);
    const selector = useSelector((state) => authSelectors.user(state));
    const [user, setUserTracking] = useState(selector);
    const [businessPartners, setBusinessPartners] = useState(null);
    const prevData = usePrevious(selector);

    const [sortType, setSortType] = useState(null);
    const [sortTitle, setSortTitle] = useState(null);
    const [sortSender, setSortSender] = useState(null);
    const [sortLastUpdate, setSortLastUpdate] = useState(null);

    const [columnType, setColumnType] = useState(null);
    const [columnTitle, setColumnTitle] = useState(null);
    const [columnSender, setColumnSender] = useState(null);
    const [columnLastUpdate, setColumnLastUpdate] = useState(null);
    const [isCheckall, setIsCheckall] = useState(false);
    const [listItemChecked, setListItemChecked] = useState([]);
    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const [listPermission, setListPermission] = useState([]);
    const { showLoading, hideLoading } = useContext(LoadingContext);
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        socket.on('new', (data) => {
            if (data && user) {
                (async function getListMessages() {
                    const contentResponse = await axios.get(`/message/get-list-messages?orgUuid=${user.get('BelongsTo')}&pageNo=${page}&itemPerPage=10&sortBy=createdAt&sortOrder=1`, {
                        headers: {
                            'x-access-token': localStorage.getItem('token')
                        }
                    });

                    const { data, status } = contentResponse;

                    if (status === 200) {
                        onLoadMessageList(data);
                    }
                })();
            }
        });

        if (user && user.get('org') && localStorage.getItem('token')) {
            getListMessages()
            // (async function getListMessages() {
            //     showLoading();
            //     const contentResponse = await axios.get(`/message/get-list-messages?orgUuid=${user.get('BelongsTo')}&pageNo=${page}&itemPerPage=10&sortBy=createdAt&sortOrder=1`, {
            //         headers: {
            //             'x-access-token': localStorage.getItem('token')
            //         }
            //     });

            //     const { data, status } = contentResponse;

            //     if (status === 200) {
            //         onLoadMessageList(data);
            //     }
            //     hideLoading();
            // })();
        }

    }, [user, page]); 

    const getListMessages = async () => {
        showLoading();
        const contentResponse = await axios.get(`/message/get-list-messages?orgUuid=${user.get('BelongsTo')}&pageNo=${page}&itemPerPage=10&sortBy=createdAt&sortOrder=1`, {
            headers: {
                'x-access-token': localStorage.getItem('token')
            }
        });

        const { data, status } = contentResponse;

        if (status === 200) {
            onLoadMessageList(data);
        }
        hideLoading();
    }

    useEffect(() => {
        if(user) {
            setListPermission(user.get('permissions').toArray());
        }
    }, [user]);

    useEffect(() => {
        if (selector) {
            setUserTracking(selector);
        }
    }, [selector]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(authActions.getProfile({ id: localStorage.getItem('id') }));
    }, []);

    const pagingHandleChange = (e, value) => {
        setPage(value);
    };

    const addStatusCheckboxForPage = (dataBusinessPartners) => {
        if (!dataBusinessPartners.records || dataBusinessPartners.records.length === 0) {
            setListItemChecked([]);
            setIsCheckall(false);
        }
        const data = dataBusinessPartners.records.map((obj, index) => ({ ...obj, checked: false }));
        dataBusinessPartners.records = data;
        return dataBusinessPartners;
    }

    const handleChangeCheckAll = () => {
        const dataBusinessPartners = JSON.parse(JSON.stringify(businessPartners));
        const oldStatus = JSON.parse(JSON.stringify(isCheckall));
        const dataRecord = dataBusinessPartners.records.map((obj, index) => ({ ...obj, checked: !oldStatus }));
        if (!oldStatus) {
            setListItemChecked(dataBusinessPartners.records);
        } else {
            setListItemChecked([]);
        }
        dataBusinessPartners.records = dataRecord;
        setBusinessPartners(dataBusinessPartners);
        setIsCheckall(!oldStatus);
    }

    const handleChangeCheckItem = (data) => {
        const dataBusinessPartners = JSON.parse(JSON.stringify(businessPartners));
        const dataListItemChecked = JSON.parse(JSON.stringify(listItemChecked));
        dataBusinessPartners.records.find(p => p.ikastUID === data.ikastUID && (p.checked = !data.checked, true));
        if (!data.checked) {
            const indexItemConflict = dataListItemChecked.findIndex(item => item.ikastUID === data.ikastUID);
            if (indexItemConflict === -1) {
                dataListItemChecked.push(data);
                setListItemChecked(dataListItemChecked);
            }
        } else {
            const indexItemConflict = dataListItemChecked.findIndex(item => item.ikastUID === data.ikastUID);
            if (indexItemConflict > -1) {
                dataListItemChecked.splice(indexItemConflict, 1);
                setListItemChecked(dataListItemChecked);
            }
        }
        setBusinessPartners(dataBusinessPartners);
    }

    const onLoadMessageList = (data) => {
        if (columnType) {
            if (sortType === 'asc') {
                sortTypeAscendingOrder();
            } else if (sortType === 'des') {
                sortTypeDescendingOrder()
            }
        } else if (columnTitle) {
            if (sortTitle === 'asc') {
                sortTitleAscendingOrder();
            } else if (sortTitle === 'des') {
                sortTitleDescendingOrder()
            }
        } else if (columnSender) {
            if (sortSender === 'asc') {
                sortSenderAscendingOrder();
            } else if (sortSender === 'des') {
                sortSenderDescendingOrder()
            }
        } else if (columnLastUpdate) {
            if (sortLastUpdate === 'asc') {
                sortLastUpdateAscendingOrder();
            } else if (sortLastUpdate === 'des') {
                sortLastUpdateDescendingOrder();
            }
        } else {
            setBusinessPartners(addStatusCheckboxForPage(data));
        }
    }

    const sortingHandle = async (sortBy, sortOrder) => {
        const messagesResponse =
            await axios.get(`/message/get-list-messages?orgUuid=${user.get('BelongsTo')}&pageNo=${page}&itemPerPage=10&sortBy=${sortBy}&sortOrder=${sortOrder}`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

        const { data, status } = messagesResponse;

        if (status === 200) {
            setBusinessPartners(addStatusCheckboxForPage(data));
        }
    };

    const sortTypeAscendingOrder = () => {
        sortingHandle('type', 0).then(r => { });
    }

    const sortTypeDescendingOrder = () => {
        sortingHandle('type', 1).then(r => { });
    }

    const sortTitleAscendingOrder = () => {
        sortingHandle('header', 0).then(r => { });
    }

    const sortTitleDescendingOrder = () => {
        sortingHandle('header', 1).then(r => { });
    }

    const sortSenderAscendingOrder = () => {
        sortingHandle('senderName', 0).then(r => { });
    }

    const sortSenderDescendingOrder = () => {
        sortingHandle('senderName', 1).then(r => { });
    }

    const sortLastUpdateAscendingOrder = () => {
        sortingHandle('createdAt', 0).then(r => { });
    }

    const sortLastUpdateDescendingOrder = () => {
        sortingHandle('createdAt', 1).then(r => { });
    }

    const sortByType = () => {
        setColumnType(true);
        setColumnTitle(false);
        setColumnSender(false);
        setColumnLastUpdate(false);
        if (!sortType) {
            sortTypeAscendingOrder();
            setSortType('asc');
        }

        if (sortType && sortType === 'asc') {
            sortTypeDescendingOrder()
            setSortType('des');
        }

        if (sortType && sortType === 'des') {
            sortTypeAscendingOrder();
            setSortType('asc');
        }
    };

    const sortByTitle = () => {
        setColumnType(false);
        setColumnTitle(true);
        setColumnSender(false);
        setColumnLastUpdate(false);
        if (!sortTitle) {
            sortTitleAscendingOrder();
            setSortTitle('asc');
        }

        if (sortTitle && sortTitle === 'des') {
            sortTitleAscendingOrder();
            setSortTitle('asc');
        }

        if (sortTitle && sortTitle === 'asc') {
            sortTitleDescendingOrder();
            setSortTitle('des');
        }
    };

    const sortBySender = () => {
        setColumnType(false);
        setColumnTitle(false);
        setColumnSender(true);
        setColumnLastUpdate(false);
        if (!sortSender) {
            sortSenderAscendingOrder();
            setSortSender('asc');
        }

        if (sortSender && sortSender === 'des') {
            sortSenderAscendingOrder();
            setSortSender('asc');
        }

        if (sortSender && sortSender === 'asc') {
            sortSenderDescendingOrder();
            setSortSender('des');
        }
    };

    const sortByLastUpdate = () => {
        setColumnType(false);
        setColumnTitle(false);
        setColumnSender(false);
        setColumnLastUpdate(true);
        if (!sortLastUpdate) {
            sortLastUpdateAscendingOrder();
            setSortLastUpdate('asc');
        }

        if (sortLastUpdate && sortLastUpdate === 'des') {
            sortLastUpdateAscendingOrder();
            setSortLastUpdate('asc');
        }

        if (sortLastUpdate && sortLastUpdate === 'asc') {
            sortLastUpdateDescendingOrder();
            setSortLastUpdate('des');
        }
    };

    const handleRefreshMessageList = async () => {
        (async function getListMessages() {
            const contentResponse = await axios.get(`/message/get-list-messages?orgUuid=${user.get('BelongsTo')}&pageNo=${page}&itemPerPage=10&sortBy=createdAt&sortOrder=1`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            });

            const { data, status } = contentResponse;

            if (status === 200) {
                onLoadMessageList(data);
            }
        })();

    };

    const openDeleteDialog = () => {
        setOpenDialogDelete(true);
    }

    const handleCloseDialogDelete = () => {
        setOpenDialogDelete(false);
    }

    const handleDelete = async () => {
        const uuidList = [];
        listItemChecked.forEach(item => {
            uuidList.push(item.ikastUID);
        });

        const data = JSON.stringify({
            "uuidList": uuidList
        });

        var config = {
            method: 'delete',
            url: '/message/delete',
            headers: {
                'x-access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: data
        };


        (async function deleteMessages() {
            const contentResponse = await axios(config);
            handleCloseDialogDelete();
            const { status } = contentResponse;

            if (status === 200) {
                
                enqueueSnackbar(t("notify.delete_success"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'success'
                });
                if(isCheckall) {
                    setPage(1);
                }
            } else {
                enqueueSnackbar(t("notify.something_wrong"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }
        })();
    }

    const handleMarkAsRead = async () => {
        const uuidList = [];
        listItemChecked.forEach(item => {
            uuidList.push(item.ikastUID);
        });

        const data = JSON.stringify({
            "uuidList": uuidList
        });

        var config = {
            method: 'put',
            url: '/message/mark-as-read',
            headers: {
                'x-access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: data
        };


        (async function markAsReadMessages() {
            const contentResponse = await axios(config);
            const { status } = contentResponse;

            if (status === 200) {
                getListMessages()         
            } else {
                enqueueSnackbar(t("notify.something_wrong"), {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },

                    variant: 'error'
                });
            }
        })();
    }

    return user ? (
        <Layout title="Messages" highlightedItem={0}>
            <div className='flex justify-between content-center'>
                <div>
                    {t("message.messages")}
                    <p className='content-title'>
                    {t("message.message_center")} ({businessPartners ? businessPartners.totalItems : 0})
                    </p>
                </div>
                <div>
                        <Button
                            disabled={listItemChecked.length === 0}
                            onClick={handleMarkAsRead}
                            style={{
                                padding: '10px 16px',
                                backgroundColor: '#D05E5A',
                                marginBottom: '0.5rem',
                                color: 'white',
                                marginRight: 20,
                                fontSize: '0.75rem'
                            }}>
                            {t("message.mark_as_read")}
                        </Button>
                    {listPermission.includes('IKAST_MESSAGE_DELETE') ? 
                        <Button
                            disabled={listItemChecked.length === 0}
                            onClick={openDeleteDialog}
                            style={{
                                padding: '10px 16px',
                                backgroundColor: '#D05E5A',
                                marginBottom: '0.5rem',
                                color: 'white',
                                fontSize: '0.75rem'
                            }}>
                            {t("common.delete")}
                        </Button>
                    : null}
                </div>
            </div>

            <div className='bg-white px-8 py-16 mt-8'>
                <div className='grid grid-cols-12 gap-2 justify-items-center text-center'>
                    {/* {listPermission.includes('IKAST_MESSAGE_DELETE') ?  */}
                        <div className='font-bold col-span-1 justify-center text-center column-header'>
                            {businessPartners && businessPartners?.records.length > 0 ? (<Checkbox checked={isCheckall} onChange={handleChangeCheckAll} />) : null}
                        </div>
                    {/* : null} */}
                    <div className='font-bold col-span-1 justify-center text-center column-header'
                        onClick={sortByType}>
                        {t("table.type")}
                        {columnType ? <SortContentIconRender sortOrder={sortType} /> : null}
                    </div>
                    <div className='font-bold col-span-4 flex justify-center text-center column-header'
                        onClick={sortByTitle}>
                        {t("table.nature")}
                        {columnTitle ? <SortContentIconRender sortOrder={sortTitle} /> : null}
                    </div>
                    <div className='font-bold col-span-3 flex justify-center text-center column-header'
                        onClick={sortBySender}>
                        {t("table.from")}
                        {columnSender ? <SortContentIconRender sortOrder={sortSender} /> : null}
                    </div>
                    <div className='font-bold col-span-2 flex justify-center text-center column-header'
                        onClick={sortByLastUpdate}>
                         {t("table.date")}
                        {columnLastUpdate ? <SortContentIconRender sortOrder={sortLastUpdate} /> : null}
                    </div>
                    <div className='font-bold col-span-1 flex justify-center text-center column-header'>
                    </div>
                </div>

                <Fragment>
                    {businessPartners && businessPartners.records && businessPartners.records.map((value, key) => {
                        return (
                            <MessageItem
                                myOrgId={user.get('BelongsTo')}
                                isContentUpdating={false}
                                item={value}
                                key={key}
                                listPermission={listPermission}
                                handleChangeCheckItem={(e) => handleChangeCheckItem(e)}
                                refreshMessageList={handleRefreshMessageList}
                            />
                        )
                    })}
                </Fragment>

                <div className='flex flex-row justify-center items-center py-4' style={{ marginTop: '20px' }}>
                    <Pagination
                        count={businessPartners ? businessPartners.totalPages : 0}
                        page={page}
                        onChange={pagingHandleChange} />
                </div>
                <Dialog
                    open={openDialogDelete}
                    onClose={handleCloseDialogDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title"> {t("message.delete_message")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {listItemChecked.length > 1 ? t("message.delete_multi") : t("message.delete_one")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialogDelete} color="primary">
                            {t("common.cancel")}
                        </Button>
                        <Button color="primary" onClick={handleDelete} autoFocus>
                        {t("common.yes")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Layout>
    ) : <LoadingLayout />
};

export default withSnackbar(Messages);
