import React, { useEffect } from 'react';
import ChartSection from '../../../components/Royalties/ChartSection';
import WorldMap from './WorldMap'
import './index.css';
const Revenue = (props) => {

    const { handleChangeStartDate, handleChangeEndDate, handleChangeBarChartView, contentStartDateView, contentEndDateView,
        pieChartData, barChartData, viewBarChartMode, totalSectionData, CURRENCY_UNIT, dataTotalRevennue } = props;

    return (
        <div className='bg-white pt-4 pb-2 px-8'>
            <ChartSection
                handleChangeStartDate={handleChangeStartDate}
                // handleChangeStartDateView={handleChangeStartDateView}
                handleChangeEndDate={handleChangeEndDate}
                // handleChangeEndDateView={handleChangeEndDateView}
                handleChangeBarChartView={handleChangeBarChartView}
                startDate={contentStartDateView}
                endDate={contentEndDateView}
                pieChartData={pieChartData}
                barChartData={barChartData}
                barChartViewMode={viewBarChartMode}
                totalRevenue={totalSectionData && totalSectionData.totalValidRoyalty ? totalSectionData.totalValidRoyalty : 0}
                currency={CURRENCY_UNIT}
                barChartHeight={totalSectionData && totalSectionData.records && totalSectionData.records.length >=3 && (totalSectionData.records.length * 40 + 300) || 350}
            />
            <WorldMap dataTotalRevennue={dataTotalRevennue}/>
        </div>
    )
};

export default Revenue;
